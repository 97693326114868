import React from "react";
import EntityHeader from "../../utils/EntityHeader.js";

const CustomerHeader = ({ customer, id, headerOptions, destroyMutation, record }) => {
    return (
        <div>
            <EntityHeader
                options={headerOptions}
                baseUrl={`/customers/${parseInt(id)}`}
                entity={customer?.customer}
                destroyMutation={destroyMutation}
                record={record}
            />
        </div>
    );
};

export default CustomerHeader;