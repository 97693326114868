import React from 'react';
import { Drawer, Form, Input, Button } from 'antd';
import axios from 'axios';
import { useMutation, useQuery } from '@apollo/client';
const { CLIENT_CONFIG } = require("../../entities/client-config");
const { create } = require("../../entities/support-ticket");

const SupportDrawer = ({ visible, onClose, currentUserData }) => {
  const { data } = useQuery(CLIENT_CONFIG);

  const [createSupportTicket] = useMutation(create, {
    onCompleted: onClose,
    onError: (e) => { console.log(e) },
  })

  const ZENDESK_USER_EMAIL = data?.clientConfig?.ZendeskUserEmail;
  const ZENDESK_API_KEY = data?.clientConfig?.ZendeskApiKey;

  const onFinish = (values) => {
    createSupportTicket({ variables: { UserId: currentUserData?.currentUser?.id, message: values.help } })
    if (ZENDESK_USER_EMAIL && ZENDESK_API_KEY) {
      axios({
        method: 'post',
        url: 'https://tidal.zendesk.com/api/v2/requests',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(`${ZENDESK_USER_EMAIL}/token:${ZENDESK_API_KEY}`)
        },
        data: {
          request: {
            requester: {
              name: values.name,
              email: currentUserData?.currentUser?.email
            },
            subject: `Support request from ${values.name}`,
            comment: {
              body: `Issue: ${values.help}\n------\nSubmitted from: ${window.location.href}`
            }
          }
        }
      })
        .then(response => {
          console.log(response.data);
          onClose();
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
    else {
      console.log('Please set ZENDESK_USER_EMAIL and ZENDESK_API_KEY in your .env file');
      onClose();
    }
  };
  return (
    <Drawer
      title="Contact us"
      closable={true}
      onClose={onClose}
      visible={visible}
    >
      <Form onFinish={onFinish}>
        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="help" label="How can we help you?" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: 'Please mention your issue' }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button style={{ marginTop: '15px' }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default SupportDrawer;