import React from 'react';
import { Col, Row } from 'antd';

const ipAddress = require('../../entities/ip-address');
const ipAssignment = require('../../entities/ip-assignment');
const zone = require('../../entities/zone');
const supportGroup = require('../../entities/support-group');
const datacenter = require('../../entities/datacenter');
const provider = require('../../entities/provider');
const customer = require('../../entities/customer');
const dns = require('../../entities/dns-entry')
const site = require('../../entities/site');
const contact = require('../../entities/contact');
const resource = require('../../entities/resource');
const request = require('../../entities/request');
const subnet = require('../../entities/subnet');
const roleAssignment = require('../../entities/role-assignment');
const nat = require('../../entities/nat');

const entities = {
    "customers": customer,
    "contacts": contact,
    "zones": zone,
    "sites": site,
    "support-groups": supportGroup,
    "nats" : nat,
    "ip-assignments": ipAssignment,
    "ip-addresses": ipAddress,
    "datacenters": datacenter,
    "providers": provider,
    "support-group-users": supportGroup,
    "dnsentry": dns,
    "resources": resource,
    "requests": request,
    "subnets": subnet,
    "role-assignments": roleAssignment,
    "alerts": {
        importer: {
            title: "Alerting",
            copy: "Alerting is a feature that allows you to create rules that will trigger alerts when certain conditions are met. Alerts can be created for a variety of conditions, such as when a subnet is running out of IP addresses, when a device goes down, or when a device is unreachable."
        }
    },
    "requester": {
      importer: {
        title: "Submit a network request",
        copy: "To create your first request click the 'New Request' button. Your can submit a request for IP addresses on any network that you have been granted access to and it will be reserved until your request is reviewed."
      }
    },
    "imports": {
        importer: {
            title: "Scans",
            copy: "Scan is a network scanning tool that can be used to scan networks for devices and services. It supports multiple scan types, including NMAP and Excel file uploads. You can import the results of a Scans operation into LightMesh to automatically create IP address records for the devices that were found. Once the file is uploaded, it updates the IP addresses within the subnet in the form of IP assignments or reservations."
        }
    },
}

const Info = ({ model, createLink }) => {

    const Model = entities[model];

    return (
        <div className='importer' style={{ overflowX: 'scroll', padding: '20px 28px 60px', minHeight: '500px', marginTop: '70px', background: '#FFFFFF' }}>
            <Row style={{ marginTop: '40px' }}>
                <Col span={14} offset={2}>
                  <h2 style={{ fontSize: '24px', marginTop: '40px', marginBottom: '40px' }}>
                        {Model?.importer?.title}
                    </h2>
                  <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                        {Model?.importer?.copy}
                        <p>
                        </p>
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default Info
