import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import RemoveLink from '../../components/RemoveLink';

import { SubnetBuilder } from '../../components/SubnetBuilder';
import { DESTROY_IP_ASSIGNMENT } from '../ip-assignment';
import { DESTROY_SUBNET } from '../subnet';  
import {Popconfirm} from 'antd';

const searchQuery = loader('./queries/search.graphql')  

const Record = ({ result }) => {
  return (
    <>
      { result.__typename == 'Subnet' &&  (
        <>
          { result?.name }
        </>
      )}

      { result.__typename == 'IPAssignment' && (
        <>
          {result?.interface?.resource?.hostname}
        </>
      )}

      { result.__typename == 'Resource' && (
        <>
          { result?.hostname  }
        </>
      )}
    </>
  )
}


const Icon = ({ result }) => {

  return (
    <>
      { result.__typename == 'Subnet' && (
        <>
          { result?.zone?.provider?.type == 'Azure' && <img style={{width: '16px', marginTop: '0px', marginRight: '5px', marginLeft: '11px'}} src='/images/azure.svg' />  }
          { result?.zone?.provider?.type == 'AWS' && <img style={{width: '30px', marginTop: '3px', marginRight: '5px', marginLeft: '4px'}} src='/images/amazon.svg' />  }
          { result?.zone?.provider?.type  == 'Google' && <img style={{width: '22px', marginTop: '-4px', marginRight: '5px', marginLeft: '12px'}} src='/images/google.svg' />  }
          { ( result?.zone?.provider?.type == 'Datacenter' || !result?.zone?.provider?.type ) && <img style={{width: '18px', marginTop: '2px', marginRight: '12px', marginLeft: '10px'}} src='/images/server2.png' />  }
        </>
      )}
      { result.__typename == 'IPAssignment' && (
        <>
          <img style={{width: '22px', marginTop: '0px', marginRight: '5px', marginLeft: '11px'}} src='/images/azure/compute/10021-icon-service-Virtual-Machine.svg'/>
        </>
      )}
    </>
  )
}

const NetworkAddress = ({ result }) => {
  return (
    <div style={{ fontSize: '1.2em' }}>
      { result.__typename == 'Subnet' && ( result?.networkAddress ) }
      { result.__typename == 'IPAssignment' && ( result?.networkAddress ) }
      { result.__typename == 'Resource' && ( result?.interfaces?.[0]?.ipAssignment?.networkAddress ) }
    </div>
  )
}

const Resource = ({ resource, iface }) => {

   
  return (
    <div >
      <div style={{float: 'left'}}>
        <p style={{fontSize: '11px', marginBottom: '0px'}}>
          <b>{ iface?.name }</b>  
          <br/>
          { iface?.macAddress }
        </p>
      </div>
    </div>
  )
}

const Details1 = ({ result }) => {

  return (
    <>
      { result.__typename == 'Subnet' && (
        <>
          <SubnetBuilder 
            onClick={(e) => { console.log(e) }} 
            active={ result?.id } 
            style={{marginTop: '-25px', width: '39em', marginBottom: '25px'}} 
            showSupernet={ false } 
            id={ result?.id } data={ result } 
            height={ 4 } />
        </>
      )}

      { result.__typename == 'IPAssignment' && (
        <>
          <Resource resource={ result?.interface?.resource } iface={ result?.interface } />
        </>
      )}

      { result.__typename == 'Resource' && (
        <>
          <Resource resource={ result } iface={ result?.interfaces?.[0] } />
        </>
      )}

    </>
  )
}

const Details2 = ({ result }) => {

  return (
    <>
      { result.__typename == 'Subnet' && (
        <>
        </>
      )}
    </>
  )
}

const search = {
  columns:  [
    { title: 'Subnet',  width: '14em', key: 'networkAddress', render: result => <NetworkAddress result={ result } />},
    { title: '', key: 'icon', width: '4em', key: '__typename', render: result => <Icon result={ result } /> },
    { title: '', width: '24em', key: 'record', render: result => <Record result={ result }/> },
    { title: '', key: 'details', width: '40em', render: result => <Details1 result={ result }/>},
    { title: '', key: 'details2', render: result => <Details2 result={ result }/>},
    { title: '', width: 100, key: 'remove', render: d => d.__typename == 'Subnet' ? 
      <RemoveLink record={ d } mutation={ DESTROY_SUBNET }/> :
      <RemoveLink record={ d } mutation={ DESTROY_IP_ASSIGNMENT } />}
  ]
}

export { searchQuery, search };
