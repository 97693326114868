import React, { useState } from 'react';
import { loader } from 'graphql.macro';

import RemoveLink from '../../components/RemoveLink';

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')

const alertSearch = {
    columns: [
      { title: 'Name', dataIndex: 'name', sorter: true},
      { title: 'Alert', dataIndex: 'type'},
      { title: 'Recipient', render: item => `${item.User.firstName} ${item.User.lastName}` },
      { title: 'Description', dataIndex: 'description'},
      { title: 'Status', dataIndex: 'status'},
      { title: '', width: 100, key: 'Destroy', render: alertRule => <RemoveLink record={alertRule} mutation={destroy} /> }
    ]
  }

export { alertSearch, getQuery, searchQuery, create, update, destroy };