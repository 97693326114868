import React, { useState, useEffect } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { Space, message, Popconfirm, Dropdown, Menu } from 'antd';
import { loader } from 'graphql.macro';
import moment from "moment";
import {DownOutlined, EllipsisOutlined} from '@ant-design/icons';

const GET_ORGANIZATIONS = loader('./queries/search.graphql')  
const get = loader('./queries/get.graphql')  
const update = loader('./mutations/update.graphql')  
const GET_LICENSE = loader('./mutations/GET_LICENSE.graphql')  
const REVOKE_LICENSE = loader('./mutations/REVOKE_LICENSE.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const SEED_MUTATION = loader('../auth/mutations/seed.graphql')  

const SeedLink = ({ organization }) => {
  const [seed, { data: seedData }] = useMutation(SEED_MUTATION, { onCompleted: (org) => { console.log(org) }});

  return (
    <a onClick={(e) => {
      e.stopPropagation()
      seed({ variables: { id: parseInt(organization?.id)}})
    }}> Reseed data </a>
  )
}

const DestroyLink = ({ organization }) => {
  const [seed, { data: seedData }] = useMutation(SEED_MUTATION, { onCompleted: (org) => { console.log(org) }});
  const [destroyOrganization, { data: destroyData }] = useMutation(destroy, { onCompleted: (org) => { console.log(org) }});


  return (
    <Popconfirm
      title="ARE YOU SURE you want to delete this organization???"
      onConfirm={() => destroyOrganization({ variables: { id: parseInt(organization?.id)}})}
      onCancel={() => { console.log('cancelled')}}
      okText="Yes... muahahahahahahahah"
      cancelText="No.  Oopsies">
      <a href="#"> Destroy </a>
    </Popconfirm>
  )
}

const License = ({ organization }) => {

  const [updateOrganization, { data: updateData }] = useMutation(GET_LICENSE, { onCompleted: (org) => { console.log(org) }});
  const [showKey, setShowKey] = useState(false)
  const [revokeLicense, { data: revokeData }] = useMutation(REVOKE_LICENSE, { onCompleted: (org) => { console.log(org) }});

  return (
    <>
      {/** HAS KEY **/}
      { organization?.licenseKey && !showKey && (
        <>
          <Space>
            <a onClick={(e) => { setShowKey(true) }}> Reveal Key </a>
            <a onClick={(e) => { revokeLicense({ variables: { id: organization?.id }})}}> Revoke Key </a>
          </Space>
        </>
      )}

      {/** SHOWED KEY **/}
      { organization?.licenseKey && showKey && (
        <>{ organization.licenseKey }</>
      )}

      {/** NEEDS KEY **/}
      { !organization?.licenseKey && (
        <a onClick={(e) => { updateOrganization({ variables: { id: organization?.id }}) }}>
          create License
        </a>
      )}
    </>
  )

}

const actions = (organization) => { 
  return (
    <Menu>
      <Menu.Item key={ organization?.id }><SeedLink organization={ organization } /></Menu.Item>
      <Menu.Item key={ organization?.id }><License organization={ organization } /></Menu.Item>
      <Menu.Item key={ organization?.id }><DestroyLink organization={ organization } /></Menu.Item>
    </Menu>
  )
}

const search = {
  query: GET_ORGANIZATIONS,
  columns: [
  { title: 'Date', dataIndex: 'createdAt', key: 'createdAt', width: '18em', render: createdAt => <span>{ moment(parseInt(createdAt)).format("MMM D, YYYY") } </span> },
  { title: 'Name', dataIndex: 'name', key: 'name', width: '12em', sorter: true},
  { title: 'Admin', dataIndex: 'users', key: 'name', width: '12em', render: users => <>{ users?.[0]?.firstName + ' ' +  users?.[0]?.lastName}</>},
  { title: 'Email', dataIndex: 'users', key: 'name', width: '20em', render: users => <>{ users?.[0]?.email }</>},
  { title: 'Last Login', dataIndex: 'users', key: 'lastLogin', width: '10em', sorter: (a, b) => {
    let userALastLogin
    let userBLastLogin
    if(a.users?.[0]?.lastLogin || b.users?.[0]?.lastLogin) {
      userALastLogin = a.users?.[0]?.lastLogin || 0;
      userBLastLogin = b.users?.[0]?.lastLogin || 0;
    }
    else {
      userALastLogin = a.users?.[0]?.createdAt || 0;
      userBLastLogin = b.users?.[0]?.createdAt || 0;
    }
    return userALastLogin - userBLastLogin;
  }, render: (users) => {
    let d
    if(users?.[0]?.lastLogin) {
        d = new Date(parseInt(users?.[0]?.lastLogin))
    } else {
        d = new Date(parseInt(users?.[0]?.createdAt))
 }

    return (<p>{ d.toLocaleDateString() + ' ' + d.toLocaleTimeString() }</p>)
}},
  { title: 'Login Count', dataIndex: 'users', key: 'loginCount', width: '10em', sorter: (a, b) => {
    const userALoginCount = a.users?.[0]?.loginCount || 0;
    const userBLoginCount = b.users?.[0]?.loginCount || 0;
    return userALoginCount - userBLoginCount;
  }, render: users => <>{ users?.[0]?.loginCount }</>},
  { title: 'Subnets', dataIndex: 'subnetCount', key: 'subnetCount', width: '10em'},
  { title: 'IP Addresses', dataIndex: 'ipCount', key: 'ipCount', width: '10em'},
  { title: 'Reservations', dataIndex: 'reservationCount', key: 'reservationCount', width: '10em'},
  { title: 'Users', dataIndex: 'users', style: { textAlign: 'right' }, key: 'name', width: '10em', render: users => <>{ users?.length || 0 }</>},
  { title: ' ', key: 'id', width: '4em', render: organization => {
    return (
      <Dropdown overlay={actions(organization)} trigger={['click']} placement='bottomRight' onClick={e => { e.stopPropagation()}}>
        <a><EllipsisOutlined /></a>
      </Dropdown>
    ) }}
]}

export { GET_ORGANIZATIONS, GET_LICENSE, REVOKE_LICENSE, search, get, update };
