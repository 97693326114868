import React from 'react'
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import RemoveLink from '../../components/RemoveLink';

import { Servers } from '../../components/pants-d/CustomIcons';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  
const sync = loader('./mutations/sync.graphql')  

const ProviderLogo = ({ type, providerStyle }) => {
  return (
    <>
      {type == 'AWS' && (<img style={{ width: '30px', marginLeft: '0px', marginRight: '2px', ...(providerStyle?.['AWS'] || []) }} src="/images/amazon.svg" /> )}
      {type == 'Azure' && (<img style={{ width: '16px', marginLeft: '6px', marginRight: '9px', ...(providerStyle?.['Azure'] || []) }} src="/images/azure.svg" /> )}
      {type !== 'Azure' && type !== 'AWS' && (
          <Servers style={{ 
            color: '#5384ac', 
            width: '14px', 
            paddingTop: '1px', 
            display: 'inline-block', 
            float: 'left', 
            marginLeft: '3px', 
            marginRight: '11px',
            ...(providerStyle?.['Datacenter'] || []) }} /> 
      )}
    </>
  )

}

const RemoveProviderLink = ({ id }) => {
  const [removeProvider, { data: updateData }] = useMutation(destroy, { 
    onCompleted: (v) => { console.log("completed") },
    update(cache, { data }) {
      cache.evict(`Provider:${id}`);
    }
  });

  return (
    <a onClick={(e) => {
      e.stopPropagation();
      removeProvider({variables: {id: parseInt(id)}})

      }}>
      Remove
    </a>
  )
}

const search = {
  columns:  [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
    { title: '', width: 100, key: 'remove', render: dc => <RemoveLink record={ dc } mutation={ destroy }/> }
  ]
}

const form = {
  fields: [
    ["name", {}]
  ]
}

export { search, form, searchQuery, getQuery, create, update, destroy, sync, ProviderLogo };
