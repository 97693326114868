import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import cidrRegex from 'cidr-regex';

const { Option } = Select;

const Netmask = ({ subnet, onSelect, style, className }) => {
  const [childNetmasks, setChildNetmasks] = useState([]);

  const isV4 = cidrRegex.v4({ exact: true }).test(subnet)
  let parentNetmask

  useEffect(() => {
    const availableChildNetmasks = [];
    if(isV4){
    parentNetmask = parseInt(subnet.split('/')[1])
    for (let i = parentNetmask + 1; i <= 31; i++) {
      availableChildNetmasks.push(i);
    }

    setChildNetmasks(availableChildNetmasks);
  }
  else {
    parentNetmask = parseInt(subnet.split('/')[1])
    for (let i = parentNetmask + 1; i <= 127; i++) {
      availableChildNetmasks.push(i);
    }

    setChildNetmasks(availableChildNetmasks);
  }
  }, [parentNetmask]);

  const formatNumberWithMagnitude = (number) => {
    if (number >= 1000000) {
      return Math.floor(number / 1000000) + 'M';
    } else if (number >= 1000) {
      return Math.floor(number / 1000) + 'k';
    } else {
      return number.toString();
    }
  };
  

  const calculateNumberOfHosts = (netmaskLength) => {
    if (isV4) {
      const numberOfHosts = Math.pow(2, 32 - netmaskLength) - 2;
      return formatNumberWithMagnitude(numberOfHosts);
    } else {
      const numberOfHosts = Math.pow(2, 128 - netmaskLength) - 2;
      return formatNumberWithMagnitude(numberOfHosts);
    }
  };


  return (
    <div className={className} style={style}>
      <Select
        size="small"
        showSearch
        style={{ width: '140px', textAlign: 'right' }}
        defaultValue={childNetmasks[0]}
        onSelect={(value) => {
          onSelect(value);
        }}
      >
        {childNetmasks.map((netmask) => (
          <Option key={netmask} value={netmask}>
            /{netmask} ({calculateNumberOfHosts(netmask)} hosts)
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Netmask;