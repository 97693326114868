const v6Count ={
128: '1',
127: '2',
126: '4',
125: '8',
124: '16',
123: '32',
122: '64',
121: '128',
120: '256',
119: '512',
118: '1024',
117: '2048',
116: '4096',
115: '8192',
114: '16384',
113: '32768',
112: '65536',
111: '131072',
110: '262144',
109: '524288',
108: '1048576',
107: '2097152',
106: '4194304',
105: '8388608',
104: '16777216',
103: '33554432',
102: '67108864',
101: '134217728',
100: '268435456',
99: '536870912',
98: '1073741824',
97: '2147483648',
96: '4294967296',
95: '8589934592',
94: '17179869184',
93: '34359738368',
92: '68719476736',
91: '137438953472',
90: '274877906944',
89: '549755813888',
88: '1099511627776',
87: '2199023255552',
86: '4398046511104',
85: '8796093022208',
84: '17592186044416',
83: '35184372088832',
82: '70368744177664',
81: '140737488355328',
80: '281474976710656',
79: '562949953421312',
78: '1125899906842624',
77: '2251799813685248',
76: '4503599627370496',
75: '9007199254740992',
74: '18014398509481985',
73: '36028797018963968',
72: '72057594037927936',
71: '144115188075855872',
70: '288230376151711744',
69: '576460752303423488',
68: '1152921504606846976',
67: '2305843009213693952',
66: '4611686018427387904',
65: '9223372036854775808',
64: '18446744073709551616',
63: '36893488147419103232',
62: '73786976294838206464',
61: '147573952589676412928',
60: '295147905179352825856',
59: '590295810358705651712',
58: '1180591620717411303424',
57: '2361183241434822606848',
56: '4722366482869645213696',
55: '9444732965739290427392',
54: '18889465931478580854784',
53: '37778931862957161709568',
52: '75557863725914323419136',
51: '151115727451828646838272',
50: '302231454903657293676544',
49: '604462909807314587353088',
48: '1208925819614629174706176',
47: '2417851639229258349412352',
46: '4835703278458516698824704',
45: '9671406556917033397649408',
44: '19342813113834066795298816',
43: '38685626227668133590597632',
42: '77371252455336267181195264',
41: '154742504910672534362390528',
40: '309485009821345068724781056',
39: '618970019642690137449562112',
38: '1237940039285380274899124224',
37: '2475880078570760549798248448',
36: '4951760157141521099596496896',
35: '9903520314283042199192993792',
34: '19807040628566084398385987584',
33: '39614081257132168796771975168',
32: '79228162514264337593543950336',
31: '158456325028528675187087900672',
30: '316912650057057350374175801344',
29: '633825300114114700748351602688',
28: '1267650600228229401496703205376',
27: '2535301200456458802993406410752',
26: '5070602400912917605986812821504',
25: '10141204801825835211973625643008',
24: '20282409603651670423947251286016',
23: '40564819207303340847894502572032',
22: '81129638414606681695789005144064',
21: '162259276829213363391578010288128',
20: '324518553658426726783156020576256',
19: '649037107316853453566312041152512',
18: '1298074214633706907132624082305024',
17: '2596148429267413814265248164610048',
16: '5192296858534827628530496329220096',
15: '10384593717069655257060992658440192',
14: '20769187434139310514121985316880384',
13: '41538374868278621028243970633760768',
12: '83076749736557242056487941267521536',
11: '166153499473114484112975882535043072',
10: '332306998946228968225951765070086144',
9: '664613997892457936451903530140172288',
8: '1329227995784915872903807060280344576',
}

export default v6Count;
