import { loader } from 'graphql.macro';
import { useMutation, useApolloClient } from '@apollo/client';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const RemoveDnsLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="DNS"
        entityTitle="DNS"
        redirectPath={`/dns`}
      />
    </div>
  )
}

const search = {
  columns:  [
    { title: 'TYPE', dataIndex: 'recordType', key: 'type'},
    { title: 'Value', dataIndex: 'name', sorter: true},
    { title: 'TTL', dataIndex: 'ttl', key: 'ttl'},
    { title: '', width: 100, key: 'remove', render: entry => <RemoveDnsLink record={ entry }/> }
  ]
}

const form = {
  fields: [
    ["code", {}],
    ["name", {}]
  ]
}

const importer = {
  title: 'DNS',
  copy: 'DNS (Domain Name System) in Lightmesh is a critical component that associates human-readable domain names with IP addresses. It enables automatic management of DNS records, ensuring that when devices are assigned IP addresses, corresponding DNS records are created or updated. This integration simplifies network administration, reduces errors, and ensures accurate domain name resolution in a network.',
}

export { search, form, searchQuery, getQuery, create, update, destroy, importer };
