import React, {useState} from 'react';
import { Row, Col, Popover, Space } from 'antd';
import _, { unionWith, flattenDeep, range } from 'lodash';
import { ArrowUpOutlined, CaretUpFilled, CaretDownFilled, CloseCircleFilled } from '@ant-design/icons';
import cidrRegex from 'cidr-regex';
import { useHistory } from 'react-router';

const { Address4, Address6 } = require('ip-address');
const { BigInteger } = require('jsbn');

const parsedSubnet = (subnet) => {

  if (!subnet) { return {}}
  if (cidrRegex.v4({exact: true}).test(subnet)) {
    return new Address4(subnet)
  } else {
    return new Address6(subnet)
  }
}

const getWidth = ( subnet, supernet ) => {

  const netmask = parsedSubnet( subnet.networkAddress ).subnetMask;
  const base = parsedSubnet( (supernet?.networkAddress || subnet?.networkAddress) ).subnetMask;

  const denominator = Math.pow(2, (netmask - base))

  if (denominator == 0) {
    return 100
  } else {
    return( 100 / denominator )
  }
}


const subnetOffset = ( subnet, supernet ) => {


  const supNet = parsedSubnet( supernet?.networkAddress || subnet.networkAddress );
  const subNet = parsedSubnet( subnet.networkAddress );

  const v4 = cidrRegex.v4({exact: true}).test(subnet.networkAddress)

  const supernetSize = v4 ? Math.pow(2, 32 - supNet.subnetMask) : Math.pow(2, 64 - supNet.subnetMask)

  const offset = (subNet.bigInteger()?.subtract(supNet.bigInteger())) / supernetSize * 100;

  return offset;

}


export const possibleSubnets = ( supernet, netmask ) => {

  const snet = parsedSubnet( supernet.networkAddress );

  if (netmask && (netmask > snet.subnetMask) && (netmask <= 32)) {

    const supernetAddress = snet.bigInteger();
    const supernetSize = Math.pow(2, 32 - snet.subnetMask);
    const subnetSize = Math.pow(2, 32 - netmask);

    let result = [];

    let i = 0;

    while ( i < supernetSize ) {

      const address = Address4.fromBigInteger(supernetAddress.add(new BigInteger(i.toString())) + "/" + netmask).address

      result.push({ 
        networkAddress: address + "/" + netmask,
        supernetId: supernet.id,
        netmask: netmask
      })
      
      i += subnetSize;
    }

    return result;

  } else {

    return [];

  }
}

const inSubnet = (s1, s2) => {
  const subnet1 = new Address4((s1.networkAddress));
  const subnet2 = new Address4(s2.networkAddress);

  return subnet1.isInSubnet(subnet2) || subnet2.isInSubnet(subnet1);
}

const SupernetBlock = ({ data, onClick }) => {
  const history = useHistory();

  let text
  let url

  if ( data ) {
    text = (data.name || data.networkAddress)
    url = `/subnets/${data.id}/${data.id}`
  } else {
    text = "All Subnets"
    url = '/subnets'
  }
  

  return(
    <div className={ "supernet-block" } onClick={(e) => { history.push(url) }} >
      <div className={ "supernet-body" }>
        <div className="icon-wrapper up-icon" style={{ background: 'none !important'}}>
          <ArrowUpOutlined style={{ fontSize: '10px', textAlign: 'center', color: '#b7cee1', display: 'block', margin: 'auto', marginTop: '2px' }}/>
        </div>
        { text }
      </div>
    </div>
  )
}

const SubnetBlock = ({ data, id, editing, editingNetmask, depth, totalWidth = 100, refetch = 0, width, showSupernet, supernet, onClick, onDblClick, onCreate, onDestroy, height, blockStyle }) => {

  const snets = editing ? 
    unionWith(data.subnets || [], possibleSubnets(data, editingNetmask), inSubnet) :
    (data?.subnets || [])
  const subnetWidth = getWidth(data, supernet);
  const remainingWidth = subnetWidth * totalWidth / 100;
  const netmask = data?.networkAddress.split("/")[1]


  const getTop = () => {
    // Top
    if ((depth == 0 || !depth) && !showSupernet ) {
      return 30 + ( height / 2) * -1
    } else {
      return height + 5
  }}

  const subnetStyle = { 
     ...blockStyle,
    left: subnetOffset(data, supernet) + "%", 
    top: getTop() + "px",
    maxHeight: height + "px",
    height: height + "px",
    width: subnetWidth + "%"
  }

  let clickFn = () => {
    if (data.id) {
      onClick(data);
    } else {
      onCreate({ variables: {...data, supernetId: supernet.id }});
  }}

  const popOvercontent = (
    <div style={{marginBottom: 0}}>
      {data?.name && <p>{data.name}</p>}
      <p>{data?.networkAddress}</p>
    </div>
  );

  const subnetProps = {
    className: "subnet-body " + ( data.id ? "" : "new-record " ) + ( id == data.id ? "active" : "" ),
    style: { height: height + "px", overflow: "hidden", ...blockStyle },
    onDoubleClick: (e) => (data?.id && onDblClick) ? onDblClick(data) : null,
    onClick: (e) => { if (e.detail === 2 && onDblClick) { onDblClick(data) } else { clickFn() }}
  }

  return (
    <div className="subnet-block" style={subnetStyle}>
      { (remainingWidth < 10) && (
        <Popover placement="top" trigger="hover" content={ popOvercontent } mouseEnterDelay={ 0.3 }>
          <div {...subnetProps} >
            { data?.name?.length > 0 && 
            <>
              { data.name }
              <br/>
            </>
            }
            { ( 2.5 < remainingWidth ) ? ("/ " + netmask) : "" }
          </div>
        </Popover>
      )}
      
      { remainingWidth >= 10 && (

      <div {...subnetProps}>
        { data?.name?.length > 0 && 
          <>
            { data.name }
            <br/>
          </>
        }

        {`${data?.networkAddress?.replace('/', ' / ')}`}
        <div style={{ float: 'right', marginRight: '2px', marginTop: '-2px' }}>{`${ data?.id ? '' : '+ '}`}</div>
      </div>

      )}

      { (data?.id && editing && onDestroy ) && (
        <CloseCircleFilled 
          fill='white'
          height='10px'
          width='10px'
          style={{ 
            position: 'absolute', 
            top: '8px', 
            right: '10px', 
            color: '#FFFFFF', 
            fontWeight: 'bold',
            opacity: '0.8',
            hover: {
              opacity: '1'
            }
          }}
          onClick={(e) => {

            onDestroy(data)

          }} />
      )}
      { 
        snets.map((subnet) => {
          return ( 
            <SubnetBlock 
              id={ id }
              editing={ editing }
              data={ subnet } 
              depth={ (depth || 0) + 1 } 
              onCreate={ onCreate } 
              onDblClick={ onDblClick }
              onClick={ onClick }
              supernet={ data } 
              editingNetmask={ editingNetmask } 
              height={ height }
              onDestroy={ onDestroy }
              totalWidth={ subnetWidth / 100 * totalWidth }
              key={ subnet?.id }
              blockStyle={ blockStyle }
            /> );
        })
      }
    </div>
  )
}

const getTotalHeight = ( data, height ) => {

  const getSubnets = (data, acc) => {

    const children = data?.subnets?.map((child) => {

      if (child.subnets?.length > 0) {
        return getSubnets(child, acc + 1);
      } else {
        return acc;
      }

    })

    if (children?.length > 0) {
      return Math.max(...flattenDeep(children))
    } else {
      return 0
    }
  }

  return (getSubnets(data, 1) + 2) * height + 30;
}

const NetmaskSelector = ({ netmask, setNetmask, min }) => {

  const caretStyle = { fontSize: '16px', color: '#3d75a4' }

  const netmasks = range(min + 1, Math.min(min + 6, 33));
  return(
      <Space size={ 2 } direction='vertical' style={{ width: '160px', height: '30px'}}>
        { netmasks.map(n => {
          return (
            <div
              className='rounded-6'
              style={{ 
                padding: '1px', 
                marginLeft: '6px', 
                marginBottom: '2px',
                border: '2px solid #729ec0',
                borderColor: netmask == n ? '#729ec0' : '#d9e7f2',
                background: '#d9e7f2',
                color: netmask == n ? '#3f6a8d' : '#81a9ca',
                fontWeight: netmask == n ? 'bold' : 'normal',
                width: '160px',
                lineHeight: '18px',
                height: '30px',
                cursor: 'pointer'
              }} 
              onClick={() => setNetmask(n)}>
              <div style={{ float: 'left', padding: '2px 1px', marginLeft: '3px', fontWeight: 'bold', width: '35px' }}>/{ n }</div> 
              <div style={{ float: 'left', padding: '2px 6px', textAlign: 'right', width: '110px'}}> { Math.pow(2, (32 - n)) } hosts </div>
            </div>
          )})}
      </Space>
  )
}

const V4 = ({ title, onClick, onDblClick, onCreate, onDestroy, editing, data, active, showSupernet = true, height = 25, style = {}, blockStyle = {}, hideEditor = false }) => {
  const history = useHistory();

  const netmask = parseInt(data?.networkAddress?.split('/')?.[1])
  const defaultNetmask = hideEditor ? null : netmask + 1

  const [editingNetmask, setEditingNetmask] = useState(defaultNetmask)

  const totalHeight = getTotalHeight(data, height)

  return (
    <div className="subnet-builder" style={{...style, height: (totalHeight > 140 ? totalHeight : 140)  + "px" }}>

      { editing && (
      <Row>
        <Col span={8}>
          { title }
        </Col>
      </Row>
      )}
      <Row>
        <Col flex={ 1 }>
          { showSupernet && (<SupernetBlock data={ data.supernet } onClick={ onClick } />) }
          <SubnetBlock 
            id={ active } 
            data={ data } 
            editing={ editing }
            onDblClick={ onDblClick }
            onClick={ onClick ? onClick : function(s) { history.push(`/subnets/${s.id}`) }} 
            //onDestroy={ onDestroy }
            onCreate={ onCreate } 
            editingNetmask={ editingNetmask || defaultNetmask } 
            showSupernet={ showSupernet }
            height={ height }
            key={ data?.id }
            blockStyle={ blockStyle }
          />
        </Col>
        { editing && !hideEditor && (
          <Col style={{ width: '180px', textAlign: 'center' }}>

            <NetmaskSelector netmask={ editingNetmask } setNetmask={ setEditingNetmask } min={ netmask } />

          </Col>
        )}
     </Row>
    </div>
  )
}

export default V4;
