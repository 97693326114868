import React from 'react'
import { loader } from 'graphql.macro';
const { ProviderLogo } = require('../provider')
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const RemoveCloudProviderLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="CloudAccount"
        entityTitle="Cloud Account"
        redirectPath={`/cloud-accounts`}
      />
    </div>

  )
}


const search = {
  columns:  [
    { title: 'Name', key: 'name', width: 400, render: p => <>
      <div style={{ width: '40px', marginRight: '3px', display: 'inline-block', float: 'left' }}>
        <ProviderLogo 
          type={ p?.type } 
          providerStyle={{ 
            'AWS': { width: '30px', marginTop: '1px', },
            'Azure': { width: '15px', marginLeft: '7px' },
            'Datacenter': { marginTop: '1px', width: '12px' }
          }}/>
      </div>
      { p?.name}
    </>},
    { title: 'ID', key: 'id', width: 200, render: p => <>
      { p?.type == 'Azure' && p.azureAppId }
      { p?.type == 'AWS' && p.awsSecretAccessKey }
      </>
    },
    { title: 'Subnet count', key: 'id', width: 140, align: 'right', render: p => <> { p?.subnetCount } </> },
    { title: 'Resource count', key: 'id', width: 140, align: 'right', render: p => <> { p?.resourceCount } </> },
    { title: 'Last synced at', width: 200, align: 'right', key: 'lastSynchronizedAt' , render: (provider) =>  {

      const d = new Date(parseInt(provider?.lastSynchronizedAt || provider?.createdAt ))

      return (<p style={{ marginBottom: '0px'}}>{ d.toLocaleDateString() + ' ' + d.toLocaleTimeString() }</p>)

    }},
    { title: '', width: '50px', key: 'remove', render: provider =>  <div>
      <RemoveCloudProviderLink record={ provider }/>
    </div>}
  ]
}

const form = {
  fields: [
    ["name", {}]
  ]
}

const importer = {
  title: 'Data Centers',
  copy: 'Data Centers in Lightmesh are specialized facilities where computer servers, networking equipment, and data storage systems are housed. Lightmesh assists in efficiently managing and organizing IP addresses within these data centers. It helps allocate and monitor IP addresses for devices and services, ensuring smooth and organized network operations within the data center environment.',
}

export { search, form, searchQuery, getQuery, create, update, destroy, importer };
