import cidrRegex from 'cidr-regex';
import utils from './utils.js';
import V4 from './V4.js';
import V6 from './V6.js';
import NetmaskForm from './NetmaskForm.js';
import LocalBuilder from './LocalBuilder.js'


const SubnetBuilder = ({ data, local, ...props }) => {

  const isV4 = cidrRegex.v4({exact: true}).test( data?.networkAddress )


  if (data || local) { 
    return (
      <>
        { local && <LocalBuilder data={ data } {...props } /> }
        { isV4 && <V4 data={ data } { ...props }  /> }
        { !isV4 && <V6 data={ data } { ...props }  /> }
      </>
    )
  } else {
    return ( <></> )
  }
}



export { SubnetBuilder, NetmaskForm, utils }
