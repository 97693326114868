import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import { Form, Layout, Button, Drawer } from "antd";

import SearchForm from "../../components/SearchForm";
import SearchAction from "../../components/SearchAction";
import SearchTable from "../../components/SearchTable";
import IPAssignmentForm from "../../views/ip-assignments/Form";
import SearchLayout from "../layout/Search.js";
import Entity from "../../entities";
import {PlusOutlined} from "@ant-design/icons";
import GettingStarted from '../account/GettingStarted';

const { Content } = Layout;

const ipAssignment = Entity["ip-assignment"];

const IPAssignments = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == "new";

  return (
    <SearchLayout section='IP Assignments'>
      <SearchForm placeholder='Search IP Assignments' modelName={IPAssignments} />
      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/ip-assignments/new'
        style={{marginTop: '12px'}}>
        Add IP Assignment
      </SearchAction>
      <SearchTable
        style={{ marginTop: '70px' }}
        query={ipAssignment.searchQuery}
        {...ipAssignment.search}
        onAdd={() => {
          history.push("/ip-assignments/new");
        }}
        onClick={(record) => {
          history.push(`/ip-assignments/${record?.id}`);
        }}
        emptyRender={ <GettingStarted style={{ margin: 'auto', width: '1200px'}} /> }
        modelName="ipAssignments"
        />

      {(id || newRecord) && (
        <IPAssignmentForm
          id={id}
          onClose={() => {
            history.push("/ip-assignments");
          }}
        />
      )}
    </SearchLayout>
  );
};

export default IPAssignments;
