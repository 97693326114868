import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Alert, Popconfirm } from 'antd';
import _ from 'lodash';
import RemoveLink from '../../components/RemoveLink';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')
const importRecords = loader('./mutations/import.graphql')

const RemoveSiteLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="site"
        entityTitle="site"
        redirectPath={`/sites`}
      />
    </div>

  )
}

const Address = ({ address }) => {
  const fields = _.pick(address, ['line1', 'line2', 'municipality', 'province', 'country', 'postalCode']);

  const fullAddress = _.compact(Object.values(fields)).join(', ')

  return (
    <> {fullAddress} </>
  )
}


const search = {
  query: searchQuery,
  columns: [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
    { title: 'Address', key: 'name', render: site => <Address address={site?.addresses?.[0]} /> },
    { title: '', width: 100, key: 'remove', render: site => <RemoveSiteLink record={ site }/> }
  ]
}


const importer = {
  exampleSheet: 'SitesExample',
  search: {
    query: searchQuery,
    columns: [
      { title: 'Name', key: 'name', dataIndex: 'name' },
      { title: 'Address', key: 'name', render: site => <Address address={site?.addresses?.[0]} /> },
      { title: '', width: 100, key: 'remove', render: site => <RemoveLink record={site} mutation={ destroy } /> }
    ]
  },
  mutation: importRecords,
  fields: [
    { name: 'name', required: true }
  ],
  title: 'Sites',
  copy: 'Sites in Lightmesh are any logical grouping of physical devices.  They can be tied to addresses, contacts, subnets, and zones.  that is able to use your parts of your network.  They can be a great place to start with network design, cost estimation, and support.',
  note: (
    <Alert
      style={{ marginTop: '10px' }}
      message={
        <div dangerouslySetInnerHTML={{ __html: "<strong>Name</strong> is a required field." }} />
      }
      type="info"
      showIcon
    />
  )
}

export { search, importer, getQuery, searchQuery, create, destroy, update };
