import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Entity from "../../entities";
import CustomerHeader from "./CustomerHeader.js";
import { Content } from "antd/lib/layout/layout.js";
import { Subnav } from "../layout/Subnav.js";
import EntityBreadcrumb from "../../utils/EntityBreadcrumb.js";
import { Route } from "react-router-dom/cjs/react-router-dom.min.js";
import EntityDetails from "../../utils/EntityDetails.js";
import { Switch, useRouteMatch } from "react-router-dom/cjs/react-router-dom.js";
import CustomerSubnet from "./CustomerSubnet.js";

const customer = Entity["customer"];

const Customer = () => {
    const { id } = useParams();
    const [editing, setEditing] = useState(false);
    const { path } = useRouteMatch();

    const { data: customers, error: customerError, loading: customerLoading } = useQuery(customer.getQuery, { variables: { id: parseInt(id) } });

    const customerFields = [
        { name: 'name', label: 'Name' },
        {
            name: 'addresses',
            label: 'Addresses',
            fields: [
                { name: 'line1', label: 'Line 1' },
                { name: 'municipality', label: 'City' },
                { name: 'province', label: 'Province' },
                { name: 'postalCode', label: 'Postal Code' },
                { name: 'country', label: 'Country' }
            ]
        }
    ];

    const headerOptions = [
        { label: 'Summary', value: 'summary', style: { fontSize: '10px !important', width: '83px', textAlign: 'center' } },
        { label: 'Subnets', value: 'subnets', style: { fontSize: '10px !important', width: '83px', textAlign: 'center' } },
    ];

    return (
        <>
            <Subnav class="sub-nav" style={{ padding: '1 10px 1px 0px' }}>
                <EntityBreadcrumb entity="Customers" customerName={customers?.customer?.name} />
            </Subnav>
            <Content style={{ padding: '40px 25px' }}>
                {customers ? (
                    <div>
                        <CustomerHeader customer={customers} id={id} headerOptions={headerOptions} style={{ marginTop: '0px' }} destroyMutation={customer?.destroy} record={customers?.customer} />
                        <Switch>
                            <Route exact path={`${path}`}>
                                    <EntityDetails
                                        entity={customers?.customer}
                                        entityName="Customers"
                                        setEditing={setEditing}
                                        fields={customerFields}
                                        mutation={customer.update}
                                        recordId={parseInt(id)}
                                        headerOptions={headerOptions}
                                    />
                            </Route>
                            <Route exact path={`${path}/subnets`}>
                                <div>
                                    <CustomerSubnet customerId={parseInt(id)} />
                                </div>
                            </Route>
                        </Switch>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </Content>
        </>
    );
};

export default Customer;