import React, { useState } from 'react';
import { Divider, Space, Form, Input, Button, Alert, Row, Col } from 'antd';
import {  useMutation, useApolloClient } from '@apollo/client';
import _ from 'lodash';

import { TextInput, PasswordInput, Submit } from '../../components/pants-d';
import AuthLayout from '../layout/Auth.js';

import Providers from './providers/Providers.js';

const { LOGIN_MUTATION, CURRENT_USER_QUERY } = require('../../entities/auth')


function Login() {

  const searchParams = window?.location?.search?.slice(1)

  const isInvite = searchParams.split('=')?.[0] == 'invite'

  const client = useApolloClient();

  const [login, { data, error }] = useMutation(LOGIN_MUTATION, {
    onCompleted: ({ login }) => { const query = {
        query: CURRENT_USER_QUERY,
        variables: {}
      }
      client.writeQuery({
        ...query,
        data: { currentUser: login.user}
      }) 
    },
    onError: ( error ) => { console.log( error ); }
  })

  const [form] = Form.useForm();

  return(
    <AuthLayout>
      <h1 style={{textAlign: 'center', marginBottom: '20px', fontWeight: '600', fontSize: '20px', color: '#a45fa5'}}>
        { isInvite ? 'Accept Invite' : 'Login' }
      </h1>

      { isInvite && (
        <h2 style={{ textAlign: 'center', marginBottom: '40px', fontSize: '15px' }}>
          Login to your account to accept your invite
        </h2>
      )}

      <div style={{ padding: '0px 35px 35px' }}>
      <Row gutter={12}>
        <Col span={24}>
            <Form
              layout="vertical"
              form={ form }
              onFinish={ (values) => login({ variables: { ...values }}) }>

              { error && 
              <>
                <Alert message="Invalid Login" type="error" />
                <br />
              </>
              }
              <Row gutter={12}>
                <Col span={24}>
                  <TextInput required name="email" />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <PasswordInput required name="password" />
                </Col>
              </Row>
              <Row style={{ marginTop: '20px'}}>
                <Col span={24}>
                  <Submit label="Login" />
                </Col>
              </Row>
            </Form>
        </Col>
      </Row>
        <Divider style={{ fontSize: '12px', color: '#AAAAAA', marginBottom: '40px' }}>Or Continue With</Divider>
        <Providers />
      </div>

      <Row>
        <Col span={ 12 } offset={ 5 }>
          {['register', 'forgot_password'].map(link => {
            return (
              <Button style={{ width: '50%', textAlign: 'center', fontSize: '14px' }} type="link" htmlType="button" href={`/${link}`}>
                { _.startCase(link) }
              </Button>
            )
          })}
        </Col>
      </Row>
    </AuthLayout>
  )
}

export default Login;
