import React from 'react'
import { loader } from 'graphql.macro';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const search = {
  columns:  [
    { title: 'Hostname', dataIndex: 'hostname', key: 'hostname', sorter: true},
    { title: 'Interface', key: 'interface', render: resource => <>{ resource?.interface?.name || 'default' }</>, sorter: true },
  ]
}

const form = {
  fields: [
    ["code", {}],
    ["name", {}]
  ]
}

export { search, form, searchQuery, getQuery, create, update, destroy };
