import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useReactiveVar } from '@apollo/client';
import { activeUserVar } from '../../cache';

import SearchTable from '../../components/SearchTable';
import SearchForm from '../../components/SearchForm';
import SearchAction from "../../components/SearchAction";
import SearchLayout from '../layout/Search.js';
import UserForm from '../../views/users/Form';
import {PlusOutlined} from "@ant-design/icons";
import { user } from '../../entities';

const { RemoveLink } = user;

const Users = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const [refetch, setRefetch] = useState()


  const activeUser = useReactiveVar(activeUserVar)

  return (
    <SearchLayout section='Users'>
      <SearchForm placeholder='Search users' modelName={Users} />

      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/users/new'
        style={{marginTop: '12px'}}>
        Invite A User
      </SearchAction>
      <SearchTable 
        style={{ marginTop: '70px' }}
        query={ user.searchQuery }
        { ...user.search }
        columns={[
          ...user.search.columns,
        ]}
        onAdd={() => { 
          history.push('/users/new') 
        }}
        refetch={ refetch }
        onClick={(record) => { 
        (record?.User?.email !== activeUser?.email) && history.push(`/users/${record?.User?.id || record?.id}`) }}
        modelName='users' />

      { id && ( <UserForm id={ id } refetch={() => setRefetch(refetch + 1)} /> ) } 
    </SearchLayout>
  )
};

export default Users;
