import React from 'react'
import Entity from '../../entities';
import { useQuery, useMutation} from "@apollo/client";

const Label = ({ name, children }) => {
  return (

    <div class="ant-form ant-form-vertical ant-form-small">
      <div className='ant-form-item-label'>
        <label>{ name }</label>
        <br />
        <div className="form-value">{ children } </div>
      </div>
    </div>
  )
}

export default Label;
