import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const SEARCH_IMPORTS = loader('./queries/search.graphql')  
const GET_IMPORT = loader('./queries/get.graphql')  
const GET_IMPORT_STATS = loader('./queries/stats.graphql')  

const CREATE_IMPORT = loader('./mutations/create.graphql')  
const UPDATE_IMPORT = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const RemoveImportLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Import"
        entityTitle="Import"
        redirectPath={`/imports`}
      />
    </div>

  )
}

const search = {
  columns:  [
    { title: 'Description', dataIndex: 'description', key: 'description'},
    { title: '', width: 100, key: 'remove', render: i => <RemoveImportLink record={ i }/> }
  ]
}

export { search, CREATE_IMPORT, SEARCH_IMPORTS, GET_IMPORT, GET_IMPORT_STATS, UPDATE_IMPORT, destroy };
