import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Result } from 'antd';
import {  useMutation, useApolloClient } from '@apollo/client';
import _ from 'lodash';

import { TextInput, Submit } from '../../components/pants-d';
import AuthLayout from '../layout/Auth.js';

const { FORGOT_PASSWORD_MUTATION, CURRENT_USER_QUERY } = require('../../entities/auth')

function ForgotPassword() {

  const client = useApolloClient();

  const [forgotPassword, { data }] = useMutation(FORGOT_PASSWORD_MUTATION, {
    onCompleted: ({ reset }) => { 
      const query = {
        query: CURRENT_USER_QUERY,
        variables: {}
      }
  }})

  const [form] = Form.useForm();

  return(
    <AuthLayout>
      <h1 style={{textAlign: 'center', marginBottom: '20px', fontWeight: '600', fontSize: '20px', color: '#a45fa5'}}>
        Forgot Password
      </h1>
      <div style={{ padding: '0px 38px 38px' }}>
        { data && (
          <Result
            status="success"
            title="Password reset submitted successfully"
            subTitle="An email will be sent if an account matching this email exists."
          />
        )}
        { !data && (
          <Form
            layout="vertical"
            form={ form }
            onFinish={ (values) => forgotPassword({ variables: {...values}}) }>

            <Row>
              <Col span={24}>
                <TextInput required name="email" /> 
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col span={24}>
                <Submit label="Send Reset Email"/>
              </Col>
            </Row>

          </Form>
        )}
        <Row style={{ marginTop: '20px' }}>
          <Col span={ 12 } offset={ 6 }>
            {['register', 'login'].map(link => {
              return (
                <Button style={{ width: '50%', textAlign: 'center', fontSize: '14px' }} type="link" htmlType="button" href={`/${link}`}>
                  { _.startCase(link) }
                </Button>
              )
            })}
          </Col>
        </Row>
      </div>
    </AuthLayout>
  )
}

export default ForgotPassword;
