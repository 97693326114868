import cidrRegex from 'cidr-regex';
const { Address4, Address6 } = require('ip-address');
const { BigInteger } = require('jsbn');
import { range, unionWith, flattenDeep } from 'lodash';

const parsedSubnet = (subnet) => {

  if (!subnet) { return {}}

  if (cidrRegex.v4({exact: true}).test(subnet)) {
    return new Address4(subnet)
  } else {
    return new Address6(subnet)
  }
}


const inSubnet = (s1, s2) => {
  const subnet1 = new Address4((s1.networkAddress));
  const subnet2 = new Address4(s2.networkAddress);

  return subnet1.isInSubnet(subnet2) || subnet2.isInSubnet(subnet1);
}


const V4PossibleSubnets = ( supernet, netmask, limit = 50, offset = 0 ) => {

  const snet = parsedSubnet( supernet.networkAddress );

  if (netmask && (netmask > snet.subnetMask)) {

    const supernetAddress = snet.bigInteger();
    const supernetSize = Math.pow(2, 32 - snet.subnetMask);
    const subnetSize = Math.pow(2, 32 - netmask);

    let result = [];

    let i = 0;

    while ( i < limit ) {

      const address = Address4.fromBigInteger(supernetAddress.add(new BigInteger(i.toString())) + "/" + netmask).address

      result.push({ 
        networkAddress: address + "/" + netmask,
        supernetId: supernet.id,
        netmask: netmask
      })
      
      i += subnetSize;
    }

    return result;

  } else {

    return [];

  }
}


const V6PossibleSubnets = ( supernet, netmask ) => {

  const snet = parsedSubnet( supernet.networkAddress );

  let result = [] 

  const supernetAddress = snet.bigInteger();

  if (netmask && (netmask > snet.subnetMask)) {

    const supernetSize = new BigInteger(snet.possibleSubnets().toString())

    const subnetsCount = new BigInteger(snet.possibleSubnets(netmask).toString())
    const subnetSize = supernetSize.divide(subnetsCount)


    let i = new BigInteger('0');

    const snetSize = new BigInteger(subnetSize.toString())

    while (i.compareTo(new BigInteger(supernetSize.toString())) < 0) {

      const address = Address6.fromBigInteger(supernetAddress.add(i)).correctForm()

      result.push({ 
        networkAddress: address + "/" + netmask,
        supernetId: supernet.id,
        netmask: netmask
      })

      i = i.add(snetSize)
    }

    return result;

} else {

  return [];

  }
}

const possibleSubnets = ({ supernet, netmask }) => {

  const isV4 = cidrRegex.v4({exact: true}).test(supernet.networkAddress )

  if (isV4) {
    return unionWith(supernet.subnets || [], V4PossibleSubnets(supernet, netmask), inSubnet);
  } else {
    return unionWith(supernet.subnets || [], V6PossibleSubnets(supernet, netmask), inSubnet);

  }
}


export default { possibleSubnets, parsedSubnet }
