import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from "@apollo/client";

import {  Form, Layout, Button } from 'antd';

const { CURRENT_ORGANIZATION_QUERY } = require('../../entities/auth');

const Permissions = ({}) => {
  const { loading, error, data } = useQuery(CURRENT_ORGANIZATION_QUERY);
  const [showKey, setShowKey] = useState(false)

  const organization = data?.currentOrganization;

  const modelName = "support-groups"

  if (loading) { return (<></>) }
  return (
    <>
      { organization?.licenseKey && !showKey && (
        <a onClick={(e) => { setShowKey(true) }}>
          Reveal Key
        </a>
      )}
      { organization?.licenseKey && showKey && (
        <>{ organization.licenseKey }</>
      )}
      { !organization?.licenseKey && (
        <p>
        No installation licenses currently available
        </p>
      )}
    </>
  )
};

export default Permissions;
