import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";

import {  Form, Layout, Button } from 'antd';
import SearchTable from '../../components/SearchTable';
import SearchForm from '../../components/SearchForm';
import SearchAction from "../../components/SearchAction";
import RoleForm from '../../views/roles/Form';
import {PlusOutlined} from "@ant-design/icons";
import { role } from '../../entities';
import { readOnlyRoles } from '../../entities/role';
import SearchLayout from '../layout/Search';

const Roles = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'
  const [refetch, setRefetch] = useState()

  const handleRoleClick = (record) => {
    if (readOnlyRoles.includes(record.name)) {
      return; 
    }

    history.push(`/roles/${record?.id}`);
  };

  return (
    <SearchLayout section="Roles">
      <SearchForm placeholder='Search roles' modelName={Roles} />
      {/** <SearchAction icon={ <PlusOutlined /> } url='/roles/new'> Add Role </SearchAction> **/}
      <SearchTable
        style={{ marginTop: '70px' }}
        query={ role.searchQuery }
        { ...role.search }
        onAdd={() => {
          history.push('/roles/new')
        }}
        refetch={refetch}
        onClick={handleRoleClick}
        modelName='roles'
        onRow={(record) => ({
          onClick: () => handleRoleClick(record),
          style: readOnlyRoles.includes(record.name) ? { cursor: 'not-allowed' } : {}
        })}
      />

      { id && ( <RoleForm id={ id } refetch={() => setRefetch(refetch + 1)} /> ) } 
    </SearchLayout>
  )
};

export default Roles;
