import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import { Form, Layout, Button, Drawer } from "antd";

import SearchForm from "../../components/SearchForm";
import SearchAction from "../../components/SearchAction";
import SearchTable from "../../components/SearchTable";
import DNSForm from "../../views/dns-entries/Form";
import SearchLayout from "../layout/Search.js";
import Entity from "../../entities";
import {PlusOutlined} from "@ant-design/icons";
import Info from "../info/Info";

const { Content } = Layout;

const dnsEntry = Entity["dns-entry"];

const DNS = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == "new";

  return (
    <SearchLayout section='DNS'>
        <SearchForm placeholder='Search DNS records' modelName={DNS} />
      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/dns-entries/new'
        style={{marginTop: '12px'}}>
        Add DNS Entry
      </SearchAction>
      <SearchTable
        style={{ marginTop: '70px' }}
        query={dnsEntry.searchQuery}
          {...dnsEntry.search}
          onAdd={() => {
            history.push("/dns-entries/new");
          }}
          onClick={(record) => {
            history.push(`/dns-entries/${record?.id}`);
          }}
          emptyMessage="Looks like you haven't added any DNS entries yet!"
          modelName="DNSEntry"
          emptyRender={<Info model="dnsentry" />}
        />

      {(id || newRecord) && (
        <Drawer
          title={newRecord ? `Add DNS entry` : `Update DNS entry`}
          width={520}
          closable={false}
          visible={true}
          onClose={() => {
            history.push("/dns");
          }}
        >
          <DNSForm id={id} />
        </Drawer>
      )}
    </SearchLayout>
  );
};

export default DNS;
