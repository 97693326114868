import React, { useState, useEffect  } from 'react';
import {  Row, Form, Table, Empty } from 'antd';
import { CheckCircleFilled, EditFilled, CloseCircleFilled, DeleteFilled, DownOutlined, CaretDownOutlined, CaretDownFilled, CaretRightFilled } from '@ant-design/icons';
import { useQuery, useReactiveVar } from '@apollo/client';
import { searchVar } from '../cache.js';
import _ from "lodash";

const EditableCell = ({
  title,
  editable,
  editing,
  children,
  dataIndex,
  renderFn,
  record,
  ...restProps
}) => {

  return ( 
    <td {...restProps}>
      { editing && renderFn  ? (
        <>
          { renderFn(record) }
        {/**
        <Form.Item
          style={{ margin: 0 }}
          name={ dataIndex }
          rules={[
            {
              required: true,
                message: `${title} is required.`,
            },
          ]}
        >
        </Form.Item> **/}
        </>
      ) : (
        <>{ children }</>
      )
      }
    </td>
  )
};

function SearchTable({ 
  query, 
  className,
  onSearch,
  data,
  editable,
  rowKey = 'id',
  modelName, 
  onAdd, 
  onCancel,
  onSave,
  onCreate,
  onDestroy,
  header,
  hideAdd, 
  hideControls,
  onClick, 
  columns, 
  filters, 
  emptyMessage, 
  emptyRender, 
  emptyAction, 
  readOnly,
  refetch,
  style,
  onInternalTableChange,
  ...props
}) {

  const [form] = Form.useForm();

  const search = useReactiveVar(searchVar);

  const { loading, error, data: response, refetch: research } = useQuery(query, {
    variables: {search: `${search}`, offset: 0, limit: 50, ...filters},
  });

  const serverData = response ? Object.values(response)?.[0] : []

  useEffect(() => { 
    research({search: `${search}`, 
        offset: 0, 
        limit: 50, 
        ...filters
    }) 
  }, [refetch, filters])

  const [ pagination, setPagination] = useState({
    page: 1,
    pageSize: 25
  });

  const [editingId, setEditingId] = useState();

  const components = {
    body: {
      cell: EditableCell
    }
  };

  const initializeForm = async (key, form, columns) => {
    const d = [...(data?.results || []), ...(serverData?.results || [])];

    const idx = d.findIndex((item) => parseInt(key) === item.id);

    const item = d[idx]

    let values = {};

    await columns?.filter(col => col.renderFn).map(({ dataIndex }) => { values[dataIndex] = item[dataIndex] });


    form.setFieldsValue(values)
  }

   const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...(data?.results || []), ...(serverData?.results || [])];
      const index = newData.findIndex((item) => key === item.key);


      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        item?.id ? onSave(newData) : onCreate(newData)
        setEditingId();
      } else {
        newData.push(row);
        newData?.id ? onSave(newData) : onCreate(newData)
        setEditingId();
      }
      form.resetFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {  
    initializeForm(editingId, form, columns)
  }, [editingId])

  const mergedColumns = (columns).map((col) => {
    if (!col.editable) { return col; }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record?.id == editingId,
        renderFn: col?.renderFn
      })
    }
  })

  const onTableChange = ({current, pageSize}, filters, sorter) => {
    research({
      sortBy: sorter.columnKey,
      sortDir: sorter.order,
      limit: pageSize, 
      offset: ((current - 1) * pageSize),
      ...filters
    }).then((data) => {

      const result = Object.values(data)[0];

      setPagination({
        ...pagination,
        current: current,
        pageSize: pageSize,
        total: result?.total
      });
    })

    onInternalTableChange({
      current,
      pageSize
    })
  }


  const results = serverData ? [...(serverData?.results || serverData || [])] : []

  
  return (
    <>
      { !loading && search?.length == 0 && (results.length == 0) && emptyRender && (
        <div style={{ marginTop: '80px', minWidth: '1100px', marginLeft: 'auto', marginRight: 'auto' }}>{ emptyRender }</div>
      )}

      { !(!loading && search?.length == 0 && (results.length == 0) && emptyRender) && (
        <>{ header }</>
      )}

      { !loading && search?.length !== 0 && (results.length == 0) && (
        <div class={ `search-results ${className}` } style={ style }>
          <Empty
            style={{ marginTop: '80px', }}
            size='small'
            description={ 
              <div style={{marginTop: '40px', fontSize: '20px'}}> 
                Looks like there aren't any results for this search!
              </div >
            } >

            { !hideAdd && !readOnly && (
              <a style={{marginTop: '30px'}} onClick={(e) => {onAdd()}}> { emptyAction || 'Add a record'} </a>
            )}
          </Empty>
        </div>
      )}
      { !loading && search?.length == 0 && (results.length == 0) && (
        <>
          {!emptyRender && (
            <div class={ `search-results ${className}` } style={ style }>
              <Empty
                style={ style }
                description={ 
                  <div style={{marginTop: '40px', fontSize: '20px'}}> 
                    { emptyMessage }
                    { !emptyMessage && (<> Looks like you don't have any {(modelName || 'records' )} yet!</>) }
                  </div >
                } >

                { !hideAdd && !readOnly && (
                  <a style={{marginTop: '30px'}} onClick={(e) => {onAdd()}}> { emptyAction || 'Add a record'} </a>
                )}

              </Empty>
            </div>
          )}
        </>
      )}
    {(results.length > 0) && (
        <Form 
          form={ form } 
          component={ false } 
          onFinish={(data) => {
            save(editingId);
          }}
        >
          <Table 
            style={ style }
            loading={ !(data?.results) && serverData && loading }
            dataSource={ results } 
            rowKey={ rowKey } 
            components={ components }
            tableLayout='fixed'
            columns={ 
              [
                ...mergedColumns,
                ...( editable ? [{ 
                  title: '', 
                  width: '3.4em',
                  fixed: 'right',
                  render: record => { 
                    if (!hideControls) {
                      if (record?.id == editingId) {
                        return  <>
                          <a style={{ color: '#a497a466', float: 'right', display: 'inline-block', marginRight: '10px' }} onClick={() => { save(record?.key) }}><CheckCircleFilled /></a>
                          <a style={{ color: '#a497a466', display: 'inline-block', marginLeft: '5px', marginRight: '10px', float: 'right' }} onClick={() => { 
                            onCancel ? onCancel() : null
                            setEditingId(); 
                          }}><CloseCircleFilled /></a>
                        </>
                      } else {
                        return  <>
                          { onDestroy && <a style={{ color: '#a497a466', marginLeft: '5px', float: 'right', marginRight: '10px' }} onClick={() => {
                            onDestroy(record)
                          }}><DeleteFilled /></a> }
                          { onSave && <a style={{ color: '#a497a466', float: 'right', marginRight: '10px' }} onClick={() => {setEditingId(record?.id)}}><EditFilled /></a> }
                        </>
                      }
                    }

                    }}] : [])
              ]
            }
            scroll={{ y: window.innerHeight - 280 }} 
            onChange={ onTableChange }
            pagination={{
              ...pagination, 
              total: (serverData || data)?.total,
              position: ["none", "bottomLeft"],
            }}
            size="small"
            onRow={(r) => {
              if (readOnly) {
                return false;
              } else {
                return {onClick: (e) => { 
                  if (onClick) {
                    onClick(r)
                  }
                }}
              }
            }} 
            { ...props }
          />
        </Form>
      )}
    </>
    )
}

export default SearchTable;
