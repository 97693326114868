import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";

import {  Form, Layout, Button, Drawer } from 'antd';
import SearchForm from '../../components/SearchForm';
import SearchAction from '../../components/SearchAction';
import SearchTable from '../../components/SearchTable';
import RequestForm from '../../views/requests/Form';
import SearchLayout from '../layout/Search.js';
import Entity from '../../entities';
import {PlusOutlined} from '@ant-design/icons';
import Info from '../info/Info';
import RemoveLink from '../../components/RemoveLink';

const request = Entity['request'] 

const { Content } = Layout;

const Requests = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'

  return (
    <SearchLayout section='Requests'>
        <SearchForm placeholder='Search requests' modelName={ Requests } />

        <SearchAction 
          icon={ <PlusOutlined /> }
          url='/requests/new'
          style={{marginTop: '12px'}}>
          New request
        </SearchAction>
        <SearchTable 
          style={{ marginTop: '70px' }}
          query={ request.searchQuery }
          { ...request.search }

          columns={[
            ...request.search.columns,
            { title: '', width: 100, key: 'remove', render: d => <RemoveLink record={ d } mutation={ request.destroy } /> }
          ]}

          onAdd={() => { 
            history.push('/requests/new') 
          }}
          onClick={(record) => { 
          history.push(`/requests/${record?.id}`) }}
          emptyMessage="You haven't made any requests yet!"
          modelName='requests' 
          emptyRender={<Info model="requester" />}
        />

      { (id || newRecord) && ( <RequestForm id={ id } /> ) }
    </SearchLayout>
  )
};

export default Requests;
