import React from 'react';
import { Link } from 'react-router-dom';
import SearchLayout from '../layout/Search';

const NotFound404 = (props) => {
    const section = props.location?.state?.section;
    const capitalizedSection = section && section.charAt(0).toUpperCase() + section.slice(1);
    return (
        <SearchLayout section={`${capitalizedSection}s`}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            background: '#f7f7f7',
            height: '100vh',
            textAlign: 'center',
            color: '#333'
        }}>
            <h1 style={{ fontSize: '60px', margin: '20px 0' }}>404</h1>
            <p style={{ fontSize: '25px', margin: '20px 0' }}>Page not found</p>
            <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <Link to="/" style={{ marginTop: '20px', display: 'inline-block', padding: '10px 20px', border: '1px solid #333', borderRadius: '5px', textDecoration: 'none', color: '#333' }}>Go to Homepage</Link>
        </div>
        </SearchLayout> 
    )
};

export default NotFound404;