import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input, Drawer, Alert, Checkbox } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect, Select } from '../../components/pants-d';
import {CloseCircleFilled, PlusCircleFilled} from '@ant-design/icons';

const organization = require("../../entities/organization");
const role = require("../../entities/role");

const UserForm = ({ id }) => {
  const history = useHistory();

  const newRecord = id == 'new'
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);


  const [getUser, { loading, error: recordError, data }] = useLazyQuery(organization.get, { variables: { id: parseInt(id) }});

  useEffect(() => { if ( id && !newRecord ) { getUser({ variables: { id: parseInt(id) }}) } },[])

  const [updateRecord, { data: updateData }] = useMutation(organization.update, { onCompleted: () => { 
    history.push(`/tidal-admin/organizations`) 
  },
    onError: e => {
      setErrors(e?.graphQLErrors?.map(error => error.message) || [e.message]);
    }})

  const [form] = Form.useForm();
  
  const cellStyle = { padding: '2px 4px' }

  if (loading) { return <></> }
  return (

    <Drawer
      title='Update Organization'
      width={1200}
      closable={false}
      visible={true}
      onClose={() => { history.push('/tidal-admin/organizations')}}>
      <Form
        layout="vertical"
        form={form}
        name="organization"
        initialValues={data?.['organization'] || {}}
        onFinish={ ({ UserOrganizations: uo, User, ...rest }) => { 

          const userOrgs = uo.map(({__typename: _, User, ...rest }) => { return { UserId: parseInt(User?.id), OrganizationId: parseInt(id), ...rest }})
          
          updateRecord({ variables: { id: parseInt(id), ...rest, UserOrganizations: userOrgs  }})

        }}>

         <div ref={errorContainerRef}>
            {(errors?.length > 0) && (
              <>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} type="error" message={errorMessage} />
                ))}
                <br />
              </>
            )}
          </div>
        <Row gutter={ 24 }>
          <Col span={ 12 }>
            <h2>{ data?.organization?.name }</h2>
          </Col>
        </Row>
        <br />

        <h3>User Organizations</h3>
        <Form.List
          name='UserOrganizations'
          initialValue={ (data?.organization?.UserOrganizations || []) }>

          {(UserOrganizations, { add, remove }) => {
            return (
              <table
                className='user-organization-table'
                style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                  <th style={{ ...cellStyle }}>email</th>
                  <th style={{ ...cellStyle }}>first name</th>
                  <th style={{ ...cellStyle }}>last name</th>
                  <th style={{ ...cellStyle }}>role</th>
                  <th style={{ ...cellStyle }}>status</th>
                  <th style={{ ...cellStyle }}>user</th>
                  <th style={{ ...cellStyle }}><PlusCircleFilled style={{ float: 'right' }} onClick={() => add({})} /></th>
                </thead>
                <tbody>
                  {(UserOrganizations || []).map((userOrg, idx) => {
                    const user = data?.organization?.UserOrganizations?.[userOrg.key]?.User

                    return (
                      <tr>
                        <td style={{ ...cellStyle, width: '140px' }}><TextInput size='small' name={[idx, "email"]} hideLabel /></td>
                        <td style={{ ...cellStyle, width: '140px' }}><TextInput size='small' name={[idx, "firstName"]} hideLabel /></td>
                        <td style={{ ...cellStyle, width: '140px' }}><TextInput size='small' name={[idx, "lastName"]} hideLabel /></td>
                        <td style={{ ...cellStyle, width: '120px', padding: '0px' }}>
                          <Select size='small' name={[idx, "role"]} hideLabel >
                            <Select.Option value='Owner'>Owner</Select.Option>
                            <Select.Option value='Administrator'>Administrator</Select.Option>
                            <Select.Option value='Contributor'>Contributor</Select.Option>
                            <Select.Option value='Reader'>Reader</Select.Option>
                          </Select>
                          </td>
                        <td style={{ ...cellStyle, ...{ width: '80px', paddingTop: '10px', verticalAlign: 'text-top' }}}>{ user ? 'Active' : 'Invited' }</td>
                        <td style={{ ...cellStyle, width: '140px' }}>{ user ? `${user?.firstName ||''} ${user?.lastName ||''} <${user?.email}>`  : ''}</td>
                        <td style={{ ...cellStyle, width: '20px' }}><CloseCircleFilled onClick={(e) => {
                        remove(idx) }} /></td>
                      </tr>
                    )

          })}
                </tbody>
              </table>
            )

          }}

        </Form.List>

        <Row style={{ marginTop: '40px' }} gutter={ 24 }>
          <Col span={ 24 }>
            <Button onClick={(e) => { history.push('/tidal-admin/organizations') }} type="primary" style={{ width: '80px', float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>

            <Button type="primary" style={{ width: '80px', float: 'right' }} htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>

      </Form>
    </Drawer>
  )
}

export default UserForm;
