import React, { useState } from 'react'
import { loader } from 'graphql.macro';
import { Space, Button, Popconfirm, Alert, Modal, Menu, Dropdown } from 'antd';
import { useParams } from "react-router-dom";
import moment from 'moment';

import { CaretDownFilled, CaretRightFilled, ExclamationCircleOutlined  } from '@ant-design/icons';

import RemoveLink from '../../components/RemoveLink';
import { useMutation } from '@apollo/client';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const { ProviderLogo } = require('../provider')
const { ipCount, isV4 } = require('../../utils/ip');
const { Address6, Address4 } = require('ip-address');
const customerQuery = loader('../customer/queries/search.graphql')

const searchQuery = loader('./queries/search.graphql')  
const availableSubnetsQuery = loader('./queries/availableSubnets.graphql')  
const GET_QUERY = loader('./queries/get.graphql')  
const usageSummary = loader('./queries/usageSummary.graphql')
const historyQuery = loader('./queries/history.graphql')  
const customerSubnets = loader('./queries/customerSubnets.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')
const refresh = loader('./mutations/refresh.graphql')
const importRecords = loader('./mutations/import.graphql')

const destroyIPAssignment = loader('../ip-assignment/mutations/destroy.graphql')
const DESTROY_SUBNET = loader('../subnet/mutations/destroy.graphql')


const IPStatus = ({ ipAddress }) => {

  const { ipAssignment, scanResults } = ipAddress;

  const active = !!ipAssignment;

  const latestScan = scanResults?.[0];

  const hasConflict = latestScan?.hostname && 
    !latestScan?.ignore &&
    (latestScan?.hostname?.toUpperCase() !== ipAssignment?.interface?.resource?.hostname?.toUpperCase()) && 
    ipAssignment?.interface?.resource?.hostname &&
    latestScan?.hostname
  ;

  const deviceState = () => {
    return 'green'
  }

  return (
    <div style={{ marginRight: '10px', float: 'left', display: 'inline-block', paddingTop: '0px' }}>
      { !hasConflict && <span class={`dot ${ active ? deviceState() : '#FAFAFA' }`}/> }
      { hasConflict && <ExclamationCircleOutlined style={{ color: '#FF4444', marginTop: '4px'}} /> }
    </div>
  )
}

const parseDate = (dateString) => {
  const d = new moment(dateString)

  return d.format("MMMM Do YYYY, h:mm:ss a")
}

const ScanResult = ({ scanResults, field }) => {

  const result = scanResults?.[0]

  return (
    <>
      {!scanResults?.[0]?.ignore && (
        <div style={{ color: '#b6afb6'}}>

        { field == 'scannedAt' ? `${result?.sourceType?.toUpperCase()}  ${parseDate(result?.[field])}` : result?.[field] }</div>
      )}
    </>
  )
}

const Resource = ({ resource }) => {

  const hidden = useState(true);

  const style = {padding: '0px 0px', background: '#fcfcfd', minHeight: '22px'}
   
  return (
    <div style={style}>
      <div style={{width: '26px', padding: '0px 2px', float: 'left'}}>
        { (resource?.resourceType == null) && <img style={{width: '12px'}} src="/images/azure/compute/10021-icon-service-Virtual-Machine.svg" /> }
      </div>
        <div style={{float: 'left'}}>
          <div style={{fontWeight: 'bold', color: '#2c6696', fontSize: '11px', lineHeight: '21px', textTransform: 'uppercase'}}>{ resource?.hostname?.toUpperCase() || 'UNKNOWN' }</div>
          { !hidden && ( 
            <p style={{fontSize: '11px', marginBottom: '0px'}}>
              <b>{ resource?.interfaces?.[0]?.name }</b>  
              <br/>
              { resource?.interfaces?.[0]?.macAddress }
            </p>
          )}
        </div>
    </div>
  )
}

const ReservationLink = ({ ipAddress, onClick }) => {

  const style = {
    padding: '4px 4px 4px 8px', 
    background: '#FAFAFA',
    borderRight: `2px solid ${ipAddress?.reservation?.color || '#FFFFFF'}`
  }

  return (
    <>
      { ipAddress?.reservation && ( 
        <div style={ style } onClick={(e) => { 
          e.stopPropagation()
          onClick(ipAddress?.reservation) 
          }}>
          <div className="ip-status reserved">
            {ipAddress?.reservation?.name}
          </div> 
        </div>
      )}
    </>
  )
}

const RequestLink = ({ ipAddress, onClick }) => {

  const style = {
    padding: '4px 4px 4px 8px', 
    background: '#FAFAFA',
    borderRight: `2px solid `
  }

  return (
    <>
      { ipAddress?.request && ( 
        <div style={ style } onClick={(e) => { 
          e.stopPropagation()
          onClick(ipAddress?.request) 
          }}>
          <div className="ip-status reserved">
            Requested
          </div> 
        </div>
      )}
    </>
  )
}

const possibleSubnets = ( networkAddress, netmask ) => {

  const parsedSubnet = (subnet) => { return new Address4(subnet) } 

  const snet = parsedSubnet( networkAddress );

  let result = [] 

  const supernetAddress = snet.bigInteger();

  if (netmask && (netmask > snet.subnetMask)) {

    const supernetSize = new BigInteger(snet.possibleSubnets().toString())

    const subnetsCount = new BigInteger(snet.possibleSubnets(netmask).toString())
    const subnetSize = supernetSize.divide(subnetsCount)

    let i = new BigInteger('0');

    const snetSize = new BigInteger(subnetSize.toString())

    while (i.compareTo(new BigInteger(supernetSize.toString())) < 0) {

      const address = Address6.fromBigInteger(supernetAddress.add(i)).correctForm()

      result.push({ 
        networkAddress: address + "/" + netmask,
        supernetId: supernet.id,
        netmask: netmask
      })

      i = i.add(snetSize)
    }

    return result;

} else {

  return [];

  }
}

const AddLink = ({ data }) => {

  const [addRecord, { data: addData}] = useMutation(create, {
    onCompleted: () => {  console.log('fired') }
  })

  return (
    <Button style={{ 
      padding: '4px 10px',
      height: '30px',
      float: 'right', 
      marginRight: '10px',
      width: '80px',
      background: '#A32DA5', 
      fontSize: '13px', 
      borderRadius: '20px', 
      color: '#FFFFFF', 
      border: '0px' }} 
      onClick={(e) => { 
        e.stopPropagation(); 
        addRecord({variables: { ...data }})
      }}>
      Add 
    </Button>
  )
}

const RemoveSubnetLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={DESTROY_SUBNET}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="subnet"
        entityTitle="Subnet"
        redirectPath={`/subnets`}
      />
    </div>

  )
}

const RemoveChildSubnetLink = ({ record }) => {

  const { subnetId, supernetId } = useParams();

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={DESTROY_SUBNET}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="subnet"
        entityTitle="Subnet"
        redirectPath={`/subnets/${supernetId}/${subnetId || supernetId}/subnets`}
      />
    </div>

  )
}


const RemoveIPLink = ({ ipAssignment }) => {

  const [removeIpAssignment, { data: removeData }] = useMutation(destroyIPAssignment, { onCompleted: (v) => { console.log('completed') },
    update(cache, { data }) {
      cache.evict(`IPAssignment:${ipAssignment?.id}`);
    }
  });

  const linkStyle = { fontSize: '13px', color: '#2c6696', textAlign: 'center' }

  return (
    <>
      { (ipAssignment) && ( <a style={linkStyle} onClick={(e) => {

        e.stopPropagation()

        removeIpAssignment({variables: {id: ipAssignment?.id}})

      }}> Release </a> ) }
      { (!ipAssignment) && ( <a style={linkStyle} > Assign </a> ) }
    </>
  )
}

const constantWidth = (subnet) => {

  const networkAddress = subnet.networkAddress


  if (isV4(networkAddress)) {

  const octets = networkAddress.split('/')?.[0].split('.')

  return octets.map((octet, idx) => {
    const blankCount = (3 - octet.length)

    const blanks = Array(blankCount).fill().map(() => (<span style={{ color: '#000000', opacity: 0.05 }}>0</span>))

    if (idx == 0 ) {
      return <>{ octet } .</>
    } else {
    return <>{ blanks }{ octet }{idx < 3 ? ' .' : ''}</>
    }
  })
  } else {
    return networkAddress.split('/')[0]
  }
}

const search = {
  query: searchQuery,
  filters: { topLevelOnly: true },
  expandable: {
    indentSize: 10,
      defaultExpandAllRows: true,
      showExpandColumn: true,
      columnTitle: '',
      columnWidth: 90,
      expandIcon: ({ expanded, onExpand, record }) => {
        if (record?.subnets?.length > 0) {
          return (
            expanded ? 
            ( <CaretDownFilled style={{ color: '#FFFFFF', opacity: '0.8', lineHeight: '20px', margin: '1px 4px 0px 6px', fontSize: '0.8em', float: 'left', display: 'inline-block' }}  onClick={e => { e.stopPropagation(); onExpand(record, e)}} />) :
            ( <CaretRightFilled style={{ color: '#FFFFFF', opacity: '0.8', lineHeight: '24px', margin: '-1px 4px 0px 6px', fontSize: '0.8em', float: 'left', display: 'inline-block' }} onClick={e => { e.stopPropagation(); onExpand(record, e)}} /> )
          )
        }
      }
  },
  columns:  [
    { title: 'Subnet', style: { fontSize: '10px' }, key: 'networkAddress', width: 75, render: subnet => <div className='search-subnet-block' >{ 
      subnet?.networkAddress?.replace('/', ' / ')
      }</div> },
    { title: 'Name', key: 'name', width: '8em', sorter: true, render: (subnet) => (
      <div style={{ fontSize: '1.1em', lineHeight: '1.3em' }}>
        <ProviderLogo type={ subnet?.provider?.type }/>
        { subnet?.name ? 
          <>{ subnet?.name }</> :
          <i style={{ opacity: '0.4' }}>Unknown</i>
        }
      </div>
    )},
    { title: 'Zone', key: 'name', width: '4em', render: (subnet) => (
      <div style={{ fontSize: '1.1em', lineHeight: '1.3em' }}>
        <a>
          { subnet?.zone ? 
            <>{ subnet?.zone?.name }</> :
            <i style={{ opacity: '0.4' }}>Unknown</i>
          }
        </a>
      </div>
    )},

    { title: 'Site', key: 'name', width: '7em', render: (subnet) => (
      <div style={{ fontSize: '1.1em', lineHeight: '1.3em' }}>
        <a>
          { subnet?.site ? 
            <>{ subnet?.site?.name }</> :
            <i style={{ opacity: '0.4' }}>Unknown</i>
          }
        </a>
      </div>
    )},

   /**
    { title: 'Region', key: 'name', width: '5em', render: (subnet) => (
      <div style={{ fontSize: '1.1em', lineHeight: '1.3em' }}>
        <a>
          { subnet?.region ? 
            <>{ subnet?.region?.name }</> :
            <i style={{ opacity: '0.4' }}>Unknown</i>
          }
        </a>
      </div>
    )},
      **/

    { title: 'Customers', key: 'name', width: '5em', render: (subnet) => (
      <div style={{ fontSize: '1.1em', lineHeight: '1.3em' }}>
        <a>
          { (subnet?.customers?.length > 0) ?
            <>{ subnet?.customers?.map(c => c.name).join(', ') } </> :
            <i style={{ opacity: '0.4' }}>No assigned customers</i>
          }
        </a>
      </div>
    )},
    { title: 'Available', width: '4em', align: 'right', key: 'inUse', onHeaderCell: () =>  {return { style: {'paddingRight': '24px'}}}, render: (subnet => { return <div style={{paddingRight: '20px'}}>{ ((ipCount(subnet.networkAddress) - subnet.inUse)  / ipCount(subnet.networkAddress) * 100.0).toFixed(2)} %</div> })},
    { title: '', width: '2em', key: 'remove', render: d => <RemoveSubnetLink record={ d }/> }
  ]
}


const childSearch = {
  columns:  [
    { title: 'Subnet',  key: 'networkAddress', dataIndex: 'networkAddress', width: '160px' },
    { title: 'Name', key: 'name', dataIndex: 'name'},
    { title: '', width: '120px', key: 'action', render: d => d?.id ? <RemoveChildSubnetLink record={ d } /> : <AddLink data={ d }/>}
  ]
}

const interfaces = ['eth0', 'eth1', 'eth2', 'eth3', 'lo', 'ppp0', 'vmnet1', 'vmnet2', '', '', '', '', '', '']

const ipSearch = {
  columns:  [
    { title: 'IP Address', width: '12em', key: 'networkAddress', render: ipAddress => <>
        <IPStatus ipAddress={ ipAddress}/>
        { ipAddress?.networkAddress }
      </>
    },
    { title: 'Resource', key: 'resource', width: '16em', render: ({ ipAssignment, scanResults }) => {
      if (ipAssignment?.interface?.resource) {
        return <Resource resource={{...ipAssignment?.interface?.resource, interfaces: [ipAssignment?.interface] }} /> 
      } else if (scanResults?.length > 0) {
        return <ScanResult scanResults={ scanResults } field='hostname' />
      }
    }},
    { title: 'Interface', key: 'interface', width: '10em', render: ({ ipAssignment, scanResults }) => {
      if (ipAssignment) { 
        return <> { ipAssignment?.interface?.name} </>
      } else if (scanResults?.length > 0) { 
        return <ScanResult scanResults={ scanResults } field='interface' />
      }
    }
    },
    { title: 'MAC Address', key: 'macAddress', width: '12em', render: ({ ipAssignment, scanResults }) => {
      if (ipAssignment?.interface?.macAddress) {
        return <>{ipAssignment?.interface?.macAddress}</> 
      } else if (scanResults?.length > 0) {
        return <ScanResult scanResults={ scanResults } field='macAddress' />
      }
    }},
    { title: 'DNS', key: 'DNSEntries', width: '14em', dataIndex: 'ipAssignment', render: ipAssignment => ipAssignment?.DNSEntries?.map(entry => <div style={{padding:'2px 4px', background: '#FAFAFA', fontSize: '11px'}}><Space>{ entry?.recordType}  <b>{ entry?.name }</b>  { entry?.ttl }</Space></div>) },
    { title: 'Notes', dataIndex: 'ipAssignment', key: 'ipAssignment', render: ipAssignment => <p>{ ipAssignment?.description } </p>},
    { title: 'Last Seen',  key: 'lastSeenAt', render: ({ ipAssignment, scanResults }) => {
      if (ipAssignment?.lastSeenAt) {
        const d = new Date(parseInt(ipAssignment?.lastSeenAt))

        return (<p style={{ marginBottom: '0px'}}>{ d.toLocaleDateString() + ' ' + d.toLocaleTimeString() }</p>)
      } else if (scanResults?.length > 0 && scanResults ) {
        return <><ScanResult scanResults={ scanResults } field='scannedAt' /></>
      }
    }}
  ]
}
const importer = {
  mutation: importRecords,
  search: {
    query: searchQuery,
    columns:  [
      { title: 'Subnet', dataIndex: 'networkAddress', key: 'networkAddress', width: '14em', render: address => address },
      { title: 'Name', key: 'name', width: '24em', render: subnet => {return <><ProviderLogo type={ subnet?.zone?.provider?.type }/> { subnet?.name }</> }},
      { title: 'Zone', dataIndex: 'zone', key: 'zone', width: '14em', render: zone => zone?.name },
      { title: 'VLAN', dataIndex: 'vlan', key: 'vlan', width: '14em', render: vlan => vlan?.number },
      { title: 'Contact First Name', key: 'contactFirstName', width: '24em', render: (record) => { record?.Contacts?.[0]?.firstName }},
      { title: 'Contact Last Name', key: 'contactLastName', width: '24em', render: (record) => { record?.Contacts?.[0]?.lastName }},
      { title: 'Contact Phone', key: 'contactPhone', width: '24em', render: (record) => { record?.Contacts?.[0]?.phone }},
      { title: 'Contact Email', key: 'contactEmail', width: '24em', render: (record) => { record?.Contacts?.[0]?.email }},
      { title: 'Customer Name', key: 'customerName', width: '24em', render: (record) => { record?.customers?.[0]?.name }},
      { title: 'Customer Street Address', key: 'customerStreetAddress', width: '24em', render: (record) => { return record?.customers[0]?.addresses[0]?.line1; }},
      { title: 'Customer Street Address Line2', key: 'customerStreetAddressLine2', width: '24em',  render: (record) => { return record?.customers?.[0]?.addresses?.[0]?.line2; } },
      { title: 'Customer City', key: 'customerCity', width: '24em', render: (record) => { return record.customers?.[0]?.addresses?.[0]?.municipality }},
      { title: 'Customer State/Province', key: 'customerStateProvince',  width: '24em', render:  (record) => { return record?.customers?.[0]?.addresses?.[0]?.province }},
      { title: 'Customer Country', key: 'customerCountry', width: '24em', render: (record) => { return record?.customers?.[0]?.addresses?.[0]?.country; } },
      { title: 'Customer Postal/Zip Code', key: 'customerPostalZipCode', width: '24em', render: (record) => { return record?.customers?.[0]?.addresses?.[0]?.postalCode }}, 
      { title: 'Site Name', key: 'siteName', width: '24em', render: (record) => { return record?.site?.name; } },
      { title: 'Site Street Address', key: 'siteStreetAddress', width: '24em', render: (record) => { return record?.site?.addresses?.[0]?.line1; } },
      { title: 'Site Street Address Line2', key: 'siteStreetAddressLine2', width: '24em',  render: (record) => { return record?.site?.addresses?.[0]?.line2; }},
      { title: 'Site City', key: 'siteCity', width: '24em', render: (record) => { return record?.site?.addresses?.[0]?.municipality }},
      { title: 'Site State/Province', key: 'siteStateProvince', width: '24em', render: (record) => { return record?.site?.addresses?.[0]?.province } },
      { title: 'Site Country', key: 'siteCountry', width: '24em', render: (record) => { return record?.site?.addresses?.[0]?.country }},
      { title: 'Site Postal/Zip Code', key: 'sitePostalZipCode', width: '24em', render: (record) => { return record?.site?.addresses?.[0]?.postalCode }},
      { title: '', width: 100, key: 'remove', render: d => <RemoveLink record={ d } mutation={ DESTROY_SUBNET }/> }
    ]
  },
  fields: [
    {name: 'name', required: true}, 
    {name: 'status'},
    {name: 'description'}, 
    {name: 'direction'}, 
    {name: 'zone'}, 
    {name: 'vlan', alias: 'VLAN'}, 
    {name: 'networkAddress', alias: 'Subnet', required: true}, 
    {name: 'planId', alias: 'PlanId'},
    {name: 'contactFirstName', alias: 'Contact First Name'},
    {name: 'contactLastName', alias: 'Contact Last Name'}, 
    {name: 'contactPhone', alias: 'Contact Phone'}, 
    {name: 'contactEmail', alias: 'Contact Email'}, 
    {name: 'customerName', alias: 'Customer Name'},
    {name: 'customerStreetAddress', alias: 'Customer Street Address'}, 
    {name: 'customerStreetAddressLine2', alias: 'Customer Street Address Line 2'}, 
    {name: 'customerCity', alias: 'Customer City'}, 
    {name: 'customerStateProvince', alias: 'Customer State/Province'}, 
    {name: 'customerCountry', alias: 'Customer Country'}, 
    {name: 'customerPostalZipCode', alias: 'Customer Postal/Zip Code'}, 
    {name: 'siteName', alias: 'Site Name'},
    {name: 'siteStreetAddress', alias: 'Site Street Address'}, 
    {name: 'siteStreetAddressLine2', alias: 'Site Street Address Line 2'}, 
    {name: 'siteCity', alias: 'Site City'}, 
    {name: 'siteStateProvince', alias: 'Site State/Province'}, 
    {name: 'siteCountry', alias: 'Site Country'}, 
    {name: 'sitePostalZipCode', alias: 'Site Postal/Zip Code'}, 
  ],
  exampleSheet: 'SubnetsExample',
  title: 'Subnets',
  copy: 'Subnets in Lightmesh represent a fixed address space and can be uploaded in CIDR format.',
  note: (
    <Alert
      style={{ marginTop: '10px' }}
      message={
        <div>
          <p dangerouslySetInnerHTML={{ __html: "<strong>Name</strong> and <strong>Subnet</strong> are required fields.</p>"}} />
          <p>VLAN values must be an integer between 0 and 4095. </p>
          <p>For example, <strong>Subnet</strong> should be in <em>CIDR notation (e.g., 10.0.0.0/16)</em>.</p>
        </div>
      }
      type="info"
      showIcon
    />
  )
}

const form = {
  fields: [
    ["name", {}],
    ["zone", {}],
    ["customers", { type: "MultiSelect", query: customerQuery }],
    ["description", {}],
    ["direction", {}],
    ["supportGroups", {}],
    ["networkAddress", {}],
    ["defaultGateway", {}],
    ["broadcast", {}],
    ["pollable", {}]
  ]
}

const customerSubnetSearch = {
  columns: [
    { 
      title: 'Subnet', 
      width: '160px', 
      render: subnet => (
        <>
          {console.log('subnet:', subnet)}
          {subnet?.networkAddress ? (
            <a 
              href={`/subnets/${subnet.id}`} 
              onClick={event => event.stopPropagation()}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {subnet?.networkAddress}
            </a>
          ) : null}
        </>
      ) 
    },
    { title: 'Name', key: 'name', dataIndex: 'name', sorter: true },
  ]
}

export { search, ipSearch, childSearch, form, refresh, searchQuery, availableSubnetsQuery, GET_QUERY, customerQuery, historyQuery, create, update, usageSummary, DESTROY_SUBNET, importer, RemoveIPLink, ReservationLink, RequestLink, possibleSubnets, customerSubnets, customerSubnetSearch };
