import React from 'react';
import { Row, Col } from 'antd';
import SearchTable from '../../components/SearchTable.js';
import SearchForm from '../../components/SearchForm';
import OrganizationForm from './OrganizationForm';

import {useHistory, useParams} from 'react-router-dom';

const SupportTicket = require('../../entities/support-ticket');

function SupportTicketSearch(props) {

  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      <Row>
        <SearchForm placeholder="Search Tickets" model={ SupportTicket }/>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SearchTable 
            { ...SupportTicket.search } 
            onClick={(org) => {
              history.push("/") }
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default SupportTicketSearch;
