import React from "react";

import { Breadcrumb, Layout } from "antd";

import { Subnav } from "../layout/Subnav.js";

const { Content } = Layout;


const SearchLayout = ({ section, children }) => {
  return (
    <>
      <Subnav>
        <Breadcrumb style={{ marginTop: '8px', display: 'inline-block', fontSize: '16px' }}>
          <Breadcrumb.Item> 
            <div style={{ fontSize: '17px', fontWeight: '500', color: '#55738b', display: 'inline-block'}}>
              { section }
            </div> </Breadcrumb.Item>
        </Breadcrumb>
      </Subnav>

      <Content className='search-layout' style={{ position: 'fixed', width: '100%', padding: '0px 32px', zIndex: '2', background: 'none' }}>
        { children }
      </Content>
    </>
  )

}

export default SearchLayout;
