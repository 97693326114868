import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input, Drawer, Alert, Table, Space } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect } from '../../components/pants-d';
import {CloseCircleFilled, PlusCircleFilled} from '@ant-design/icons';

const request = require("../../entities/request");
const subnet = require("../../entities/subnet");
const ipAddress = require("../../entities/ip-address");

const RequestForm = ({ refetch, id }) => {
  const history = useHistory();

  const { model, subnetId, supernetId, childId } = useParams();
  const newRecord = id == 'new'
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [updateNetworkRequest, { loading: updateLoading, data: updateData, error: updateError }] = useMutation(request.update, {
    onCompleted: () => {
      history.push(`/subnets/${supernetId}/${subnetId}/requests`)
    }
  })

  const { loading, error: recordError, data } = useQuery(request.getQuery, { variables: { id: parseInt(childId) }});

  const [form] = Form.useForm();

  if (loading) { return <></> }

  return (

    <Drawer
      title={`Review Request`}
      width={820}
      closable={false}
      visible={true}
      onClose={() => { 
        history.push(`/subnets/${supernetId}/${subnetId}/requests`)
      }}>

      <div style={{ marginBottom: '30px' }}>
        <Space direction='vertical'>
          <label>Requested By</label>
          <b>{ data?.networkRequest?.requester?.firstName } { data?.networkRequest?.requester?.lastName } ( { data?.networkRequest?.requester?.email || '' } )</b>
        </Space>
      </div>
      <label>Request Details</label>
      <div style={{ background: '#edf0f3', border: '1px solid #EAEAEA', marginTop: '10px', marginBottom: '20px', padding: '12px 8px' }}>
          <p>
            { data?.networkRequest?.details }
          </p>
        </div>
      <Row>
        { (data?.networkRequest?.NetworkRequestItems?.length > 0) && (
          <Table
            className='result-table' 
            style={{ marginTop: '40px', padding: '10px', width: '100%' }}
            dataSource={ data?.networkRequest?.NetworkRequestItems || [] }
            pagination={ false }
            columns={[
              { title: 'IP Address', dataIndex: 'ipAddress' },
              { title: 'Hostname', dataIndex: 'hostname' },
              { title: 'MAC Address', dataIndex: 'macAddress' },
              { title: 'Interface', dataIndex: 'interface' },
              { title: 'Description', dataIndex: 'description' },
            ]}
          />
        )}
      </Row>
      <Row style={{ marginTop: '40px' }} gutter={ 24 }>
        <Col span={ 24 }>
          { (data?.networkRequest.status == 'Submitted') && (
            <>
              <Button type="default" 
                onClick={(e) => { history.push(`/subnets/${supernetId}/${subnetId}/requests`) }} 
                style={{ width: '80px', float: 'right', marginLeft: '20px', height: '32px', padding: '2px 8px' }}>
                Cancel
              </Button>

              <Button type="danger" 
                style={{ width: '80px', marginLeft: '20px', float: 'right', background: '#ed5e61' }} 
                htmlType="submit"
                onClick={(e) => { updateNetworkRequest({ variables: {
                  id: parseInt(childId),
                  status: 'Rejected'
                }})}}
              >
                Reject
              </Button>

              <Button type="primary" 
                style={{ width: '80px', float: 'right' }} 
                onClick={(e) => { updateNetworkRequest({ variables: { id: parseInt(childId), status: 'Approved' }}) }}>
                Approve
              </Button>
            </>
          )}

          { (data?.networkRequest.status !== 'Submitted') && (
            <Button type="default" 
              onClick={(e) => { history.push(`/subnets/${supernetId}/${subnetId}/requests`) }} 
              style={{ width: '80px', float: 'right', marginLeft: '20px', height: '32px', padding: '2px 8px' }}>
              Close
            </Button>
          )}
        </Col>
      </Row>
    </Drawer>
  )
}

export default RequestForm;
