import React from 'react';
import { Layout, Menu } from 'antd';
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useHistory
} from "react-router-dom";

const { Header } = Layout


const NavLink = function({ url, children, ...props }) {
  return (
    <Menu.Item key={url} {...props}>
      { url?.length > 0 && <Link to={ url }/> }
      <span> { children } </span>
    </Menu.Item>
  )
}

const Links = function({ children, defaultSelectedKeys, style, ...props }) {
   return (
    <Menu className="sub-nav" style={{float: 'left', ...style }}
      { ...props }
      defaultSelectedKeys={[defaultSelectedKeys || '1']}
      defaultOpenKeys={['sub0']}
      mode="horizontal">
      { children }
    </Menu>
  )
}

const Actions = function({ children }) {
   return (
    <Menu className="sub-nav" style={{ float: 'right' }}
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub0']}
      mode="horizontal">
      { children }
    </Menu>
  )
}

const Subnav = function ({ searchable, children }) {
  return (
    <Header class="sub-nav" style={{ position: 'fixed', width: '100%',  zIndex: 1, top: '52px', background: '#eff3f5', padding: '1px 22px'}}>
      <div style={ searchable ? { marginLeft: '320px' } : {}}>
        { children }
      </div>
    </Header>
  )
}

export { Subnav, NavLink, Actions, Links };
