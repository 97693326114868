import React from 'react';
import Icon from '@ant-design/icons'

const createArrowSvg = ({width=30, height=28, rotation = 0, flip}) => {
  return function() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        transform={`rotate(${rotation}) ${ flip ? 'scale(1, -1)' : ''}`}
        transformOrigin="center"
        enableBackground="new 0 0 512 512"
        viewBox="0 0 64 64"
        xmlSpace="preserve"
      >
        <path 
          xmlns="http://www.w3.org/2000/svg" 
          d="M60 6a45.13 45.13 0 0 0-45.08 45.17l-9.51-9.51a2 2 0 0 0-2.82 2.83l12.92 12.92a2 2 0 0 0 2.83 0l12.92-12.92a2 2 0 0 0-2.83-2.83l-9.51 9.51A41.13 41.13 0 0 1 60 10a2 2 0 0 0 0-4z" 
          dataName="Layer 40" 
          fill="#3d75a4" 
          opacity="1" 
          dataOriginal="#000000" 
          class=""/>
      </svg>
    )
  }
}

const createServersSvg = () => {
  return function() {
    return (

      <svg 
        clipRule="evenodd" 
        fillRule="evenodd" 
        strokeLinejoin="round" 
        strokeMiterlimit="2" 
        viewBox="0 0 32 32" 
        xmlns="http://www.w3.org/2000/svg" 
        id="fi_11708333">
          <g transform="translate(-384 -192)">
            <path
              fill='#3d75a4'
              d="m390.5 202v1h-3.5c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1h3.5v1h-3.5c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1h3.5v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h17v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h3.5c.552 0 1-.448 1-1v-6c0-.552-.448-1-1-1h-3.5v-1h3.5c.552 0 1-.448 1-1v-6c0-.552-.448-1-1-1h-3.5v-1h3.5c.552 0 1-.448 1-1v-6c0-.552-.448-1-1-1h-26c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1zm.5 13c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm20 0h-8v2h8zm-19.5-4v1h17v-1zm-.5-5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm20 0h-8v2h8zm-19.5-4v1h17v-1zm-.5-5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm20 0h-8v2h8z">
            </path>
          </g>
      </svg>
    )
  }
}


const createLayersSvg = () => {
  return function() {
    return (
      <svg 
        version="1.1" 
        id="fi_481865" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 469.333 469.333" 
        style={{ color: '#ff0000', enableBackgroundNew : '0 0 469.333 469.333' }} 
        fill='#5384ac'
        xmlSpace="preserve">
        <g>
          <g>
            <path 

              stroke='#5384ac'
              strokeWidth='2.2%'
              d="M469.333,234.654c0-11.157-5.917-21.324-15.854-27.22l-26.134-15.375c10.068-5.978,19.039-11.31,26.155-15.544
              c9.917-5.917,15.833-16.084,15.833-27.199c0-11.115-5.917-21.282-15.833-27.21C420.833,102.678,310.062,36.851,255.458,5.548
              c-12.646-7.261-28.021-7.552-41.688,0.063c0,0-148.5,87.224-197.917,116.486C5.917,127.992,0,138.159,0,149.316
              c0,11.115,5.917,21.282,15.833,27.21c7.115,4.231,16.085,9.562,26.15,15.537l-26.129,15.371C5.917,213.33,0,223.497,0,234.654
              c0,11.115,5.917,21.282,15.833,27.21c7.115,4.231,16.085,9.562,26.15,15.537l-26.129,15.371C5.917,298.668,0,308.835,0,319.992
              c0,11.115,5.917,21.282,15.833,27.21C58.104,372.338,162,434.019,213.875,463.76c6.354,3.646,13.563,5.573,20.792,5.573
              s14.438-1.927,20.813-5.584c51.854-29.731,155.75-91.411,198.021-116.558c9.917-5.917,15.833-16.084,15.833-27.199
              c0-11.157-5.917-21.324-15.854-27.22l-26.134-15.375c10.068-5.978,19.039-11.31,26.155-15.544
              C463.417,255.936,469.333,245.769,469.333,234.654z M26.75,158.219c-1.625-0.979-5.417-3.792-5.417-8.885
              c0-5.115,3.771-7.906,5.396-8.865C76.083,111.24,224.375,24.146,224.354,24.156c6.792-3.823,14.208-3.677,20.5-0.073
              c54.458,31.219,165.125,96.969,197.729,116.365c1.625,0.979,5.417,3.792,5.417,8.885s-3.792,7.906-5.417,8.875
              c-42.208,25.104-145.979,86.719-197.708,116.365c-6.417,3.667-14.021,3.656-20.396,0.01
              C172.729,244.927,68.958,183.313,26.75,158.219z M442.604,311.135c1.625,0.958,5.396,3.75,5.396,8.865
              c0,5.094-3.792,7.906-5.417,8.875c-42.208,25.104-145.979,86.719-197.708,116.365c-6.417,3.667-14.021,3.656-20.396,0.01
              c-51.75-29.656-155.521-91.271-197.729-116.365c-1.625-0.979-5.417-3.792-5.417-8.885c0-5.115,3.771-7.906,5.396-8.865
              l36.199-21.301c48.928,29.001,113.47,67.107,150.947,88.592c6.354,3.646,13.563,5.573,20.792,5.573s14.438-1.927,20.813-5.583
              c37.461-21.478,101.997-59.578,150.923-88.583L442.604,311.135z M442.583,243.542c-42.208,25.104-145.979,86.719-197.708,116.365
              c-6.417,3.667-14.021,3.656-20.396,0.01C172.729,330.26,68.958,268.646,26.75,243.552c-1.625-0.979-5.417-3.792-5.417-8.885
              c0-5.115,3.771-7.906,5.396-8.865l36.199-21.301c48.928,29.001,113.47,67.107,150.947,88.592
              c6.354,3.646,13.563,5.573,20.792,5.573s14.438-1.927,20.813-5.583c37.461-21.478,101.997-59.578,150.923-88.583l36.202,21.302
              c1.625,0.958,5.396,3.75,5.396,8.865C448,239.76,444.208,242.573,442.583,243.542z"></path>
          </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>
      </svg>
    )
  }}


const ArrowUpRight = (props) => <div style={ props.style }><Icon component={createArrowSvg({ rotation: 180})} {...props} /></div>;
const ArrowUpLeft = (props) => <div style={props.style}><Icon component={createArrowSvg({ rotation: 0, flip: true})} {...props} /></div>;

const ArrowDownRight = (props) => <div style={props.style}><Icon component={createArrowSvg({ rotation: 180, flip: true})} {...props} /></div>;
const ArrowDownLeft = (props) => <div style={props.style}><Icon component={createArrowSvg({ rotation: 0})} {...props} /></div>;

const Layers = (props) => <div style={props.style}><Icon component={createLayersSvg({ rotation: 0})} {...props} /></div>;
const Servers = (props) => <div style={props.style}><Icon component={createServersSvg({ rotation: 0})} {...props} /></div>;


export { ArrowUpRight, ArrowUpLeft, ArrowDownLeft, ArrowDownRight, Layers, Servers };
