import React, { useState, useEffect } from 'react';

import {  Form, Button, Layout, Card, Row, Col, Tabs, Table, List } from 'antd';
import { TextInput, TextArea, SearchSelect, Select } from '../../components/pants-d';

import RecordLayout from '../layout/Record.js';

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { LinkError } from 'apollo-link/lib/linkUtils';
import { SyncOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import moment from "moment";

const { TabPane } = Tabs;

const { destroy, getQuery, searchQuery, create, update, sync} = require('../../entities/provider');

const AWS = ({ onSubmit, addingProvider }) => {
  const [form] = Form.useForm();

  const { loading, error, data, refetch } = useQuery(searchQuery, {variables: { type: 'AWS' } }) 

  const [createProvider, { data: createData, loading: createLoading, error: createErrors}] = useMutation(create, {
    onCompleted: () => { refetch(); onSubmit(); }
  })

  return (
    <>
      <p style={{ fontSize: '25px', padding: '8px 20px', margin: '27px 0px'}}>Add AWS access keys to import in to Lightmesh from your AWS environment</p>
      {!addingProvider && data && (
        <List>
          { data?.providers?.results?.map(provider => {
            return (<List.Item style={{ padding: '25px 10px' }}>

              <Provider id={ provider?.id } />
            </List.Item>)
          })}
        </List>
      )}
      {addingProvider && (
        <Form 
          form={ form }
          onFinish={(data) => { createProvider({ variables: {...data, type: 'AWS' }})}}
          layout="vertical">
          <TextInput name="awsAccessKeyId" label="Access Key ID"/>
          <TextInput name="awsSecretAccessKey" label="Secret Access Key" />
          <TextInput name="awsSessionToken" label="Session Token" />
          { !(data?.length == 0) &&  ( <Button type="primary" htmlType="submit">Add Role</Button> ) }
        </Form>
      )}
    </>
  )
}

const Azure = ({ onSubmit, addingProvider }) => {
  const [form] = Form.useForm();

  const { loading, error, data, refetch } = useQuery(searchQuery, {variables: { type: 'Azure' } }) 

  const [createProvider, { data: createData, loading: createLoading, error: createErrors}] = useMutation(create, {
    onCompleted: () => { refetch(); onSubmit(); }
  })

  return (
    <>
      <p style={{ fontSize: '25px', padding: '8px 20px', margin: '27px 0px'}}>Add a service principal to import in to Lightmesh from your Azure environment</p>
      {!addingProvider && data && (
        <List>
          { data?.providers?.results?.map(provider => {
            return (<List.Item style={{ padding: '25px 10px' }}>

              <Provider id={ provider?.id } />
            </List.Item>)
          })}
        </List>
      )}
      {addingProvider && (
        <Form 
          layout="vertical"
          form={ form }
          onFinish={(data) => { createProvider({ variables: {...data, type: 'Azure' }})}}>
          <TextInput name="azureAppId" label="App Id" />
          <TextInput name="azurePassword" label="Password"/>
          <TextInput name="azureTenantId" label="Tenant ID" />
          { !(data?.length == 0) &&  ( <Button type="primary" htmlType="submit">Add Service Principle</Button> ) }
        </Form>
      )}
    </>
  )
}

const Google = () => {
  return (
    <>
      <p style={{ marginBottom: '40px'}}>Add a service principal to import in to Lightmesh from your GCP environment</p>
      <Form layout="vertical">
        <TextInput name="projectId" />
        <TextArea name="accessKey" />
        <Button type="primary">Add Service Principle</Button>
      </Form>
    </>
  )
}



const Provider = ({ id }) => {

  const { loading, error, data, refetch, stopPolling, startPolling } = useQuery(getQuery, { variables: { id: parseInt(id) }, pollInterval: 5000})

  const [ destroyProvider, { data: destroyData }] = useMutation(destroy, { update(cache, { data }) { cache.evict(`Provider:${id}`)}}) 
  const [ syncProvider, _ ] = useMutation(sync, { onCompleted: () => { console.log('completed') } }) 

  const provider = data?.provider

  if ( !loading && provider?.syncStatus !== 'Synchronizing') { stopPolling() }

  const synchronizedAt = moment(parseInt(provider?.lastSynchronizedAt))

  return(
    <div style={{ border: '4px solid #F4F4F4', padding: '20px', width: '100%'}}>
      <div style={{ width: '60px', marginTop: '8px', float: 'left' }}>

        { provider?.syncStatus == 'Synchronized' && <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '30px', marginRight: '15px'}}/> }
        { provider?.syncStatus == 'Synchronizing' && <SyncOutlined style={{ color: '#AAAAAA', fontSize: '30px', marginRight: '15px' }} spin/> }
      </div>
      <div style={{ float: 'left' }}>
        { provider?.type == 'Azure' && ( <h2>App Id:  { provider?.azureAppId } </h2>)}
        { provider?.type == 'AWS' && ( <h2>Access Key Id:  { provider?.awsAccessKeyId } </h2>)}
        <h4>{ provider?.syncStatus == 'Synchronizing' ? 'Synchronizing' : `Last Synchronized at ${ synchronizedAt.format("MMM DD YYYY, h:mm:ss a") }` }</h4>
      </div>
      <a onClick={(e) => {
        destroyProvider({ variables: { id: provider?.id }})}}
        style={{float: 'right'}}>
        Remove
      </a>

      <a onClick={(e) => {
        syncProvider({ variables: { id: provider?.id }})}}
        style={{float: 'right', marginTop: '20px'}}>
        Resync
      </a>
    </div>
  )
}

const CloudAccounts = ({ onClose }) => {

  const [form] = Form.useForm() 


  const [updateProvider, { data: updateData, loading: updateLoading, error: updateErrors}] = useMutation(update, {
    onCompleted: () => { 
      onClose()
    }
  })

  const [addingProvider, setAddingProvider] = useState()

  return (
    <RecordLayout section="Cloud Accounts">
      <Tabs style={{ marginTop: '20px'}} type="card">
        <TabPane 
          key={1}
          tab={
          <div style={{padding: '5px 20px'}}>
            <img src="/images/azure.svg" style={{ width: '35px', marginLeft: 'auto', marginRight: 'auto'}} />
          </div>
          }>
          { !addingProvider && (
            <Button 
              onClick={(e) => { setAddingProvider(true) }}
              type="primary"
              style={{ float: 'right', margin: '40px 40px 0px'}}>Add Service Principal</Button>
          )}
          <div style={{ padding: '20px 20px', background: '#FFFFFF', marginTop: '-15px', minHeight: '200px'}}>
            <Azure addingProvider={addingProvider} onSubmit={() => setAddingProvider(false)}/>
          </div>

        </TabPane>
        <TabPane 
          key={2}
          tab={
            <div style={{padding: '5px 20px'}}>
              <img src="/images/amazon.png" style={{ width: '40px', marginLeft: 'auto', marginRight: 'auto'}} />
            </div>
          }>
          { !addingProvider && (
            <Button 
              onClick={(e) => { setAddingProvider(true) }}
              type="primary"
              style={{ float: 'right', margin: '40px 40px 0px'}}>Add Access Keys</Button>
          )}
          <div style={{ padding: '20px 20px', background: '#FFFFFF', marginTop: '-15px', minHeight: '200px'}}>
            <AWS addingProvider={addingProvider} onSubmit={() => setAddingProvider(false)}/>
          </div>


        </TabPane>

        {/**
        <TabPane tab={
          <div style={{padding: '10px 20px'}}>
            <img src="/images/google.svg" style={{ width: '40px', marginLeft: 'auto', marginRight: 'auto'}} />
          </div>
          }>

          <div style={{ padding: '20px 20px', background: '#FFFFFF', marginTop: '-15px', minHeight: '200px'}}>
            <Form layout="vertical">
            <Google />
            </Form>
          </div>

          </TabPane>
          **/}
      </Tabs>
    </RecordLayout>
  )
};

export default CloudAccounts;
