import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";

import { Breadcrumb, Form, Layout, Button, Drawer } from 'antd';


import SearchForm from '../../components/SearchForm';
import SearchAction from '../../components/SearchAction';
import SearchTable from '../../components/SearchTable';
import SubnetForm from '../../views/subnets/Form';
import SearchLayout from '../layout/Search.js';
import Entity from '../../entities';
import {PlusOutlined} from '@ant-design/icons';
import GettingStarted from '../account/GettingStarted';

const { Content } = Layout;

const Subnet = Entity['subnet'] 

const  Subnets = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'

  return (
    <SearchLayout section='Subnets'>
        <SearchForm placeholder='Search subnets' modelName={ Subnets } />
        <SearchAction 
          icon={ <PlusOutlined /> }
          url='/subnets/new'
          style={{marginTop: '12px'}}>
          Add Subnet
        </SearchAction>
        <SearchTable 
          style={{ marginTop: '70px' }}
          childrenColumnName='subnets'
          query={ Subnet.searchQuery }
          { ...Subnet.search }
          onAdd={() => { history.push('/subnets/new') }}
          onClick={(record) => { history.push(`/subnets/${record?.id}`) }}
          emptyRender={ <GettingStarted style={{ margin: 'auto', width: '1200px'}} /> }
          modelName='Subnets' />

      { (id || newRecord) && ( 

        <SubnetForm id={ id } onClose={() => { history.push('/subnets') }} /> 
      ) }
    </SearchLayout>
  )
};

export default Subnets;
