import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';

const RemoveLink = ({ record, mutation, confirmMessage }) => {

  const [removeRecord] = useMutation(mutation, { update( cache ) { cache.evict(`${record.__typename}:${record.id}`); }});

  return (
    <div onClick={e => e.stopPropagation()}>
      <Popconfirm
        title={ confirmMessage || 'Are you sure you want to remove this record?' }
        onConfirm={(e) => { removeRecord({ variables: { id: parseInt(record.id) } }) }}>
        <a>
          Remove
        </a>
      </Popconfirm>
    </div>
  )
}

export default RemoveLink;
