import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import { Form, Layout, Button, Drawer, Breadcrumb } from "antd";

import SearchForm from "../../components/SearchForm";
import SearchAction from "../../components/SearchAction";
import SearchTable from "../../components/SearchTable";
import CustomerForm from "../../views/customers/Form";
import SearchLayout from "../layout/Search.js";
import Entity from "../../entities";
import {PlusOutlined} from "@ant-design/icons";
import Info from "../info/Info";

const { Content } = Layout;

const customer = Entity["customer"];

const Customers = ({newRecord1}) => {
  const history = useHistory();

  return (
    <SearchLayout section='Customers'>
      <SearchForm placeholder='Search customers' modelName={Customers} />
      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/customers/new'
        style={{marginTop: '12px'}}>
        Add customer
      </SearchAction>
      <SearchTable
        style={{ marginTop: '70px' }}
        query={customer.searchQuery}
        {...customer.search}
        onAdd={() => {
          history.push("/customers/new");
        }}
        onClick={(record) => {
          history.push(`/customers/${record?.id}`);
        }}
        modelName="customers"
        emptyRender={<Info model="customers" />}
      />

      {newRecord1 && (
        <Drawer
          title="Add customer"
          width={520}
          closable={false}
          visible={true}
          onClose={() => {
            history.push("/customers");
          }}
        >
          <CustomerForm id="new" />
        </Drawer>
      )}
    </SearchLayout>
  );
};

export default Customers;
