import React from 'react';
import '../styles/App.less';
import { Layout } from 'antd';
import { useLocation, useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import { activeUserVar } from '../cache';
const apiurl = process.env.REACT_APP_API_URL;

import CloudAccounts from './search/CloudAccounts';
import Contacts from './search/Contacts';
import Customers from './search/Customers';
import Datacenters from './search/Datacenters';
import DNS from './search/DNS';
import Exports from './search/Exports';
import Imports from './search/Imports';
import Importer from './imports/Importer';
import IPAssignments from './search/IPAssignments';
import Network from './search/Network';
import Projects from './search/Projects';
import Resources from './search/Resources';
import Sites from './search/Sites';
import SupportGroups from './search/SupportGroups';
import Subnets from './search/Subnets';
import UnAuthorizedAccess from './search/UnAuthorizedAccess';
import Users from './search/Users';
import UserGroups from './search/UserGroups';
import Roles from './search/Roles';
import Zones from './search/Zones';

import GettingStarted from './account/GettingStarted.js';

import Planner from './Planner';
import Reports from './reports/Reports';
import Plan from './plans/Record';
import Subnet from './subnets/Subnet';
import Project from './projects/Project';
import SubnetTemplate from './subnets/Template';
import SupportGroup from './support-groups/Record';

import Register from './authentication/Register.js';
import Login from './authentication/Login.js';
import ResetPassword from './authentication/ResetPassword.js';
import ForgotPassword from './authentication/ForgotPassword.js';

import Navbar from './layout/Navbar';
import SearchLayout from './layout/Search';
import RecordLayout from './layout/Record';
import SystemAlert from './layout/SystemAlert';
import Welcome from './account/Welcome';

import Requests from './search/Requests';

import TidalSummary from './tidal-admin/Summary';
import OrganizationSearch from './tidal-admin/OrganizationSearch';
import UserSearch from './tidal-admin/UserSearch';
import TidalRegistrations from './tidal-admin/Registrations';
import TidalActivity from './tidal-admin/Activity';
import SupportTicketSearch from './tidal-admin/SupportTicketSearch.js';

import Landing from './landing/Landing';
import Admin from './admin/Admin';
import Account from './account/Account';
import Profile from './account/Profile';
import Alerting from './admin/Alerting.js';
import UserCommunicationVersionSearch from './tidal-admin/UserCommunication.js';
import NotFound404 from './search/404-not-found.js';
import Customer from './customers/Customer.js';

const { CURRENT_USER_QUERY, LOGOUT_MUTATION } = require('../entities/auth');

const { Content } = Layout;

const getRole = (user) => {
  const orgs = user?.UserOrganizations?.filter(({ Organization }) => {
    return Organization.id == user?.currentOrganizationId
  })

  return (orgs?.[0]?.role || user?.UserOrganizations?.[0]?.role)
}

const App = () => {
  const client = useApolloClient();
  const { loading, error, data } = useQuery(CURRENT_USER_QUERY);

  FreshworksWidget('hide', 'launcher');

  const validEmails = ['andrew@tidalmigrations.com', 'deep.bhalotia@tidalmigrations.com', 'amber.weaver@tidalmigrations.com', 'david@tidalmigrations.com', 'ravi.gaddam@tidalmigrations.com'];

  const location = window.location.href
  const baseView = location.split('/')[3]

  const isExternal = (baseView == 'invite' || baseView == 'planner')

  const [logout, { }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      activeUserVar()
      const query = {
        query: CURRENT_USER_QUERY,
        variables: {}
      }
      client.writeQuery({
        ...query,
        data: { currentUser: null }
      })
      window.location.href = '/';
    }
  })

  const currentUser = data?.currentUser;

  activeUserVar(currentUser)

  const isLoggedIn = !!currentUser;
  const newUser = currentUser?.loginCount <= 2;

  const profileComplete = Object.keys(currentUser?.profile || {}).length == 6



  const showWelcome = (newUser && !currentUser?.hideWelcome) || !profileComplete
  const role = getRole(currentUser)

  const searchParams = window?.location?.search?.slice(1);
  const isCli = searchParams.split('=')?.[0] == 'cli';
  const cliToken = searchParams.split('=')?.[1];
  if (isLoggedIn && isCli) {
    const logCliToken = async (event) => {
      try {
        const response = await fetch(`${apiurl}/check-clitoken`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ cli: cliToken, userId: currentUser.id }),
        });
    
        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response:", errorText);
          return;
        }
        const clidata = await response.json();
      } catch (error) {
        console.error("Fetch error:", error);
      }
    
      setTimeout(() => {
        window.close();
      }, 3000);
    };
    logCliToken();
  }

  if (loading) return <div />;
  if (error) return <div>Error: {JSON.stringify(error)}</div>;
  if (isLoggedIn && !isExternal && !isCli) {
    return (
      <div className="App">
        <Router>
          {showWelcome && (role !== 'Requestor') && (
            <Layout className="welcome-layout">
              <Content style={{ background: ' #FFFFFF', padding: '20px', marginTop: '80px' }}>
                <Welcome />
              </Content>
            </Layout>
          )}
          {(role == 'Requestor') && (
            <Layout className="site-layout">
              <>
                <Navbar role='Requestor' user={data?.currentUser} logout={logout} />
                <Content style={{ padding: '0px 0px 0px', marginTop: '80px' }}>
                  <SystemAlert />
                  <Switch>
                    <Route exact path="/" render={props => (<Requests />)} />
                    <Route exact path="/requests" render={props => (<Requests />)} />
                    <Route exact path="/requests/:id" render={props => (<Requests {...props} />)} />
                    <Route path="/account/:section(profile|history)" render={props => (<Account {...props} user={data?.currentUser} />)} />
                    <Route component={UnAuthorizedAccess} />
                  </Switch>
                </Content>
              </>
            </Layout>
          )}
          {!showWelcome && !isExternal && (role !== 'Requestor') && (
            <Layout className="site-layout">
              <>
                <Navbar role={role || 'Owner'} user={data?.currentUser} logout={logout} />
                <Content style={{ padding: '0px 0px 0px', marginTop: '80px' }}>
                  <SystemAlert />
                  <Switch>
                    <Route exact path="/" render={props => (<Subnets {...props} />)} />

                    <Route exact path="/:action(register|login|forgot_password)" render={props => (<Network />)} />
                    <Route exact path="/activation_success" render={() => <IPAssignments />} />

                    {/* SEARCH  */}

                    <Route exact path="/network" render={props => (<Network />)} />
                    <Route exact path="/network/:childModel(ip-assignments|subnets)/new" render={props => (<Network />)} />
                    <Route exact path="/network/:childModel(ip-assignments|subnets)/:id" render={props => (<Network />)} />

                    <Route exact path="/ip-assignments" render={props => (<IPAssignments {...props} />)} />
                    <Route exact path="/ip-assignments/:id" render={props => (<IPAssignments {...props} />)} />

                    <Route exact path="/subnets" render={props => (<Subnets {...props} />)} />
                    <Route exact path="/subnets/:id(new)" render={props => (<Subnets {...props} />)} />

                    <Route exact path="/page-not-found" render={props => (<NotFound404 {...props} />)} />


                    {/* SUBNETS */}

                    <Route path="/:model(subnets)/:supernetId/edit" key=":supernetId" render={props => (<Subnet editing={true} {...props} />)} />
                    <Route path="/:model(subnets)/:supernetId/:subnetId/edit" key=":supernetId" render={props => (<Subnet editing={true} {...props} />)} />
                    <Route path="/:model(subnets)/:supernetId/:subnetId/:childView(dashboard|summary|ip-addresses|reservations|subnets|nats|activity|requests|resources|support-groups|contacts|role-assignments|customers|alerts|imports)/:childId" key=":supernetId" render={props => (<Subnet {...props} />)} />
                    <Route path="/:model(subnets)/:supernetId/:subnetId/:childView(dashboard|summary|ip-addresses|reservations|subnets|nats|activity|requests|resources|support-groups|contacts|role-assignments|customers|alerts|imports)" key=":supernetId" render={props => (<Subnet {...props} />)} />
                    <Route path="/:model(subnets)/:supernetId/:subnetId?" key=":supernetId" render={props => (<Subnet {...props} />)} />

                    <Route exact path="/customers" render={props => (<Customers {...props} />)} />
                    <Route exact path="/customers/new" render={props => (<Customers {...props} newRecord1={true} />)} />
                    <Route path="/customers/:id" render={props => (<Customer {...props} />)} />

                    <Route exact path="/sites" render={props => (<Sites {...props} />)} />
                    <Route exact path="/sites/:id" render={props => (<Sites {...props} />)} />

                    <Route exact path="/resources" render={props => (<Resources {...props} />)} />
                    <Route exact path="/resources/:id" render={props => (<Resources {...props} />)} />

                    <Route exact path="/projects" render={props => (<Projects {...props} />)} />
                    <Route exact path="/projects/:id(new)" render={props => (<Projects {...props} />)} />
                    <Route exact path="/projects/:id" render={props => (<Project {...props} />)} />
                    <Route exact path="/projects/:id/:childView" render={props => (<Project {...props} />)} />
                    <Route exact path="/projects/:id/:childView/new" render={props => (<Project {...props} />)} />
                    <Route exact path="/projects/:id/:childView/:childId" render={props => (<Project {...props} />)} />

                    <Route exact path="/support-groups" render={props => (<SupportGroups {...props} />)} />
                    <Route exact path="/support-groups/:id" render={props => (<SupportGroups {...props} />)} />


                    <Route exact path="/contacts" render={props => (<Contacts {...props} />)} />
                    <Route exact path="/contacts/:id" render={props => (<Contacts {...props} />)} />

                    <Route exact path="/cloud-accounts" render={props => (<CloudAccounts {...props} />)} />
                    <Route exact path="/cloud-accounts/:id" render={props => (<CloudAccounts {...props} />)} />

                    <Route exact path="/datacenters" render={props => (<Datacenters {...props} />)} />
                    <Route exact path="/datacenters/:id" render={props => (<Datacenters {...props} />)} />

                    <Route exact path="/zones" render={props => (<Zones {...props} />)} />
                    <Route exact path="/zones/:id" render={props => (<Zones {...props} />)} />

                    <Route exact path="/getting-started" render={props => (<GettingStarted style={{ margin: 'auto', width: '1200px' }}  {...props} />)} />


                    <Route exact path="/imports" render={props => (<Imports {...props} />)} />
                    <Route exact path="/imports/:id" render={props => (<Importer {...props} />)} />
                    <Route exact path="/imports/:id/:childView" render={props => (<Importer {...props} />)} />

                    <Route exact path="/exports" render={props => (
                      data.currentUser.UserOrganizations[0].role === 'Owner'
                        ? <Exports {...props} />
                        : <UnAuthorizedAccess />
                    )} />

                    <Route exact path="/dns" render={props => (<DNS {...props} />)} />
                    <Route exact path="/dns/:id" render={props => (<DNS {...props} />)} />
                    <Route exact path="/dns-entries/:id" render={props => (<DNS {...props} />)} />

                    <Route path="/network/:childModel(ip-assignments|subnets)/new" key=":model" render={props => (<Search defaultModel="network" {...props} />)} />
                    <Route path="/network/:childModel(ip-assignments)/:id" key=":model" render={props => (<Search defaultModel="network" {...props} />)} />

                    {/* TIDAL */}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin" render={props => <RecordLayout section="Summary"><TidalSummary {...props} /></RecordLayout>} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/summary" render={props => (<RecordLayout section="Summary"> <TidalSummary {...props} /> </RecordLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/users" render={props => (<SearchLayout section="Search"> <UserSearch {...props} /> </SearchLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/users/:id" render={props => (<SearchLayout section="Search"> <UserSearch {...props} /> </SearchLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/organizations" render={props => (<SearchLayout section="Search"> <OrganizationSearch {...props} /> </SearchLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/organizations/:id" render={props => (<SearchLayout section="Search"> <OrganizationSearch {...props} /> </SearchLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/registrations" render={props => (<SearchLayout section="Registrations"> <TidalRegistrations {...props} /> </SearchLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/activity" render={props => (<SearchLayout section="Activity"> <TidalActivity {...props} /> </SearchLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/user-communication" render={props => ( <SearchLayout section="User Communication"> <UserCommunicationVersionSearch {...props}  /> </SearchLayout>)} />)}
                    {(validEmails.includes(data?.currentUser.email)) && (<Route exact path="/tidal-admin/support-ticket" render={props => (<SearchLayout section="Support Tickets"> <SupportTicketSearch {...props} /> </SearchLayout>)} />)}

                    {/* REPORTS */}
                    <Route exact path="/reports" render={props => (<Reports {...props} />)} />
                    <Route path="/reports/:reportType(device-allocation|customers|dns)" render={props => (<Reports {...props} />)} />
                    <Route exact path="/reports" render={props => (<Reports {...props} />)} />
                    <Route exact path="/reports" render={props => (<Reports {...props} />)} />


                    {(role !== 'Reader') && (
                      <>
                        <Route exact path="/users" render={props => (<Users {...props} />)} />
                        <Route exact path="/users/:id" render={props => (<Users {...props} />)} />

                        <Route exact path="/user-groups" render={props => (<UserGroups {...props} />)} />
                        <Route exact path="/user-groups/:id" render={props => (<UserGroups {...props} />)} />

                        <Route exact path="/roles" render={props => (<Roles {...props} />)} />
                        <Route exact path="/roles/:id" render={props => (<Roles {...props} />)} />

                        {/* ADMIN */}

                        <Route exact path="/settings/:section(roles|contacts|datacenters|support-groups|imports|custom-attributes|synchronization)/:id" render={props => (<Admin {...props} />)} />
                        <Route path="/settings/:section(roles|contacts|datacenters|permissions|contacts|imports)/:id/:childView(customers|contacts|sites|zones|subnets|support-groups|ip-assignments)" render={props => (<Admin {...props} />)} />
                        <Route path="/settings/:section(profile|roles|contacts|datacenters|api-keys|alert-destination|alert-rule|billing|permissions|support-groups|license|imports|custom-attributes|downloads|synchronization)" render={props => (<Admin {...props} />)} />
                        <Route path="/account/:section(profile|history|notification)" render={props => (<Account {...props} user={data?.currentUser} />)} />
                        <Route path="/settings/alerting/:section(alert-destinations|alert-rules)" render={props => (<Alerting {...props} user={data?.currentUser} />)} />
                      </>
                    )}
                  </Switch>
                </Content>
              </>
            </Layout>
          )
          }
        </Router>
      </div>
    );
  }

  return (
    <div className="App" >
      {isLoggedIn && isCli ? (
        <h1 style={{ display: 'grid', placeItems: 'center', padding: '20px', marginTop: '80px' }}>Login Successful</h1>
      ) : (
        <Router>
          <Switch>
            <Route exact path="/planner" render={props => (<Landing type="planner" />)} />

            <Route path="/register" render={props => (<Register />)} />
            <Route path="/login" render={props => (<Login />)} />
            <Route path="/forgot_password" render={props => (<ForgotPassword />)} />
            <Route path="/reset_password/:token" render={props => (<ResetPassword />)} />

            <Route exact path="/invite/:inviteToken" render={props => (<Register />)} />

            <Route path="*" render={props => (<Login />)} />
          </Switch>
        </Router>

      )}


    </div>
  )
}

export default App;
