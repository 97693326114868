import React, { useState } from 'react';

import {  Row, Col, Button, Drawer, Form, Menu, Layout, Select, Input, Card } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Pie } from 'react-chartjs-2'
import { Link, useHistory, useParams } from "react-router-dom";

import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import { TextInput } from '../../components/pants-d';

import { Subnav, Actions, Links, NavLink } from '../layout/Subnav.js';
import SearchTable from '../../components/SearchTable';
import SearchSelect from '../../components/SearchSelect';
import MultiSelect from '../../components/MultiSelect';
import TextEditable from '../../components/Editable';

import UserUserGroupForm from './UserUserGroupForm';

const { Content } = Layout;
const { Option } = Select;

const userGroup = require('../../entities/user-group')

const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 }

const UserGroupHeader = () => {
  const client = useApolloClient();
  const { id } = useParams();
  let history = useHistory();
  const [editing, setEditing] = useState(false);

  const { loading, error, data, refetch } = useQuery(userGroup.getQuery, { variables: { id: parseInt(id) }});
  const [updateRecord, { data: updateData }] = useMutation(userGroup.update, {onCompleted: (v) => { console.log("completed") }} );

  const [form] = Form.useForm();

  if (loading) {
    return (
      <div className="record-wrapper">
        <div className="record">
        </div>
      </div>
    )
  } else {
    return (
      <h1 style={{ marginTop: '-20px', fontSize: '32px', marginBottom: '5px'}}> 
        <TextEditable 
          style={{ 
            background: 'none', 
            border: 'none', 
            fontSize: '32px', 
            color: '#000000',
            fontWeight: '400',
            padding: '0px', 
          }} 
          value={data?.userGroup?.name || ''} 
          placeholder={'Unnamed subnet'}
          onSubmit={(d) => { 
            updateRecord({variables: {id: parseInt(id), name: d}})
          }}
        />
      </h1>
    )
  }
}

function UserGroupView(props) {
  const client = useApolloClient();
  const { id } = useParams();
  let history = useHistory();

  const [addingUser, setAddingUser] = useState(null)

  const { loading, error, data, refetch } = useQuery(userGroup.getQuery, { variables: { id: parseInt(id) }});
  const [updateRecord, { data: updateData }] = useMutation(userGroup.update, {onCompleted: (v) => { console.log("completed") }} );

  const [form] = Form.useForm();

  if (loading) {
    return(
      <div className="record-wrapper">
        <div className="record">
        </div>
      </div>
    )
  } else {
    return (
      <>
        <Subnav class="sub-nav">
          <Links>
            <NavLink> { data?.userGroup?.name } </NavLink>
          </Links>
          <Actions>
            <NavLink> Delete User Group </NavLink>
            <NavLink onClick={(e) => { setShowingHistory(true) }}> History </NavLink>
          </Actions>
        </Subnav>
        <Content style={{ marginTop: '15px', padding: '40px 0px' }}>
          <div className="record-wrapper">
            <Row gutter={ rowGutter }>
              <Col span={ 24 }>
                <UserGroupHeader id={ id } />
                { !data?.userGroup?.users?.length && !addingUser && (
                  <Row>
                    <Col span={24}>
                      <Button style={{float: 'right', marginTop: '24px'}}onClick={() => { setAddingUser(true) }}>Add users</Button>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col span={24}>
                    { addingUser && ( <UserUserGroupForm onClose={() => setAddingUser(false)} userGroupId={ parseInt(id) } /> )}
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <SearchTable 
                      query={ userGroup.USER_GROUP_USERS }
                      modelName={"user-group-users"} 
                      columns={ userGroup.userSearch.columns }
                      filters={{ userGroupId: parseInt(id) }}
                      onClick={(r) => { null }}
                      onAdd={(e) => { setAddingUser(true)}}>
                    </SearchTable>
                  </Col>
                </Row>
              </Col>
            </Row>

          </div>
        </Content>
      </>) } };

export default UserGroupView;
