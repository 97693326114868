import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Layout, Card, Row, Col, Form, Drawer, Popconfirm, Empty } from 'antd';
import { useLocation, useHistory } from "react-router-dom";
import { Subnav, Actions, Links, NavLink } from './layout/Subnav.js';

import { TextInput } from '../components/pants-d';

import SubnetBuilder from '../components/SubnetBuilder';

const { Content } = Layout;

const { destroy, create, SEARCH_QUERY } = require('../entities/plan')

const dev = process.env.NODE_ENV == 'development'


const PlanForm = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const [createRecord, data] = useMutation(create, {onCompleted: ({ createPlan: p }) => { history.push(`/plans/${p.id}`) }} );

  return (
    <Form
      layout="vertical"
      form={form}
      name="supportGroup"
      initialValues={(data||{})['supportGroup']}
      onFinish={ (values) => createRecord({ variables: {...values}}) } >

      <TextInput name="name"/>

      <TextInput name="description"/>

      <Button type="primary" htmlType="submit">
        Submit
      </Button>

    </Form>
  )
}


const Plan = ({ plan }) => {
  const [destroyRecord, { destroyPlan }] = useMutation(destroy);

  if (destroyPlan) { return ( <></> ) }

  return (
    <Card style={{ marginBottom: '20px', width: '400px' }}>
      <Row>
        <Col span={20}>
        <h2>
          <a href={`/plans/${plan?.id}`} style={{ color: '#000000' }}>
            { plan.name }
          </a>
        </h2>
        </Col>
        <Col span={4}>
          <Actions>
            <NavLink url={`/plans/${plan?.id}`}>Edit</NavLink>
            <NavLink>
              <Popconfirm
                title="Are you sure you want to delete this plan?"
                onConfirm={e => destroyRecord({ variables: {id: plan?.id}, update(cache) {
                  const normalizedId = cache.identify({ id: plan?.id, __typename: 'Plan' });
                  cache.evict({ id: normalizedId });
                  cache.gc();
                }})}>
                <a href="#">Delete</a>
              </Popconfirm>
            </NavLink>
          </Actions>
        </Col>
      </Row>
    </Card>
  )
}
const Planner = ({}) => {
  const history = useHistory();

  const { loading, error, data } = useQuery(SEARCH_QUERY, {});

  const routeSegments = useLocation().pathname.split('/');
  const newRecord = routeSegments[(routeSegments?.length || 0) - 1] === 'new';

  if (loading) { return ( <></> ) }

  return (
    <>
      <Subnav className="sub-nav">
        <h1 style={{marginTop: '10px', display: 'inline-block'}}>Planner</h1>

        <Actions>
          <NavLink url="/plans/new"> New Plan </NavLink>
        </Actions>
      </Subnav>

      <Content style={{ marginTop: '15px', padding: '40px 25px', minHeight: '800px' }}>

        { data?.plans?.results?.map(p => ( 
          <Plan plan={p} />
        ))}

        { (data?.plans?.results?.length == 0 ) && ( 
          <Empty
            style={{ marginTop: '40px', }}
            description={ <div style={{marginTop: '40px', fontSize: '20px'}}> Looks like you don't have any plans yet! </div>} >
            <Button type="primary" style={{marginTop: '30px'}} href="/plans/new"> Create a Plan </Button>
          </Empty>
        )}


        { newRecord && ( 
          <Drawer
            title='Add plan'
            width={520}
            closable={false}
            visible={true}
            onClose={() => { history.push(`/plans`) }}>

            <PlanForm /> 

          </Drawer>
        ) }
      </Content>
    </>
  )
}

export default Planner;
