import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";

import {  Form, Layout, Button, Drawer } from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import SearchTable from '../../components/SearchTable';
import SearchForm from '../../components/SearchForm';
import UserGroup from '../../views/user-groups/Record';
import UserGroupForm from '../../views/user-groups/Form';
import Entity from '../../entities';
import SearchLayout from '../layout/Search';
import SearchAction from '../../components/SearchAction';

const userGroup = Entity['user-group']

const UserGroups = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'

  return (
    <SearchLayout section='Groups'>
      {(!id || newRecord) && (
        <>
          { !newRecord && (
            <>
              <SearchAction 
                icon={ <PlusOutlined /> }
                url='/user-groups/new'
                style={{marginTop: '12px'}}>
                Add User Group
              </SearchAction>
            </>
          )}

          {(newRecord) && ( <UserGroupForm id={ id } /> )}
          <SearchForm placeholder='Search groups' modelName={UserGroups} />
          <SearchTable 
            style={{ marginTop: '70px' }}
            query={ userGroup.searchQuery }
            { ...userGroup.search }
            onAdd={() => { history.push('/user-groups/new') }}
            onClick={(record) => { history.push(`/user-groups/${record?.id}`) }}
            modelName='user-groups' />
          </>
      )}

      {(id && !newRecord) && ( <UserGroup id={ id } /> )}

    </SearchLayout>
  )
};

export default UserGroups;
