import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input, Space, Alert, Drawer } from 'antd';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { TextInput, TextArea, SearchSelect, Select } from '../../components/pants-d';

const { Option } = Select;

const { SEARCH_IDENTITIES } = require("../../entities/user");

const role = require("../../entities/role");
const roleAssignment = require("../../entities/role-assignment");

const subnet = require("../../entities/subnet");

const RoleAssignmentForm = ({ onCreate, onClose }) => {
  const { model, subnetId, supernetId, childId } = useParams();
  const newRecord = childId == 'new'

  const history = useHistory();

  const [open, setOpen] = useState(true);

  const [form] = Form.useForm();


  const [getRoleAssignment, { loading, error: recordError, data }] = useLazyQuery(roleAssignment.getQuery, { variables: { id: parseInt(childId) }});
  const { loading: dataLoading, error:dataError, data:subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });

  useEffect(() => { if ( childId && !newRecord ) { getRoleAssignment({ variables: { id: parseInt(childId) }}) } },[])

  const [ updateRoleAssignment, {}] = useMutation(roleAssignment.update, {
    onCompleted: onClose,
    onError: ( e ) => { console.log(e) }
  })

  const [ createRoleAssignment, { createData, error }] = useMutation(roleAssignment.create, {
    onCompleted: onClose,
    onError: ( e ) => { console.log(e) },
    update(cache, { data }) {

      const searchVariables = {search: "", offset: 0, limit: 50, recordId: parseInt(subnetId || supernetId), recordType: 'Subnet'  }
      const existingRoleAssignments = cache.readQuery({ query: roleAssignment.searchQuery, variables: searchVariables })

      cache.writeQuery({
        query: roleAssignment.searchQuery,
        variables: searchVariables,
        data: {
          roleAssignments: {
            count: existingRoleAssignments?.count + 1,
            results: [...(existingRoleAssignments.results || []), data.createRoleAssignment]
          }
        }
      })

      onClose();
    }
  })

  if ( loading ) { return <></>  }


  return (
    <Drawer
      title="Manage role assignments"
      onClose={ onClose }
      width={520}
      visible={open}>
      <Form
        layout="vertical"
        form={form}
        name="roleAssignment"
        initialValues={ data ? { 
          identity: { 
            id: `${data?.roleAssignment?.subjectType}_${data?.roleAssignment?.subjectId}`,
          },
          role: {
            id: parseInt(data?.roleAssignment?.role?.id)
          }
        } : {} }
        onFinish={ ({ identity, role }) => {

          const { subnet } = subnetData 

          const payload = { 
            recordId: parseInt(subnetId || supernetId), 
            recordType: 'Subnet',
            subjectId: parseInt(identity.id.split('_')[1]),
            subjectType: identity.id.split('_')[0],
            roleId: role?.id,
            roleName: role?.name,
            userName: identity?.name,
            networkAddress: subnet?.networkAddress,
            zoneId: subnet?.zone?.id
          }
        
          if (childId && childId !== 'new') {
            updateRoleAssignment({ variables: { id: parseInt(childId), ...payload } });
          } else {
            createRoleAssignment({ variables: payload, scope: { 
              type: 'Record',
              recordType: 'Subnet', 
              recordId: parseInt(subnetId || supernetId), 
              networkAddress: subnetData?.subnet?.networkAddress ,
              zoneId: subnetData?.subnet?.zone?.id
            }});
          }
        }}
         >

        <SearchSelect
          name="identity"
          model="identities"
          query={ SEARCH_IDENTITIES }
          resultKey='identities'
          label='User / Group'
          defaultValue={ data ? { 
            id: `${data?.roleAssignment?.subjectType}_${data?.roleAssignment?.subjectId}`,
          } : {}}
          displayFn={(val) => val.__typename == 'User' ? `${val.firstName} ${val.lastName}` : val.name}
          keyFn={(record) => { return (record.__typename + '_' + record.id) }}
          required
        />

        <SearchSelect
          name="role"
          model="roles"
          query={ role.searchQuery }
          defaultValue={{ 
            name: data?.roleAssignment?.role?.name,
            id: data?.roleAssignment?.role?.id }}
          resultKey='roles'
          displayKey='name'
          required
        />

        <Button type="secondary" onClick={e => { onClose() }} style={{float: 'right', marginLeft: '20px'}}>
          Cancel
        </Button>
        <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}} htmlType="submit">
          Submit
        </Button>
      </Form>
    </Drawer>
  )
}

export default RoleAssignmentForm;
