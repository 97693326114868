import React, { useState } from 'react';
import { loader } from 'graphql.macro';

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const destroy = loader('./mutations/destroy.graphql')

const search = {
  query: searchQuery,
    columns: [
      { title: 'User', render: item => `${item.User.firstName} ${item.User.lastName} - ${item.User.email} ` },
      { title: 'Message', dataIndex: 'message'}
    ]
  }

export { search, getQuery, searchQuery, create, destroy };