import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from "react-router-dom";
import SearchAction from '../../components/SearchAction';
import { PlusOutlined } from "@ant-design/icons";
import AlertDestinationForm from '../alerting/AlertDestinationForm';
import SearchTable from '../../components/SearchTable';
import AlertRuleForm from '../alerting/AlertRuleForm';
import { Drawer } from 'antd';

const alertRule = require('../../entities/alert-rule');


const AlertRule = ({ }) => {

  const history = useHistory();
  const { id: paramId } = useParams();
  const [id, setId] = useState(paramId === 'new')
  const [newRecord, setNewRecord] = useState(paramId === 'new');

  const [refetch, setRefetch] = useState()

  const [showAlertRuleForm, setShowAlertRuleForm] = useState(false);

  const handleAddAlertRuleClick = () => {
    history.push('/settings/alerting/alert-rules/new');
    setId("new");
    setNewRecord(true);
    setShowAlertRuleForm(true);
  };


  const handleCloseAlertRuleClick = () => {
    history.push('/settings/alerting/alert-rules');
    setShowAlertRuleForm(false);
  };

  const handleAlertRuleClick = (record) => {
    history.push(`/settings/alerting/alert-rules/${record?.id}`);
    setNewRecord(false);
    setId(record?.id);
    setShowAlertRuleForm(true);
  };

  const { search } = alertRule.search;

  return (
    <>
      <h2>Alert Rules</h2>
      <h1>
        <SearchAction
          icon={<PlusOutlined />}
          url='/settings/alerting/alert-rules/new'
          onClick={handleAddAlertRuleClick}
          style={{ marginBottom: '20px', marginTop: '0px' }}>
          Add Alert Rule
        </SearchAction>
      </h1>
      <SearchTable
        query={alertRule.searchQuery}
        {...alertRule.search}
        onAdd={() => {
          history.push("/settings/alerting/alert-rules/new");
        }}
        refetch={refetch}
        onClick={handleAlertRuleClick}
        modelName='alert rule'
      />
      {showAlertRuleForm && (
        <Drawer
          title={newRecord ? `Add Rule` : `Update Rule`}
          width={520}
          closable={false}
          visible={true}
          onClose={handleCloseAlertRuleClick}
        >
          <AlertRuleForm id={id}
            onClose={handleCloseAlertRuleClick}
          />
        </Drawer>
      )}
    </>
  )
};

export default AlertRule;
