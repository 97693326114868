import React, { useEffect, useState } from 'react';
import { LeftOutlined, SyncOutlined } from '@ant-design/icons';
import {  Row, Col, Layout, Breadcrumb, Radio, List, Typography, Avatar } from 'antd';

import { searchVar } from '../../cache.js';

import { Subnav, Links, Actions, NavLink } from '../layout/Subnav.js';
import { SubnetBuilder, NetmaskForm } from '../../components/SubnetBuilder';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';

import { Area, Pie } from '../../components/Charts';
import SearchForm from '../../components/SearchForm.js';
import SearchTable from '../../components/SearchTable.js';
import SearchAction from '../../components/SearchAction';
import Entity from '../../entities';

import Planner from '../plans/Record';

const { getQuery } = require('../../entities/project');

const { Content } = Layout;

const searchButtonStyle = {width: '130px', textAlign: 'center'};

const Overview = () => {

  const { id, childView, childId } = useParams()
  const [searchView, setSearchView] = useState('plan')


  return (
      <Content style={{ marginTop: '25px', padding: '40px 40px' }}>
        <Row colSpan={ 12 }>
          <Col span={ 6 } offset={ 0 }>
            <h3 style={{ marginBottom: '20px'}}>Project Impact</h3>
            <List
              dataSource={[
                ["Changes", 238],
                ["Subnets", 86],
                ["IP Assignments", 21385],
                ["Customers", 7],
                ["Resources", 24844],
              ]}
              renderItem={([k, v]) => (
                <List.Item style={{ fontSize: '14px', padding: '10px 10px 10px 0px' }}>
                  { k }
                <b style={{ float: 'right' }}>{ v }</b>
                </List.Item>
              )}
            />
          </Col>

          <Col span={ 7 } offset={ 2 }>
            <h3>Progress</h3>
            {/** <Area style={{ padding: '0px' }}/> **/}
          </Col>

          <Col span={ 8 } offset={ 1 }>
            <h3>Recent Activity</h3>

            <List
              dataSource={[
                ["Andrew Joe", "marked planned change 287 complete", "10:10 am"],
                ["David Colebatch",  "created plan change 383", "9:15 am"],
                ["Sam King", "created plan change 383", "7:30 am"]
              ]}
              renderItem={([user, value, time]) => (
                <List.Item style={{ fontSize: '12px', padding: '5px 10px 5px 0px', marginTop: '20px' }}>
                  <List.Item.Meta
                    avatar={<Avatar style={{ backgroundColor: '#f56a00' }}>{user[0]}</Avatar>}
                    description={ <a style={{ float: 'left' }}>{user} { value }</a> }
                  />

                  <div style={{ float: 'right', fontSize: '14px' }}>{time}</div>
                </List.Item>
              )}
            />

          </Col>

        </Row>

        <Row style={{ marginTop: '60px' }}>
          <Col span={24}>
            <SearchForm style={{float: 'left', display: 'inline-block'}} modelName={ 'project' }/>
            <Radio.Group value={ searchView } onChange={(e) => setSearchView(e.target.value)}  style={{ marginLeft: '20px', marginTop: '8px'}}>
              <Radio.Button style={{...searchButtonStyle }} value={ 'plan' }>Plans</Radio.Button>
              <Radio.Button style={{...searchButtonStyle }} value={ 'planned-changes' }>Changes</Radio.Button>
              <Radio.Button style={{...searchButtonStyle }} value={ 'customers' }>Customers</Radio.Button>
              <Radio.Button style={{...searchButtonStyle }} value={ 'contacts' }>Contacts</Radio.Button>
              <Radio.Button style={{...searchButtonStyle }} value={ 'permissions' }>Permissions</Radio.Button>
              <Radio.Button style={{...searchButtonStyle }} value={ 'support' }>Support</Radio.Button>
            </Radio.Group>

            { searchView == 'plan' && (<>
              <SearchAction url={`/projects/${id}/plans/new`}>New Plan</SearchAction>
              <SearchTable { ...(Entity.plan.search) } /> 
            </>)}

            { searchView == 'planned-change' && (<>
              <SearchAction url={`/projects/planned-changes/new`}>New Plan</SearchAction>
              <SearchTable { ...(Entity.planned-change.search) } /> 
            </>)}

            { searchView == 'customers' && ( <>
              <SearchAction url={`/projects/customers/new`}>New Plan</SearchAction>
              <SearchTable { ...(Entity.customer.search) } /> 
            </>)}

            { searchView == 'contacts' && ( <>
              <SearchAction url={`/projects/contacts/new`}>New Plan</SearchAction>
              <SearchTable { ...(Entity.contact.search) } /> 
            </>)}

            { searchView == 'permissions' && ( <> 
              <SearchAction url={`/projects/permissions/new`}>New Plan</SearchAction>
              <SearchTable { ...(Entity.permissions.search) } /> 
            </>)}

            { searchView == 'support' && ( <>
              <SearchAction url={`/projects/support/new`}>New Plan</SearchAction>
              <SearchTable { ...(Entity.plan.search) } /> 
            </>)}
          </Col>
        </Row>
      </Content>
  )
}


const ProjectView = () => {

  const { id, childView, childId } = useParams()

  const { data, error, loading } = useQuery(getQuery, { variables: { id: parseInt(id) }})

  if ( loading ) { return (<></>) }

  return (
    <>
      <Subnav class="sub-nav">
        <h1 style={{marginTop: '10px', marginLeft: '20px', display: 'inline-block', float: 'left', fontWeight: '300', fontSize: '22px'}}>{ data?.project?.name }</h1>

        <Links style={{marginTop: '4px', marginLeft: '250px' }} selectedKeys={[childView || 'overview']}>
          <NavLink url={ `/projects/${id}`} key="overview"> 
            Overview
          </NavLink>
          <NavLink url={ `/projects/${id}/planner`} key="planner">
            Planner
          </NavLink>
          <NavLink url={ `/projects/${id}/scope`} key="scope">
            Scope
          </NavLink>
        </Links>

        <Actions>
          <NavLink>

          </NavLink>
        </Actions>
      </Subnav>

        { !childView && ( <Overview /> )}
        { childView == 'planner' && ( <Planner />)}
    </>
  )
};

export default ProjectView;
