import React from 'react'
import { loader } from 'graphql.macro';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';
import { Link, useHistory, useParams } from "react-router-dom";

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  
const subnet = require("../../entities/subnet");

const RemovePermissionSubnetLink = ({ record }) => {

  const { subnetId, supernetId } = useParams();

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Role Assignment"
        entityTitle="Role Assignment"
        redirectPath={`/subnets/${supernetId}/${subnetId || supernetId}/role-assignments`}
      />
    </div>

  )
}

const search = {
  columns:  [
    {title: 'Assignee', width: 400, key: 'assignee', render: record => <>{ record?.user?.email ? `${record?.user?.firstName} ${record?.user?.lastName} <${record?.user?.email}>` : record?.userGroup?.name }</>},
    {title: 'Role', width: 100, dataIndex: 'role', key: 'role', render: role => <>{ role?.name }</>},
    {title: 'Scope', width: 200, key: 'role', render: (r) => <>{ r.recordType ? ((r?.zoneId ? `/zones/${r?.zoneId}/ ` : ' ') +  r?.networkAddress) : 'App' }</>},
    {title: 'Description', dataIndex: 'role', key: 'role', render: role => <>{ role?.description }</>},
    { title: '', width: 100, key: 'remove', render: ra => <RemovePermissionSubnetLink record={ ra }/> }
  ]
}


const form = {
  fields: [
    ["name", {}],
  ]
}

const importer = {
  title: 'Role Assignments',
  copy: 'In Lightmesh, "Role Assignments" refer to the process of granting specific permissions and responsibilities to users or groups, determining what actions they can perform within the Lightmesh system. This helps control access and maintain security when managing IP addresses and network resources.',
}

export { search, form, searchQuery, getQuery, create, update, destroy, importer };
