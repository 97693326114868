import React from 'react';
import { Table } from 'antd';
import { useQuery, useReactiveVar } from '@apollo/client'; 
import _ from "lodash";

const humanNumber = require('../utils/number')

function Metric({ 
  query, 
  label,
  variables,
  data,
  value,
  style={},
  ...props
}) {


  const { data: response, refetch } = useQuery(query, {
    variables: { ...variables },
  });

  const result = response ? Object.values(response)?.[0] : []

  return (
    <div className='metric rounded' style={{ background: '#deecf7', ...style }}>
      <h1 style={{ fontSize: '4em', textAlign: 'center', marginBottom: '0px'}}>
        { humanNumber(result?.value || value || 0)  }
    </h1>
      <h2 style={{ textAlign: 'center', lineHeight: '20px', marginBottom: '0px' }}>{ label }</h2>
    </div>
  )
}

export default Metric;
