import React, { Component, useState, useForm, useEffect } from 'react';
import { Menu, Row, Col } from 'antd';
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

const SideLayout = ({links = [], children}) => {

  return (
    <Row gutter={ 44 }>
      <Col flex={ '300px' }>
        <Menu 
          mode="inline" 
          style={{ 
            marginTop: '0px',
            marginRight: '40px',
            border: '0px solid #e9edf1',
            borderRadius: '4px',
            paddingTop: '5px',
            paddingBottom: '10px',
          }}>
          { links.map(({url, title, render}, idx) => {
            return (
              <Menu.Item key={idx} style={{fontSize: '15px', color: '#5a7890', paddingLeft: '12px'}}>

                { render && render() }
                { !render && (
                  <>
                    <Link to={url} />
                    <span>{ title }</span>
                  </>
                )}
              </Menu.Item>
            )
          })}
        </Menu>
      </Col>
      <Col span= {18} flex={ 1 }>
        { children }
      </Col>
    </Row>
  )
}

export default SideLayout;
