import React from 'react';
import _ from "lodash";
import {useHistory} from 'react-router-dom';

const defaultStyle = { 
  fontSize: '15px', 
  fontWeight: '500', 
  padding: '0px 4px 2px 0px', 
  float: 'right', 
  margin: '14px 12px 0 0', 
  color: '#5384ac' 
}

const SearchAction = ({ icon, url, children, onClick, style, ...props }) => {

  const history = useHistory();

  return (
    <a size='small' type="primary" onClick={(e) => { onClick ? onClick() : history.push( url ) }} style={{ ...defaultStyle,  ...style }} { ...props } >
      { icon }
      <div style={{ display: 'inline-block', marginLeft: icon ? '10px' : '0px' }}>
      { children }
      </div>
    </a>
  )
}

export default SearchAction;
