import React, { useState } from 'react';
import { Divider, Form, Button, Alert, Row, Col, Spin } from 'antd';
import {  useMutation, useApolloClient } from '@apollo/client';
import _ from 'lodash';

import { TextInput, PasswordInput, Submit } from '../../components/pants-d';
import AuthLayout from '../layout/Auth.js';
import { useHistory } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import PasswordStrengthBar from 'react-password-strength-bar';

const { REGISTRATION_MUTATION, CURRENT_USER_QUERY } = require('../../entities/auth')

import Providers from './providers/Providers.js';

export const triggerGoogleAnalyticsEvent = () => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'conversion', { 'send_to': 'AW-836050445/oNLvCKmMnJUZEI281I4D' });
  }
};

function Register() {
  const client = useApolloClient();

  const history = useHistory();
  
  const searchParams = window?.location?.search?.slice(1)

  const isInvite = searchParams.split('=')?.[0] == 'invite'
  const token = isInvite ? searchParams.split('=')?.[1] : ''

  const [register, { error } ] = useMutation(REGISTRATION_MUTATION, {
    onCompleted: ({ register }) => {
      const query = {
        query: CURRENT_USER_QUERY,
        variables: {}
      }
      client.writeQuery({
        ...query,
        data: { currentUser: register.user}
      })

      history.push('/')
    },
    onError: (e) => { console.log(e) }
  })

  const [form] = Form.useForm();

  const [showPasswordHint, setShowPasswordHint] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordError, setPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);


  

  const onFinish = async (values) => {
    setLoading(true);
    if (values?.password.length < 8 || values?.password.length > 64) {
      setLoading(false);
      setPasswordError('The password must be at least 8 characters long and not longer than 64 characters.'); // Set the password error
      return;
    } 

    try {
      await register({ variables: { ...values, token: token } })
      triggerGoogleAnalyticsEvent(); // Trigger the event after successful registration
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (value.trim() !== '') {
      setShowPasswordHint(false);
      const strengthScore = zxcvbn(value).score;
      setPasswordStrength(strengthScore);
    } else {
      setShowPasswordHint(true);
      setPasswordStrength(0);
    }
  };

  return (
    <AuthLayout>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spin tip="Creating your account" />
        </div>
      ) : (
      <>
        <h1 style={{textAlign: 'center', fontWeight: '600', marginBottom: '40px', fontSize: '17px', color: '#b463b6'}}>
          <div style={{ fontSize: '22px', lineHeight: '32px' }}>Join over 1000</div>
          teams on Lightmesh
        </h1>

        { isInvite && (
          <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>
            Create an account to accept your invite
          </h2>
        )}

        <div style={{ padding: '0px 38px 38px' }}>
          <Row gutter={ 12 }>
            <Col span={ 24}>
                <Form id="registration-form" layout="vertical" form={form} onFinish={onFinish}>
                  {(error || passwordError) && (
                    <>
                      <Alert message={error ? error.message : passwordError} type="error" />
                      <br />
                    </>
                  )}

                  <TextInput placeholder="name@email.com" required name="email" />
                  <Row style={{ marginTop: '20px' }} gutter={24}>
                    <Col span={24}>
                      <PasswordInput
                        required
                        name="password"
                        label="Password"
                        placeholder="Password +8 characters"
                        onChange={handlePasswordChange}
                      />
                      <div className="password-strength-bar">
                        { form.getFieldValue('password')?.length > 0 && (
                          <PasswordStrengthBar
                            style={{ marginTop: '0px'}}
                            password={form.getFieldValue('password')}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Submit label="Register" loading={loading}/>

                  <p style={{ color: '#AAAAAA', marginTop: '25px', fontSize: '11px' }}>
                    By clicking Google, Microsoft, or Register  you agree with and accept the <a target="_blank" href="https://tidalcloud.com/customer-terms">Customer Terms of Service</a> and <a target="_blank" href="https://tidalcloud.com/privacy-policy">Privacy Policy</a>.
                  </p>

                </Form>
            </Col>
          </Row>
              <Divider style={{ fontSize: '12px', color: '#AAAAAA', marginBottom: '40px' }}>Or Continue With</Divider>
              <Providers />
        </div>

        <Row>
          <Col span={ 12 } offset={ 5 }>
            {['login', 'forgot_password'].map(link => {
              return (
                <Button style={{ width: '50%', textAlign: 'center', fontSize: '14px' }} type="link" htmlType="button" href={`/${link}`}>
                  { _.startCase(link) }
                </Button>
              )
            })}
          </Col>
        </Row>
      </>
    )}
    </AuthLayout>
  )
}

export default Register;
