import React from 'react'
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Popconfirm, Alert } from 'antd';
import RemoveLink from '../../components/RemoveLink';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  
const importRecords = loader('./mutations/import.graphql')  

const RemoveZoneLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="zone"
        entityTitle="zone"
        redirectPath={`/zones`}
      />
    </div>

  )
}

const search = {
  query: searchQuery,
  columns:  [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
    { title: 'Datacenter', dataIndex: 'provider', key: 'provider', sorter: true, render: provider => <>{ provider?.name || '-'}</> },
    { title: 'Description', dataIndex: 'description', key: 'description', sorter: true },
    { title: 'VRF', dataIndex: 'VRF', key: 'VRF', sorter: true },
    { title: '', width: 100, key: 'remove', render: zone => <RemoveZoneLink record={ zone }/> }
    /**{
      { title: 'Subnet Count', dataIndex: 'name', key: 'name', sorter: true },
      { title: 'Subnet Count', dataIndex: 'name', key: 'name', sorter: true },
      }**/
  ]
}

const importer = {
  exampleSheet: 'ZonesExample',
  mutation: importRecords,
  search: {
    query: searchQuery,
    columns:  [
      { title: 'Datacenter', dataIndex: 'provider', key: 'provider', sorter: true, render:provider => <>{provider?.name}</> },
      { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
      { title: 'Description', dataIndex: 'description', key: 'description', sorter: true },
      { title: 'VRF', dataIndex: 'VRF', key: 'VRF', sorter: true },
      { title: '', width: 100, key: 'remove', render: zone => <RemoveLink record={ zone } mutation={ destroy }/> }
  ]
  },
  fields: [
    {name: 'name', required: true},
    {name: 'VRF'},
    {name: 'provider', alias: 'DataCenter'}
  ],
  title: 'Network Zones',
  copy: 'Network Zones in Lightmesh are a flexible way to organize overlapping address ranges. They can be tied to Locations, Organizations, or other organizational schemes. Zones can also be broken down in to smaller zones to accomodate complex environments.',
  note: (
    <Alert
      style={{ marginTop: '10px' }}
      message={
        <div dangerouslySetInnerHTML={{ __html: "<strong>Name</strong> is a required field." }} />
      }
      type="info"
      showIcon
    />
  )
}

const form = {
  fields: [
    ["code", {}],
    ["name", {}]
  ]
}

export { search, form, importer, searchQuery, getQuery, create, update, destroy };
