import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Space, Alert, Drawer, Divider } from 'antd';
import Submit from '../../components/pants-d/Submit';
import Label from '../../components/pants-d/Label';
import { useQuery, useMutation } from "@apollo/client";
import { Area } from '../../components/Charts';
//import PaneForm from './PaneForm';
import { useReactToPrint } from 'react-to-print';
const { GET_QUERY, usageSummary } = require('../../entities/subnet')
const { getMeasurement, getTimeSeries, getTabulation } = require('../../entities/report')
const { getBroadcast } = require('../../utils/Network')
import { TextInput, TextArea, SearchSelect, Select, CustomAttributes } from '../../components/pants-d';
import DataTable from '../../components/DataTable';
import Metric from '../../components/Metric';
import { AreaChartOutlined, ArrowRightOutlined, EditOutlined, MinusCircleFilled, PrinterOutlined, SaveOutlined} from '@ant-design/icons';
import { displayFunctionSite } from './Form';
const Address4 = require('ip-address').Address4;
const Address6 = require('ip-address').Address6;
const {IPAddress6 } = require("../../utils/ip");

const subnet = require("../../entities/subnet");
const { CURRENT_USER_QUERY } = require("../../entities/auth");
const user = require("../../entities/user");
const vlan = require("../../entities/vlan");
const zone = require("../../entities/zone");
const site = require("../../entities/site");
const gateway = require("../../entities/gateway");
import { subnetMask } from '../../utils/subnetMask';

function toLocaleUTCDateString(date, locales, options) {
  const timeDiff = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.valueOf() + timeDiff);
  return adjustedDate.toLocaleDateString(locales, options);
}

/**
const reports = {
  'In Use': { query: getMetric, variables: { }},
  'Reserved': { query: RESERVED_COUNT },
  'Average Last Seen At': { query: AVERAGE_LAST_SEEN_AT },
  'Available': { query: AVAILABLE_COUNT },
  'By IP Status': { query: BY_IP_STATUS},
  'Last Seen At': { query: LAST_SEEN_AT },
  'By Resource Type': { query: BY_RESOURCE_TYPE },
  'By Customer': { query: BY_CUSTOMER },
  'By OS': { query: BY_OS },
  'Unrecognized Hosts': { query: UNRECOGNIZED_HOSTS }
}
 **/


const ReportForm = () => {
  const { supernetId, subnetId, childView, childId } = useParams();
  const [parameters, setParameters] = useState([])

  const [form] = Form.useForm();
  const reportType = Form.useWatch('reportType', form);

  const { loading, error, data } = useQuery(CURRENT_USER_QUERY);
  const history = useHistory();

  const [updateUser, {}] = useMutation(user.update, {
    update(cache, { data }) {

      cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: {
          currentUser: {
            ...data?.currentUser
          }
        }
      })
    }
  })


  return (
    <Form
      form={ form }
      layout="vertical"
      onFinish={(values) => {
        updateUser({
          variables: {
            id: data?.currentUser?.id, config: JSON.stringify(_.merge(JSON.parse(data?.currentUser?.config), {
              views: { subnet: { dashboard: { [parseInt(childId)]: values } } }
            }))
          }
        })

        history.push(`/subnets/${(supernetId || subnetId)}/${subnetId ? subnetId : ''}`)
      }}
    >

      {/** <TextInput name="name" label="Name"/> **/}
      <Select
        name="reportType"
        label="Report Type"
      >
        <Select.Option value="Metric"/>
        <Select.Option value="Time Series"/>
        <Select.Option value="Table"/>
      </Select>

      { (reportType == 'Metric') && (
        <Select name="report" label="Report">
          <Select.Option value="In Use"/>
          <Select.Option value="Available"/>
          <Select.Option value="Reserved"/>
          {/** <Select.Option value="Average Last Seen At"/> **/}
        </Select>
      )}

      { (reportType == 'Time Series') && (
        <Select name="report" label="Report">
          <Select.Option value="Available IPs"/>
        </Select>
      )}


      { (reportType == 'Table') && (
        <Select name="report" label="Report">
          <Select.Option value="By IP Status"/>
          <Select.Option value="Last seen at"/>
          <Select.Option value="By Resource Type"/>
          <Select.Option value="By Customer"/>
          <Select.Option value="By OS"/>
          <Select.Option value="Unrecognized Hosts"/>
        </Select>
      )}

      { (reportType == 'Time Series') && (
        <Select name="dateRange" label="Date Range">
          <Select.Option value="Last 30 days"/>
          <Select.Option value="Last 90 days"/>
          <Select.Option value="Last 6 months"/>
        </Select>
      )}


      <Submit />
    </Form>
  )
}

const Pane = ({ index, data, editing, onSelect, onDelete }) => {

  const { supernetId, subnetId } = useParams();
  const iconStyle = { fontSize: '80px', color: '#d5dee7' , marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }
  const wrapperStyle = { minHeight: '200px', width: '100%',  padding: '40px 40px', background: '#FFFFFF', display: 'inline-block', textAlign: 'left'}


  if (data) {
    return (
      <div className={ editing ? 'elevated' : '' } style={ editing ? { margin: '20px', padding: '20px', background: '#FFFFFF', border: '1px solid #FAFAFA' } : { padding: '20px'}}>
        <h4 style={{ marginBottom: '10px' }}>
          { (data?.reportType == 'Metric') ? '' : data?.report  }
          { editing && (
            <MinusCircleFilled
              onClick={(e) => {
                e.stopPropagation();
                onDelete(index)
              }}
              style={{
                float: 'right',
                color: '#e36763'
              }} />
          )}
        </h4>
        { (data?.reportType == 'Metric') && (
          <Metric query={ getMeasurement } label={ data?.report } variables={{ recordId: parseInt(subnetId || supernetId), metric: data?.report  }} />
        )}

        { (data?.reportType == 'Table') && (
          <DataTable
            columns={[
              {
                title: 'Month',
                dataIndex: 'label',
                render: (d) => {
                  const date = new Date(parseInt(d));
                    return toLocaleUTCDateString(date,undefined, { month: 'long', year: 'numeric' }) }},
              { title: 'In use', 
                dataIndex: 'value',
                align: 'right',
                render: (v) => { return v.toFixed(2) }
              }
            ]}
            query={ getTabulation }
            variables={{ recordId: parseInt(subnetId || supernetId) }}
          />
        )}
      { (data?.reportType == 'Time Series') && <Area showTable={ false } query={ getTimeSeries } variables={{ recordId: parseInt(subnetId || supernetId) }}/> }
      </div>
    )
  } else {
    return (
      <div onClick={() => { editing ? onSelect(index) : null}} class='pane'>
        { data && <div style={ wrapperStyle }>
          <h4>
            { data?.report }
            <MinusCircleFilled
              onClick={(e) => { e.stopPropagation(); console.log('deleting') }}
              danger style={{ float: 'right', color: '#e36763' }} />
          </h4>
          <Space direction="vertical">
            { data?.type }
            { data?.report }
            { data?.dateRange }
          </Space>
        </div>}
        { !data && editing && <AreaChartOutlined style={ iconStyle }/> }
      </div>
    )
  }
}

const PaneForm = React.forwardRef((({ editing, title }, ref) => {

  const { supernetId, subnetId, childView, childId } = useParams();
  const { data: currentUserData, loading } = useQuery(CURRENT_USER_QUERY);

  const history = useHistory();

  const userConfig = JSON.parse(currentUserData?.currentUser?.config)

  const [updateUser, {}] = useMutation(user.update, {
    update(cache, { data }) {

      cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: {
          currentUser: {
            ...data?.currentUser
          }
        }
      })
    }
  })

  const defaults = {
    onSelect: (index) => {
      history.push(`/subnets/${(supernetId || subnetId)}/${(subnetId || supernetId)}/dashboard/${index}`)
    },
    onDelete: (index) => {
      updateUser({
        variables: {
          id: currentUserData?.currentUser?.id,
          config: JSON.stringify(_.omit(JSON.parse(currentUserData?.currentUser?.config), [`views.subnet.dashboard.${index}`]))
        }
      })
    }
  }

  const dashboardDefault = {
    1: { reportType: 'Metric', report: 'In Use' },
    2: { reportType: 'Metric', report: 'Available' }
  }

  if (loading) return (<></>);

  return (
    <div ref={ref} style={{ minHeight: '200px', ...(title ? { background: 'none', padding: '60px 40px'} : {})}}>
      { title && <h2 style={{ marginLeft: '20px', marginBottom: '20px'}}>{ title }</h2> }
      <Row gutter={ 24 } style={{ marginBottom: '20px' }}>
        {[1, 2, 3, 4].map(idx => {
          return (
            <Col span={ 12 }>
              <Pane index={ idx } { ...defaults } editing={ editing } data={ (Object.keys(userConfig?.views?.subnet?.dashboard || {}).length > 0 || editing) ? userConfig?.views?.subnet?.dashboard?.[idx] : dashboardDefault?.[idx]} />
            </Col>
          )
        })}
      </Row>
      { title && <div style={{ float: 'right', marginRight: '20px', marginTop: '40px' }}>Printed at { (new Date()).toString() }</div>} 
    </div>
  )
}))

export const getIpAddresses = (networkAddress, offset, batchSize) => {
  const v4 = new Address4(networkAddress).isValid();
  const address = v4 ? new Address4(networkAddress) : new Address6(networkAddress);
  let startAddress = address.startAddressExclusive();
  let allIpAddresses = [];

  for (let i = 0; i < offset; i++) {
    const [a, b, c, d] = startAddress.address.split('.').map(Number);
    startAddress = new Address4(`${a}.${b}.${c}.${d + 1}`);
  }

  for (let i = 0; i < batchSize && startAddress <= address.endAddressExclusive(); i++) {
    allIpAddresses.push(startAddress.address);
    const [a, b, c, d] = startAddress.address.split('.').map(Number);
    startAddress = new Address4(`${a}.${b}.${c}.${d + 1}`);
  }

  return allIpAddresses;
};


export const getIPV6IPAddress = (networkAddress, offset, limit) => {
  const result = IPAddress6.search(" ", networkAddress, offset, limit, '')

  return result.map(item => item.networkAddress);
}

export const firstIpAddress = (networkAddress) => {
  if(networkAddress) {
  const v4 = new Address4(networkAddress).isValid()

  const address = v4 ? new Address4(networkAddress) : new Address6(networkAddress)

 const firstUsableIpAddress = v4 ? address.startAddressExclusive().address: address.addressMinusSuffix

  return firstUsableIpAddress
  }
}

const SubnetDetails = ({ readOnly }) => {
  const { supernetId, subnetId, childView, childId } = useParams();

  const [updateSubnet] = useMutation(subnet.update, {
    onCompleted: () => { setEditing(false)}
  });
  const [editing, setEditing] = useState(false);
  const [editingDashboard, setEditingDashboard] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [alertStatus, setAlertStatus] = useState(null);

  const dashboardRef = useRef();

  const { data: currentUserData } = useQuery(CURRENT_USER_QUERY);
  const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: { id: parseInt(subnetId || supernetId) } });
  const { data: usageData } = useQuery(usageSummary, { variables: { subnetId: parseInt(subnetId || supernetId) } });

  const handlePrint = useReactToPrint({
    content: () => dashboardRef.current,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...data?.subnet, status: (data?.subnet?.status || 'Active') })
  }, [form, data])

  const dashboardConfig = JSON.parse(currentUserData?.currentUser?.config)

  const { data: queryResults } = useQuery(site.searchQuery, { variables: { search: '', offset: 0, limit: 50 } })

  const [createSite, { data: createSiteData, error: createError }] = useMutation(site.create, {
    onCompleted: (e) => {
      setAlertStatus(true),
        setTimeout(() => {
          setAlertStatus(false);
        }, 3000);
      setShowForm(false);
    },
    onError: (e) => { console.log(e) },
    update(cache, { data }) {
      const existingSites = cache.readQuery({ query: site.searchQuery, variables: { search: "", offset: 0, limit: 50 } })
      cache.writeQuery({
        query: site.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          sites: {
            count: (existingSites?.count || 0) + 1,
            results: [...(existingSites?.sites?.results || []), data.createSite]
          }
        }
      })
    }
  });

  const handleAddSiteClick = () => {
    setShowForm(true);
  };

  const handleAddSiteFormCancel = () => {
    setShowForm(false);
  };


  const [createGateway] = useMutation(gateway.create, {
    onCompleted: (data) => {
    },
  });


  const handleOnFinish = async ({ vlan, zone, sites, gateways, ...rest }) => {

    let gateway

    if(gateways){

    try {
      gateway = await createGateway({
        variables: { gateway: gateways, subnetId: parseInt(subnetId || supernetId) },
      });
    } catch (error) {
    }
  }

    const baseVariables = {
      ...rest,
      VLANId: vlan?.id,
      VLANNumber: vlan?.number,
      zoneName: zone?.name,
      zoneId: zone?.id,
    };
  
    const updateVariables = {
      id: parseInt(subnetId || supernetId),
      ...baseVariables,
      siteId: sites,
    };
  
    if (gateways) {
      updateVariables.gatewayId = gateway.data.createGateway.id;
    }
  
    updateSubnet({
      variables: updateVariables,
    });
  };

  const [ipAddresses, setIpAddresses] = useState([]);

  useEffect(() => {
    if (data?.subnet?.networkAddress) {
      const v4 = new Address4(data?.subnet?.networkAddress).isValid()
      v4 ? setIpAddresses(getIpAddresses(data.subnet.networkAddress, 0, 50)):  setIpAddresses(getIPV6IPAddress(data?.subnet?.networkAddress, 0, 50))
    }
  }, [data]);
  const [offset, setOffset] = useState(50);

  const handleLoadMore = () => {
    const v4 = new Address4(data?.subnet?.networkAddress).isValid()
    const newIpAddresses = v4 ? getIpAddresses(data?.subnet?.networkAddress, offset, 50): getIPV6IPAddress(data?.subnet?.networkAddress, offset, 50)
    setIpAddresses((prevIpAddresses) => [...prevIpAddresses, ...newIpAddresses]);
    setOffset((prevOffset) => prevOffset + 50);
  };

  console.log(readOnly)

  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <div className='subnet-form' style={{ marginTop: '20px' }}>

            { !readOnly && (
              <a 
                onClick={() => { setEditing(true) }}
                style={{ position: 'absolute', zIndex: '1', right: '10px', fontSize: '12px', marginRight: '10px', paddingTop: '2px' }} 

              >
                <EditOutlined style={{ fontSize: '1.8em', color: '#698dab',  marginRight: '6px', display: 'inline', float: 'left' }}/>
              </a>
            )}
            {!editing && (

              <>

                <Row gutter={24}>
                    <Col span={24}>
                      <Label name="Name">
                        {data?.subnet?.name}
                        {!data?.subnet?.name && <i 
                          onClick={() => { setEditing(true) }}
                            style={{ cursor: 'pointer', fontSize: '0.6em', color: '#c2cdd6'}}>Add a name</i>}
                      </Label>
                    </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Label name="Description">
                      <div style={{ padding: '0px 4px' }}>
                        <div style={{ fontSize: '0.7em', marginLeft: '6px'}}>{data?.subnet?.description}</div>
                        {!data?.subnet?.description && <i 
                          onClick={() => { setEditing(true) }}
                          style={{ cursor: 'pointer', fontSize: '0.6em', color: '#c2cdd6'}}>Add a description</i>}
                      </div>
                    </Label>
                  </Col>
                </Row>

                <div style={{ margin: '20px 0px', borderTop: '1px solid #F2F2F8' }}/>
                <Row gutter={24}>
                  <Col span={12}>
                    <Label name="Gateway"> {data?.subnet?.gateway?.gateway || firstIpAddress(data?.subnet?.networkAddress)} </Label>
                  </Col>
                  <Col span={12}>
                    <Label name="VLAN"> <>
                    {data?.subnet?.vlan?.number }

                      {!data?.subnet?.vlan?.number && <i 
                        onClick={() => { setEditing(true) }}
                        style={{ cursor: 'pointer', fontSize: '0.6em', color: '#c2cdd6'}}>Select a VLAN</i>}
                    </> </Label>
                  </Col>
                </Row>

                <Row gutter={24}>

                  <Col span={12}>
                    <Label name="Broadcast"> <>{data?.subnet?.networkAddress ? (data?.subnet?.broadcast || getBroadcast(data?.subnet?.networkAddress)) : '--'}</> </Label>
                  </Col>

                  <Col span={12}>
                    <div style={{ padding: '0px 0px', marginTop: '3px', marginBottom: '20px' }}>
                      <Label name="Site"> 
                      {data?.subnet?.site?.name || ''} 
                      {!data?.subnet?.site && <i 
                        onClick={() => { setEditing(true) }}
                        style={{ cursor: 'pointer', fontSize: '0.6em', color: '#c2cdd6'}}>Select a Site</i>}
                      </Label>

                      {data?.subnet?.site?.addresses ? (
                        <div className='form-value' style={{ marginTop: '-10px' }}>
                          {
                            (data?.subnet?.site?.addresses).map((address, idx) => (
                              <>
                                <Row gutter={12}>
                                  <Col span={24}>
                                    {address.line1}
                                  </Col>
                                </Row>
                                {address.line2 && (
                                  <Row>
                                    <Col span={24}>
                                      {address.line2}
                                    </Col>
                                  </Row>
                                )}
                                {address.line3 && (
                                  <Row>
                                    <Col span={24}>
                                      {address.line3}
                                    </Col>
                                  </Row>
                                )}
                                {address.line4 && (
                                  <Row>
                                    <Col span={24}>
                                      {address.line4}
                                    </Col>
                                  </Row>
                                )}
                                <Row gutter={12}>
                                  <Col span={24}>
                                    { address?.municipality && address?.province && <>{address.municipality}, {address.province}</> }
                                  </Col>
                                </Row>
                                <Row gutter={12}>
                                  <Col span={24}>
                                    {address.country}, {address.postalCode}
                                  </Col>
                                </Row>
                              </>
                            ))
                          }
                        </div>
                      ) : ('')}

                    </div>
                  </Col>


                  {/**
                  <Col span={ 4 }>
                    <Label name="Monitoring">
                      <CheckCircleOutlined />
                    </Label>
                  </Col>
                    **/}
                </Row>

                <Row gutter={24}>
                  <Col span={12}>
                    <Label name="Subnet Mask"> {data? (subnetMask(data?.subnet?.networkAddress) || '--') : ''} </Label>
                  </Col>

                  <Col span={12}>
                    <Label name="Zone"> {data?.subnet?.zone?.name } </Label>
                  </Col>
                </Row>

                { data?.subnet?.customAtttributes && (
                <div style={{ marginTop: '20px', borderTop: '1px solid #F2F2F8' }}>
                  <Row gutter={24}>
                    <Col span={8}>
                      <CustomAttributes initialValues={data?.subnet?.customAttributes} form={form} modelName="Subnet" startIndex={0} endIndex={3} readOnly={!editing} />
                    </Col>
                    <Col span={8}>
                      <CustomAttributes initialValues={data?.subnet?.customAttributes} form={form} modelName="Subnet" startIndex={3} endIndex={6} readOnly={!editing} />
                    </Col>

                    <Col span={8}>
                      <CustomAttributes initialValues={data?.subnet?.customAttributes} form={form} modelName="Subnet" startIndex={6} readOnly={!editing} />
                    </Col>
                  </Row>
                </div>
                )}
              </>
            )}
            {editing && (
              <Form
                readOnly
                form={form}
                layout="vertical"
                size='small'
                initialValues={{ ...data?.subnet }}
                onFinish={(rest) => handleOnFinish(rest)}>
                <>
                  <Row gutter={24}> <Col span={ 24}><TextInput name='name' style={{ width: '100%' }} /></Col> </Row>
                  <Row><Col span={ 24 }><TextArea name='description' /></Col></Row>
                  <Row gutter={ 24 }>
                      <Col span={12}>
                        <Select
                          label="Gateway"
                          name="gateways"
                          placeholder="Select a Gateway"
                          defaultValue={data?.subnet?.gateway?.id ? { key: data?.subnet?.gateway?.id, label: data?.subnet?.gateway?.gateway } : firstIpAddress(data?.subnet?.networkAddress)}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: '8px 0' }} />
                              <Space style={{ padding: '0 8px 4px' }}>
                                <Button
                                  type="primary"
                                  onClick={handleLoadMore}
                                >
                                  Load More
                                </Button>
                              </Space>
                            </>
                          )}
                        >

                      {ipAddresses.map((ip) => (
                              <Option key={ip} value={ip}>
                                {ip}
                              </Option>
                            ))}
                    </Select>
                      </Col>
                      {/* <Col span={6}>
                        <SearchSelect
                          readOnly={!editing}
                          placeholder="Select a Zone"
                          defaultValue={data?.subnet?.zone}
                          name="zone"
                          label="Zone"
                          query={zone.searchQuery}
                          createMutation={zone.create}
                          createType='String'
                          createLabel="Add Zone"
                          resultKey="zones"
                          displayKey="name"
                        />
                      </Col> */}
                      <Col span={12}>
                        <SearchSelect
                          readOnly={!editing}
                          placeholder="Select VLAN (0-4095)"
                          defaultValue={data?.subnet?.vlan}
                          name="vlan"
                          label="VLAN"
                          query={vlan.searchQuery}
                          createMutation={vlan.create}
                          createType='Int'
                          createLabel="Add Vlan"
                          resultKey="vlans"
                          displayKey="number"
                        />
                      </Col>
                    </Row>
                  <Row gutter={24}>
                    <Col span={12}>

                      <Label name="Broadcast">
                        <>{data?.subnet?.broadcast || getBroadcast(data?.subnet?.networkAddress)}</>
                      </Label>

                    </Col>

                    <Col span={12}>
                      {showForm ? (
                        <div>
                          <Form
                            layout="vertical"
                            form={form}
                            name="site"
                            onFinish={(values) => {
                              createSite({ variables: { name: values.siteName, addresses: [{ line1: values.line1, line2: values.line2, line3: values.line3, line4: values.line4, municipality: values.municipality, postalCode: values.postalCode, province: values.province, country: values.country }] } })
                            }} >
                            <TextInput name="siteName" label="Site Name" required />

                            <div style={{ padding: '0px 0px', marginBottom: '0px' }}>
                              <Row gutter={12}>
                                <Col span={24}>
                                  <TextInput size="small" allowClear={false} name="line1" label="Street Address Line 1" />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <TextInput size="small" allowClear={false} name="line2" label="Street Address Line 2" />
                                </Col>
                              </Row>
                              <Row gutter={12}>
                                <Col span={12}>
                                  <TextInput size="small" allowClear={false} name="municipality" label="City" />
                                </Col>
                                <Col span={12}>
                                  <TextInput size="small" allowClear={false} name="province" label="State / Province" />
                                </Col>
                              </Row>
                              <Row gutter={12}>
                                <Col span={12}>
                                  <TextInput size="small" allowClear={false} name="country" label="Country" />
                                </Col>
                                <Col span={12}>
                                  <TextInput size="small" allowClear={false} name="postalCode" label="Postal / Zip Code" />
                                </Col>
                              </Row>
                            </div>

                            <Space>
                              <Button type="primary" htmlType="submit">
                                Add Site
                              </Button>
                              <Button type="secondary" onClick={handleAddSiteFormCancel}>
                                Cancel
                              </Button>
                            </Space>
                          </Form>
                        </div>
                      ) : (
                        <div>
                          {alertStatus && (
                            <Alert
                              message="New Site Added Successfully! Open Site DropDown to Select"
                              type="success"
                              showIcon
                              style={{ marginBottom: '16px' }}
                            />
                          )}
                          <Select
                            label="Site"
                            name="sites"
                            placeholder="Select or Create a Site"
                            defaultValue={data?.subnet?.site?.id ? { key: data?.subnet?.site?.id, label: data?.subnet?.site?.name } : ''}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                  <Button
                                    type="primary"
                                    onClick={handleAddSiteClick}
                                  >
                                    Add a new Site
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {queryResults?.sites?.results.map((site) => (
                              <Option key={site.id} value={site.id}>{displayFunctionSite(site)}</Option>
                            ))}
                          </Select>
                        </div>
                        )}

                    </Col>
                </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Label name="Subnet Mask">
                        {subnetMask(data?.subnet?.networkAddress) || '--'}
                      </Label>
                    </Col>
                    <Col span={12}>
                      <Label name="Zone"> {data?.subnet?.zone?.name } </Label>
                    </Col>
                  </Row>
                  { data?.subnet?.customAttributes && (
                  <div style={{ margin: '20px 0px', paddingTop: '20px', borderTop: '1px solid #F2F2F8' }}>
                  <Row gutter={24}>
                    <Col span={8}>
                      <CustomAttributes initialValue={data?.subnet?.customAttributes} form={form} modelName="Subnet" startIndex={0} endIndex={3} readOnly={!editing} />
                    </Col>
                    <Col span={8}>
                      <CustomAttributes initialValue={data?.subnet?.customAttributes} form={form} modelName="Subnet" startIndex={3} endIndex={6} readOnly={!editing} />
                    </Col>

                    <Col span={8}>
                      <CustomAttributes initialValue={data?.subnet?.customAttributes} form={form} modelName="Subnet" startIndex={6} readOnly={!editing} />
                    </Col>
                  </Row>
                </div>
                  )}
                <Row>
                  <Col span={24}>
                    {!showForm && (
                      <div>
                        {editing && (
                          <>
                            <Submit style={{ position: 'absolute', bottom: '0px', right: '80px', height: '32px' }} />
                            <Button onClick={() => { setEditing(false) }} style={{ position: 'absolute', marginBottom: '10px', bottom: '1px', right: '0px', height: '33px', lineHeight: '14px' }}>Cancel</Button>
                          </>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
                </>
              </Form>)}
          </div>
        </Col>
        <Col span={12}>
          { (usageData?.usageSummary?.inUse == 0 && usageData?.usageSummary?.scanned == 0) && (
            <div style={{ padding: '35px 80px 60px 70px' }}>
              <h2 style={{ color: '#b136b3', fontSize: '2em', fontWeight: '400'}}>
                What's on this subnet?
              </h2>
              <p style={{ color: '#a3b4c2', fontSize: '1.35em', marginLeft: '5px', marginTop: '30px', fontWeight: '300' }}>
                You can start building a better picture of your network by uploading scans to lightmesh.  Scans can be uploaded in a variety of formats or pushed directly to lightmesh via API or CLI.              
              </p>
              <img src="/images/lightmesh-scan-result-example.png" style={{ width: '100%', marginTop: '20px' }}/>
              <p style={{ color: '#a3b4c2', fontSize: '1.35em', marginLeft: '5px', marginTop: '40px', fontWeight: '300' }}>
                You can use scan results to build network plans, or to check for conflicts and keep your plan up to date.
              </p>
              <Row style={{ marginTop: '40px' }}>
                <Col flex={ 1 }> 
                  <a 
                    onClick={() => { history.push(`/subnets/${(supernetId || subnetId)}/${subnetId || supernetId}/imports/new`)}}
                    style={{ color: '#336b99', marginLeft: '3px', marginTop: '40px', fontSize: '20px'}}>
                    Get started with scans
                    <ArrowRightOutlined style={{ 
                      fontSize: '16px', 
                      lineHeight: '40px',
                      marginLeft: '10px'
                    }}/></a>
                </Col>
                <Col flex={ 1 }></Col>
                <Col flex={ 1 }></Col>
              </Row>

            </div>
          )}
          { (usageData?.usageSummary?.inUse !== 0 || usageData?.usageSummary?.scanned !== 0) && (
            <div style={{ marginTop: '6px'}}>
              <Row>
                <Col span={6} offset={18}>
                  <a style={{ fontSize: '12px', float: 'right', marginRight: '10px', paddingTop: '7px' }} onClick={() => { setEditingDashboard(!editingDashboard) }}>
                    { editingDashboard && <SaveOutlined style={{ fontSize: '1.8em', color: '#698dab', marginRight: '6px', display: 'inline', float: 'left' }}/> }
                    { !editingDashboard && <EditOutlined style={{ fontSize: '1.8em', color: '#698dab',  marginRight: '6px', display: 'inline', float: 'left' }}/> }
                  </a>
                  <a style={{ fontSize: '12px', float: 'right', marginRight: '20px', paddingTop: '6px' }} onClick={() => { handlePrint() }}>
                    <PrinterOutlined style={{ fontSize: '1.8em', color: '#698dab'  }}/>
                  </a>
                </Col>
              </Row>
              <PaneForm  editing={editingDashboard} />
              <div style={{ overflow: 'hidden', height: '0'}}>
                <PaneForm title={ `Summary for ${data?.subnet?.networkAddress} ${data?.subnet?.name || ''}` } ref={dashboardRef} editing={editingDashboard} />
              </div>
            </div>
          )}
        </Col>
      </Row>

      { childId && (
        <Drawer
          title={'Choose a report'}
          width={520}
          closable={true}
          visible={true}
          onClose={() => {
            history.push(`/subnets/${(supernetId || subnetId)}/${subnetId ? subnetId + '/' : ''}`)
          }}>

          <ReportForm />

        </Drawer>

      )}
    </div>
  )
}

export default SubnetDetails;
