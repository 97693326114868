import { makeVar, InMemoryCache } from '@apollo/client';

export const searchVar = makeVar([]);
export const importVar = makeVar([]);
export const activeUserVar = makeVar([]);

export const cache = new InMemoryCache({
  typePolicies: {
    IPAddress: {
      keyFields: ['networkAddress']
    },
    Query: {
      fields: {
        ipAddresses: {
          read(existing, { args: { offset, limit }}) {
            return {...existing, results: existing?.results?.slice(offset, offset + limit)};
          },
          keyArgs: ['search', 'subnetId'],
          merge(existing, incoming, { args: { offset = 0 }}) {
            console.log(existing)
            const merged = existing?.results ? existing?.results?.slice(0) : [];

            for (let i = 0; i < incoming?.results?.length; ++i) {
              merged[offset + i] = incoming?.results[i];
            }

            return { ...incoming, results: merged }
          }
        },
        search: {
          read() {
            return searchVar();
          }
        },
        activeUser: {
          read() {
            return activeUserVar();
          }
        }
        }
      }
    }
  }
);
