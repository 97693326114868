import React, { useState } from 'react';
import {  Form, Input } from 'antd';

import { startCase } from "lodash";

class TextInput extends React.Component {

  render () {
    const { name, required, rules, hideLabel=false, style, formItemStyle, ...props } = this.props;

    const requiredRule = [{ required: required, message: `${props.label || startCase(name)} is required.` }]

    return (
      <Form.Item
        label={  hideLabel ? null : props.label || startCase(name) }
        name={ name }
        autoComplete="off"
        rules={[...requiredRule]}
        style={ formItemStyle }
      >

        <Input 
          autocomplete='false'
          allowClear
          focus={ true }  
          { ...props  } 
          style={ style } />

      </Form.Item>
    )
  }
}

export default TextInput;
