import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form } from 'antd';
import { useMutation, useLazyQuery, useQuery  } from "@apollo/client";

import { TextInput, CustomAttributes } from '../../components/pants-d';

const resource = require("../../entities/resource");

const ResourceForm = () => {
  const { id } = useParams();
  const newRecord = id == 'new';

  const history = useHistory();


  const [getResource, { loading, error: recordError, data }] = useLazyQuery(resource.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => { if (!newRecord) { getResource({ variables: {id: parseInt(id)}})}}, [])


  const [createRecord, { data: createData }] = useMutation(resource.create, {
    onCompleted: () => { history.push(`/resources`) },
    update(cache, { data }) { 
      const existingData = cache.readQuery({ query: resource.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: resource.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          resources: {
            count: (existingData?.count || 0) + 1,
            results: [...(existingData?.resources?.results || []), data.createResource]
          }
        }
      })
    }});

  const [updateRecord, { data: updateData }] = useMutation(resource.update, { onCompleted: () => { history.push(`/resources`);  getResource({ variables: {id: parseInt(id)}}); }} )
  
  const saveRecord = (values) => { if (newRecord) {
    createRecord({ variables: {resource: {...values}}}) 
  } else {
    updateRecord({variables: {id: parseInt(id), hostname: values?.hostname, OS: values?.OS, OSVersion: values?.OSVersion, macAddress: values?.macAddress, interface: {id: data?.resource?.interfaces[0]?.id, name: values?.interface }}}) }
  }

  const [form] = Form.useForm();

  useEffect(() => {
    if (data && data?.resource) {
      const firstInterface = data?.resource?.interfaces ? data?.resource?.interfaces[0]?.name : null;
      if (firstInterface !== null) {
        form.setFieldsValue({ ...data.resource, interface: firstInterface });
      }
    }
  }, [data, form]);

  if (loading) { return 'Loading' }
  return (
    <Form
      layout="vertical"
      form={form}
      name="resource"
      initialValues={(data||{})['resource']}
      onFinish={ (values) => saveRecord(values) } >

      <TextInput
        size='small'
        allowClear={ false }
        name={'hostname'}
        label='Hostname'
      />
      <Row gutter={ 24 }>
        <Col span={ 18 }>
          <TextInput
            size='small'
            allowClear={ false }
            name={'OS'}
            label='Operating System'
          />
        </Col>
        <Col span={ 6 }>
          <TextInput
            size='small'
            allowClear={ false }
            name={'OSVersion'}
            label='OS Version'
          />
        </Col>

      </Row>
      <TextInput
        size='small'
        allowClear={ false }
        name={'macAddress'}
        label='MAC Address'
      />
      <TextInput
        size='small'
        allowClear={ false }
        name={'interface'}
        label='Interface'
      />
      <CustomAttributes modelName='Resource' />

      <Button type="primary" htmlType="submit">
        Submit
      </Button>

    </Form>
  )
}

export default ResourceForm;
