import { CheckOutlined, ArrowRightOutlined, FileTextOutlined, PlusOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import { List, Layout, Row, Col, Form, Button, Space, Alert } from 'antd';
import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import {TextInput} from '../../components/pants-d';

import { Address4 as IPv4, Address6 as IPv6 } from 'ip-address';
const { CREATE_IMPORT } = require('../../entities/import');

import { create } from '../../entities/subnet';

import { getClosestValidCIDRFromError } from '../subnets/Form';

const { Content } = Layout;

export const CreateSubnetButton = ({ networkAddress, onCreate }) => {

  const history = useHistory();

  const [createSubnet, { data, error }] = useMutation(create, {
    onCompleted: ({ createSubnet }) => {
      onCreate();
      history.push(`/subnets/${createSubnet?.id}/${createSubnet?.id}/ip-addresses`)
    }
  })

  return (
    <Button 
      onClick={(e) => { createSubnet({ variables: { networkAddress: networkAddress } }) }}
      size='small'
      style={{width: '140px', textAlign: 'left', fontSize: '13px', lineHeight: '20px', padding: '4px 12px 20px 10px', marginRight: '5px', marginBottom: '10px', border: '1px solid #A32DA5AA', borderRadius: '20px'}}>
      { networkAddress }
      <PlusOutlined style={{ fontSize: '9px', marginTop: '4px', float: 'right' }} />
    </Button>
  )
}

const ActionTitle = ({ step, title, background, color }) => {

  return (

    <h3 style={{ marginBottom: '20px', fontSize: '20px', textAlign: 'left', color: color }}>
      <div style={{ 
        width: '26px',
        height: '26px',
        borderRadius: '20px',
        textAlign: 'center',
        lineHeight: '26px',
        display: 'inline-block',
        marginRight: '10px',
        marginBottom: '20px',
        fontSize: '16px',
        color: color,
        background: background }}>
        { step }
      </div>
      { title }
    </h3>
  )
}

const GettingStarted = ({ showFeatures, hideTitle, hideDocs, style, hideWelcome = () => {} }) => {

  const [form] = Form.useForm();

  const containerStyles = {
    ...style,
    overflow: 'auto',
    maxHeight: 'calc(100vh - 150px)',
  };

  const history = useHistory();

  const [errors, setErrors] = useState([])
  const [createImport, { data: importData, error: importError }] = useMutation(CREATE_IMPORT, {
    onCompleted: () => { 
      history.push('/imports/1') 
      hideWelcome()
    }
  }
  )

  const [createSubnet, { data, error }] = useMutation(create, {
    onCompleted: ({ createSubnet }) => {
      hideWelcome();
      history.push(`/subnets/${createSubnet?.id}/${createSubnet?.id}/ip-addresses`)
    },
    onError: (err) => {
      const message = err?.message;
      const errorMessage = (message.charAt(0).toUpperCase() + message.slice(1)).replaceAll('\"', '');
      const suggestedCIDR = getClosestValidCIDRFromError(errorMessage); // Get the closest valid CIDR from the error message

      const errorWithSuggestion = suggestedCIDR
      ? (
        <>
          {errorMessage}. Did you mean &nbsp;<CreateSubnetButton networkAddress={suggestedCIDR} onCreate={ hideWelcome } />?
        </>
      )
      : `Invalid CIDR value: ${errorMessage}.`;

      setErrors([{ message: errorWithSuggestion }]);
    },
  })

  const panelStyles = { width: '350px', border: '4px solid #FAFAFA', background: '#FFFFFF', padding: '30px 25px', marginTop: '10px', height: '520px', fontSize: '14px' }
  const checkStyles= {  fontSize: '12px', fontWeight: 'bold', color: '#a32da5', marginTop: '9px', marginRight: '-4px' }
  const titleStyle= {  fontSize: '18px' }

  return (
    <div style={{
      ...containerStyles,
      overflow: 'auto',
      marginTop: '20px',
      maxHeight: 'calc(100vh - 150px)',
    }}>
    <>
      {!hideTitle && (
        <Row>
          <Col span={ 6 }> 
            <h1 style={{ fontSize: '24px', marginTop: '40px', marginLeft: '14px'}}>Get Started</h1>
          </Col>
        </Row>
      )}
      <Row gutter={ 10 }>

        { showFeatures && (
          <Col flex={1 }>
            <div style={{ ...panelStyles, textAlign: 'left' }}>
              <h1 style={{ fontSize: '25px', fontWeight: '400', color: '#a32da5', lineHeight: '30px' }}>lightmesh </h1>
              <List id="feature-list">
                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={ checkStyles } />}
                    title={<h2 style={ titleStyle }>tracks network conflicts</h2>}
                    description={<p>Devices, DNS, DHCP, Cloud, Network Zones</p>}
                  />
                </List.Item>

                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={ checkStyles } />}
                    title={<h2 style={ titleStyle }>tracks operational information</h2>}
                    description={<p>Datacenters, Sites, Customers, Support Contacts</p>}
                  />
                </List.Item>

                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={ checkStyles } />}
                    title={<h2 style={ titleStyle }>has a flexible schema</h2>}
                  />
                </List.Item>

                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={ checkStyles } />}
                    title={<h2 style={ titleStyle }>has a request portal</h2>}
                  />
                </List.Item>

                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={ checkStyles } />}
                    title={<h2 style={ titleStyle }>has role based access</h2>}
                  />
                </List.Item>

                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={ checkStyles } />}
                    title={<h2 style={ titleStyle }>is available self hosted</h2>}
                  />
                </List.Item>
                

                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={ checkStyles } />}
                    title={<h2 style={ titleStyle }>has a cli tool</h2>}
                  />
                </List.Item>

              </List>
            </div>
          </Col>
        )}
        <Col flex={ 1 }> 
          <div style={ panelStyles }>
            <ActionTitle step={ 1 } title='Create a subnet' background='#A32DA599' color='#A32DA5FF' />
            <div style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
              <Form 
                layout="vertical"
                name="subnet"
                form={form}
                onFinish={(subnet) => { 

                  const cleanedNetworkAddress = subnet?.networkAddress.replace(/\s/g, '');
                  const isIPv4CIDR = new IPv4(cleanedNetworkAddress).isValid();
                  const isIPv6CIDR = new IPv6(cleanedNetworkAddress).isValid();
                  if (!isIPv4CIDR && !isIPv6CIDR) {
                      form.setFields([
                          {
                              name: 'networkAddress',
                              errors: ['Invalid CIDR value'],
                          },
                      ]);
                      return;
                  }
                  createSubnet({variables: { networkAddress: cleanedNetworkAddress }})
                }}>
                <Row>
                  <Col span={ 20 }>
                    <TextInput 
                      placeholder='Network Address ( i.e. 10.0.0.0/16 )'
                      style={{ border: '1px solid #76177888'}}
                      name='networkAddress' 
                      hideLabel={ true }
                      autocomplete='off'
                      showClear={ false }
                    />
                  </Col>
                  <Col span={ 4 }>
                    <Button htmlType='submit' style={{ marginTop: '0px', border: 'none' }}><ArrowRightOutlined /></Button>
                  </Col>
                </Row>
                { errors?.map(error => { return (<><Alert type="error" message={error.message} /><br /></>) })}
              </Form>
            </div>
            <Row style={{ marginTop: '20px', marginBottom: '10px' }}>
              <h3 style={{ fontWeight: 'normal' }}>Suggested addresses</h3>
            </Row>
            <Row  style={{ marginBottom: '2px' }}>
              <CreateSubnetButton networkAddress='10.0.0.0/16' onCreate={ hideWelcome }/>
              <CreateSubnetButton networkAddress='10.0.0.0/24' onCreate={ hideWelcome }/>
            </Row>
            <Row style={{ marginBottom: '2px' }}>
              <CreateSubnetButton networkAddress='192.168.0.0/16' onCreate={ hideWelcome }/>
              <CreateSubnetButton networkAddress='192.168.0.0/24' onCreate={ hideWelcome }/>
            </Row>
            <Row>
              <CreateSubnetButton networkAddress='fd00::/64' onCreate={ hideWelcome }/>
              <br/>
            </Row>
          </div>
        </Col>

        <Col flex={ 1 }> 
          <div style={ panelStyles }>
            <ActionTitle step={ 2 } title='Import some data' background='#2c669699' color='#2c6696FF'/>
            <p style={{ marginBottom: '20px', fontSize: '14px', textAlign: 'left'}}>
              You can import your existing network data from an existing product or from spreadsheets.
            </p>
            <p style={{ marginBottom: '20px', fontSize: '14px', textAlign: 'left'}}>
              We'll help you build out your network in Lightmesh and fill in the blanks.
            </p>
            <div style={{ border: '1px solid #999999', padding: '10px 20px', lineHeight: '20px', height: '45px', width: '200px', marginTop: '40px' }}>
              <a onClick={() =>  {
                createImport({ variables: { description: 'Initial Import' }})
              }}
              style={{ fontSize: '15px', marginTop: '10px' }}>Start Importing</a>
              <ArrowRightOutlined style={{ fontSize: '14px', marginLeft: '10px', color: '#2C6696' }} />
            </div>


          </div>
        </Col>

        { !hideDocs && (
        <Col flex={ 1 }> 
          <div style={ panelStyles }>
            <ActionTitle step={ 3 } title='Check out the guides' background='#ABABABAA' color='#8A8A8AFF'/>
            <p style={{ marginBottom: '60px', fontSize: '14px'}}>
              Learn more about Lightmesh and it's capabilities over at our guides.
            </p>

            <Space direction='vertical' size='large' style={{ marginLeft: '5px' }}>
              <a href='https://guides.lightmesh.com/getting-started' target='_blank' style={{ fontSize: '15px' }}>
                <div style={{ border: '1px solid #999999', padding: '2px 16px 2px 4px', lineHeight: '18px', height: '45px' }}>
                  <FileTextOutlined style={{ fontSize: '16px', marginRight: '10px', marginTop: '10px', marginLeft: '10px' }} />
                  Getting Started
                  <ArrowRightOutlined style={{ fontSize: '14px', marginLeft: '10px', color: '#2C6696' }} />
                </div>
              </a>

              <a href='https://guides.lightmesh.com/installation/' target='_blank' style={{ fontSize: '15px' }}>
                <div style={{ border: '1px solid #999999', padding: '2px 10px 2px 4px', lineHeight: '18px', height: '45px' }}>
                  <FileTextOutlined style={{ fontSize: '16px', marginRight: '10px', marginTop: '10px', marginLeft: '10px' }} />
                  Lightmesh CLI Installation
                  <ArrowRightOutlined style={{ fontSize: '14px', marginLeft: '10px', color: '#2C6696' }} />
                </div>
              </a>

              <a href='https://api-documentation.lightmesh.com/' target='_blank' style={{ fontSize: '15px' }}>
                <div style={{ border: '1px solid #999999', padding: '2px', lineHeight: '18px', height: '45px' }}>
                  <FileTextOutlined style={{ fontSize: '16px', marginRight: '10px', marginTop: '10px', marginLeft: '10px' }} />
                  API documentation
                  <ArrowRightOutlined style={{ fontSize: '13px', marginLeft: '10px', color: '#2C6696' }} />
                </div>
              </a>
            </Space>
          </div>
        </Col>
        )}
      </Row>
    </>
    </div>
  )
}

export default GettingStarted;
