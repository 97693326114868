import React, { useState, useEffect, useRef} from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input, Space, Alert, Drawer, Divider, Col } from 'antd';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
const { GET_QUERY } = require('../../entities/subnet')
const Address4 = require('ip-address').Address4;

import {Select } from '../../components/pants-d';
import { getIPV6IPAddress, getIpAddresses } from './SubnetDetails';

const { Option } = Select;
const { create, get, update } = require("../../entities/nat");

const NATSubnetForm = ({ onCreate, onClose }) => {
  const { model, subnetId, supernetId, childId } = useParams();

  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);
  const [ipAddresses, setIpAddresses] = useState([]);
  const [offset, setOffset] = useState(50);
  const natType = ['Source NAT', 'Destination NAT', 'Static NAT'];

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [form] = Form.useForm();

  const { loading, data: subnetData, } = useQuery(GET_QUERY, { variables: { id: parseInt(subnetId || supernetId) } });

  const [createNat, { data, error }] = useMutation(create, {
    onCompleted: onClose,
    onError: (e) => { console.log(e) },
  })

  const [getNatData, { data: natData }] = useLazyQuery(get);

  const [ updateNat, {}] = useMutation(update, {
    onCompleted: onClose,
    onError: ( e ) => { console.log(e) }
  })

  useEffect(() => {
    if (childId && childId !== 'new') {
      getNatData({ variables: { id: parseInt(childId) } });
    }
  }, [childId, getNatData]);



  const handleLoadMore = () => {
    const v4 = new Address4(subnetData?.subnet?.networkAddress).isValid()
    const newIpAddresses = v4 ? getIpAddresses(subnetData?.subnet?.networkAddress, offset, 50) : getIPV6IPAddress(subnetData?.subnet?.networkAddress, offset, 50)
    setIpAddresses((prevIpAddresses) => [...prevIpAddresses, ...newIpAddresses]);
    setOffset((prevOffset) => prevOffset + 50);
  };

  useEffect(() => {
    if (subnetData?.subnet?.networkAddress) {
      const v4 = new Address4(subnetData?.subnet?.networkAddress).isValid()
      v4 ? setIpAddresses(getIpAddresses(subnetData.subnet.networkAddress, 0, 50)) : setIpAddresses(getIPV6IPAddress(subnetData?.subnet?.networkAddress, 0, 50))
    }
  }, [subnetData]);

  useEffect(() => {
    if (natData?.nat && childId) {
      form.setFieldsValue({
        natType: natData?.nat?.natType,
        sourceNat: natData?.nat?.sourceNatAddress,
        destinationNat: natData?.nat?.destinationNatAddress,
      });
    }
  }, [natData]);

  const onFinish = async ({ sourceNat, destinationNat, natType }) => {

    if (childId && childId !== 'new') {
      updateNat({ variables: { 
                    id: parseInt(childId), 
                    sourceNatAddress: sourceNat,
                    natType: natType,
                    destinationNatAddress: destinationNat
      } });
    } else {
      createNat({
        variables: {
          sourceNatAddress: sourceNat || null,
          natType: natType,
          subnetId: parseInt(subnetId || supernetId),
          destinationNatAddress: destinationNat || null,
        },
      });
    }

  };

  return (
    <Drawer
      title="Manage NAT"
      onClose={onClose}
      width={520}
      visible={open}>
      <Form
        layout="vertical"
        form={form}
        name="nat"
        initialValues={{}}
        onFinish={onFinish} >
        <Row gutter={12}>
          <Col span={24}>
          <Select
              label="Type"
              name="natType"
              placeholder="Select NAT Type"
              required
              >
                {natType.map(option => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={24}>
            <Select
              label="Source IP Address"
              name="sourceNat"
              placeholder="Select a Source IP Address"
              required
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="primary"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </Button>
                  </Space>
                </>
              )}
            >
              {ipAddresses.map((ip) => (
                <Option key={ip} value={ip}>
                  {ip}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Select
              label="Destination IP Addresses"
              name="destinationNat"
              mode="multiple"
              placeholder="Select Destination IP Addresses"
              required
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="primary"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </Button>
                  </Space>
                </>
              )}
            >

              {ipAddresses.map((ip) => (
                <Option key={ip} value={ip}>
                  {ip}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <div>
          <Button type="secondary" onClick={(e) => onClose()} style={{ float: 'right', marginLeft: '20px' }}>
            Cancel
          </Button>
          <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}} htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default NATSubnetForm;
