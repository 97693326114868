import React, { useState } from 'react';
import { Row, Col,  Radio, Menu } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import { useLazyQuery, useQuery, useMutation, useApolloClient } from '@apollo/client';
import TextEditable from '../../components/Editable';
import SubnetDetails from './SubnetDetails';

import Entity from '../../entities';
import { useForm } from 'antd/lib/form/Form.js';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const { GET_QUERY, update, refresh, DESTROY_SUBNET  } = Entity['subnet'];

const Syncer = ({ subnet }) => {
  const [getSubnet, { loading, error, data, refetch, stopPolling, startPolling }] = useLazyQuery(GET_QUERY, {
    variables: { id: parseInt(subnet?.id) },
    pollInterval: 5000,
    update(cache, { data }) {
      if (data?.subnet?.syncStatus !== 'Synchronizing') {
        stopPolling()
      }
    }
  })

  const [refreshSubnet, { data: updateSubnet }] = useMutation(refresh, {
    onCompleted: () => {
      getSubnet({ variables: { id: parseInt(subnet?.id) } })
    }
  })

  const date = moment(parseInt(subnet?.lastSynchronizedAt))

  return (
    <div style={{ padding: '0px', marginLeft: '100px', fontWeight: 300 }}>

      {(subnet?.syncStatus == 'Synchronized') && (
        <>
          {date.format("dddd, MMMM Do YYYY, h:mm:ss a")}
          <a onClick={() => refreshSubnet({ variables: { id: parseInt(subnet?.id) } })}>
            <SyncOutlined twoToneColor="#52c41a" style={{ fontSize: '12px', marginLeft: '10px' }} />
          </a>
        </>
      )}

      {(subnet?.syncStatus == 'Synchronizing') && (
        <>
          <SyncOutlined twoToneColor="#52c41a" spin style={{ fontSize: '12px', marginRight: '10px' }} />
          Synchronizing with {subnet?.zone?.provider?.type}...
        </>
      )}
    </div>
  )
}


const ProviderLogo = ({ zone }) => {
  return (
    <>
      {(zone?.provider?.type == 'Azure' || zone?.provider?.type == 'AWS') && (
        <div class="provider-logo" style={{ display: 'inline-block', marginTop: '0px', marginRight: '10px', width: '60px', height: '40px'}}>
          {zone?.provider?.type == 'Azure' && <img style={{ width: '40px', marginTop: '25px', marginLeft: '14px' }} src='/images/azure.svg' />}
          {zone?.provider?.type == 'AWS' && <img style={{ width: '70px', marginTop: '24px', marginRight: '20px' }} src='/images/amazon.svg' />}
          {/**
        {zone?.provider?.type == 'Google' && <img style={{ width: '50px', marginTop: '0px' }} src='/images/google.svg' />}
        {zone?.provider?.type == 'Datacenter' && <img style={{ width: '22px', marginTop: '0px', marginLeft: '0px' }} src='/images/server2.png' />}
        {zone?.provider?.type == null && <img style={{ width: '22px', marginTop: '0px', marginLeft: '0px' }} src='/images/server2.png' />}
            **/}
        </div>
      )}
    </>
  )}


const SubnetHeader = ({ id, readOnly, style }) => {
  const { subnetId, supernetId, childView = 'summary' } = useParams();
  const subnetUrl = `/subnets/${supernetId}/${subnetId || supernetId}`

  const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: { id: parseInt(id) } });
  const [updateRecord, { data: updateData }] = useMutation(update, { onCompleted: (v) => { console.log("completed") } });
  const [updateSubnet] = useMutation(update);
  const [selectedVLAN, setSelectedVLAN] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuTitle, setMenuTitle] = useState('');


  const handleMenuClick = (menuItem) => {
    setMenuTitle(menuItem);
    setDrawerVisible(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleMenuClick('Clear Subnet Space')}>Clear subnet space</Menu.Item>
      <Menu.Item key="2" onClick={() => handleMenuClick('Remove Subnet')}>Remove Subnet</Menu.Item>
    </Menu>
  );

  const searchButtonStyle = { fontSize: '10px !important', width: '83px', textAlign: 'center' };

  const [form] = useForm()

  const history = useHistory()

  const handleSave = async ({ vlan, ...rest }) => {
    const id = parseInt(vlan?.value);

    if (id) {
      setSelectedVLAN(vlan.label);
      await updateSubnet({
        variables: {
          id: data?.subnet?.id,
          VLANId: id,
          ...rest
        }
      });
      setEditMode(false);
    }
  };

  if (loading) { return (
    <div className="subnet-header" style={{ height: '80px', marginTop: '20px', marginBottom: '32px', ...style }}>
    </div>
  )}

  if (error && error.graphQLErrors?.filter(e => { return e?.extensions?.code == 'FORBIDDEN' })?.length > 0) {
    return <>
      <h1>Unauthorized</h1>
      <p>Please contact your administrator to request access</p>
    </>
  }
  

  return (
    <div className="subnet-header" style={{ height: ['Azure', 'AWS'].includes(data?.subnet?.zone?.provider?.type) ? '130px': '90px', marginTop: '20px', marginBottom: '8px', ...style }}>
      <Row>
        <Col span={24}>

          <Row>
            <ProviderLogo zone={data?.subnet?.zone} />
            <h2 style={{ background: '#fdfcfd', letterSpacing: '0.05em', marginLeft: ['Azure', 'AWS'].includes(data?.subnet?.zone?.provider?.type) ? '40px': '0px', marginRight: '8px', marginBottom: '0px'}}>
              {data?.subnet?.networkAddress.replace('/', ' / ')} {data?.subnet?.name ? `  -  ${data?.subnet?.name}` : ''}
            </h2>


            { !readOnly && (
              <RemoveRecordButton 
                record={data?.subnet} 
                mutation={DESTROY_SUBNET}
                mutationVariables={{id: data?.subnet?.id}}
                menuItems={["clear", "remove"]}
                entityName="subnet"
                entityTitle="Subnet"
                redirectPath={`/subnets`}
              />
            )}

          </Row>
          { ['Azure', 'AWS'].includes(data?.subnet?.zone?.provider?.type) && (
            <Row style={{ marginBottom: '20px', height: '20px'}}>
              <Col span={18}>
                <Syncer subnet={data?.subnet} />
              </Col>
              <Col span={6}>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={ 24 }>
              <Radio.Group 
                value={(childView == 'reservations' ? 'ip-addresses' : childView) || 'summary'} 
                onChange={(e) => history.push(`${subnetUrl}/${(e.target.value == 'summary' ? '' : e.target.value)}`)} 
                style={{ marginLeft: '2px', marginTop: '-5px' }}>
                <Radio.Button size='small' style={{ ...searchButtonStyle }} value={'summary'}>Summary</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '95px' }} value={'ip-addresses'}>IP Addresses</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle }} value={'subnets'}>Subnets</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '55px' }} value={'nats'}>NAT</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '90px'}} value={'resources'}>Resources</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '85px'}} value={'requests'}>Requests</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle }} value={'customers'}>Customers</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '80px' }} value={'contacts'}>Contacts</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '120px' }} value={'support-groups'}>Support Teams</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '75px' }} value={'activity'}>Activity</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '90px' }} value={'role-assignments'}>Permissions</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '75px' }} value={'alerts'}>Alerts</Radio.Button>
                <Radio.Button size='small' style={{ ...searchButtonStyle, width: '80px' }} value={'imports'}>Scans</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default SubnetHeader;
