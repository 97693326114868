const subnetMask = (cidr) => {
    const [, prefix] = cidr.split('/');
    const prefixLength = parseInt(prefix);

    const subnetMask = Array(4).fill(0).map((_, index) => {
        const maskByte = Math.min(8, Math.max(prefixLength - index * 8, 0));
        return (256 - Math.pow(2, 8 - maskByte)) & 255;
    }).join('.');

    return subnetMask;
}

export { subnetMask }
