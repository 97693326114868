import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Form, Row, Col, Space } from 'antd';
import { SubnetHeader, parsedSubnet } from '../plans/Record';
import qs from 'qs';
import {useHistory} from 'react-router-dom';
import {Submit, TextInput} from '../../components/pants-d';
import { triggerGoogleAnalyticsEvent } from '../authentication/Register';


const { CURRENT_USER_QUERY } = require('../../entities/auth');
const user = require('../../entities/user')



const UserInfo = ({ onUpdate }) => {
  const client = useApolloClient();

  const { data } = useQuery(CURRENT_USER_QUERY);
  const { currentUser } = data;

  const [updateUser] = useMutation(user.update, {
    onCompleted: onUpdate,
    onError: ( e ) => { console.log(e) },

    update(cache, { updateUser }) {
      const existing = cache.readQuery({ query: CURRENT_USER_QUERY })

      cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: { currentUser: { ...existing, ...updateUser } }
      }) 
    }
  })

  const [form] = Form.useForm();

  return (
    <>
      <h2 style={{ fontSize: '20px', marginBottom: '40px'}}>
        Please confirm your information:
      </h2>
      <Form 
        layout="vertical"
        form={ form }
        name="user"
        initialValues={{}}
        onFinish={ (values) => {
          updateUser({ variables: {...values, id: currentUser.id } })
        }}
        style={{ 
          width: '600px', 
          marginLeft: 'auto', 
          marginRight: 'auto'}}>

        <Row gutter={ 16 }>
          <Col span={12}> <TextInput name='firstName' required /> </Col>
          <Col span={12}> <TextInput name='lastName' required /> </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col span={24}> <TextInput name='company' /> </Col>
        </Row>

        <Row gutter={ 16 }>
          <Col span={24}> 
            <Submit style={{
              marginTop: '20px',
              width: '400px'
            }}
            onClick={triggerGoogleAnalyticsEvent} />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default UserInfo;
