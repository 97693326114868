const { BigInteger } = require('jsbn');
const {Address4, Address6} = require('ip-address');

const isV4 = (networkAddress) => {
  const v4 = new Address4(networkAddress).isValid()
  
  return v4
}

const ipCount = ( networkAddress ) => {
  const v4 = new Address4(networkAddress).isValid()

  const MAX_SAFE_INTEGER = 2147483647

  let result = (v4) => {
    if (v4) {
      return Math.pow(2, (32 - new Address4( networkAddress ).subnetMask))
    } else {
      const subnetCount = new BigInteger( new Address6( networkAddress ).possibleSubnets() )

      if (subnetCount.compareTo(new BigInteger(MAX_SAFE_INTEGER.toString())) > 0) {
        return 2147483647
      } else {
        return subnetCount.intValue()
      }
    } 
  }

  return result(v4)
}

const parseSubnetAddress = (networkAddress) => {
  const v4 = new Address4(networkAddress).isValid()
  const v6 = new Address6(networkAddress).isValid()

  if (v4) {
    return new Address4(networkAddress)
  } else if (v6) {
    return new Address6(networkAddress)
  } else {
    return null
  }
}

const IPAddress4 = {

  search: (search, networkAddress, offset, limit, contiguous, exclusions = []) => {

    const octets = search?.split('.') || [];
    const networkOctets = networkAddress.split('.')

    const subnetLowerBound = new Address4(networkAddress).bigInteger();
    const subnetUpperBound = new Address4(networkAddress).bigInteger();

    const lowerAddress = new Address4([...Array(4)].map((_, i) => { 
      return (octets[i] || networkOctets[i] || 0)
    }).join('.'));

    const lowerBound = lowerAddress.bigInteger();

    const upperAddress = new Address4([...Array(4)].map((_, i) => { return octets[i] || 255}).join('.'))
    const upperBound = upperAddress.bigInteger();


    const start = (subnetLowerBound.compareTo(lowerBound) > 0) ? subnetLowerBound : lowerBound;
    const end = start + offset + limit;

    let results = [];

    let i = search?.length > 0 ? 0 : 1;

    while (results.length < limit) {
      const increment = new BigInteger(i.toString());
      const n = start.add( new BigInteger((offset || 0).toString())).add( increment ); 

      const address = new Address4.fromBigInteger(n).address;


      if (!exclusions.includes(address) && (networkAddress.split('/')[0] !== address)) {
        results.push({networkAddress: address});
      }

      if ( address == lowerAddress.endAddress().address ) break 

      i ++;
    }

    return results;
  }
}

const IPAddress6 = {

  search: (search, networkAddress, offset, limit, contigous, exclusions = []) => {

    const octets = search?.split('.') || [];

    const endAddress = new Address6(networkAddress).endAddress().correctForm()

    const subnetLowerBound = new Address6(networkAddress).bigInteger();
    const subnetUpperBound = new Address6(networkAddress).bigInteger();

    const lowerBound = new Address6([...Array(4)].map((_, i) => { return octets[i] || 0}).join('.')).bigInteger();
    const upperBound = new Address6([...Array(4)].map((_, i) => { return octets[i] || 255}).join('.')).bigInteger();

    const start = subnetLowerBound > lowerBound ? subnetLowerBound : lowerBound;
    const end = start + limit;

    let results = [];

    let i = 0;

    while (results.length < limit) {
      const increment = new BigInteger(i.toString());
      const n = start.add( new BigInteger((offset || 0).toString())).add( increment ); 

      const address = new Address6.fromBigInteger(n).correctForm();
      
      if (!exclusions.includes(address)) {
        results.push({networkAddress: address});
      }

      if ( address == endAddress ) break 

      i ++;
    }

    return results;
  }
}

export { IPAddress4, IPAddress6, ipCount, parseSubnetAddress, isV4 }
