import React, { useState, useEffect  } from 'react';
import { Select, Input,  Spin, Divider } from 'antd';
import { useMutation, useLazyQuery } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

const { Option } = Select;

const RecordForm = ({ createMutation, displayKey, createLabel }) => {

  const [newRecord, setNewRecord] = useState('');

  const [createRecord, createdRecord] = useMutation(createMutation) 


  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
      <Input style={{ flex: 'auto' }} value={newRecord} onChange={(e) => {setNewRecord(e.target.value)}} />
      <a
        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
        onClick={(e) => {
          createRecord({variables: {name: newRecord}})
        }}
      >
        <PlusOutlined /> Add item
      </a>
    </div>

  )
}


function MultiSelect({query, resultKey, displayFn, displayKey, filters, createMutation, createLabel, value, ...rest}) {

  const [loadData, { loading, data }] = useLazyQuery(query, {
    variables: { filters },
  });

  const debouncedLoadData = debounce((value) => {
    loadData({ variables: { search: value } });
  }, 250);

  useEffect(() => {
    loadData({ variables: { search: '' } });
  }, []);


  return(
    <Select
      {...rest}
      value={value?.map(d => {return {key: (d?.id || d[(displayKey || 'name')]), label: d[(displayKey || 'name')]}})}
      dropdownRender={menu => (
        <div>
          {menu}
          { !!createMutation && 
            <>
              <Divider style={{ margin: '4px 0' }} />
              <RecordForm createMutation={ createMutation } displayKey={ displayKey } createLabel={ createLabel } />
            </>
          }
        </div>
      )}
      showSearch
      mode="multiple"
      filterOption={false}
      labelInValue={true}
      optionLabelProp="label"
      notFoundContent={loading ? <Spin size="small" /> : null}
      onSearch={(value) => {
        debouncedLoadData({search: value}) 
      }}>

      {(data && data[resultKey] && data[resultKey].results.length == 0) && (
        <Option> No Results Found </Option>
      )}

      {data && data[resultKey] && data[resultKey].results.map(d => (
        <Option 
          value={d?.id}
          label={displayFn(d)}>
          { displayFn(d) }
        </Option>
      ))}

    </Select>
  )
}


export default MultiSelect;
