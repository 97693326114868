import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { FileExcelOutlined, EllipsisOutlined, CopyOutlined } from '@ant-design/icons';

function flattenObject(obj, prefix = '') {
    const flatObject = {};
    for (const [key, value] of Object.entries(obj)) {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                const arrayKey = `${newKey}[${index}]`;
                if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
                    Object.assign(flatObject, flattenObject(item, arrayKey));
                } else {
                    flatObject[arrayKey] = item;
                }
            });
        } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flatObject, flattenObject(value, newKey));
        } else {
            flatObject[newKey] = value;
        }
    }
    return flatObject;
}

const ExportCSVButton = ({ subnetData }) => {
    const [copyButtonText, setCopyButtonText] = useState('Copy to Clipboard');
    const [menuVisible, setMenuVisible] = useState(false);

    const generateCSVData = () => {

        if (subnetData?.ipAddresses?.results.filter(ipAddress => ipAddress.ipAssignment || ipAddress.reservation)) {

            const filteredIpAddresses = subnetData?.ipAddresses?.results.filter(ipAddress => ipAddress.ipAssignment || ipAddress.reservation);

            if (filteredIpAddresses?.length === 0) {
                return null;
            }

            const headerMapping = {
                'zone.name': 'Zone',
                'subnet.networkAddress': 'Subnet',
                'networkAddress': 'Network Address',
                'ipAssignment.DNSEntries[0].recordType': 'DNS Record Type',
                'ipAssignment.DNSEntries[0].name': 'DNS Record',
                'ipAssignment.DNSEntries[0].ttl': 'DNS Record TTL',
                'ipAssignment.interface.resource.hostname': 'Host Name',
                'ipAssignment.interface.resource.OS': 'Resource Type',
                'ipAssignment.interface.macAddress': 'MAC Address',
                'ipAssignment.interface.name': 'Interface',
                'ipAssignment.description': 'Notes',
                'reservation.name': 'Reservation',
            };

            const flattenedIpAddresses = filteredIpAddresses?.map((ipAddress) => {
                const flattened = flattenObject(ipAddress);
                const modifiedHeaders = {};

                const headersInOrder = Object.keys(headerMapping);

                for (const key of headersInOrder) {
                    const value = flattened[key];
                    modifiedHeaders[headerMapping[key]] = value;
                }

                return modifiedHeaders;
            });

            const headers = Object.keys(flattenedIpAddresses[0]);
            const csvData = [
                headers.join(','),
                ...flattenedIpAddresses.map(row =>
                    headers.map(fieldName => JSON.stringify(row[fieldName] || '')).join(',')
                )
            ].join('\n');

            return csvData;
        }
    };

    const exportToCSV = async () => {
        const csvData = generateCSVData();
        if (!csvData) return;

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'data.csv';
        link.click();

        URL.revokeObjectURL(url);
    };
    const copyToClipboard = async () => {
        const csvData = generateCSVData();
        if (!csvData) return;
    
        // Convert CSV to a string that represents a table
        const lines = csvData.split('\n');
        const headers = lines[0].split(',');
        const rows = lines.slice(1).map(line => line.split(','));
        const columnWidths = headers.map((header, i) => Math.max(header.length, ...rows.map(row => row[i]?.length || 0)));
        const formatRow = row => row.map((cell, i) => cell.replace(/"/g, '').padEnd(columnWidths[i])).join(' | ');
        const tableData = [formatRow(headers), ...rows.map(formatRow)].join('\n');
    
        try {
            await navigator.clipboard.writeText(tableData);
            setCopyButtonText('Copied!');
            setTimeout(() => {
                setCopyButtonText('Copy to Clipboard');
            }, 4000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const handleVisibleChange = flag => {
        setMenuVisible(flag);
    };

    const csvData = generateCSVData();

    const menu = (
      <Menu disabled={!csvData} style={{ color: '#4b7aa0' }}>
        <Menu.Item key="1" onClick={() => exportToCSV(subnetData)} style={{ paddingBottom: '10px' }}>
          <FileExcelOutlined style={{ marginRight: '4px' }}/>Export to CSV
        </Menu.Item>
        <Menu.Item key="2" onClick={copyToClipboard}>
          <CopyOutlined style={{ marginRight: '4px' }}/>{copyButtonText}
        </Menu.Item>
      </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']} visible={menuVisible} onVisibleChange={handleVisibleChange}>
            <Button size='small' style={{ fontSize: '12px', float: 'right', marginTop: '12px', padding: '6px 10px', height: '36px', marginRight: '20px', marginLeft: '10px', background: 'transparent', border: 'none' }}>
                <EllipsisOutlined />
            </Button>
        </Dropdown>
    );
};

export default ExportCSVButton;
