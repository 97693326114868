import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Result, Alert } from 'antd';
import {  useMutation, useApolloClient } from '@apollo/client';
import zxcvbn from 'zxcvbn';
import PasswordStrengthBar from 'react-password-strength-bar';
import AuthLayout from '../layout/Auth.js';

import { TextInput, PasswordInput, Submit } from '../../components/pants-d';
import {useParams} from 'react-router-dom';

const { RESET_PASSWORD_MUTATION, CURRENT_USER_QUERY } = require('../../entities/auth')

function ResetPassword() {

  const client = useApolloClient();

  const { token } = useParams();

  const [resetPassword, { data }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: ({ reset }) => {
      const query = {
        query: CURRENT_USER_QUERY,
        variables: {},
      };
    },
  });

  const [form] = Form.useForm();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [showPasswordHint, setShowPasswordHint] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (value.trim() !== '') {
      setShowPasswordHint(false);
      const strengthScore = zxcvbn(value).score;
      setPasswordStrength(strengthScore);
    } else {
      setShowPasswordHint(true);
      setPasswordStrength(0);
    }
  };

  const handleFinish = (values) => {
    const { password, passwordConfirmation } = values;

    // Validate the password field only when it's not empty
    if (password && (password.length < 8 || password.length > 64)) {
      form.setFields([
        {
          name: 'password',
          errors: ['The password must be at least 8 characters long and not longer than 64 characters.'],
        },
      ]);
      setAlertStatus('error');
      return;
    }

    if (password !== passwordConfirmation) {
      // Display alert when passwords do not match
      setPasswordMismatch(true);
    } else {
      // Passwords match, call the resetPassword mutation
      resetPassword({ variables: { ...values, token: token } });
    }
  };

  return(
    <AuthLayout>
      { data?.resetPassword && (
        <Result
          status="success"
          title="Password has been successfully reset"
          extra={[
            <a href="/login">Back to Login</a>
          ]}
        />
      )}

      { data && !data?.resetPassword && (
        <Result
          status="error"
          title="Password could not be reset"
          extra={[
            <a href="/forgot_password">Request another reset</a>
          ]}
        />
      )}

      {!data && (
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Row>
            <Col span={16} offset={4}>
              <PasswordInput
                required
                label="New Password"
                name="password"
                onInput={handlePasswordChange} // Add onInput event handler for password strength validation
              />
              {/* Always show the PasswordStrengthBar */}
              <PasswordStrengthBar password={form.getFieldValue('password')} />

              {showPasswordHint && !form.getFieldValue('password') && (
                <Alert
                  style={{ marginTop: '10px', marginBottom: '20px' }}
                  message="The password must be at least 8 characters long and not longer than 64 characters."
                  type="info"
                  showIcon
                />
              )}

              <PasswordInput required label="Confirm Password" name="passwordConfirmation" />
              <Submit />
            </Col>
          </Row>

        </Form>
      )}

      {/* Alert for password mismatch */}
      {passwordMismatch && (
        <div style={{ color: 'red', textAlign: 'center' }}>
          Passwords do not match. Please try again.
        </div>
      )}
    </AuthLayout>
  )
}

export default ResetPassword;
