'use strict'

import React, { useState, useEffect  } from 'react';
import { TwitterPicker } from 'react-color'

const ColorPick = ({ color, onPick }) => {

  const [showPicker, setShowPicker] = useState(false);

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const button = {
    height: '40px',
    width: '1px',
    marginTop: '18px',
    marginRight: '-10px',
    border: `1px solid ${color}`,
    borderRadius: '2px 0px 0px 2px'
  }

  return (
    <div>
      <button type="button" style={{...button, background: `${color}`}} onClick={() => setShowPicker(true) }/>
      { showPicker ? <div style={ popover }>
        <div style={ cover } onClick={() => setShowPicker(false) }/>
        <TwitterPicker 
          color={ color }
          onChangeComplete={({ hex }) => { 
            onPick(hex) 
            setShowPicker(false)
          }}
        />
        </div> : null }
      </div>
  )
}

export default ColorPick
