import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";
import SearchLayout from '../layout/Search';
import { Table, Select, Button } from 'antd';
import { useQuery } from '@apollo/client';
import Entity from "../../entities";

const subnet = Entity["subnet"];

const UnAuthorizedAccess = ({ }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 20px',
            background: '#FFFFFF',
            marginTop: '-15px',
            minHeight: '200px'
        }}>
            <p style={{ fontSize: '25px', padding: '8px 20px', margin: '27px 0px' }}>Access Denied.</p>
        </div>
    )
};

export default UnAuthorizedAccess
