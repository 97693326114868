import React, { useState } from 'react';
import { Form, Input } from 'antd';

import { startCase } from "lodash";

const { TextArea: AntTextArea } = Input;

class TextArea extends React.Component {

  render () {
    const { name, hideLabel=false, ...props } = this.props;

    return (
      <Form.Item
        label={  hideLabel ? null : props.label || startCase(name) }
        name={ name }
        autoComplete="off" >
        <AntTextArea />
      </Form.Item>
    )
  }
}

export default TextArea;
