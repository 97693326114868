import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input, Alert } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput, CustomAttributes } from '../../components/pants-d';

const customer = require("../../entities/customer");

const CustomerForm = (props) => {
  const { id } = props;
  const newRecord = id == 'new'
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const history = useHistory();

  const [getCustomer, { loading, error: recordError, data }] = useLazyQuery(customer.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => { if (!newRecord) { getCustomer({ variables: {id: parseInt(id)}})}}, [])

  const [createRecord, { data: createData, error: createErrors  }] = useMutation(customer.create, {
    onCompleted: () => { history.push(`/customers`) },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
      console.log(e)
    },
    update(cache, { data }) { 
      const existingCustomers = cache.readQuery({ query: customer.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: customer.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          customers: {
            count: (existingCustomers?.count || 0) + 1,
            results: [...(existingCustomers?.customers?.results || []), data.createCustomer]
          }
        }
      })
    }});

  const [updateCustomer, { data: updateData }] = useMutation(customer.update, { onCompleted: () => { history.push(`/customers`) },     onError: e => {
    setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
    console.log(e)
  }} )

  const [form] = Form.useForm();

  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  if (loading) { return <></> }
  return (
    <>
      { (newRecord || data) && (
        <Form
          layout="vertical"
          form={form}
          name="customer"
          initialValues={{ ...(data?.['customer'] || {}) }}
          onFinish={(values) => {
            if (newRecord) {
              createRecord({ variables: { ...values } });
            } else {
              const cleanedAddresses = values.addresses.map(address => {
                const { __typename, ...cleanedAddress } = address;
                return cleanedAddress;
              });
              updateCustomer({ variables: { id: parseInt(id), ...values, addresses: cleanedAddresses } });
            }
          }}  >
          <div ref={errorContainerRef}>
            {(errors?.length > 0) && (
              <>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} type="error" message={errorMessage} />
                ))}
                <br />
              </>
            )}
          </div>

          <TextInput name="name" required/>

          <Form.List name="addresses" initialValue={[...(data?.site?.addresses || []), {}]} >
            {(addresses, { add, remove }) => {
              return (
                <>
                  <h3>Address</h3>
                  <div style={{ padding: '15px 20px', marginBottom: '20px', background: "#F8F8F8"}}>
                    {
                      (addresses || []).map((address, idx) => (
                        <>
                          <Row gutter={ 12 }>
                            <Col span={ 24 }>
                              <TextInput size="small" allowClear={false} name={[idx, "line1"]} label="Street Address" />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={ 24}>
                              <TextInput size="small" allowClear={false} name={[idx, "line2"]} label="Street Address Line 2" />
                            </Col>
                          </Row>
                          <Row gutter={ 12 }>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "municipality"]} label="City" />
                            </Col>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "province"]} label="State / Province" />
                            </Col>
                          </Row>
                          <Row gutter={ 12 }>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "country"]} label="Country" />
                            </Col>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "postalCode"]} label="Postal / Zip Code" />
                            </Col>
                          </Row>
                        </>
                      ))
                    }
                  </div>

                </>
              )
            }}
          </Form.List>

          <CustomAttributes modelName='Customer' />

          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      )}
    </>
  )
}

export default CustomerForm;
