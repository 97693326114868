import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input, Drawer } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput } from '../../components/pants-d';

const role = require("../../entities/role");

const RoleForm = ({ refetch, id }) => {
  const history = useHistory();

  const { model } = useParams();

  const newRecord = id == 'new'


  const [getRole, { loading, error: recordError, data }] = useLazyQuery(role.getQuery, { variables: { id: parseInt(id) } });

  useEffect(() => { if (id && !newRecord) { getRole({ variables: { id: parseInt(id) } }) } }, [])

  const [createRecord, { data: createData }] = useMutation(role.create, {
    onCompleted: () => { history.push(`/roles`) },
    update(cache, { data }) {
      const existingRoles = cache.readQuery({ query: role.searchQuery, variables: { search: "", offset: 0, limit: 50 } })
      cache.writeQuery({
        query: role.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          roles: {
            count: existingRoles.count + 1,
            results: [...existingRoles.roles.results, data.createRole]
          }
        }
      })
    }
  });

  const [updateRecord, { data: updateData }] = useMutation(role.update, {
    onCompleted: () => {
      history.push(`/roles`);
      refetch(); 
    },
  });

  const [form] = Form.useForm();

  if (loading) { return <></> }
  return (

    <Drawer
      title={newRecord ? `Add role` : `Update role`}
      width={520}
      closable={false}
      visible={true}
      onClose={() => { history.push('/roles') }}>
      <Form
        layout="vertical"
        form={form}
        name="role"
        initialValues={data?.['role'] || {}}
        onFinish={(values) => newRecord ? createRecord({ variables: { ...values } }) : updateRecord({ variables: { id: parseInt(id), ...values } })} >

        <TextInput required name="name" />

        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Drawer>
  )
}

export default RoleForm;
