import React, { useState, useEffect, useRef } from 'react'

import { TextInput, TextArea, SearchSelect, CustomAttributes } from '../../components/pants-d';
import {  useLocation, useHistory, useParams } from 'react-router-dom'

import { useMutation, gql, useLazyQuery, useQuery  } from '@apollo/client'
import {  Row, Col, Button, Form, Input, Drawer, AutoComplete, Alert, Space, Divider, Radio } from 'antd';

import moment from 'moment';

import Entity from '../../entities'

import {
  CaretDownFilled,
  CaretRightFilled,
  CloseCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons'


const {
  GET_IP_ASSIGNMENT,
  CREATE_IP_ASSIGNMENT,
  UPDATE_IP_ASSIGNMENT,
  DESTROY_IP_ASSIGNMENT,
  searchQuery
} = require('../../entities/ip-assignment')

const dnsEntry = require('../../entities/dns-entry')

const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 }

const { GET_QUERY } = require('../../entities/subnet')
const Address4 = require('ip-address').Address4;

import {Select } from '../../components/pants-d';
import { getIPV6IPAddress, getIpAddresses } from '../subnets/SubnetDetails';
import _ from 'lodash';
import {GET_SCAN_RESULT, UPDATE_SCAN_RESULT} from '../../entities/scan-result';

const { Option } = Select;
const { create, update } = require("../../entities/nat");

function useSearchParams () {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const subnet = Entity['subnet']

const sectionHeaderStyles = { 
  color: '#7591a9', 
  fontSize: '14px', 
  borderBottom: '1px solid #F1F6Fa', 
  paddingBottom: '3px' 
}

const sectionStyles = {
  padding: '10px 15px',
  marginTop: '-4px',
  marginBottom: '20px',
  background: '#FEFEFF'
}

const caretStyles = { 
  fontSize: '12px',
  marginRight: '6px',
  verticalAlign: 'textTop',
  lineHeight: '20px',
  color: '#7591a9'
}

export const SectionHeader = ({ title, key, openSections, setOpenSections }) => {
  return (
    <a onClick={(e) => { 
      !openSections?.includes(key || title) ? 
        setOpenSections([...openSections, (key || title)]) :
        setOpenSections([...(_.filter(openSections, section => { return (section !== (key || title)) }  ))])
    }}>
      <h3 style={ sectionHeaderStyles }>
        { openSections?.includes(key || title) ? 
          <CaretDownFilled style={ caretStyles } /> : 
          <CaretRightFilled style={ caretStyles } /> } 
        { title || key }
      </h3>
    </a>
  )
}

const parseDate = (dateString) => {
  const d = new moment(dateString)

  return d.format("MMMM Do YYYY, h:mm:ss a")
}

const ScanResult = ({ scanData, ipAssignment, onAssign, refetch }) => {

  const history = useHistory();

  const hasConflicts = () => {
    return ipAssignment?.interface?.resource && scanData?.hostname && scanData?.hostname?.toUpperCase() !== ipAssignment?.interface?.resource?.hostname
  }

  const [updateScanResult, {}] = useMutation(UPDATE_SCAN_RESULT, {
    onCompleted: () => { 
      refetch()
    },
  })

  const { supernetId, subnetId } = useParams();

  const { scanResult } = scanData;


  return (
    <>
      { scanResult && !scanResult?.ignore && (<Alert 
        style={{ 
          marginBottom: '20px', 
          background: 'none',
          paddingLeft: '10px'
        }}
        type={ hasConflicts() ? 'error' : 'info' }
        showIcon
        description={
          <div>
            {`${scanResult.hostname || scanResult.macAddress} was seen at ${parseDate(scanResult?.scannedAt)}`}
            <br/>
            <Space direction='horizontal' style={{ marginTop: '40px', float: 'right' }}>
              <Button size='small' onClick={(e) => { onAssign(scanResult) }}>Assign</Button>
              <Button size='small' onClick={(e) => { 
                                                      updateScanResult({ 
                                                          variables: { 
                                                              id: parseInt(scanResult?.id), 
                                                              ignore: true 
                                                          }
                                                      }).catch(error => {
                                                          console.error('Error in updateScanResult:', error);
                                                      });
                                                  }}>Ignore</Button>
              <Button size='small' onClick={(e) => { 
                                                      updateScanResult({ 
                                                          variables: { 
                                                              id: parseInt(scanResult?.id), 
                                                              ignore: true 
                                                          }
                                                      }).catch(error => {
                                                          console.error('Error in updateScanResult:', error);
                                                      });
                                                  }}>Reject</Button>
            </Space>
          </div>
        }
      />)}
    </>
  )
}

const IPForm = ({ onCreate, onUpdate, onDestroy, onClose, id, subnetId }) => {
  const [form] = Form.useForm()
  const [dnsForm] = Form.useForm()
  const [open, setOpen] = useState(true);
  const [recordType, setRecordType] = useState('');
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);
  const history = useHistory();
  const { model, supernetId } = useParams();
  const [ipAddresses, setIpAddresses] = useState([]);
  const [offset, setOffset] = useState(50);
  const [selectedSubnetIdForNat, setSelectedSubnetIdForNat] = useState(null)
  const [openSections, setOpenSections] = useState(['DNS', 'Resource', 'Notes']);
  const [filteredNatData, setFilteredNatData] = useState([]);



  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);
  


  const handleRecordTypeChange = (value) => {
    setRecordType(value);
  };

  const handleRecordTypeSelect = (value) => {
    setRecordType(value);
  };

  const dnsTypeOptions = [
    { value: 'A' },
    { value: 'AAAA' },
    { value: 'CNAME' },
    { value: 'NS' },
    { value: 'MX' },
    { value: 'TXT' },
  ];

  const [getIPAssignment, { loading, error, data }] = useLazyQuery(GET_IP_ASSIGNMENT)

  const { data: subnetData } = useQuery(GET_QUERY, {
    variables: {
      id: parseInt(
        (selectedSubnetIdForNat ? selectedSubnetIdForNat : data?.ipAssignment?.subnet?.id) || subnetId || data?.ipAssignment?.subnet?.id 
      ),
    },
    skip: !subnetId && !selectedSubnetIdForNat && !data?.ipAssignment?.subnet?.id, 
  });

  const [searchScanResults, { loading: scanLoading, data: scanData, refetch }] = useLazyQuery(GET_SCAN_RESULT)

  const queryParams = useSearchParams()

  useEffect(() => {

    const networkAddress = queryParams?.get('networkAddress')

    if (networkAddress && subnetData) {
      searchScanResults({ 
        variables: {
          networkAddress: networkAddress,
          zoneId: subnet?.zone?.id,
          limit: 1
        }
      })
    }

    if (data?.ipAssignment?.subnet?.nat) {
      const filteredData = data?.ipAssignment?.subnet?.nat.filter(
        (nat) => nat.sourceNatAddress === data?.ipAssignment?.networkAddress
      );
      setFilteredNatData(filteredData);
    }
  }, [data?.ipAssignment?.subnet?.nat, data?.ipAssignment?.networkAddress, queryParams?.networkAddress, subnetData]);
    

  const [selectedSubnetId, setSelectedSubnetId] = useState(null)


  const defaults = {
    networkAddress: queryParams.get('networkAddress'),
    subnetId: parseInt(subnetId)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      getIPAssignment({ variables: { id: parseInt(id) } })
    }
  }, [])

    useEffect(() => {
    if (subnetData?.subnet?.networkAddress) {
      const v4 = new Address4(subnetData?.subnet?.networkAddress).isValid();
      const newIpAddresses = v4 ? getIpAddresses(subnetData?.subnet?.networkAddress, 0, 50) : getIPV6IPAddress(subnetData?.subnet?.networkAddress, 0, 50);
      setIpAddresses(newIpAddresses);
    }
  }, [subnetData]);
 

  const [createNat] = useMutation(create, {
    onCompleted: onClose,
    onError: (e) => { console.log(e) },
  })

  const [updateNat ] = useMutation(
    update,
    {
      onCompleted: onClose,
      onError: (e) => { console.log(e) }
    }
  )

  const [createIPAssignment, { data: createData, error: createErrors }] =
    useMutation(CREATE_IP_ASSIGNMENT, {
      onCompleted: () => { 
        onClose() },
      onError: (e) => {
        setErrors(e?.graphQLErrors?.map(error => error.message) || [e.message]);
      },
      
      update (cache, { data }) {
        const existingIpAssignment = cache.readQuery({ 
          query: searchQuery, 
          variables: {
            search: "", 
            offset: 0, 
            limit: 50,
            subnetId: subnetId
          }
        })

        cache.writeQuery({
          query: searchQuery,
          variables: { search: "", offset: 0, limit: 50 },
          data: {
            ipAssignments: {
              count: (existingIpAssignment?.count || 0) + 1,
              results: [...(existingIpAssignment?.ipAssignments?.results || []), data.createIPAssignment]
            }
          }
        })
      }
    })

  const [updateIPAssignment, { data: updateData, error: updateErrors }] = useMutation(
    UPDATE_IP_ASSIGNMENT,
    {
      onCompleted: onClose,
      onError: e => {
        setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
        console.log(e)
      },
    }
  )

  const [destroyIPAssignment, { data: destroyData }] = useMutation(
    DESTROY_IP_ASSIGNMENT,
    {
      onCompleted: onDestroy,
      onError: e => {
        console.log(e)
      },
      update (cache, result) {
        cache.evict(`IPAssignment:${id}`)
      }
    }
  )

  const [formType, setFormType] = 'single'
  const dnsEntriesValue = Form.useWatch('dnsEntries', form)
  const resourceValue = Form.useWatch('resource', form)

  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  if (updateErrors) {
    const fieldErrors = updateErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  if (id && id !== 'new' && !data?.ipAssignment) {
    return <></>
  }

  const isUpdating = (id && id !== 'new')

  const handleFinish = async values => {
    const { subnetNetworkAddress, sourceNat, destinationNat, natType, Subnet, networkAddress, ...restValues } = values

    if((defaults.networkAddress || networkAddress) && destinationNat){
      createNat({
        variables: {
          sourceNatAddress: networkAddress ? networkAddress : defaults.networkAddress || null,
          natType: natType,
          subnetId: parseInt(Subnet?.id || supernetId),
          destinationNatAddress: destinationNat || null,
        },
      });
  }

    const dnsEntries = values?.dnsEntries
      ?.filter(d => d?.name)
      .map(entry => {
        const { ['__typename']: removed, ...entryWithoutTypeName } = entry
        if (entry.name) {
          return { ...entryWithoutTypeName, ttl: parseInt(entry.ttl) }
        }
      })

      const updatedResource = {
        ...values?.resource
      }

    const createIPAssignmentVariables = {
      ...defaults,
      ...data,
      ...values,
      ...restValues,
      dnsEntries: dnsEntries
    }

    if(networkAddress){
      createIPAssignmentVariables.networkAddress = networkAddress
    }

    if(selectedSubnetId) {
      createIPAssignmentVariables.subnetId = selectedSubnetId
    }

    if (isUpdating && data?.ipAssignment?.id) {

      if(!filteredNatData[0]?.id && (natType || destinationNat)) {
        createNat({
          variables: {
            sourceNatAddress: data?.ipAssignment?.networkAddress || null,
            natType: natType,
            subnetId: parseInt(data?.ipAssignment?.subnet?.id),
            destinationNatAddress: destinationNat || null,
          },
          refetchQueries: [{ query: searchQuery,  variables: { search: "", offset: 0, limit: 50 }, }]
        });
      }

      if((natType || destinationNat) && filteredNatData[0].id){
        try {
          const natResponse = await updateNat({
            variables: {
              id: filteredNatData[0].id,
              natType: natType,
              destinationNatAddress: destinationNat
            },
            refetchQueries: [{ query: searchQuery,  variables: { search: "", offset: 0, limit: 50 }, }]
          });
          return natResponse;
        } catch (error) {
          console.error("Mutation Error:", error);
        }
    }

      try {
        const response = await updateIPAssignment({
          variables: {
            ...(data?.ipAssignment),
            ...values,
            resource: updatedResource,
            dnsEntries: dnsEntries
          },
          refetchQueries: [{ query: searchQuery,  variables: { search: "", offset: 0, limit: 50 }, }]
        });
        return response;
      } catch (error) {
        console.error("Mutation Error:", error);
      }
    } else {
      createIPAssignment({
        variables: createIPAssignmentVariables
      })
    }
  }

  const natType = ['Source NAT', 'Destination NAT', 'Static NAT'];

  const handleLoadMore = () => {
    const v4 = new Address4(subnetData?.subnet?.networkAddress).isValid()
    const newIpAddresses = v4 ? getIpAddresses(subnetData?.subnet?.networkAddress, offset, 50) : getIPV6IPAddress(subnetData?.subnet?.networkAddress, offset, 50)
    setIpAddresses((prevIpAddresses) => [...prevIpAddresses, ...newIpAddresses]);
    setOffset((prevOffset) => prevOffset + 50);
  };

  return (
    <Drawer
      title={ queryParams?.get('networkAddress') || (isUpdating ? 'Update IP Assignment' : 'Add IP Assignment') }
      onClose={onClose}
      extra={
        isUpdating && (
          <a
            style={{
              fontSize: '14px',
              color: '#FF8888',
              '&:hover': { color: '#FF0000' }
            }}
            onClick={e => {
              destroyIPAssignment({
                  variables: { id: data?.ipAssignment?.id },
                  onCompleted: () => { onClose() },
              });
          }}
          >
            <b> Release </b>
          </a>
        )
      }
      width={520}
      visible={open}
    >

      { scanData && (
        <ScanResult scanData={ scanData } ipAssignment={ data?.ipAssignment } refetch={ refetch } onAssign={(scanResult) => {
          form.setFieldsValue({
            resource: {
              interface: scanResult?.interface,
              hostname: scanResult?.hostname,
              macAddress: scanResult?.macAddress
          }})

        }}/>
      )}
      <Form
        layout='vertical'
        form={form}
        name='ipAddress'
        initialValues={{
          ...defaults,
          ...data?.ipAssignment,
          dnsEntries: data?.ipAssignment?.DNSEntries,
          resource: {
            ...data?.ipAssignment?.interface?.resource,
            interface: data?.ipAssignment?.interface?.name,
            macAddress: data?.ipAssignment?.interface?.macAddress
          },
          subnetNetworkAddress: data?.ipAssignment?.subnet?.networkAddress
        }}
        onFinish={handleFinish}
      >

      <div ref={errorContainerRef}>
        {(errors?.length > 0) && (
          <>
            {errors.map((errorMessage, index) => (
              <Alert key={index} type="error" message={errorMessage} />
            ))}
            <br />
          </>
        )}
      </div>
        {!isUpdating && !subnetId && (
          <>
            <Form.Item
              label='Subnet'
              name='subnet'
              rules={[ { required: !subnetId, message: 'Please enter the subnet address' } ]}
            >
              <SearchSelect
                placeholder='Select the Subnet address'
                name='Subnet'
                hideLabel={ true }
                query={subnet.searchQuery}
                resultKey='subnets'
                displayKey='networkAddress'
                onChange={value => setSelectedSubnetId(value?.key)}
              />
            </Form.Item>
            <div>
              <Form.Item
                label='IP Address'
                name='networkAddress'
                rules={[ { required: !queryParams?.get('networkAddress'), message: 'Please enter the ip address' } ]}
              >
                <Input />
              </Form.Item>
            </div>
          </>
        )}
        <Form.List
          name='dnsEntries'
          initialValue={[
            ...(data?.ipAssignment?.DNSEntries || []),
            { recordType: 'A', ttl: 600 }
          ]}
        >
          {(dnsEntries, { add, remove }) => {
            return (
              <>
                <SectionHeader title='DNS' openSections= { openSections } setOpenSections={ setOpenSections } />
                { openSections.includes('DNS') && (
                <div
                  style={ sectionStyles }
                >
                  <table
                    className='dns-table'
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    <thead>
                      <th>Type</th>
                      <th>Value</th>
                      <th>TTL</th>
                      <th>
                        <PlusCircleFilled
                          style={{ float: 'right' }}
                          onClick={() => {
                            add({ recordType: 'A' })
                          }}
                        />
                      </th>
                    </thead>
                    <tbody>
                      {
                        (dnsEntries || []).map((dnsEntry, idx) => (
                          <tr>
                            <td>
                              <Form.Item
                                name={[idx, "recordType"]}
                                style={{ marginBottom: "0px", marginRight: "5px" }}
                              >
                                <AutoComplete
                                  size="small"
                                  style={{ width: "90px" }}
                                  defaultValue={dnsEntry.recordType}
                                  onChange={handleRecordTypeChange}
                                  onSelect={handleRecordTypeSelect}
                                  placeholder="Select or enter"
                                  allowClear={ false }
                                  options={dnsTypeOptions}
                                />
                              </Form.Item>
                            </td>
                            <td> <TextInput size="small" allowClear={false} style={{width: '240px', marginBottom: '0px'}} name={[idx, "name"]} hideLabel /></td>
                            <td> <TextInput type="number" size="small" allowClear={false} style={{ width: '55px', marginBottom: '0px'}} name={[idx, "ttl"]} hideLabel /> </td>
                            <td style={{width: '30px', textAlign: 'center'}}> <CloseCircleFilled style={{ fontSize: '12px', marginRight: '1px', float: 'right', color: '#5a7890'}} onClick={(e) => remove(dnsEntry.name)} /></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                )}
              </>
            )
          }}
        </Form.List>
        { <SectionHeader title='NAT' openSections={ openSections } setOpenSections={ setOpenSections } /> }
        {openSections.includes('NAT') && (
          <Form.Item
          layout="vertical"
          form={form}
          name="nat"
          initialValues={{}}
          >
          <div
            style={ sectionStyles }
          >
          <Row gutter={12}>
          <Col span={24}> 
              <SearchSelect
                label="Subnet"
                size='small'
                placeholder='Select the Subnet address for DNAT'
                name='SubnetForNat'
                query={subnet.searchQuery}
                resultKey='subnets'
                displayKey='networkAddress'
                defaultValue={{}}
                onChange={value => setSelectedSubnetIdForNat(value?.key)}
              />
              </Col>
            <Col span={24}>
              <Select
                  label="Type"
                  size='small'
                  name="natType"
                  defaultValue={filteredNatData[0]?.natType}
                  placeholder="Select NAT Type">
                    {natType.map(option => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                </Select>
              </Col>
          </Row>
          <Row gutter={12}>
          <Col span={24}>
            <Select
              label="Destination IP Addresses"
              size='small'
              name="destinationNat"
              mode="multiple"
              defaultValue={filteredNatData[0]?.destinationNatAddress}
              placeholder="Select Destination IP Addresses"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="primary"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </Button>
                  </Space>
                </>
              )}
            >
              {ipAddresses.map((ip) => (
                <Option key={ip} value={ip}>
                  {ip}
                </Option>
              ))}
            </Select>
          </Col>
          </Row>
          </div>
          </Form.Item>
        )}
        <SectionHeader title='Resource' openSections={ openSections } setOpenSections={ setOpenSections } />
        { openSections.includes('Resource') && (
          <div
            style={ sectionStyles}
          >
            <TextInput
              size='small'
              allowClear={ false }
              name={['resource', 'hostname']}
              label='Hostname'
            />
            <Row gutter={ 24 }>
              <Col span={ 18 }>
                <TextInput
                  size='small'
                  allowClear={ false }
                  name={['resource', 'OS']}
                  label='Operating System'
                />
              </Col>
              <Col span={ 6 }>
                <TextInput
                  size='small'
                  allowClear={ false }
                  name={['resource', 'OSVersion']}
                  label='OS Version'
                />
              </Col>

            </Row>
            <TextInput
              size='small'
              allowClear={ false }
              name={['resource', 'macAddress']}
              label='MAC Address'
            />
            <TextInput
              size='small'
              allowClear={ false }
              name={['resource', 'interface']}
              label='Interface'
            />
          </div>
        )}
        <SectionHeader title='Notes' openSections= { openSections } setOpenSections={ setOpenSections } />
        { openSections?.includes('Notes') && (  
          <div
            style={ sectionStyles }
          >
            <TextArea size='small' name={['description']} hideLabel />
          </div>
        )}

        <CustomAttributes modelName='IPAssignment' />

        <Row style={{marginTop: '40px'}}>
          <Col span={24}>
            <Button
              type='secondary'
              onClick={e => {
                onClose()
              }}
              style={{ float: 'right', marginLeft: '20px' }}
            >
              Cancel
            </Button>
            <Button  htmlType='submit' style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}}>
              { isUpdating ? 'Update' : 'Create'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}

export default IPForm
