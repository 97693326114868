import React, { useState, useEffect } from 'react';

import { useParams } from "react-router-dom";
import RecordLayout from '../layout/Record';

const Synchronization = ({ }) => {

  return (
    <RecordLayout section="Synchronization" >
      <div> 
      </div>
    </RecordLayout>
  )
};

export default Synchronization;
