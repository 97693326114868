import React from 'react';
import loadable from '@loadable/component'
import { Row, Col, Layout, Drawer } from 'antd';
import { useLocation, useParams, useHistory } from "react-router-dom";
import SearchForm from '../../components/SearchForm';
import SearchAction from '../../components/SearchAction';
import SearchTable from '../../components/SearchTable';
import _ from "lodash";
import SearchLayout from '../layout/Search.js';
import Entities from '../../entities';
import {PlusOutlined} from '@ant-design/icons';
import GettingStarted from '../account/GettingStarted';

const pluralize = require('pluralize');

const { Content } = Layout;

const route = (admin, rest) => { return `${admin ? '/admin/' : '/'}${rest}` }

function SearchView({ admin }) {
  const history = useHistory();
  const routeSegments = useLocation().pathname.split('/');

  const { id, model, childModel } = useParams();

  const modelName = 'network';

  const modelSingular = pluralize.singular(modelName);
  const Model = Entities[modelSingular]
  const newRecord = routeSegments[(routeSegments?.length || 0) - 1] === 'new';

  const ModelForm = loadable(() => import(`../${childModel}/Form.js`))

  const recordUrl = (record) => {

    const url = {
      'Subnet' : `/subnets/${record?.id}`,
      'IPAssignment': `/network/ip-assignments/${record?.id}`
    }[record.__typename]

    return url
  }

  return (
    <SearchLayout section='Network'>
        <Row gutter={16} >
          <Col span={24}>
            <SearchForm placeholder='Search network' modelName={ modelName } />
            <SearchAction 
              icon={ <PlusOutlined /> }
              url='/subnets/new'
              style={{marginTop: '12px'}}>
              New Subnet            
            </SearchAction>
            <SearchTable 
              style={{ marginTop: '70px' }}
              query={ Model.searchQuery }
              { ...Model.search }
              onAdd={() => { history.push(`${modelName}/new`) }} 
              hideAdd={ true }
              emptyRender={ <GettingStarted style={{ margin: 'auto', width: '1200px'}} /> }
              onClick={record => { history.push(recordUrl(record, modelName)) }}
              modelName={ modelName } />
          </Col>
          { (id || newRecord) && ModelForm && ( 
            <ModelForm 
              id={ id } 
              onDestroy={() => { history.push(`/${(childModel || network)}`) }} 
              onClose={() => { history.push(`/${(childModel || network)}`)}} />  
          )}
        </Row>
    </SearchLayout>
  )
}

export default SearchView;
