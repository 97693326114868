import React, { useState } from 'react';
import {  Row, Col, Button, Drawer, Form, Menu, Layout, Select, Input, Card, Space } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { getQuery, ADD_CONTACTS_TO_SUPPORT_GROUP } from '../../entities/support-group';
import MultiSelect from '../../components/MultiSelect';

const contact = require('../../entities/contact');
const { SUPPORT_GROUP_CONTACTS } = require('../../entities/support-group');

const ContactSupportGroupForm = ({ supportGroupId, onClose }) => {

  const [form] = Form.useForm();

  const { loading, error, data } = useQuery(getQuery, { variables: { id: parseInt(supportGroupId) }});
  const [addContact, { createData, createLoading, createError}] = useMutation(ADD_CONTACTS_TO_SUPPORT_GROUP, {
    onCompleted: () => onClose(),
    update(cache, { data }) {

      const searchVars = {search: "", offset: 0, limit: 50, supportGroupId: parseInt(supportGroupId) }

      const { supportGroupContacts } = cache.readQuery({ query: SUPPORT_GROUP_CONTACTS, variables: searchVars })

      const newContacts = data.addContactsToSupportGroup;

      cache.writeQuery({
        query: SUPPORT_GROUP_CONTACTS,
        variables: searchVars,
        data: {
          supportGroupContacts: {
            count: (supportGroupContacts?.count || 0) + newContacts?.length,
            results: [...(supportGroupContacts?.results || []), ...newContacts],
            __typename: "ContactList"

          }
        }
      })

    }
  });

  if (loading) { return <></>}

  return (
    <Form
      style={{ padding: '20px 5px'}}
      layout="vertical"
      form={form}
      name="contact"
      normalize={(contacts) => {
        const result = contacts?.map(c => {
          return {
            id: c.value,
            name: c.label
          }})
        return result
      }}
      initialValues={[]}
      onFinish={ ({ contacts }) => {

        addContact({ variables: { contactIds: contacts.map(u => parseInt(u.id)), id: parseInt(supportGroupId) }})

      }}
    >
      <Form.Item 
        label={`Add contacts to ${ data?.supportGroup?.name }`} 
        name="contacts"
        normalize={(contacts) => {
          const result = contacts?.map(c => {
            return {
              id: c.value,
              name: c.label
            }})
          return result
        }}>
        <MultiSelect 
          query={ contact.searchQuery }
          resultKey='contacts'
          displayFn={(v) => { 
            if (v.firstName || v.lastName) {
              return `${v.firstName || ''} ${v.lastName || ''}`.trim();
            } else {
              return v.email
            }
          }}

        />
      </Form.Item>

      <Row>
        <Col span={24}>
          <Space>
            <Button type="primary" htmlType="submit"> Submit </Button>
            <Button onClick={() => onClose()} type="primary"> Cancel </Button> 
          </Space>
        </Col>
      </Row>

    </Form>
  )
}

export default ContactSupportGroupForm;
