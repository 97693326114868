import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Drawer, Alert, Select } from 'antd';
import { useLazyQuery, useMutation, useQuery, } from "@apollo/client";

const alertDestination = require("../../entities/alert-destination");
const user = require("../../entities/user");

const AlertDestinationForm = ({ refetch, id, onClose }) => {
    const history = useHistory();

    const newRecord = id == 'new'
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const [platform, setPlatform] = useState(null);

    const { loading: alertDestinationLoading, error: recordError, data } = useQuery(alertDestination.getQuery, {
        variables: { id: parseInt(id) },
        skip: newRecord
    });

    useEffect(() => {
        if (data?.alertDestination) {
            setPlatform(data.alertDestination.type);
        }
    }, [data]);

    const {  data: userData } = useQuery(user.searchQuery, { variables: { search: '', offset: 0, limit: 50 } })

    const [createAlertDestination, response] = useMutation(alertDestination.create, {
        onCompleted: (v) => { },
        update(cache, { data }) {
            const existingAlertDestination = cache.readQuery({ query: alertDestination.searchQuery, variables: { search: "", offset: 0, limit: 50 } })

            cache.writeQuery({
                query: alertDestination.searchQuery,
                variables: { search: "", offset: 0, limit: 50 },
                data: {
                    alertDestinations: {
                        count: (existingAlertDestination?.count || 0) + 1,
                        results: [...(existingAlertDestination?.alertDestinations?.results || []), data.createAlertDestination]
                    }
                }
            })
        }
    });

    const [updateAlertDestination, { data: updateData }] = useMutation(alertDestination.update, {
        onCompleted: () => { history.push(`/settings/alerting/alert-destinations`) }, onError: e => {
            setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
            console.log(e)
        },
        refetchQueries: [{ query: alertDestination.getQuery, variables: { id: parseInt(id) } }]
    })

    const handleCloseForm = () => {
        history.push(`/settings/alerting/alert-destinations`)
        onClose();
    };

    const handleSubmit = (values) => {
        setLoading(true);
        const variables = {
            name: values.name || null,
            type: values.type || null,
            URL: values.URL ? values.URL : 'N/A',
            slackChannel: values.slackChannel || null,
            email: values.email || null
        };

        if (values.URL !== data?.alertDestination?.URL) {
            variables.status = 'Pending';
        } else {
            if (values.email && userData?.users?.results.map(user => user.User.email === values.email)) {
                    variables.status = 'Pending';
            } else {
                variables.status = 'Verified';
            }
        }
        if (newRecord) {

            createAlertDestination({ variables: variables })
                .then(() => {
                    setLoading(false);
                    handleCloseForm();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Create Alert Destination Mutation error:", error);
                });
        } else {
            updateAlertDestination({ variables: { id: parseInt(id), ...variables } })
                .then(() => {
                    setLoading(false);
                    handleCloseForm();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Update Alert Destination Mutation error:", error);
                });
        }
    };
    if (alertDestinationLoading) { return <></> }
    return (
        <Form
            layout="vertical"
            name="alertDestinationForm"
            initialValues={data?.['alertDestination'] || {}}
            onFinish={(values) => {
                handleSubmit(values)
            }}
        >
            <Form.Item
                name="name"
                label="Title"
                rules={[{ required: true, message: 'Please input the title' }]}
            >
                <Input placeholder="Enter the title" />
            </Form.Item>

            <Form.Item name="type" label="Platform" required>
                <Select placeholder="Select a platform" onChange={value => setPlatform(value)}>
                    <Option value="Slack">Slack</Option>
                    <Option value="Microsoft Teams">Microsoft Teams</Option>
                    <Option value="Email">Email</Option>
                </Select>
            </Form.Item>

            {platform === 'Slack' && (
                <Form.Item
                    name="slackChannel"
                    label="Slack Channel"
                    rules={[{ required: true, message: 'Please input the Slack Channel' }]}
                >
                    <Input placeholder="Enter Slack Channel" />
                </Form.Item>
            )}

            {platform !== 'Email' && platform && (
                <Form.Item
                    name="URL"
                    label="Webhook URL"
                    rules={[{ required: true, message: 'Please input the webhook URL' }]}
                >
                    <Input placeholder="Enter webhook URL" />
                </Form.Item>
            )}

            {platform === 'Email' && (
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Please input the email' }]}
                >
                    <Input placeholder="Enter email" />
                </Form.Item>
            )}

            <Button type="primary" htmlType="submit" loading={loading}>
                Submit
            </Button>
        </Form>
    )
}

export default AlertDestinationForm;
