import React, { useState } from 'react';
import {  Form, Input } from 'antd';

import { startCase } from "lodash";

class PasswordInput extends React.Component {

  render () {
    const { name, required, ...props } = this.props;

    return (
      <Form.Item
        label={  props.label || startCase(name) }
        name={ name }
        autoComplete="off"
        rules={[{ required: required, message: `${props.label || startCase(name)} is required.` }]}
      >
        <Input.Password { ...props } />
      </Form.Item>
    )
  }
}

export default PasswordInput;
