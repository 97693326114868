import React from 'react'
import { loader } from 'graphql.macro';

import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Link, useHistory, useParams } from "react-router-dom";

import { Avatar, List, Popconfirm } from 'antd';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')
const customerQuery = loader('../customer/queries/search.graphql')
const getQuery = loader('./queries/get.graphql')
const SUPPORT_GROUP_CONTACTS = loader('./queries/SUPPORT_GROUP_CONTACTS.graphql')
const SUBNET_SUPPORT_GROUPS = loader('./queries/SUBNET_SUPPORT_GROUPS.graphql')
const SUBNET_SUPPORT_GROUP_CONTACTS = loader('./queries/SUBNET_SUPPORT_GROUP_CONTACTS.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')
const ADD_CONTACTS_TO_SUPPORT_GROUP = loader('./mutations/ADD_CONTACTS_TO_SUPPORT_GROUP.graphql')
const ADD_SUPPORT_GROUPS_TO_SUBNET = loader('./mutations/ADD_SUPPORT_GROUPS_TO_SUBNET.graphql')
const REMOVE_SUPPORT_GROUP_FROM_SUBNET = loader('./mutations/REMOVE_SUPPORT_GROUP_FROM_SUBNET.graphql')
const removeContact = loader('./mutations/removeContact.graphql')
const subnet = require("../../entities/subnet");

const RemoveSupportGroupLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Support Group"
        entityTitle="support group"
        redirectPath={`/support-groups`}
      />
    </div>

  )
}

const RemoveContactLink = ({ record }) => {
  const { id } = useParams();
  
    return (
        <div onClick={e => e.stopPropagation()}>
        <RemoveRecordButton 
          record={record} 
          mutation={removeContact}
          mutationVariables={{id: parseInt(id), contactId: parseInt(record?.id)}}
          menuItems={["remove"]} 
          entityName="contact"
          entityTitle="contact"
          redirectPath={`/support-groups/${id}`}
        />
      </div>
    )
}

const RemoveSubnetSupportGroupLink = ({ record, supportGroupId, supportGroupName }) => {

  const { subnetId, supernetId } = useParams();

    const { loading, error:dataError, data:subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });


  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={REMOVE_SUPPORT_GROUP_FROM_SUBNET}
        mutationVariables={{supportGroupId: parseInt(supportGroupId), subnetId: parseInt(subnetId || supernetId), supportGroupName: supportGroupName, networkAddress: subnetData.subnet?.networkAddress, zoneId: subnetData.subnet?.zone?.id}}
        menuItems={["remove"]} 
        entityName="Support Group"
        entityTitle="support group"
        redirectPath={`/subnets/${supernetId}/${subnetId || supernetId}/support-groups`}
      />
    </div>

  )
}

const SupportGroupContacts = ({ contacts }) => {
  return (
    <List
      style={{ marginTop: '-10px' }}
      dataSource={contacts}
      renderItem={(contact) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{contact.firstName[0]}</Avatar>}
            title={`${contact?.firstName || ''} ${contact?.lastName || ''}`}
            description={<> {contact?.email} {contact?.phoneNumber} </>}
          />
        </List.Item>
      )}
    />
  )
}

const search = {
  columns: [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
    { title: 'Description', dataIndex: 'description', key: 'description', sorter: true },
    { title: '', width: 100, key: 'remove', render: sg => <RemoveSupportGroupLink record={ sg }/> }
  ]
}

const contactSearch = {
  columns: [
    { title: 'Contact Name', key: 'name', sorter: true, render: u => <>{`${u.firstName || ''} ${u.lastName || ''}`}</> },
    { title: 'Email', dataIndex: 'email', key: 'email', sorter: true },
    { title: '', width: 100, key: 'remove', render: u => <RemoveContactLink record={ u } /> }
  ]
}

const subnetSearch = {
  columns: [
    { title: 'Support Team', width: 160, dataIndex: 'name', key: 'name', sorter: true },
    {
      title: 'Contacts', width: 600, key: 'users', render: sg => <>{sg?.contacts?.length > 0 ?
        <SupportGroupContacts contacts={sg?.contacts} /> :
        "No support contacts have been added to this group"} </>
    },
    { title: '', width: 100, key: 'remove', render: u => <RemoveSubnetSupportGroupLink record={u} supportGroupId={u.id} supportGroupName={u.name} /> }
  ]
}


const form = {
  fields: [
    ["name", {}],
    ["description", {}],
  ]
}

const importer = {
  title: 'Support Groups',
  copy: 'Support groups in Lightmesh are teams or departments within an organization responsible for managing and maintaining IP addresses and related network resources. They ensure efficient IP address allocation, security, troubleshooting, and compliance with industry standards. Support groups collaborate, enforce policies, and use tools to streamline IP address management tasks.',
}

export { search, contactSearch, subnetSearch, form, ADD_CONTACTS_TO_SUPPORT_GROUP, searchQuery, customerQuery, getQuery, SUPPORT_GROUP_CONTACTS, SUBNET_SUPPORT_GROUPS, ADD_SUPPORT_GROUPS_TO_SUBNET, SUBNET_SUPPORT_GROUP_CONTACTS, REMOVE_SUPPORT_GROUP_FROM_SUBNET, removeContact, create, update, destroy, importer };
