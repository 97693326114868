import { loader } from 'graphql.macro';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const GET_SCAN = loader('./queries/get.graphql')  
const SEARCH_SCAN = loader('./queries/search.graphql')  
const UPDATE_SCAN = loader('./mutations/update.graphql')  
const DESTROY_SCAN = loader('./mutations/destroy.graphql')  
const SYNC = loader('./mutations/sync.graphql')  

const RemoveSubnetLink = ({ record }) => {

  const { subnetId, supernetId } = useParams();

    return (
      <div onClick={e => e.stopPropagation()}>
        <RemoveRecordButton 
          record={record} 
          mutation={DESTROY_SCAN}
          mutationVariables={{id: record?.id}}
          menuItems={["remove"]} 
          entityName="scan"
          entityTitle="scan"
          redirectPath={`/subnets/${supernetId}/${subnetId || supernetId}/imports`}
        />
      </div>
  
    )
  }
  

const scanSearch = {
    columns:  [
      { title: 'File Name', width: '12em', key: 'fileName', render: nmap => nmap?.fileName},
      { title: 'File Type', key: 'type', width: '16em', render: nmap => nmap?.sourceType.toUpperCase()},
      { title: 'Result', key: 'result', width: '16em', render: nmap => nmap?.result},
      { 
        title: 'Scanned By', 
        key: 'scannedBy', 
        width: '12em', 
        render: nmap => {
          if (nmap?.userId === 0) {
            return 'LightMesh CLI';
          }
          return nmap?.user?.firstName + ' ' + nmap?.user?.lastName;
        }
      },
      { title: 'Scanned At', key: 'scannedAt', width: '10em', render: nmap => (new Date(nmap?.scannedAt).toLocaleString()) },
      { title: '', width: '2em', key: 'remove', render: d => <RemoveSubnetLink record={ d }/> }
    ]
  }

export { GET_SCAN, UPDATE_SCAN, SEARCH_SCAN, DESTROY_SCAN, scanSearch, SYNC };
