import React, { Component, useState, useForm, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Drawer, Layout, Menu } from 'antd';
import { DownOutlined, CloudOutlined, LogoutOutlined, ReadOutlined, ToolOutlined, BellOutlined, BarChartOutlined, CloseOutlined } from '@ant-design/icons';
import {
  BrowserRouter as Router,
  Link,
  useHistory,
} from "react-router-dom";
const { CURRENT_USER_QUERY } = require("../../entities/auth");
const  alert  = require("../../entities/alert");
import ReactMarkdown from 'react-markdown';

import Notifications from "react-notifications-menu";

const { truncate } = require('lodash');

const { SubMenu } = Menu;
const { Content, Header } = Layout

const { SET_ORGANIZATION_MUTATION } = require("../../entities/auth");
import SupportDrawer from '../support/SupportDrawer';


const Navbar = function Navbar({ role, user, logout, handleClick }) {

  const history = useHistory();

  const notificationsRef = useRef();

  const [drawer1Visible, setDrawer1Visible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [unreadAlertsCount, setUnreadAlertsCount] = useState(0);

  const showDrawer = () => {
    setVisible(true);
  };

  const openWidget = () => { FreshworksWidget('open');}

  const onClose = () => {
    setVisible(false);
  };
  
  const validEmails = ['andrew@tidalmigrations.com', 'deep.bhalotia@tidalmigrations.com', 'amber.weaver@tidalmigrations.com', 'david@tidalmigrations.com', 'ravi.gaddam@tidalmigrations.com'];

  const [setOrganization, { data, error }] = useMutation(SET_ORGANIZATION_MUTATION, {
    onCompleted: () => { window.location.href = "/" }
  })

  const { loading: currentUserLoading, data: currentUserData } = useQuery(CURRENT_USER_QUERY);
  const { data: alertData, refetch } = useQuery(alert.searchQuery, { variables: { search: '', offset: 0, limit: 50, filter: { 
    clearNotification: false,
    status: { eq: "Unread" }}  }})

  const [updateAlert, { data: updateData }] = useMutation(alert.update, {
    onCompleted: refetch()
  })

  const unreadAlerts = (alertData?.alerts?.results || [])

  const handleClearAllClick = () => {
    unreadAlerts.forEach(alert => {
      updateAlert({ variables: { id: alert?.id, clearNotification: true } });
    });
  }

  const handleCloseDrawer = () => {
    setDrawer1Visible(false);
  };
  

  useEffect(() => {
    const count = alertData?.alerts?.results?.filter(alert => alert.UserId === currentUserData?.currentUser?.id && alert.status === 'Unread').length;
    setUnreadAlertsCount(count);
  }, [alertData, currentUserData]);

  useEffect(() => {
    return history.listen((location) => { 
      if (location?.pathname === "/alerts") {
        setDrawer1Visible(true)
      }
    }); 
  },[history]); 

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.innerText === 'View All') {
        setDrawer1Visible(true)
        const container = document.querySelector('.container');
        if (container) {
          container.style.visibility = 'hidden';
        }
      }
    };

    document.addEventListener('click', handleClick);
  
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);



  return (
    <Header 
      className='navBar'>
      <a href='/'><div className="logo" /></a>
      <Menu
        style={{ 
          width: 'calc(100% - 250px)',
          marginLeft: '225px',
          marginRight: '25px',
          justifyContent: 'flex-end',
          zIndex: '3', 
          fontSize: '14px', 
          color: '#567999' 
        }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub0']}
        onClick={ handleClick }
        trigger='click'
        subMenuOpenDelay='0.3'
        mode="horizontal"
        getPopupContainer={node => node.parentNode}
      >
      { (role !== 'Requestor') && ( <>

        <SubMenu
          style={{ width: '95px', textAlign: 'left', paddingLeft: '10px', marginRight: '10px'}}
          key="network"
          popupClassName="network-dropdown"
          title={<span>
            <Link style={{ color: '#567999', marginRight: '12px'}}>Network</Link>
            <DownOutlined
              style={{ fontSize: '9px', fontWeight: '500', marginLeft: '0px'}}
            />
          </span>}>

          <Menu.Item key="subnets">
            <Link to="/subnets"/> 
            <span>Subnets</span>
          </Menu.Item>

          <Menu.Item key="ip-assignments">
            <Link to="/ip-assignments"/> 
            <span>IP Assignments</span>
          </Menu.Item>

          <Menu.Item key="zones">
            <Link to="/zones"/> 
            <span>Zones</span>
          </Menu.Item>

          <Menu.Item key="sites">
            <Link to="/sites"/> 
            <span>Sites</span>
          </Menu.Item>

          <Menu.Item key="dns">
            <Link to="/dns"/> 
            <span>DNS</span>
          </Menu.Item>

          <Menu.Item key="datacenters">
            <Link to="/datacenters"/> 
            <span>Datacenters</span>
          </Menu.Item>
        </SubMenu>
        <Menu.Item style={{ lineHeight: '52px' }}>
          <Link to="/resources"/> 
          Resources
        </Menu.Item>

        <SubMenu
          style={{ width: '110px', textAlign: 'left', marginLeft: '10px', marginRight: '5px'}}
          key="organization"
          popupClassName="profile-dropdown"
          title={<span>
            Organization
            <DownOutlined
              style={{ fontSize: '8px', marginLeft: '6px'}}
            />
          </span>}>
          <Menu.Item key="customers">
            <Link to="/customers"/> 
            <span>Customers</span>
          </Menu.Item>

          <Menu.Item key="supportGroups">
            <Link to="/support-groups"/> 
            <span>Support Groups</span>
          </Menu.Item>

          <Menu.Item key="contacts">
            <Link to="/contacts"/> 
            <span>Contacts</span>
          </Menu.Item>
        </SubMenu>


        { (role !== 'Reader' && role !== 'Contributor') && ( 
          <SubMenu
            style={{ width: '70px', textAlign: 'left', marginLeft: '20px', marginRight: '0px'}}
            key="admin"
            popupClassName="profile-dropdown"
            title={<span>
              Admin
              <DownOutlined
                style={{ fontSize: '8px', marginLeft: '6px'}}
              />
            </span>}>
            <Menu.Item key="users">
              <Link to="/users"/> 
              <span>Users</span>
            </Menu.Item>
            
              <Menu.Item key="alerting">
                <Link to="/settings/alerting/alert-destinations"/> 
                <span>Alerts</span>
              </Menu.Item>

            <Menu.Item key="api">
              <Link to="/settings/api-keys"/> 
              <span>API Keys</span>
            </Menu.Item>

            <Menu.Item key="user-groups">
              <Link to="/user-groups"/> 
              <span>Groups</span>
            </Menu.Item>

            <Menu.Item key="user">
              <Link to="/roles"/> 
              <span>Roles</span>
            </Menu.Item>
          </SubMenu>
        )}


        <SubMenu
          style={{ width: '90px', textAlign: 'left', marginLeft: '15px', marginRight: '5px'}}
          key="settings"
          popupClassName="profile-dropdown"
          title={<span>
            Settings
            <DownOutlined
              style={{ fontSize: '8px', marginLeft: '6px'}}
            />
          </span>}>
          <Menu.Item key="imports">
            <Link to="/imports"/> 
            <span>Imports</span>
          </Menu.Item>

          {
            currentUserData.currentUser.UserOrganizations[0].role === 'Owner' &&
              <Menu.Item key="exports">
                <Link to="/exports"/> 
                <span>Exports</span>
              </Menu.Item>
          }


          <Menu.Item key="schema">
            <Link to="/settings/custom-attributes"/> 
            <span>Schema</span>
          </Menu.Item>

          { process.env.NODE_ENV == 'development' && (
            <Menu.Item key="synchronization">
              <Link to="/settings/synchronization"/> 
              <span>Synchronization</span>
            </Menu.Item>
          )}

          <Menu.Item key="downloads">
            <Link to="/settings/downloads"/> 
            <span>Downloads</span>
          </Menu.Item>
        </SubMenu>
        
        <Menu.Item key="reports" style={{ width: '105px' }}>
          <Link to="/reports"/> 
          <BarChartOutlined />
          <span>Reports</span>
        </Menu.Item>
      </> )}

      { (role === 'Requestor')  && ( 
                <Menu.Item key="requests" style={{ width: '105px' }}>
                <Link to="/requests"/> 
                <span>Requests</span>
              </Menu.Item>

      )}

        <SubMenu
          style={{ textAlign: 'right', marginLeft: '2px', marginRight: '2px'}}
          key="support"
          title={<span>
            <ToolOutlined style={{ marginRight: '6px' }}/>
              Support
            </span>}
          onTitleClick={openWidget}>

        </SubMenu>


        <Menu.Item key="guides" style={{ width: '105px' }}>
          <a target="_blank" href="https://guides.lightmesh.com" style={{ color: '#567999'}}>
          <ReadOutlined />
          <span>Guides</span>
          </a>
        </Menu.Item>

        <Menu.Item style={{ marginTop: '0px', marginLeft: '8px' }} key="cloud">
          <CloudOutlined 
              style={{ fontSize: '13px', fontWeight: '500', marginRight: '8px'}}/>
          <Link to="/cloud-accounts"/> 
          <span>Cloud</span>
        </Menu.Item>


        <SubMenu
          style={{ minWidth: '220px', textAlign: 'left', marginRight: '0px'}}
          key="profile"
          popupClassName="profile-dropdown"
          title={<span>
            {truncate(user?.email || `${user?.firstName} ${user.lastName}` )}
            <DownOutlined style={{ fontSize: '8px', marginLeft: '6px'}} />
          </span>}>
          { (user?.Organizations?.length > 1) && user?.Organizations?.map(org => {
            return (

              <Menu.Item key={`org-${org?.id}`}>
                <Link onClick={() => { user?.currentOrganizationId !== org?.id ? setOrganization({ variables: {id: parseInt(org?.id) }}): null}}/> 
                <span>
                { user?.currentOrganizationId !== org?.id ? 'switch to ' : null}
                { org?.name }
                { user?.currentOrganizationId == org?.id && '( active )' }
                </span>
              </Menu.Item>

            )
            })}
          <Menu.Item key="account">
            <Link to="/account/profile"/> 
            <span>Account</span>
          </Menu.Item>


          <Menu.Item key="logout">
            <a onClick={logout} type="button" style={{ fontSize: "12px" }}>
              Logout
            </a>
          </Menu.Item>

          { (validEmails.includes(user.email)) &&  
          <>

            <Menu.Item key="summary" style={{ borderLeft: '10px solid #f2def2'}}>
              <Link to="/tidal-admin/summary"/> 
              <span>Summary</span>
            </Menu.Item>

            <Menu.Item key="user-search" style={{ borderLeft: '10px solid #f2def2'}}>
              <Link to="/tidal-admin/users"/> 
              <span>Users</span>
            </Menu.Item>

            <Menu.Item key="search" style={{ borderLeft: '10px solid #f2def2'}}>
              <Link to="/tidal-admin/organizations"/> 
              <span>Organizations</span>
            </Menu.Item>

            <Menu.Item key="activity" style={{ borderLeft: '10px solid #f2def2'}}>
              <Link to="/tidal-admin/activity"/> 
              <span>Activity</span>
            </Menu.Item>

            <Menu.Item key="registrations" style={{ borderLeft: '10px solid #f2def2'}}>
              <Link to="/tidal-admin/registrations"/> 
              <span>Registrations</span>
            </Menu.Item>
            <Menu.Item key="user-communication-version" style={{ borderLeft: '10px solid #f2def2'}}>
              <Link to="/tidal-admin/user-communication"/> 
              <span>User Communication</span>
            </Menu.Item>
            <Menu.Item key="support-ticket" style={{ borderLeft: '10px solid #f2def2'}}>
              <Link to="/tidal-admin/support-ticket"/> 
              <span>Support Tickets</span>
            </Menu.Item>
          </>
          }
        </SubMenu>

        <SubMenu
          key="notifications"
          popupOffset={[-25,3]}
          title={<span ref={notificationsRef}>
            <Badge count={unreadAlertsCount} size='small'>
              <BellOutlined style={{fontSize: '1.1em', marginTop: '0px', color: '#567999'}} />
            </Badge>
          </span>}>
          { alertData?.alerts?.results?.length > 0 && (
            <>
              { alertData?.alerts?.results?.filter(alert => alert.UserId === currentUserData.currentUser?.id && alert.clearNotification == false).slice(0, 5).map(alert => (
                <Menu.Item style={{ width: '300px', fontWeight: 'normal' }}>
                  <span style={{ lineHeight: '1.5', width: '260px', whiteSpace: 'normal', margin: '0', padding: '0', overflow: 'hidden'}}>
                    <ReactMarkdown 
                      children={alert.message.trim()} 
                      components={{
                        a: ({node, ...props}) => <Link to={props.href} style={{ textDecoration: 'underline' }} {...props} />,
                        p: ({node, ...props}) => <p style={{ margin: '0', padding: '0' }} {...props} />
                      }} 
                    />
                </span>
                  <CloseOutlined onClick={() => { updateAlert({ variables: { id: alert.id, clearNotification: true } }) }} style={{ fontSize: '0.65em', color: '#567999', position: 'absolute', right: '14px', top: '20px' }} />
                </Menu.Item>
              ))}
              { alertData?.alerts?.results?.some(alert => alert.UserId === currentUserData.currentUser?.id && alert.clearNotification == false) && 
                <Menu.Item style={{ textAlign: 'center' }}>
                  <a onClick={() => { handleClearAllClick() }} style={{ color: '#567999', fontSize: '11px' }}>
                    Clear all
                  </a>
                </Menu.Item>
              }
            </>
          )}
          { alertData?.alerts?.results?.length == 0 && (
            <Menu.Item style={{ width: '250px', color: '#567999' }}>
              You are up to date!
            </Menu.Item>
          )}
        </SubMenu>


      </Menu>
      <SupportDrawer currentUserData={currentUserData} visible={visible} onClose={onClose} />
      <Drawer title="Notifications" open={drawer1Visible} closable={true} onClose={handleCloseDrawer}>
        {alertData?.alerts?.results?.length > 0 ? (
          alertData.alerts.results.map((alert, index) => (
            <div key={index} style={{border: '1px solid #ccc', padding: '10px', margin: '10px 0'}}>
              <p>{alert.message}</p>
            </div>
          ))
        ) : (
          <p>No Notifications</p>
        )}
      </Drawer>
    </Header>
  )
}
export default Navbar;
