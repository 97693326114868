import React, { useState } from 'react';
import { Col, Row, Space, Tree } from 'antd';
import {ArrowUpOutlined, CaretDownFilled, CaretUpFilled, DownOutlined, FullscreenOutlined} from '@ant-design/icons';

import { unionWith, flattenDeep } from 'lodash';

const { Address4, Address6 } = require('ip-address');
import cidrRegex from 'cidr-regex';
import {useHistory} from 'react-router-dom';
const { BigInteger } = require('jsbn');

const parsedSubnet = (subnet) => {
  if (!subnet) { return {}}
  if (cidrRegex.v4({exact: true}).test(subnet)) {
    return new Address4(subnet)
  } else {
    return new Address6(subnet)
  }
}

const inSubnet = (s1, s2) => {
  const subnet1 = new Address4((s1.networkAddress));
  const subnet2 = new Address4(s2.networkAddress);

  return subnet1.isInSubnet(subnet2) || subnet2.isInSubnet(subnet1);
}

const compareSubnet = (s1, s2) => {
  const subnet1 = new Address4((s1.networkAddress));
  const subnet2 = new Address4(s2.networkAddress);

  return subnet1.bigInteger() - subnet2.bigInteger();
}

export const possibleSubnets = ( supernet, netmask ) => {

  const snet = parsedSubnet( supernet.networkAddress );

  const isV4 = snet instanceof Address4;
  const maxNetmask = isV4 ? 32 : 128;
  const supernetAddress = snet.bigInteger();
  let result = [];

  if (netmask && (netmask > snet.subnetMask) && (netmask <= maxNetmask) && isV4) {


    const supernetSize = Math.pow(2, 32 - snet.subnetMask);
    const subnetSize = Math.pow(2, 32 - netmask);

    let i = 0;

    while ( i < supernetSize && i < (100 * subnetSize) ) {

      const address = Address4.fromBigInteger(supernetAddress.add(new BigInteger(i.toString())) + "/" + netmask).address

      result.push({ 
        networkAddress: address + "/" + netmask,
        supernetId: supernet.id,
        netmask: netmask
      })

      i += subnetSize;
    }


    return result;

  }
  else if(netmask && (netmask > snet.subnetMask) && (netmask <= maxNetmask) && !isV4){
    const supernetSize = new BigInteger(snet.possibleSubnets().toString())

    const subnetsCount = new BigInteger(snet.possibleSubnets(netmask).toString())
    const subnetSize = supernetSize.divide(subnetsCount)


    let i = new BigInteger('0');

    const snetSize = new BigInteger(subnetSize.toString())

    while (i.compareTo(new BigInteger(supernetSize.toString())) < 0) {

      const address = Address6.fromBigInteger(supernetAddress.add(i)).correctForm()

      result.push({ 
        networkAddress: address + "/" + netmask,
        supernetId: supernet.id,
        netmask: netmask
      })

      i = i.add(snetSize)
    }

    return result;

  } else {

    return [];

  }
}

const allSubnets = (subnet, netmask) => {

  const subnets = unionWith(subnet.subnets || [], possibleSubnets(subnet, netmask), inSubnet )

  if (subnets.length > 0) {

   const children = subnets.sort(compareSubnet).map(s => { return allSubnets(s, netmask)})

   return { ...subnet , subnets: (children || []) } 
  } else {
    return subnet
  }
}

const getSubnet = ( networkAddress, subnet ) => {

  const results = []

  if (networkAddress == subnet.networkAddress) {
    results.push( )
  } else {
    return subnet?.subnets?.map(subnet => {
      return getSubnet(networkAddress, subnet)
    })
  }
}

function search (subnet, value, key = 'networkAddress', reverse = false) {
  const stack = [ subnet ]

  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']()
    if (node['networkAddress'] === value) return node
    node.subnets && stack.push(...node.subnets)
  }
  return null
}


const NetmaskSelector = ({ netmask, setNetmask, min, v6 }) => {

  const caretStyle = { fontSize: '13px', color: '#3d75a4', lineHeight: '4px' }

  return(
    <Space size={ 4 } direction='horizontal' style={{  width: '275px', height: '30px', padding: ' 0px 8px', textAlign: 'right', marginBottom: '30px', }}>
      <Space.Compact size={ 0 } direction='vertical' style={{ padding: '0px 0px', marginLeft: '160px', width: '10px', marginTop: '5px'}}>
        <CaretUpFilled style={{ ...caretStyle}} onClick={() => { (netmask < (v6 ? 128 : 32)) ? setNetmask(netmask + 1) : null }} />
        <CaretDownFilled style={{ ...caretStyle }} onClick={() => { (netmask > (min + 1)) ? setNetmask(netmask - 1) : null}} />
      </Space.Compact>
      <h2 style={{ fontSize: '22px', textAlign: 'right', verticalAlign: 'center', lineHeight: '24px', width: '60px', marginTop: '15px' }}>/ { netmask }</h2>
      { !v6 && (<p style={{  fontSize: '12px', marginTop: '20px', color: '#999999', verticalAlign: 'center', lineHeight: '14px', width: '40px', marginLeft: '10px', textAlign: 'right'}}>{ Math.pow(2, (32 - netmask)) } hosts </p>)}
    </Space>
  )
}

const SubnetTree = ({ onSelect, data, selected, onCreate, onDestroy, onClick, onDblClick }) => {

  const history = useHistory();

  const isV4 = cidrRegex.v4({exact: true}).test( data?.networkAddress )

  const netmask = parseInt(data?.networkAddress?.split('/')?.[1])
  const defaultNetmask = netmask + 1

  const [editingNetmask, setEditingNetmask] = useState(defaultNetmask)

  const newData = allSubnets(data || {}, editingNetmask) 

  
  return (
    <Row>
      <Col span={ 24 }>
        <NetmaskSelector netmask={ editingNetmask } setNetmask={ setEditingNetmask } min={ netmask } v6={ !isV4 }/>

        { data?.subnet?.supernet?.networkAddress && (
          <div
            className='supernet-body'
            onClick={() => {
              const id = data?.subnet?.supernet?.id

              const url = id ? `/subnets/${id}/${id}` : `/subnets`
              history.push(url)

            }}
            style={{
              fontSize: '14px',
              width: '275px',
              height: '25px',
              marginLeft: '24px',
              padding: '0px 8px 0px',
              color: '#d0e2f1',
              marginBottom: '10px',
              verticalAlign: 'text-top',
              lineHeight: '25px',
              background: '#2c6696'
            }}>

            { data?.subnet?.supernet?.networkAddress || 'Back to search' }

            <ArrowUpOutlined style={{ fontSize: '10px', paddingTop: '7px', display: 'inline-block', float: 'right',
              marginRight: '4px' }}/>
          </div>
        )
        }


        <Tree
          style={{ width: '300px' }}
          titleRender={subnet => <div 
            style={{ height: '25px', width: '280px', overflow: 'hidden', verticalAlign: 'text-top' }}
            className={`subnet-bar ${selected == subnet?.id ? 'selected' : ''} ${ subnet?.id ? '' : 'new-record'}`}>


            { subnet?.name && (<>
              <div style={{ display: 'inline-block', float: 'left', maxWidth: '80px', height: '25px', marginRight: '10px', overflow: 'hidden' }}> { subnet?.name } </div>
              <div style={{ fontSize: '13px', display: 'inline-block', width: '60px'}}> { subnet.networkAddress?.replace('/', ' / ') } </div>
            </>
            )}

            { !subnet?.name && ( <div style={{ display: 'inline-block'}}> { subnet.networkAddress?.replace('/', ' / ') } </div>) }
            { subnet?.id ? '' : <div style={{ display: 'inline-block', float: 'right', marginRight: '20px' }}>+</div>}

            {/**{ !subnet?.supernet && (<FullscreenOutlined className='expandIcon' style={{ color: '#d0e2f1', position: 'absolute', right: '58px', top: '6px', color: '#FFFFFF', fontSize: '12px', fontWeight: '500', opacity: '0.8' }}/>) }**/}

          </div>
          }
          showLine
          height={ 800 }
          defaultExpandAll
          switcherIcon={<DownOutlined />}
          onSelect={(s) => {
            const subnetAddress = s[0]

            const sdata = search(newData, subnetAddress)

            if (sdata?.id) {
              onSelect(sdata)
            } else {
              onCreate({ variables: { ...sdata, zoneId: data?.subnet?.zoneId }})
            }
          }}
          defaultSelectedKeys={ selected || [] }
          treeData={ [ newData ] }
          fieldNames={{
            title: 'networkAddress',
            children: 'subnets',
            key: 'networkAddress'
          }}
        />
      </Col>
    </Row>
  )
}

export default SubnetTree;
