import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput } from '../../components/pants-d';

const project = require("../../entities/project");

const ProjectForm = () => {
  const { model, id } = useParams();
  const newRecord = id == 'new'

  const history = useHistory();

  const [getProject, { loading, error: recordError, data }] = useLazyQuery(project.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => { if (!newRecord) { getProject({ variables: {id: parseInt(id)}})}}, [])

  const [createRecord, { data: createData }] = useMutation(project.create, {
    onCompleted: () => { history.push(`/projects`) },
    update(cache, { data }) { 
      const existingProjects = cache.readQuery({ query: project.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: project.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          projects: {
            count: (existingProjects?.count || 0) + 1,
            results: [...(existingProjects?.projects?.results || []), data.createProject]
          }
        }
      })
    }});

  const [updateProject, { data: updateData }] = useMutation(project.update, { onCompleted: () => { history.push(`/projects`) }} )

  const [form] = Form.useForm();

  if (loading) { return <></> }
  return (
    <Form
      layout="vertical"
      form={form}
      name="project"
      initialValues={data?.['project'] || {}}
      onFinish={ (values) => {
        newRecord ? createRecord({ variables: {...values}}) : updateProject({ variables: {id: parseInt(id), ...values}}) 
      }} >

      <TextInput name="name" />

      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  )
}

export default ProjectForm;
