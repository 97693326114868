import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input, Space, Alert, Drawer, Divider } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect, Select, CustomAttributes } from '../../components/pants-d';

import { Address4 as IPv4, Address6 as IPv6 } from 'ip-address';
import { CreateSubnetButton } from '../account/GettingStarted';
import { SectionHeader } from '../ip-assignments/Form';

const { Option } = Select;

const subnet = require("../../entities/subnet");
const zone = require('../../entities/zone')
const site = require('../../entities/site')
const { searchQuery } = require("../../entities/site");

const sectionStyles = {
  padding: '10px 15px',
  marginTop: '-4px',
  marginBottom: '20px',
  background: '#FAFAFA',
}

export function displayFunctionSite(val) {
  return `${val.name} ,  ${val.addresses[0]?.line1 || ''} ${val.addresses[0]?.line2 || ''} ${val.addresses[0]?.line3 || ''} ${val.addresses[0]?.line4 || ''}  ${val.addresses[0]?.municipality || ''} ${val.addresses[0]?.province || ''} ${val.addresses[0]?.postalCode || ''} ${val.addresses[0]?.country || ''}`;
}

const getAdjustedIPv4Address = (ip, suggestedSubnetMask) => {
  const octets = ip.split(".");
  for (let i = Math.floor(suggestedSubnetMask / 8); i < 4; i++) {
    octets[i] = "0";
  }
  return octets.join(".");
};

const getAdjustedIPv6Address = (ip, suggestedSubnetMask) => {
  const ipBlocks = ip.split(':');
  const adjustedBlocks = ipBlocks.slice(0, 8); // Ensure maximum 8 blocks

  const fullBlockCount = Math.floor(suggestedSubnetMask / 16);
  const partialBlockMask = suggestedSubnetMask % 16;

  if (fullBlockCount < 8) {
    const lastBlock = parseInt(adjustedBlocks[fullBlockCount], 16);
    const newLastBlock = (lastBlock >> (16 - partialBlockMask)) << (16 - partialBlockMask);
    adjustedBlocks[fullBlockCount] = newLastBlock.toString(16).padStart(4, '0');

    for (let i = fullBlockCount + 1; i < 8; i++) {
      adjustedBlocks[i] = '0';
    }
  }

  return adjustedBlocks.join(':');
};

const getClosestValidCIDR = (cidr) => {
  const [ip, subnetMask] = cidr.split("/");
  const parsedSubnetMask = parseInt(subnetMask, 10);

  const suggestedSubnetMask = Math.min(128, Math.max(parsedSubnetMask, 0));

  let adjustedIP;
  if (ip.includes(":")) {
    const ipObject = new IPv6(ip);
    adjustedIP = getAdjustedIPv6Address(ipObject.address, suggestedSubnetMask);
  } else {
    const ipObject = new IPv4(ip);
    adjustedIP = getAdjustedIPv4Address(ipObject.address, suggestedSubnetMask);
  }

  const suggestedCIDR = `${adjustedIP}/${suggestedSubnetMask}`;
  return suggestedCIDR;
};


export const getClosestValidCIDRFromError = (errorMessage) => {
  const match = errorMessage.match(/Invalid cidr value: (.*)/i);
  if (!match) {
    return null;
  }

  const invalidCIDR = match[1].trim();

  return getClosestValidCIDR(invalidCIDR);
};

const SubnetForm = ({ onClose }) => {
  let { model, id } = useParams();

  const newRecord = id === 'new'

  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [alertStatus, setAlertStatus] = useState(null);
  const [openSections, setOpenSections] = useState(['Sites', 'Zone']);

  const { data: queryResults, refetch } = useQuery(searchQuery, { variables: { search: '', offset: 0, limit: 50 } })

  const [createSite, { data: createSiteData, error: createError }] = useMutation(site.create, {
    onCompleted: (e) => {
      setAlertStatus(true),
        setTimeout(() => {
          setAlertStatus(false);
        }, 30000);
      setShowForm(false);
    },
    onError: (e) => { console.log(e) },
    update(cache, { data }) {
      const existingSites = cache.readQuery({ query: site.searchQuery, variables: { search: "", offset: 0, limit: 50 } })
      cache.writeQuery({
        query: site.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          sites: {
            count: (existingSites?.count || 0) + 1,
            results: [...(existingSites?.sites?.results || []), data.createSite]
          }
        }
      })
    }
  });

  const handleAddSiteClick = () => {
    setShowForm(true);
  };

  const handleAddSiteFormCancel = () => {
    setShowForm(false);
  };

  const [getSubnet, { loading, error: recordError, data, }] = useLazyQuery(subnet.GET_QUERY, { variables: { id: parseInt(id) }, });
  useEffect(() => { if (id && !newRecord) { getSubnet({ variables: { id: parseInt(id) } }) } }, [])
  const [errors, setErrors] = useState([])

  const [createRecord, { data: createData, loading: createLoading, error: createErrors }] = useMutation(subnet.create, {
    onCompleted: ({ createSubnet: subnet }) => {
      history.push(`/subnets/${subnet?.id}`)
    },
    onError: (err) => {
      const message = err?.message;
      const errorMessage = (message.charAt(0).toUpperCase() + message.slice(1)).replaceAll('\"', '');
      const suggestedCIDR = getClosestValidCIDRFromError(errorMessage);

      const errorWithSuggestion = suggestedCIDR
        ? (
          <>
            {errorMessage}. Did you mean &nbsp;<CreateSubnetButton networkAddress={suggestedCIDR} />?
          </>
        )
        : `Invalid CIDR value: ${errorMessage}.`;

      setErrors([{ message: errorWithSuggestion }]);
    },
  });

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  if (loading) { return "Loading" }

  if (createErrors) {

    const fieldErrors = createErrors.graphQLErrors?.filter(e => e.extensions?.exception?.errors?.[0]?.path).map(e => {
      return {
        name: e.extensions?.exception?.errors?.[0]?.path,
        errors: [e.extensions?.exception?.errors?.[0]?.message]
      }
    });

    form.setFields(fieldErrors)
  }

  return (
    <Drawer
      title={"New Subnet"}
      width={520}
      onClose={onClose}
      visible={open}>
      <Form
        size='small'
        layout="vertical"
        form={form}
        name="subnet"
        initialValues={data?.['subnet'] || {}}
        onFinish={({ zone, site, ...values }) => {
          const cleanedNetworkAddress = values?.networkAddress.replace(/\s/g, '');
          const isIPv4CIDR = new IPv4(cleanedNetworkAddress).isValid();
          const isIPv6CIDR = new IPv6(cleanedNetworkAddress).isValid();
          if (!isIPv4CIDR && !isIPv6CIDR) {
              form.setFields([
                  {
                      name: 'networkAddress',
                      errors: ['Invalid CIDR value'],
                  },
              ]);
              return;
          }
          createRecord({ variables: { ...values, networkAddress: cleanedNetworkAddress, zoneId: zone?.id, siteId: site } })
        }} >

        {errors && errors.map(error => {
          return (<><Alert type="error" message={error.message} /><br /></>)
        })}
          <div>
            <Row gutter={12}>
              <Col span={12}>
                <TextInput name="name" required initialValues='' />
              </Col>

              <Col span={12}>
                <TextInput name="networkAddress" placeholder="i.e. 10.0.0.0/16  or  fd00:1::/64" required />
              </Col>
            </Row>

            <TextArea name="description" />
          </div>
          <>
            <SectionHeader title='Sites' openSections= { openSections } setOpenSections={ setOpenSections } />
            { openSections.includes('Sites') && (
              <>
                {!showForm ? (
                  <div style={sectionStyles}>
                    {alertStatus && (
                      <Alert
                        message="New Site Added Successfully! Open Site DropDown to Select"
                        type="success"
                        showIcon
                        style={{ marginBottom: '16px' }}
                      />
                    )}
                    <Select
                      label="&nbsp;"
                      name="site"
                      placeholder="Select / Create a Site"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />
                          <Space style={{ padding: '0 8px 4px' }}>
                            <Button
                              type="primary"
                              onClick={handleAddSiteClick}
                            >
                              Add a new Site
                            </Button>
                          </Space>
                        </>
                      )}
                    >
                      {queryResults?.sites?.results.map((site) => (
                        <Option key={site.id} value={site.id}>{displayFunctionSite(site)}</Option>
                      ))}
                    </Select>
                  </div>
                ) : (
                <div style={ sectionStyles }>
                    <Form
                    layout="vertical"
                    form={form2}
                    name="site"
                    onFinish={(values) => {
                      createSite({ variables: { name: values.siteName, addresses: [{ line1: values.line1, line2: values.line2, line3: values.line3, line4: values.line4, municipality: values.municipality, postalCode: values.postalCode, province: values.province, country: values.country }] } })
                    }} >
                    <h2>Add a new Site</h2>
                    <TextInput name="siteName" label="Site Name" />
                    <div style={{ padding: '4px 0px', marginBottom: '20px' }}>
                      <Row gutter={12}>
                        <Col span={24}>
                          <TextInput size="small" allowClear={false} name="line1" label="Street Address Line 1" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <TextInput size="small" allowClear={false} name="line2" label="Street Address Line 2" />
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col span={12}>
                          <TextInput size="small" allowClear={false} name="municipality" label="City" />
                        </Col>
                        <Col span={12}>
                          <TextInput size="small" allowClear={false} name="province" label="State / Province" />
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col span={12}>
                          <TextInput size="small" allowClear={false} name="country" label="Country" />
                        </Col>
                        <Col span={12}>
                          <TextInput size="small" allowClear={false} name="postalCode" label="Postal / Zip Code" />
                        </Col>
                      </Row>
                    </div>

                    <Space>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                      <Button type="secondary" onClick={handleAddSiteFormCancel}>
                        Cancel
                      </Button>
                    </Space>
                    </Form>
                </div>
                )}
              </>
            )}
            </>
          <div>
            <SectionHeader title='Zone' openSections= { openSections } setOpenSections={ setOpenSections } />
              { openSections.includes('Zone') && (
                <div style={sectionStyles}>
                  <SearchSelect
                    name="zone"
                    label="&nbsp;"
                    placeholder="Select / Create a Zone"
                    resultKey="zones"
                    displayKey="name"
                    query={zone.searchQuery}
                    createLabel={'Add Network Zone'}
                    createType='String'
                    createMutation={zone.create}
                  />
                </div>
              )}
            <CustomAttributes modelName='Subnet' />
            <Button type="secondary" onClick={e => { onClose() }} style={{ float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>
            <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}} htmlType="submit">
              Submit
            </Button>
          </div>
      </Form>
    </Drawer>
  )
}

export default SubnetForm;
