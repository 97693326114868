import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input, Drawer, Alert, Select } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect } from '../../components/pants-d';
import {CloseCircleFilled, PlusCircleFilled} from '@ant-design/icons';

const request = require("../../entities/request");
const subnet = require("../../entities/subnet");
const ipAddress = require("../../entities/ip-address");

const RequestForm = ({ refetch, id, onClose }) => {
  const history = useHistory();

  const { model } = useParams();
  const newRecord = id == 'new'
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [getRequest, { loading, error: recordError, data }] = useLazyQuery(request.getQuery, { variables: { id: parseInt(id) }});

  const [getAvailableIPAddresses, { error, data: availableIPAddresses, loading: loadingIPAddresses }] = useLazyQuery(ipAddress.AVAILABLE_IP_ADDRESSES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      form.setFieldsValue({
        NetworkRequestItems: data?.ipAddresses?.results
      })
    }
  });

  useEffect(() => { if ( id && !newRecord ) { getRequest({ variables: { id: parseInt(id) }}) } },[])

  const [createRecord, { data: createData, error: createErrors }] = useMutation(request.create, {
    onCompleted: () => {  
      history.push(`/requests`) },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
    },
    update(cache, { data }) { 
      const existingRequests = cache.readQuery({ query: request.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: request.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          networkRequests: {
            count: (existingRequests?.networkRequests?.count || 0) + 1,
            results: [...(existingRequests?.networkRequests?.results || []), data.createNetworkRequest]
          }
        }
      })
    }});

  const [updateRecord, { data: updateData }] = useMutation(request.update, { onCompleted: () => { 
    history.push(`/requests`) 
    refetch();
  },

  onError: e => { setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]); }})

  const [form] = Form.useForm();

  const ipAddressCount = Form.useWatch('ipAddressCount', form)


  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  if (loading) { return <></> }
  return (

    <Drawer
      title={newRecord ? `New request` : `Update request`}
      width={920}
      closable={false}
      visible={true}
      onClose={() => { history.push('/requests')}}>
      <Form
        layout="vertical"
        form={form}
        name="request"
        initialValues={data?.['networkRequest'] || { ipAddressCount: 1, status: 'Submitted' }}
        onFinish={ ({ NetworkRequestItems, subnet, ipAddressCount, ...values }) => {

          const items = NetworkRequestItems.map(({networkAddress, __typename, ...item}) => {
            return { ipAddress: networkAddress, ...item }
          })

          newRecord ? 
            createRecord({ variables: {...values,
              ipAddressCount: parseInt(ipAddressCount),
              networkAddress: subnet?.networkAddress,
              subnetId: subnet?.id,
              NetworkRequestItems: items
            }}) : 
            updateRecord({ variables: {
              id: parseInt(id), 
              ...values, 
              networkAddress: subnet?.networkAddress,
              subnetId: subnet?.id,
              NetworkRequestItems: items
            }}) 
        }} >

         <div ref={errorContainerRef}>
            {(errors?.length > 0) && (
              <>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} type="error" message={errorMessage} />
                ))}
                <br />
              </>
            )}
          </div>


        <Row gutter={ 24 }>
          <Col span={ 24 }>
            <Form.Item name='status' label='Status'>
              <Select>
                <Select.Option value='Draft'>Draft</Select.Option>
                <Select.Option value='Submitted'>Submitted</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ 24 }>
          <Col span={ 24 }>
            <TextArea required name="details" label="What will the address space be used for?" />
          </Col>
        </Row>

        <Row gutter={ 24 }>
          <Col span={ 8 }>
            <TextInput type='number' required name="ipAddressCount" label="How many IP addresses do you need?" />
          </Col>
          <Col span={ 11 }>
            <SearchSelect
              name='subnet'
              query={ subnet.searchQuery } 
              resultKey='subnets'
              displayFn={(s) => { return `${s.networkAddress} ${s.name || ''}`}}
            />
          </Col>
          <Col span={ 5 }>
            <Button 
              onClick={() => {

                getAvailableIPAddresses({ variables: {
                  available: true,
                  subnetId: form.getFieldValue('subnet')?.id,
                  limit: parseInt(form.getFieldValue('ipAddressCount'))
                }})
              }}
              type='primary' 
              style={{ width: '100%', marginTop: '28px', height: '55px' }}>Find Available IPs</Button>
          </Col>
        </Row>

        <div style={{ marginTop: '40px' }}>
          <Form.List
            name='NetworkRequestItems'
            initialValue={[]}
          >
            {(NetworkRequestItems, { add, remove }) => {
              return (
                <>
                  { NetworkRequestItems?.length > 0 && (<>
                    {/** <h3 style={{ marginTop: '10px' }}>IP Addresses</h3> **/}
                    <div
                      style={{
                        padding: '15px 5px',
                        marginBottom: '20px',
                      }}
                    >
                      <table
                        style={{ width: '100%', textAlign: 'left' }}
                      >
                        <thead>
                          <th style={{ width: '30px' }}>IP Address</th>
                          <th style={{ width: '120px' }}>Hostname</th>
                          <th style={{ width: '120px' }}>MAC Address</th>
                          <th style={{ width: '120px' }}>iface</th>
                          <th style={{ width: '120px' }}>Description</th>
                        </thead>
                        <tbody>
                          {
                            (NetworkRequestItems || []).map((item, idx) => (
                              <tr >
                                <td style={{ paddingBottom: '15px', width: '10px' }}>
                                  { availableIPAddresses?.ipAddresses?.results[idx]?.networkAddress?.length > 0 ?
                                      availableIPAddresses?.ipAddresses?.results[idx]?.networkAddress :
                                      <TextInput name={[idx, "ipAddress"]} hideLabel allowClear={ false } style={{ padding: '4px', border: 'none' }}/>
                                  }
                                </td>
                                <td style={{ marginRight: '10px', padding: '0px 5px', width: '120px' }}><TextInput size='small' allowClear={false} style={{width: '100%'}} name={[idx, "hostname"]} hideLabel /></td>
                                <td style={{ marginRight: '10px', padding: '0px 5px', width: '120px' }}><TextInput size='small' allowClear={false} style={{width: '100%'}} name={[idx, "macAddress"]} hideLabel /></td>
                                <td style={{ marginRight: '10px', padding: '0px 5px', width: '120px' }}><TextInput size='small' allowClear={false} style={{width: '100%'}} name={[idx, "interface"]} hideLabel /></td>
                                <td style={{ marginRight: '10px', padding: '0px 5px', width: '120px' }}><TextInput size='small' allowClear={false} style={{width: '100%'}} name={[idx, "description"]} hideLabel /></td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                  )}
                </>
              )
            }}
          </Form.List>
        </div>
        {/** newRecord && ( <TextArea name="message" /> )**/}

        <Row style={{ marginTop: '40px' }} gutter={ 24 }>
          <Col span={ 24 }>
            <Button onClick={(e) => { history.push('/requests') }} type="primary" style={{ width: '80px', float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>

            <Button type="primary" style={{ width: '80px', float: 'right' }} htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>

      </Form>
    </Drawer>
  )
}

export default RequestForm;
