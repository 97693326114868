import React, { useState, useEffect } from 'react';

import {  Row, Col, Menu, Layout, Breadcrumb, } from 'antd';
import { useParams } from "react-router-dom";
import SearchTable from '../../components/SearchTable';

const account = require("../../entities/account");


const History = () => {

  return (
    <>
      <h2>My History</h2>
      <p style={{ marginBottom: '30px'}}>
        Here you can see your personal account activity. Some API operations are internal to the application and therefore operation is not listed. 
      </p>
      <SearchTable { ...account.search } />
    </>
  )
}

export default History;
