import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button, Form } from 'antd';
import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client";

import { TextInput, CustomAttributes, Select } from '../../components/pants-d';
import MultiSelect from '../../components/MultiSelect';

const provider = require("../../entities/provider");

const ProviderForm = () => {
  const { id } = useParams();
  const newRecord = id == 'new';

  const history = useHistory();

  const [getProvider, { loading, error, data }] = useLazyQuery(provider.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => { if (!newRecord) { getDataCenter({ variables: {id: parseInt(id)}})}}, [])

  const [createRecord, { data: createData }] = useMutation(provider.create, {
    onCompleted: () => { history.push(`/cloud-accounts`) },
    update(cache, { data }) { 
      const existingData = cache.readQuery({ query: provider.searchQuery, variables: {search: "", offset: 0, limit: 50, type: 'Cloud'} })

      cache.writeQuery({
        query: provider.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          providers: {
            count: (existingData?.count || 0) + 1,
            results: [...(existingData?.providers?.results || []), data.createProvider]
          }
        }
      })
    }});

  const [updateRecord, { data: updateData }] = useMutation(provider.update, { onCompleted: () => { history.push(`/datacenters`) }} )
  
  const saveRecord = (values) => { if (newRecord) {
    createRecord({ variables: {...values}}) 
  } else {
    updateRecord({variables: {id: parseInt(id), ...values}}) }
  }

  const [cloudType, setCloudType] = useState('')

  const [form] = Form.useForm();

  if (loading) { return 'Loading' }
  return (
    <Form
      layout="vertical"
      form={form}
      name="provider"
      onValuesChange={(changedValues) => {
        if (changedValues['type']) {
          setCloudType(changedValues['type'])
        }
      }}
      initialValues={(data||{})['provider']}
      onFinish={ (values) => saveRecord(values) } >

      <Select 
        name="type" 
        label="Select a cloud provider"
        options={[
          { value: 'AWS', label: 'AWS'}, 
          { value: 'Azure', label: 'Azure'}
        ]}
        required
      />

      { cloudType == 'AWS' && <TextInput label="Name" name="name" required/>}
      { cloudType == 'AWS' && <TextInput label="Access Key ID" name="awsAccessKeyId" required/>}
      { cloudType == 'AWS' && <TextInput label="Secret Access Key" name="awsSecretAccessKey" required/>}
      { cloudType == 'AWS' && <TextInput label="Session Token" name="awsSessionToken" required/>}

      { cloudType == 'Azure' && <TextInput label="Name" name="name" required/>}
      { cloudType == 'Azure' && <TextInput label="App ID" name="azureAppId" required/>}
      { cloudType == 'Azure' && <TextInput label="Password" name="azurePassword" required/>}
      { cloudType == 'Azure' && <TextInput label="Tenant ID" name="azureTenantId" required/>}

      <Button type="primary" htmlType="submit">
        Submit
      </Button>

    </Form>
  )
}

export default ProviderForm;
