import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";
import { DownloadOutlined } from '@ant-design/icons';

import {  Form, Layout, Button, Drawer, Space, Row, Col } from 'antd';
import SearchTable from '../../components/SearchTable';
import UserForm from '../../views/users/Form';
import RecordLayout from '../layout/Record';

const Downloads = ({}) => {
  const history = useHistory();

  return (
    <RecordLayout section="Downloads">
      <p>Lightmesh CLI is available for installation via NPM or by installer</p>

      <h4> NPM </h4>
      <a href="https://www.npmjs.com/package/lightmesh">https://www.npmjs.com/package/lightmesh</a>
      <br/>
      <br />
      <br />
      <Row>
        <Col span={8}>
          <h4>Mac OS</h4>
          <Space direction="vertical">
            <a href="https://get.lightmesh.com/lightmesh-macos-32-latest"><Space><DownloadOutlined />lightmesh-macos-32</Space></a>
            <a href="https://get.lightmesh.com/lightmesh-macos-64-latest"><Space><DownloadOutlined />lightmesh-macos-64</Space></a>
          </Space>
        </Col>
        <Col span={8}>
          <h4>Ubuntu / Debian</h4>
          <Space direction="vertical">
            <a href="https://get.lightmesh.com/lightmesh-ubuntu-debian-32-latest"><Space><DownloadOutlined />lightmesh-ubuntu-debian-32</Space></a>
            <a href="https://get.lightmesh.com/lightmesh-ubuntu-debian-64-latest"><Space><DownloadOutlined />lightmesh-ubuntu-debian-64</Space></a>
          </Space>
        </Col>
        <Col span={8}>
          <h4>Windows</h4>
          <Space direction="vertical">
            <a href="https://get.lightmesh.com/lightmesh-win-32-latest"><Space><DownloadOutlined />lightmesh-win-32</Space></a>
            <a href="https://get.lightmesh.com/lightmesh-win-64-latest"><Space><DownloadOutlined />lightmesh-win-64</Space></a>
          </Space>
        </Col>
      </Row>
    </RecordLayout>
  )
};

export default Downloads;
