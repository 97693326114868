import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { RightOutlined, UploadOutlined } from '@ant-design/icons';
import { read, utils} from 'xlsx';
import { useMutation, useQuery } from '@apollo/client';
import { Alert,Table, Layout, Col, Row, Upload, Button, Select, Checkbox, Result} from 'antd';

const pluralize = require('pluralize')

const { Content } = Layout;

const customAttribute = require('../entities/custom-attribute');
const ipAddress = require('../entities/ip-address');
const ipAssignment = require('../entities/ip-assignment');
const subnet = require('../entities/subnet');
const zone = require('../entities/zone');
const subnetTemplate = require('../entities/subnet-template');
const user = require('../entities/user');
const supportGroup = require('../entities/support-group');
const datacenter = require('../entities/datacenter');
const provider = require('../entities/provider');
const customer = require('../entities/customer');
const site = require('../entities/site');
const contact = require('../entities/contact');


const entities = {
  "subnets": subnet,
  "customers": customer,
  "contacts": contact,
  "zones": zone,
  "sites": site,
  "subnet-templates": subnetTemplate,
  "support-groups": supportGroup,
  "ip-assignments": ipAssignment,
  "ip-addresses": ipAddress,
  "users": user,
  "datacenters": datacenter,
  "providers": provider,
  "support-group-users": supportGroup
}


const netmask2cidr = (netmask) => {
  return {
    '255.255.255.255': '32',
    '255.255.255.254': '31',
    '255.255.255.252': '30',
    '255.255.255.248': '29',
    '255.255.255.240': '28',
    '255.255.255.224': '27',
    '255.255.255.192': '26',
    '255.255.255.128': '25',
    '255.255.255.0': '24',
    '255.255.254.0': '23',
    '255.255.252.0': '22',
    '255.255.248.0': '21',
    '255.255.240.0': '20',
    '255.255.224.0': '19',
    '255.255.192.0': '18',
    '255.255.128.0': '17',
    '255.255.0.0' : '16',
    '255.254.0.0' : '15',
    '255.252.0.0' : '14',
    '255.248.0.0' : '13',
    '255.240.0.0' : '12',
    '255.224.0.0' : '11',
    '255.192.0.0' : '10',
    '255.128.0.0' : '9',
    '255.0.0.0': '8',
    '254.0.0.0': '7',
    '252.0.0.0': '6',
    '248.0.0.0': '5',
    '240.0.0.0': '4',
    '224.0.0.0': '3',
    '192.0.0.0': '2',
    '128.0.0.0': '1'
  }[netmask] || netmask
}

const uniqueKeys = (data) => {
  return new Set(data?.flatMap(d => { return Object.keys(d) }))
}

const validateData = (data, fieldMap, fields) => {
  const names = Object.values(fieldMap)

  const missingFields = fields.filter(f => f.required)?.map(v => names.includes(v.name) ? null : (v.alias ? (v.alias.charAt(0).toUpperCase() + v.alias.slice(1)) : (v.name.charAt(0).toUpperCase() + v.name.slice(1))) ).filter(i => i)

  if (missingFields.length > 0 ) { return `The following fields are required: ${ missingFields.join(', ')}`
  }
}

const transformData = (data, fieldMap, fields, setError) => {


  const results = data.map((d, idx) => {
    const record = {
      customAttributes: {

      }
    }

    for (const [k, v] of Object.entries(fieldMap)) {

      if ( fields.filter(f => f.custom && f.name == v)?.length > 0 ) {
        record['customAttributes'][v] = d[k]
      } else {
        record[v] = d[k]
      }
    }

    if (record.hasOwnProperty("contactPhone")) {
      if (record["contactPhone"] !== undefined) {
          record["contactPhone"] = record["contactPhone"].toString();
      }
  }
    // custom logic for subnets
    //record['networkAddress'] = record['networkAddress'] + "/" +netmask2cidr(record['netmask'])
    //delete record['netmask']

    return record
  })


  return results
}

const Importer = ({ model, onCompleted, onCancel, onViewRecords, onSkip }) => {


  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);

  const [exclusions, setExclusions] = useState([]);

  const [result, setResult] = useState(null);

  const Model = entities[model];

  const { data: attributeData, loading: attributesLoading } = useQuery(customAttribute.searchQuery, { variables: { modelName: _.capitalize(pluralize.singular(model)) }})

  const columnHeaders = uniqueKeys(data);
  const fields = [
    ...Model?.importer?.fields,
    ...(attributeData?.customAttributes?.results?.map(attribute => { return { name: attribute.name, custom: true }}) || [])
  ]

  const fieldOptions = fields.map(field => { return { label: (field.required ? '* ' : '') + (field.alias ? field.alias.charAt(0).toUpperCase() + field.alias.slice(1) : field.name.charAt(0).toUpperCase() + field.name.slice(1)), value: field.name } }) || []

  const [importRecords, { data: importData, error, loading }] = useMutation(Model?.importer?.mutation, {
    onCompleted: (data) => { onCompleted(data) },
    onError: (e) => { 
      console.log(e.message)
    },
  })


  const [fieldMap, setFieldMap] = useState({});
  const [validationError, setValidationError] = useState();

  let columns = [...columnHeaders].map(header => {

    return { 
      title: () => {
        return (
          <Select 
            size='small'
            style={{width: '200px', fontSize: '12px'}} 
            options={fieldOptions} 
            placeholder="Select a field"
            key={ header } 
            onChange={(v) => {
              let columnMapping = {}
              columnMapping[header] = v

              setFieldMap({...fieldMap, ...columnMapping})
            }}
          /> 
        )
      }, 
      render: (value, _, idx) => { 
        return (
          <div style={ exclusions.includes(idx) ? { opacity: '0.2' } : {} } >{ value }</div>
       )},
      dataIndex: header, 
      width: '100em',
      sorter: false,
      key: header }
  })

  columns = [
    {
      title: '',
      width: '10em',
      render: (record, _, idx) => {
        const excluded = exclusions.includes(idx)

        return (
          <>
            { !excluded &&
              <a onClick={() => { setExclusions([...exclusions, idx]) }} >
                Exclude
              </a>
            }
            { excluded && 
              <a onClick={() => { 
                setExclusions(exclusions.filter((v) => { return (v !== idx) })) }} >
                Include
              </a>
            }
          </>
        )
      }},
    ...columns
  ]

  return (
    <>

      { !data && (
        <Row style={{ marginTop: '40px' }}>
          <Col span={ 14 } offset={ 2 }>
            <h2 style={{ marginTop: '40px', marginBottom: '40px' }}>
              { Model?.importer?.title }
            </h2>

            <p style={{marginBottom: '20px'}}>
              { Model?.importer?.copy }
            <p>
            <p>
              { Model?.importer?.note }
            </p>
            </p>
              { Model?.importer?.exampleSheet && (
                <a href={`/${ Model?.importer?.exampleSheet }.xlsx`}><b>Download an example template</b></a>
              )}
            </p>
            <Row style={{ marginTop: '60px'}}>
              <Col span={8} >
                <Upload
                  name='file'
                  style={{ background: '#FFFFFF' }}
                  beforeUpload={ async (file) => {
                    const f = await(file.arrayBuffer());
                    const wb = read(f);
                    const sheet = wb.Sheets[wb.SheetNames[0]]
                    const data = await utils.sheet_to_json(sheet)

                    setData( data )

                    return false
                  }}>
                  <Button type="primary" style={{ width: '200px' }}>
                    Upload Sheet <UploadOutlined style={{marginLeft: '20px'}}/>
                  </Button>
                </Upload>
              </Col>
              <Col span={8}>
                { onCancel && (
                  <Button 
                    onClick={() => { onCancel() }} 
                    style={{ width: '200px' }}>
                    Cancel
                  </Button>
                )}
                { onSkip && (
                  <Button 
                    onClick={() => { onSkip() }} 
                    style={{ width: '200px' }}>
                    Skip for now
                    <RightOutlined style={{fontSize: '12px'}} />
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      { data && (
        <Row>
          <Col span={ 24 }>
            { result && (
              <div style={{ marginTop: '30px', marginRight: '25px' }}>
                <Result
                  status="success"
                  title="Records imported successfully!"
                  subTitle={`You can view your records in the network section as well as review and modify your import here.`}
                  extra={[
                    <Button 
                      style={{ marginTop: '40px', marginRight: '20px' }}
                      onClick={() => { onViewRecords() }} 
                      type="primary" 
                      key="console">

                      View Records

                    </Button>,

                    <Button 
                      style={{ marginTop: '40px', marginLeft: '20px' }}
                      onClick={() => { 
                        setResult(null) 
                        setData(null)
                      }} 
                      type="primary" 
                      key="console">

                      Import another sheet

                    </Button>
                  ]}
                />
              </div>
            )}
            {!result && (
              <>
                <h3 style={{ marginTop: '20px', marginBottom: '0px' }}>
                  Select fields
                </h3>
                  <p style={{marginBottom: '0px', marginTop: '10px', lineHeight: '20px', fontSize: '12px', fontStyle:'italic', color: '#666666'}}>
                    Map your uploaded columns to the available fields in the dropdown and click submit to validate and upload.
                    <Button size='small' style={{fontSize: '12px', float: 'right', marginLeft: '10px'}} onClick={(e) => { 
                        setValidationError()
                        setData()
                    }}>Clear</Button>
                    <Button size='small' style={{fontSize: '12px', float: 'right', marginLeft: '40px'}} onClick={(e) => { 

                      const error = validateData(data, fieldMap, fields)

                      if ( error ) {
                        setValidationError(error)
                      } else {
                        setValidationError()
                        importRecords({ 
                          variables: { 
                            importId: parseInt(id), 
                            data: transformData(data.filter((_,idx) => { return !exclusions.includes(idx) }), fieldMap, fields, setValidationError) 
                          }}) 
                      }
                    }}>Upload Records</Button>
                  </p>
                  <Row gutter={24}>
                    <Col span={12}>
                      { Model?.importer?.note }
                    </Col>
                  </Row>

                { error?.networkError && error?.networkError?.result?.errors?.map((e) => {
                  return ( <>{ e?.message}</> )
                })}


                { (validationError || error?.message) && ( 
                  <Alert
                    style={{ width: '50%', marginTop: '20px'}}
                    message={ validationError || error?.message }
                    closable
                    type="error"
                  />
                )}
                <div style={{ overflowX: 'scroll', width: '100%' }}>
                <Table
                  size='small'
                  style={{ background: '#FFFFFF', marginTop: '20px'}}
                  pagination={ false }
                  columns={ columns }
                  dataSource={ data }
                />
                </div>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export default Importer
