import React from 'react';
import { Row, Col, Layout } from 'antd';
const { Content } = Layout;
import SearchTable from '../../components/SearchTable.js';
import SearchForm from '../../components/SearchForm';
import {useHistory, useParams} from 'react-router-dom';

import UserForm from './UserForm';
const User = require('../../entities/user');

function UserSearch(props) {

  const history = useHistory();

  const { id } = useParams();

  return (
    <>
      <Row>
        <SearchForm placeholder="Search Users" model={ User }/>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SearchTable 
            { ...User.allSearch }
            onClick={(user) => { 
              history.push(`/tidal-admin/users/${user?.id}`) }
            }
          />
          { id && (
            <UserForm id={ id } />
          )}
        </Col>
      </Row>
    </>
  )
}

export default UserSearch;
