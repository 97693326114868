import { loader } from 'graphql.macro';
const pluralize = require('pluralize')
import _ from 'lodash';

const SEARCH_ACTIVITIES = loader('./queries/activities.graphql')  

const operationMap = {
  'CurrentUserQuery': 'New session',
  'ipAddresses': 'IP Addresses',
  'ipAssignments': 'IP Assignments',
  'availableIpAddresses': 'Available IP Addresses'
}

const entities = [
  'subnet',
  'ipAssignment',
  'ipAddress',
  'reservation',
  'zone',
  'site',
  'dnsEntry',
  'dataCenter',
  'supportGroup',
  'contact',
  'user',
  'userGroup',
]

const crudOperations = Object.assign(...(entities.map(e => {
  return {
    [e]: `Retrieves a ${e}`,
    [pluralize(e)]: `Searches ${pluralize(_.startCase(e))}`,
    [`destroy${_.capitalize(e)}`]: `Destroys ${pluralize(_.startCase(e))}`,
    [`create${_.capitalize(e)}`]: `Creates a ${_.startCase(e)}`
  }
})))

const namingExceptions = {
  'networkSearch':  'Searches the network',
  'CurrentUserQuery': 'Retrieves user settings on page refresh or login',
  'ipAddresses': 'Searches a set of IP Addresses',
  'ipAssignments': 'Searches IP Assignments',
  'createReservation': 'Creates a new reservation',
  'usageSummary': 'Gets number of allocated IP Addresses for the subnet',
  'availableIpAddresses': 'Gets a set of available IP Addresses',
  'activeUsersByMonth': 'Shows the active users by month',
  'averageActiveUsersByDay': 'Shows the active users by month',
  'newUsersByMonth': 'Shows the new users by month',
  'logout': 'Ends the current user session',
}

const apiDescriptions = {...crudOperations, ...namingExceptions}


const search = {
  query: SEARCH_ACTIVITIES,
  columns: [
    { title: 'Time', width: 200, dataIndex: 'timestamp', key: 'timestamp', render: (timestamp) => {
      const d = new Date(timestamp)

      return (<p>{ d.toLocaleDateString() + ' ' + d.toLocaleTimeString() }</p>)
    }},
    { title: 'API Operation', key: 'operation', width: 200, dataIndex: 'operation', render: operation => <>{_.startCase(operationMap[operation] || operation)}</> },
    { title: 'Description', key: 'description', dataIndex: 'operation', render: operation => <>{apiDescriptions[operation]}</> }
  ]
}

export { SEARCH_ACTIVITIES, search };
