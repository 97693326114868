import { loader } from 'graphql.macro';

const GET_ACTIVITY_SUMMARY = loader('./queries/activity.graphql')  
const GET_ACTIVE_USERS_BY_DAY = loader('./queries/activeUsersByDay.graphql')  
const GET_AVERAGE_DAILY_ACTIVE_USERS_BY_MONTH = loader('./queries/averageActiveUsersByDay.graphql')  
const GET_ACTIVE_USERS_BY_MONTH = loader('./queries/activeUsersByMonth.graphql')  
const GET_RETURNING_USERS_BY_MONTH = loader('./queries/returningUsersByMonth.graphql')  
const GET_NEW_USERS_BY_MONTH = loader('./queries/newUsersByMonth.graphql')  
const GET_NEW_USERS_BY_DAY = loader('./queries/newUsersByDay.graphql')  

export { GET_ACTIVITY_SUMMARY, GET_ACTIVE_USERS_BY_DAY, GET_AVERAGE_DAILY_ACTIVE_USERS_BY_MONTH, GET_NEW_USERS_BY_DAY, GET_NEW_USERS_BY_MONTH, GET_ACTIVE_USERS_BY_MONTH, GET_RETURNING_USERS_BY_MONTH };
