import React from 'react'
import { loader } from 'graphql.macro';
const { ProviderLogo } = require('../provider')
import moment from 'moment';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const RemoveResourceLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="resource"
        entityTitle="resource"
        redirectPath={`/resources`}
      />
    </div>
  )
}

const search = {
  columns:  [
    { title: 'Hostname', key: 'Hostname', width: 100, render: resource => <>
      <ProviderLogo 
        type={ resource?.provider?.type } 
        providerStyle={{ 
          'AWS': { width: '30px', marginTop: '1px' },
          'Azure': { width: '12px' },
          'Datacenter': { marginTop: '1px', width: '12px' }
        }}/>
      { resource?.hostname || 'UNKNOWN'}</> },
    { title: 'Resource Type', key: 'ResourceType', width: 100, render: resource => { return <>
        { resource?.resourceType?.name }</> } },
    { title: 'IP Address', key: 'IP', 
      width: 100, 
      render: resource => {         
        return resource?.interfaces.map(i => { 
          return <a
            style={{ 
              textUnderlineOffset: '.25em',
              textDecoration: 'underline', cursor: 'pointer'}}
            onClick={e => e.stopPropagation()}
            href={`/subnets/${i.ipAssignment?.subnet?.id}/${i.ipAssignment?.subnet?.id}/ip-addresses/${i.ipAssignment?.id}?networkAddress=${i.ipAssignment?.networkAddress}`}>
            { i.ipAssignment?.networkAddress }
          </a>
        }) 
      }
    },
    { 
      title: 'Subnet', 
      key: 'Subnet', 
      width: 100, 
      render: resource => { 
        return resource?.interfaces.map(i => { 
          return <a 
            style={{ 
              textUnderlineOffset: '.25em',
              textDecoration: 'underline', cursor: 'pointer'}}
            onClick={e => e.stopPropagation()}
            href={`/subnets/${i.ipAssignment?.subnet?.id}`}>
            {i.ipAssignment?.subnet?.networkAddress}
          </a> })
    }},
    { title: 'Operating System', key: 'OS', width: 100, render: resource => { return <>{ (resource?.OS || '') + ' ' + (resource?.OSVersion || '') }</> } },
    { title: 'MAC Address', key: 'macAddress', width: 100, render: resource => <>{ resource?.interfaces?.map(i => i.macAddress).join(', ')}</> },
    { title: 'Interface', key: 'interfaces', width: 100, render: resource => { return <> {resource?.interfaces ? resource?.interfaces[0]?.name : null} </>} },
    { 
      title: 'Last seen at', 
      key: 'IP Address', 
      width: 100, 
      render: (resource) => {
        const lastSeenAt = new Date(parseInt(resource?.createdAt || resource?.interfaces?.[0]?.ipAssignment?.lastSeenAt))

        return (<p>{ lastSeenAt.toLocaleDateString() + ' ' + lastSeenAt.toLocaleTimeString() }</p>)
      }
    },
    { title: '', width: 100, key: 'remove',width: 40, render: resource => <RemoveResourceLink record={ resource }/> }
  ]
}

const form = {
  fields: [
    ["code", {}],
    ["name", {}]
  ]
}

const importer = {
  title: 'Resources',
  copy: 'In Lightmesh, "Resources" refer to the components or entities associated with IP addresses in a network. These include IP addresses themselves, Hostname, Operating System, OS Version, MAC Address and Interface. Lightmesh systems help organize, allocate, and track these resources to ensure efficient network management.',
}

export { search, form, searchQuery, getQuery, create, update, destroy, importer };
