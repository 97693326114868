import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import { Breadcrumb, Form, Layout, Button, Drawer } from "antd";

import SearchForm from "../../components/SearchForm";
import SearchAction from "../../components/SearchAction";
import SearchTable from "../../components/SearchTable";
import ZoneForm from "../../views/zones/Form";
import { Subnav, Links } from "../layout/Subnav.js";
import SearchLayout from "../layout/Search.js";
import Entity from "../../entities";
import {PlusOutlined} from "@ant-design/icons";
import Info from "../info/Info";

const { Content } = Layout;

const zone = Entity["zone"];

const Zones = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == "new";

  return (
    <SearchLayout section='Zones'>
      <SearchForm placeholder='Search zones' modelName={Zones} />
      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/zones/new'
        style={{marginTop: '14px'}}>
        Add Zone
      </SearchAction>
      <SearchTable
        style={{ marginTop: '70px' }}
        query={zone.searchQuery}
        {...zone.search}
        onAdd={() => { history.push("/zones/new"); }}
        onClick={(record) => {
          history.push(`/zones/${record?.id}`);
        }}
        modelName="zones"
        emptyRender={<Info model="zones" />}
      />

      {(id || newRecord) && (
        <Drawer
          title={newRecord ? `Add zone` : `Update zone`}
          width={520}
          closable={false}
          visible={true}
          onClose={() => {
            history.push("/zones");
          }}
        >
          <ZoneForm
            id={id}
            onClose={() => {
              history.push("/zones");
            }}
          />
        </Drawer>
      )}
    </SearchLayout>
  );
};

export default Zones;
