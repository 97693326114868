import { DatePicker, Space, Form } from 'antd';
import React from 'react';
const { RangePicker } = DatePicker;

import { startCase } from "lodash";

class DateTimeInput extends React.Component {

  render () {
    const { name, required, rules, onChange, onOk, hideLabel=false,  ...props } = this.props; 

    const requiredRule = [{ required: required, message: `${props.label || startCase(name)} is required.` }]

    return (
      <Form.Item 
        label={  hideLabel ? null : props.label || startCase(name) }
        name={ name }
        autocomplete="off" 
        rules={[...requiredRule]}
      > 

        <DatePicker showTime onChange={onChange} onOk={onOk} />
      </Form.Item>
    )
  }
}

export default DateTimeInput;
