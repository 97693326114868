import { loader } from 'graphql.macro';

const SEARCH_QUERY = loader('./queries/search.graphql')  
const GET_QUERY = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const search = {
  query: SEARCH_QUERY,
  columns:  [
    { title: 'name', dataIndex: 'name', key: 'name', width: '14em' },
    { title: 'description', key: 'description', width: '60em' },
  ]
}

export { search, SEARCH_QUERY, GET_QUERY, create, update, destroy };
