import React, { useState } from 'react';
import { Row, Col, Button, Input, Form } from 'antd';
import { Label } from '../components/pants-d';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';


const EntityDetails = ({ entity, entityName, setEditing, fields, recordId, mutation, headerOptions }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();

    const [updateRecord, { data: updateData }] = useMutation(mutation, {
        onCompleted: () => {
            setIsEditing(false);
            history.push(`/${entityName.toLowerCase()}/${recordId}`);
        }
    });

    const renderFieldValue = (value, field) => {
        if (Array.isArray(value)) {
            return value.map((item, index) => (
                <div key={index}>
                    {field.fields.map(subField => (
                        <div key={subField.name}>
                            {item[subField.name] && <div>{item[subField.name]}</div>}
                        </div>
                    ))}
                </div>
            ));
        }
        return <div>{value}</div>;
    };

    const renderFormField = (field) => {
        if (field.fields && Array.isArray(entity[field.name])) {
            return entity[field.name].map((item, index) =>
                field.fields.map(subField => (
                    <Col span={12} key={`${field.name}[${index}].${subField.name}`} style={{ marginBottom: '16px' }}>
                        <Form.Item
                            name={[field.name, index, subField.name]}
                            label={subField.label}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                ))
            );
        }

        if (field.fields) {
            return field.fields.map(subField => (
                <Col span={12} key={`${field.name}.${subField.name}`} style={{ marginBottom: '16px' }}>
                    <Form.Item
                        name={[field.name, subField.name]}
                        label={subField.label}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            ));
        }

        return (
            <Col span={12} key={field.name} style={{ marginBottom: '16px' }}>
                <Form.Item
                    name={field?.name}
                    label={field?.label}
                >
                    <Input />
                </Form.Item>
            </Col>
        );
    };

    const handleSave = () => {
        form.validateFields().then(values => {
            updateRecord({ variables: { ...values, id: recordId } })
        });
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    return (
        <div className={'subnet-view-body'} style={{ padding: '15px 25px', background: '#FFFFFF', minHeight: '400px' }}>
            <Row gutter={24}>
                <Col span={12} style={{ position: 'relative' }}>
                    {!isEditing && (
                        <EditOutlined
                            onClick={() => setIsEditing(true)}
                            style={{ position: 'absolute', top: '10px', right: 0, fontSize: '1.8em', padding: '10px', color: '#698dab', cursor: 'pointer' }}
                        />
                    )}
                    {isEditing ? (
                        <Form form={form} initialValues={entity} layout="vertical">
                            {fields.map(field => renderFormField(field))}
                            <Form.Item>
                                <Button type="secondary" onClick={handleCancel} style={{ float: 'right', marginLeft: '20px' }}>
                                    Cancel
                                </Button>
                                <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff' }} onClick={handleSave} htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        fields.map(field => (
                            <div className='customer-form' style={{ marginTop: '20px' }}>
                                <Col span={12} key={field.name}>
                                    <Label name={field.label}>
                                        {renderFieldValue(entity?.[field?.name], field)}
                                        {!entity?.[field.name] && (
                                            <i
                                                onClick={() => setIsEditing(true)}
                                                style={{ cursor: 'pointer', fontSize: '0.6em', color: '#c2cdd6' }}
                                            >
                                                Add a {field.label.toLowerCase()}
                                            </i>
                                        )}
                                    </Label>
                                </Col>
                            </div>
                        ))
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default EntityDetails;