import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import SearchLayout from '../layout/Search';
import { Card, Alert, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import Entity from "../../entities";
import { utils, writeFile } from 'xlsx';

const ipAssignment = Entity['ip-assignment'];
const subnet = Entity["subnet"];

function flattenObject(obj, prefix = '') {
  const flatObject = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        const arrayKey = `${newKey}[${index}]`;
        if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
          Object.assign(flatObject, flattenObject(item, arrayKey));
        } else {
          flatObject[arrayKey] = item;
        }
      });
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flatObject, flattenObject(value, newKey));
    } else {
      flatObject[newKey] = value;
    }
  }
  return flatObject;
}
const Exports = ({ }) => {
  const history = useHistory();
  const { id } = useParams();
  const client = useApolloClient();

  const [subnetData, setSubnetData] = useState([]);
  const limit = 50000; 
  const ipLimit = 50000;

  useEffect(() => {
    const fetchData = async () => {
      const { data: subnetDataChunk } = await client.query({ query: subnet.searchQuery, variables: { search: "", limit } });
      setSubnetData(subnetDataChunk.subnets.results);
    };
    fetchData();
  }, [client]);

  const generateCSVData = (data, headerMapping) => {
    if (Array.isArray(data)) {
      if (data?.length === 0) {
        return null;
      }

      const flattenedData = data?.map((item) => {
        const flattened = flattenObject(item);
        const modifiedHeaders = {};

        const headersInOrder = Object.keys(headerMapping);

        for (const key of headersInOrder) {
          const value = flattened[key];
          modifiedHeaders[headerMapping[key]] = value;
        }

        return modifiedHeaders;
      });

      return flattenedData;
    }
  };

  const exportToCSV = async () => {
    let currentOffset = 0;
    let fileIndex = 1;

    while (true) {
      const { data: subnetDataChunk } = await client.query({ query: subnet.searchQuery, variables: { search: "", limit, offset: currentOffset } });
      const { data: subnetIpDataChunk } = await client.query({ query: ipAssignment.searchQuery, variables: { search: "", limit: ipLimit, offset: currentOffset } });

      const subnetResults = subnetDataChunk.subnets.results;
      const ipResults = subnetIpDataChunk.ipAssignments.results;

      if (subnetResults.length === 0 && ipResults.length === 0) break;

      const csvData = generateCSVData(subnetResults, {
        'zone.name': 'Zone',
        'networkAddress': 'Network Address',
        'name': 'Name',
        'vlan.number': 'VLAN',
        'description': 'Description',
        'Contacts[0].firstName': 'Contact First Name',
        'Contacts[0].lastName': 'Contact Last Name',
        'Contacts[0].phone': 'Contact Phone',
        'Contacts[0].email': 'Contact Email',
        'customers[0].name': 'Customer Name',
        'customers[0].addresses[0].line1': 'Customer Street Address',
        'customers[0].addresses[0].line2': 'Customer Street Address Line 2',
        'customers[0].addresses[0].muncipality': 'Customer City',
        'customers[0].addresses[0].province': 'Customer Province',
        'customers[0].addresses[0].country': 'Customer Country',
        'site.name': 'Site Name',
        'site.addresses[0].line1': 'Site Street Address',
        'site.addresses[0].line2': 'Site Street Address Line 2',
        'site.addresses[0].muncipality': 'Site City',
        'site.addresses[0].province': 'Site Province',
        'site.addresses[0].country': 'Site Country',
      });

      const csv2Data = generateCSVData(ipResults, {
        'subnet.zone.name': 'Zone',
        'subnet.networkAddress': 'Subnet',
        'networkAddress': 'Network Address',
        'DNSEntries[0].recordType': 'DNS Record Type',
        'DNSEntries[0].name': 'DNS Record',
        'DNSEntries[0].ttl': 'DNS Record TTL',
        'interface.resource.hostname': 'Host Name',
        'interface.resource.OS': 'Resource OS',
        'interface.macAddress': 'MAC Address',
        'interface.name': 'Interface',
        'description': 'Notes',
      });

      if (!csvData && !csv2Data) break;

      const wb = utils.book_new();
      if (csvData) {
        const ws1 = utils.json_to_sheet(csvData);
        utils.book_append_sheet(wb, ws1, "Subnets");
      }
      if (csv2Data) {
        const ws2 = utils.json_to_sheet(csv2Data);
        utils.book_append_sheet(wb, ws2, "IP Assignments");
      }

      writeFile(wb, `LightMesh-Subnet-Export_${fileIndex}.xlsx`);

      currentOffset += limit;
      fileIndex++;
    }
  };

  return (
    <SearchLayout section="Exports">
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <Card title="Subnets" style={{ width: 500, margin: '10px' }} headStyle={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <p style={{ fontSize: '15px' }}>Action:</p>
              <Button onClick={exportToCSV} disabled={subnetData.length === 0}>
                <DownloadOutlined /> Export to CSV
              </Button>
            </div>
          </div>
          <Alert
            style={{ marginTop: '20px' }}
            message="Download subnets in CSV format by clicking Export to CSV. The data will be split into multiple files if it exceeds 50,000 records."
            type="info"
            showIcon
          />
        </Card>
      </div>
    </SearchLayout>
  );
};

export default Exports;
