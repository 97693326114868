import {Col, Row, Table, Alert} from 'antd';
import {ReadOutlined} from '@ant-design/icons';

import Metric from '../../components/Metric';
const { getMeasurement } = require('../../entities/report')
import { getUsage } from '../../entities/report';
import {useQuery} from '@apollo/client';
import {  useHistory } from 'react-router-dom'

const NetworkSummary = ({}) => {

  const history = useHistory();

  const { loading: subnetsLoading, data: subnetData } = useQuery(getUsage, {
    variables: { reportType: 'Subnet' },
    fetchPolicy: "no-cache",
    onCompleted: (result) => { }
  })

  return (
    <>
      { subnetData?.getUsage?.length < 2 && (
        <Row>
          <Col span={ 20 }>
            <div style={{ marginBottom: '40px', marginTop: '-30px' }}>
              <Alert 
                style={{ width: '100%' }}
                type='info'
                description={
                  <p style={{ fontWeight: '400', fontSize: '16px' }}>
                    Once you've got more of your network in to Lightmesh you'll be able to get better insight
                    <br />
                    <br/>
                    Checkout the  <div 
                      className='rounded-4'
                      style={{ 
                        display: 'inline-block', 
                        background: '#669ecc', 
                        padding: '2px 6px 4px',
                        margin: '4px 4px 0px'
                      }}><a style={{ color: '#FFFFFF' }}
                        onClick={() => history.push('/getting-started')}>
                        <ReadOutlined style={{ fontSize: '16px', lineHeight: '20px', marginRight: '8px' }}/>
                    getting started</a></div> page to learn more about what can Lightmesh do
                  </p>

                }
              />
            </div>
          </Col>
        </Row>
      )}
    <Row gutter={ 20 }>
      <Col span={ 12 }>
        <Metric style={{  }} query={ getMeasurement } label={ 'In Use' } variables={{ metric: 'In Use' }}/>
      </Col>
      <Col span={ 12 }>
        <Metric style={{ background: '#ecd4ed', color: '#FFFFFF' }} query={ getMeasurement } label={ 'Allocated' } variables={{ metric: 'Available' }}/>
      </Col>
      {/**
      <Col span={ 8 }>
        <Metric query={ getMeasurement } label={ 'Conflicts' } variables={{ metric: 'Available' }}/>
      </Col>
        **/}
    </Row>
    <Row gutter={ 20 }>
      <Col span={ 24 }>
      <Table
        size='large'
        className="result-table"
        style={{ marginTop: '20px' }}
        pagination={{ pageSize: 5 }}
        columns={[
          { title: "Subnet", dataIndex: 'name', key: 'name' },
          { title: 'Allocated', width: 180, dataIndex: 'allocated', key: 'allocated', align: 'right', render: (allocated) => { return <>{allocated?.toLocaleString() || 0}</>}},
          { title: 'In Use', width: 180, dataIndex: 'inUse', key: 'inUse', align: 'right', render: (inUse) => { return <>{inUse?.toLocaleString() || 0}</> }},
          { title: '% In Use', width: 180, dataIndex: 'percentInUse', key: 'percentInUse', align: 'right', render: (percentInUse) => { return <>{ percentInUse || '0.00%'}</>}},
        ]}
        dataSource={ subnetData?.getUsage }
      />
      </Col>
    </Row>
    </>
  )
}


export default NetworkSummary;
