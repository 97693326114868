import React, { Component, useState, useForm, useEffect } from 'react';

import { WindowsFilled, ChromeFilled, MailFilled, ApiFilled } from '@ant-design/icons';
import { Form, Layout, Space, Row, Col, Select, Input, Button, Typography, Table, Alert, Divider } from 'antd';
import { Link, useLocation, useHistory,useParams,useNavigate } from "react-router-dom";
import _ from 'lodash';

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const { Content, Header } = Layout

function AuthLayout({ children }) {
  return (
      <Content style={{ 
        marginTop: '0px',
        width: '760px',
        minHeight: '100%',
        position: 'absolute', 
        top: '0px',
        left: '50%', 
        marginLeft: '-350px', 
        background: '#FFFFFF',
        padding: '180px 120px 40px' }}>

        <Row>
          <Col span={22} offset={1}>
            <a href="https://tidalcloud.com/lightmesh/" target="_blank" rel="noopener noreferrer">
              <img 
                style={{ width: '240px', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', marginBottom: '50px', display: 'block'}}
                src="/images/lightmesh-light-bg.png" />
             </a>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            { children }
          </Col>
        </Row>

      </Content>
  )
}

export default AuthLayout;
