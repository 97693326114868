import React from 'react'
import Entity from '../../entities';
import { useQuery, useMutation} from "@apollo/client";

const RemoveLink = ({ id, mutation, type }) => {


  const [removeRecord, { data }] = useMutation(mutation, { 
    onCompleted: (v) => { console.log("completed") },
    update(cache, { data }) {
      cache.evict(`${type}:${id}`);
    }
  });

  return (
    <a onClick={(e) => {
      e.stopPropagation();
      removeRecord({variables: {id: parseInt(id)}})
      }}>
      Remove
    </a>
  )
}

export default RemoveLink;
