import React, { useState, useEffect } from 'react';
import cidrRegex from 'cidr-regex';
import utils from './utils.js';
import V4 from './V4.js';
import V6 from './V6.js';
import NetmaskForm from './NetmaskForm.js';
import {Form} from 'antd';

const defaultSubnet = {
  id: '10.0.0.0/16',
  networkAddress: "10.0.0.0/16",
  subnets: []
}

const removeSubnet = ( subnetId, supernet ) => {

  if (subnetId == supernet?.id) {
    return null
  } else {

    const data = { ...supernet }

    data?.subnets?.forEach((d,i) => {
      if (subnetId == d.id) {
        data.subnets[i] = null
      } else {
        data.subnets[i] = removeSubnet(subnetId, d)
      }
    })

    return { ...data, subnets: data?.subnets?.filter(d => d) };  
  }

}

const addSubnet = ( subnet, supernet ) => {

  const data = { ...supernet }

  if (data.id == subnet.supernetId) {
    data['subnets'] = [ ...data.subnets, subnet ]
  } else {
    data?.subnets?.forEach((d,i) => {
      data.subnets[i] = addSubnet(subnet, d)
    })
  }

  return data  
}

const updateSubnet = ( subnet, supernet ) => {

  const data = { ...supernet }

  if (data.id == subnet.id) {
    return { ...data, ...subnet }
  } else {
    data?.subnets?.forEach((d,i) => {
      data.subnets[i] = updateSubnet(subnet, d)
    })
  }

  return data  
}

export const parsedSubnet = (subnet) => {
  if (subnet) {
    if (cidrRegex.v4({exact: true}).test(subnet)) {
      return new Address4(subnet)
    } else {
      return new Address6(subnet)
    }
  }
}

export const getAllSubnets = ( subnet ) => {

  const results = []

  const getSubnets = ( subnet ) => {

    const snet = parsedSubnet( subnet.networkAddress )

    results.push({ 
      networkAddress: subnet.networkAddress, 
      name: (subnet.name || ''),
      broadcast: snet?.endAddress().address,
      hosts: Math.pow(2, (32 - snet.subnetMask)) - 2
    })

    subnet.subnets?.map(s => { getSubnets(s) })
  }

  getSubnets(subnet);

  return results
}



const LocalBuilder = ({ data, local, ...props }) => {

  const isV4 = cidrRegex.v4({exact: true}).test( data?.networkAddress )
  const [subnetTree, setSubnetTree] = useState(data);
  const [active, setActive] = useState(subnetTree?.id);
  const [netmask, setNetmask] = useState();
  const [error, setError] = useState();

  const [form] = Form.useForm()

  if (data) { 
    return (
      <>

        {!subnetTree && (
          <Row>
            <Col offset={ 3 } span={18} >

              { error && <Alert message={ error } type="error"/> }
              <div class="subnet-block-form" style={{ marginTop: '20px' }}>
                <Form 
                  layout="vertical"
                  form={ form }
                  onFinish={({ networkAddress }) => {

                    if (!utils.parsedSubnet(networkAddress || '10.0.0.0/24').isValid()) { return setError('Please enter a valid CIDR address') }

                    setError(null)

                    const newSubnet = {
                      id:  (networkAddress || '10.0.0.0/24'),
                      networkAddress: (networkAddress || '10.0.0.0/24'), 
                      subnets: []
                    }

                    setNetmask(n => parseInt((networkAddress || '10.0.0.0/24').split('/')[1]) + 1 )
                    setActive(() => { return newSubnet })
                    setSubnetTree((s) => { return newSubnet })
                  }}
                >
                  <Space direction="horizontal">
                    <TextInput 
                      name="networkAddress" 
                      hideLabel={ true }  
                      placeholder="10.0.0.0/24"
                      style={{ width: '240px', marginTop: '22px', marginRight: '10px' }}/>
                    <Button style={{ marginTop: '0px', marginLeft: '12px', padding: '2px 10px', height: '32px', border: 'none', background: '#F2F2F2', color: '#444444'}} type="primary" htmlType="submit">
                      Create subnet
                    </Button>
                  </Space>
                </Form>
              </div>
            </Col>
          </Row>
        )}
      { subnetTree && isV4 && <V4 
        data={ data } 
        style={{ marginBottom: '-10px', }} 
        blockStyle={{ fontSize : '16px' }}
        editing={ true }
        editingNetmask={ netmask }
        showSupernet={ false }
        height={65}
        active={ active?.networkAddress }
        onClick={(s) => setActive( s ) }
        data={ subnetTree }
        onDestroy={(s) => {
          const newSubnetTree = removeSubnet(s?.id, subnetTree)
          setSubnetTree(newSubnetTree);
        }}
        onCreate={(s) => { 
          const newSubnetTree = addSubnet({...s?.variables, id: s?.variables?.networkAddress, subnets: [],}, subnetTree) 

          setSubnetTree(newSubnetTree);
          setActive(s?.variables);
        }}/> }
      { subnetTree && !isV4 && <V6 
        data={ data } 
        style={{ marginBottom: '-10px', }} 
        blockStyle={{ fontSize : '16px' }}
        editing={ true }
        editingNetmask={ netmask }
        showSupernet={ false }
        height={65}
        active={ active?.networkAddress }
        onClick={(s) => setActive( s ) }
        data={ subnetTree }
        onDestroy={(s) => {
          const newSubnetTree = removeSubnet(s?.id, subnetTree)
          setSubnetTree(newSubnetTree);
        }}
        onCreate={(s) => { 
          const newSubnetTree = addSubnet({...s?.variables, id: s?.variables?.networkAddress, subnets: [],}, subnetTree) 

          setSubnetTree(newSubnetTree);
          setActive(s?.variables);
        }}/> }
      </>
    )
  } else {
    return ( <></> )
  }
}



export default LocalBuilder;
