import React, { useState } from 'react';
import { loader } from 'graphql.macro';

import { useMutation } from '@apollo/client';
import RemoveLink from '../../components/RemoveLink';
import { Alert, Button, Drawer, Form, Input, Spin } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')


export { getQuery, searchQuery, create, update, destroy };
