import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";

import {  Form, Layout, Button } from 'antd';
import SearchTable from '../../components/SearchTable';
import SearchForm from '../../components/SearchForm';
import SearchAction from "../../components/SearchAction";
import {PlusOutlined} from "@ant-design/icons";
import { search, SEARCH_IMPORTS } from '../../entities/import';
import SearchLayout from '../layout/Search';

const Imports = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'
  const [refetch, setRefetch] = useState()

  const handleRoleClick = (record) => {
    if (readOnlyRoles.includes(record.name)) {
      return; 
    }

    history.push(`/imports/${record?.id}`);
  };

  return (
    <SearchLayout section="Imports">
      <SearchForm placeholder='Search imports' modelName={Imports} />
      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/imports/new'
        style={{marginTop: '12px'}}>
        New Import      
      </SearchAction>

      <SearchTable
        style={{ marginTop: '70px' }}
        query={ SEARCH_IMPORTS }
        { ...search }
        onAdd={() => {
          history.push('/imports/new')
        }}
        refetch={refetch}
        onClick={(record) => {
          history.push(`/imports/${record?.id}`)
        }}
        modelName='imports'
      />

    </SearchLayout>
  )
};

export default Imports
