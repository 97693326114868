import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { read, utils } from 'xlsx';
import { Row, Button, Form, Input, Space, Alert, Drawer, Divider, message, Upload, Spin } from 'antd';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { Select } from '../../components/pants-d';
const { Option } = Select;
const scan = require("../../entities/scan");
import xml2js from 'xml2js';
import { searchQuery, importRecords } from '../../entities/ip-assignment';

const ImportNmapSubnetForm = ({ onCreate, onClose }) => {
    const { model, supernetId, subnetId } = useParams();
    const subnetUrl = `/subnets/${supernetId}/${subnetId || supernetId}`

    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(true);

    const [form] = Form.useForm();

    const [selectedFile, setSelectedFile] = useState(null);

    const [scanType, setScanType] = useState(null);

    const [excelData, setExcelData] = useState(null);

    const errorContainerRef = useRef(null);
    const [errors, setErrors] = useState([])

    useEffect(() => {
        if (errorContainerRef.current) {
          errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, [errors]);

    const handleScanTypeChange = (value) => {
        setScanType(value);
    };

    const handleFileChange = (file) => {
        setSelectedFile(file);
        return false;
    };

    const [createScanSync, { data: createSubnetSyncData, error: createSubnetSyncDataErrors }] = useMutation(scan.SYNC, {
        onCompleted: (e) => {
        },
        onError: (e) => {
            setErrors(e?.graphQLErrors?.map(error => error.message) || [e.message]);
        },
        refetchQueries: [{ 
            query: scan.SEARCH_SCAN, 
            variables: { search: '', offset: 0, limit: 50, subnetId: parseInt(subnetId)} 
        }]
    });

    if (createSubnetSyncDataErrors) {
        const fieldErrors = createSubnetSyncDataErrors.graphQLErrors
          ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
          .map(e => {
            return {
              name: e.extensions?.exception?.errors?.[0]?.path,
              errors: [e.extensions?.exception?.errors?.[0]?.message]
            }
          })
        if (fieldErrors) {
          form.setFields(fieldErrors)
        }
      }

    const {  data: attributeData, loading: attributesLoading } = useQuery(searchQuery, { variables: { search: '', offset: 0, limit: 50, subnet_id: subnetId }})

    let conflicts = 0;
    const handleScan = async (values) => {
        if (selectedFile) {
            if(values.scanType === 'Excel') {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const data = new Uint8Array(e.target.result);
                    const wb = read(data, {type: 'array'});
                    const sheet = wb.Sheets[wb.SheetNames[0]];
                    const jsonData = utils.sheet_to_json(sheet);

                    const mapping = {
                        'Zone': 'zone',
                        'Network Address': 'networkAddress',
                        'DNS Record Type': 'dnsEntryType',
                        'DNS Record': 'dnsEntryValue',
                        'DNS Record TTL': 'dnsEntryTTL',
                        'Hostname': 'hostname',
                        'Resource Type': 'resourceType',
                        'MAC address': 'macAddress',
                        'Interface': 'interface',
                        'Direction': 'direction',
                        'Reservation': 'reservation'
                    };
        
                    let mappedData = jsonData.map(record => {
                        const newRecord = {};
                        for (const key in record) {
                            if (mapping[key]) {
                                newRecord[mapping[key]] = record[key];
                            }
                        }
                        return newRecord;
                    });

                    Promise.all(mappedData).then((value) => {

                        createScanSync({
                            variables :{
                                subnetId: parseInt(subnetId),
                                fileName: selectedFile.name,
                                result: `${value.length} results`,
                                sourceType: values?.scanType,
                                createAssignments: true,
                                IPData: value
                            }
                        }).then(result => {
                            if(result?.data !== undefined) {
                                setLoading(false); 
                                setOpen(false); 
                            }
                        }).catch((error)=> {
                            setOpen(true); 
                            setLoading(false); 
                        })
                    });
                };
                reader.readAsArrayBuffer(selectedFile);
            }
            if(values.scanType === 'NMAP') {
                setLoading(true);
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const xmlData = e.target.result;
                    xml2js.parseString(xmlData, async (err, result) => {
                        if (err) {
                            console.error(err.toString());
                        } else {
                            const ipData = result?.nmaprun?.host.map(async (host) => {
                                const ipAddress = host?.address?.filter((address) => {
                                    const addressType = address?.['$']?.addrtype;
                                    return addressType == ('ipv4' ?? 'ipv6')
                                })[0]?.['$']?.addr
                        
                                const macAddress = host?.address?.filter((address) => {
                                    const addressType = address?.['$']?.addrtype;
                                    return addressType == 'mac'
                                })[0]?.['$']?.addr
                        
                                return {
                                    hostname: host?.hostnames?.[0]?.hostname?.[0]?.['$']?.['name'],
                                    status: host?.status[0]?.['$']?.state,
                                    networkAddress: ipAddress,
                                    macAddress: macAddress,
                                    time: result?.nmaprun?.runstats?.[0]?.finished?.[0]?.['$']?.timestr || ''
                                }
                            });
        
                            Promise.all(ipData).then((value) => {
                                if(attributeData) {
                                value.forEach(scan => {
                                    attributeData?.ipAssignments?.results.forEach(assignment => {
                                        if (assignment?.networkAddress === scan?.networkAddress && assignment?.subnet?.id === parseInt(subnetId)) {
                                            conflicts++;
                                        }
                                    });
                                });
                            }
                            createScanSync({
                                    variables :{
                                        subnetId: parseInt(subnetId),
                                        fileName: selectedFile.name,
                                        result: `${value.length} results, ${conflicts} conflicts.`,
                                        sourceType: values?.scanType,
                                        IPData: value
                                    }
                                }).then(result => {
                                    if(result?.data !== undefined) {
                                        setLoading(false); 
                                        setOpen(false); 
                                    }
                                setOpen(false); 
                                }).catch(()=> {
                                    setOpen(true); 
                                    setLoading(false); 
                                })
                            });
                        }
                    });
                };
                reader.readAsText(selectedFile);
            }
        }
    };

    return (
        <Drawer
            title="Scan"
            width={520}
            visible={open}
            onClose={() => {
                setLoading(true);
                history.push(`${subnetUrl}/imports`);
            }}
        >
            <Spin spinning={loading}>

                <Form
                    layout="vertical"
                    form={form}
                    name="alerts"
                    initialValues={{}}
                    onFinish={handleScan}
                >
                    <div ref={errorContainerRef}>
                        {(errors?.length > 0) && (
                        <>
                            {errors.map((errorMessage, index) => (
                            <Alert key={index} type="error" message={errorMessage} />
                            ))}
                            <br />
                        </>
                        )}
                    </div>
                    <Form.Item
                        name="scanType"
                        label="Scan Type"
                        rules={[{ required: true, message: 'Please select the Scan Type' }]}
                    >
                        <Select
                            placeholder="Select file type" onChange={handleScanTypeChange}
                        >
                            <Select.Option value="NMAP">NMAP</Select.Option>
                            <Select.Option value="Excel">Excel</Select.Option>
                        </Select>
                    </Form.Item>
                    {scanType && (
                        <p>
                            Download example file: &nbsp;
                            <a href={scanType === 'Excel' ? `/IPAssignmentExampleInsideSubnet.xlsx` : `/NmapExampleInsideSubnet.xml`} download>
                                { scanType === 'Excel' ? 'Excel File' : 'NMAP (XML) File' }
                            </a>
                        </p>
                    )}
                    <Form.Item
                        name="file"
                        label="File"
                        rules={[{ required: true, message: 'Please upload the selected File' }]}
                    >
                        <Upload
                            name='file'
                            style={{ background: '#FFFFFF' }}
                            beforeUpload={handleFileChange}>
                            <Button style={{ width: '472px' }}>
                                Upload File <UploadOutlined style={{ marginLeft: '20px' }} />
                            </Button>
                        </Upload>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ backgroundColor: '#447aa7', color: '#ffffff', width: '200px' }}
                        >
                            Scan
                        </Button>
                    </div>
                </Form>
            </Spin>

            <Alert
                style={{
                    marginTop: '30px',
                    marginBottom: '20px',
                    backgroundColor: '#fffbf0',
                    border: '1px solid #ffa39e',
                    color: '#ad2102',
                    borderRadius: '5px'
                }}
                message={
                    <span>
                        Note: NMAP Sync can also be done Via LightMesh CLI. Here is the &nbsp;
                        <a href="https://guides.lightmesh.com/getting-started/#scan-import" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                            documentation guide
                        </a> for it.
                    </span>
                }
                type="warning"
                showIcon
            />
        </Drawer>
    )
}

export default ImportNmapSubnetForm;
