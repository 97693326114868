import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {Table} from 'antd';

function toLocaleUTCDateString(date, locales, options) {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const defaultOptions = {
  responsive: true,
  elements: {
    line: {
      borderWidth: 0
    },
    point: {
      radius: 0
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      position: 'left',
      border: { display: false },
      grid: { 
        display: false,
        drawTicks: false 
      }
    }
  },
  plugins: {
    legend: { display: false },
    title: {
      display: false,
    },
  },
};

const getMonth = ( d ) => {
  const date= new Date(parseInt(d?.label));

  return toLocaleUTCDateString(date,undefined, { month: 'long', year: 'numeric' })
}

const plugins = [
  {
    afterDraw: function (chart) {
      if (chart.data.datasets[0].data.length < 1) {
        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "30px Arial";
        ctx.fillText("No data to display", width / 2, height / 2);
        ctx.restore();
      }
    },
  },
];



const AreaChart = ({ query, variables, labels, pagination = false, showTable = true, labelFn = getMonth, valueFn = (d) => { return (d?.value || 0) }, ...props }) =>  {

  /**
   * A wrapper around chartjs components.  This component allows you to pass a query and functions for post query processing
   *
   * example usage:
   *
   * <TimeSeries 
     * query={{ GET_REPORT }} 
     * labelFn={(d) => { return ( d?.label )}}
     * valueFn={(d) => { return ( d?.value )}
     * dataSetLabels={['Month']}
     * }}/>
   */


  const { data: queryResults, loading } = useQuery(query, { variables: (variables || {})});

  const results = queryResults?.[Object.keys(queryResults || {})[0]]

  const dataSet = {
    labels: results?.map(d => { return labelFn(d) }),
    datasets: [
      {
        label: 'Month',
        yAxisID: 'y',
        xAxisID: 'x',
        fill: true,
        data: results?.map(d => d?.value),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }
    ]
  }

  return (
    <>
      <Line options={defaultOptions} data={ dataSet } props={{...props}} plugins={ plugins } />
      { showTable && results?.length > 0 && (
        <Table
          className='report-results'
          bordered={ false }
          size='small'
          pagination={ pagination}
          style={{ marginTop: '20px' }}
          dataSource={ [...results].reverse() }
          columns={[
            { title: 'Month', key: 'label', render: d => labelFn(d) },
            { title: '', key: 'value', align: 'right', render: d => valueFn(d) },
          ]}
        />

      )}
    </>
  )
}

export default AreaChart;
