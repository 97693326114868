import React, { useState } from 'react';
import { Button } from 'antd';
import SearchTable from '../../components/SearchTable';
import { useParams, useHistory } from "react-router-dom";
import ApiKeyForm from '../../views/api-keys/Form';
import RecordLayout from '../layout/Record'
import {  Form, Layout } from 'antd';
import SearchAction from '../../components/SearchAction';
import { PlusOutlined } from '@ant-design/icons';

const apiKeys = require('../../entities/api-key')

const Keys = ({ }) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'
  const [refetch, setRefetch] = useState()

  const handleKeyClick = (record) => {


    history.push(`/settings/api-keys/${record?.id}`);
  };

  const [showApiKeyForm, setShowApiKeyForm] = useState(false);

  const handleAddApiKeyClick = () => {
    history.push('/settings/api-keys/new');
    setShowApiKeyForm(true);
  };

  const handleCloseApiKeyForm = () => {
    setShowApiKeyForm(false);
  };

  return (
    <RecordLayout section="API Keys">
      <h1>
        <SearchAction 
          icon={ <PlusOutlined /> }
          onClick={handleAddApiKeyClick}
          url='/user-groups/new'
          style={{marginTop: '12px', marginBottom: '12px'}}>
          Add API Key
        </SearchAction>
      </h1>
        <SearchTable
          query={apiKeys.searchQuery}
          {...apiKeys.search}
          onAdd={handleAddApiKeyClick}
          refetch={refetch}
          onClick={handleKeyClick}
          modelName='api-keys'
          onRow={(record) => ({
            onClick: () => handleKeyClick(record),
          })}
        />
      {showApiKeyForm && (
        <ApiKeyForm
          refetch={() => setRefetch(refetch + 1)}
          onClose={handleCloseApiKeyForm} 
        />
      )}
    </RecordLayout>
  )
};

export default Keys;
