import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Alert, Popconfirm } from 'antd';

import { useParams } from 'react-router-dom';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const create = loader('./mutations/create.graphql')
const get = loader('./queries/get.graphql')
const update = loader('./mutations/update.graphql')
const search = loader('./queries/search.graphql')
const destroy = loader('./mutations/destroy.graphql')

const RemoveNATSubnetLink = ({ record }) => {

  const { subnetId, supernetId } = useParams();

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="NAT"
        entityTitle="NAT"
        redirectPath={`/subnets/${supernetId}/${subnetId || supernetId}/nats`}
      />
    </div>

  )
}


const natSearch = {
    columns: [
      { title: 'Type', key: 'natType', dataIndex: 'natType' },
      { title: 'Source IP Address', key: 'sourceNatAddress', dataIndex: 'sourceNatAddress' },
      { title: 'Destination IP Address', key: 'destinationNatAddress', dataIndex: 'destinationNatAddress', render: destinationNatAddress => {return destinationNatAddress.join(', ');} },
      { 
        title: '', 
        width: 100, 
        key: 'remove', 
        render: nat => {
          return <RemoveNATSubnetLink record={nat} />;
        }
      }
    ]
  }

  const importer = {
    title: 'NAT',
    copy: 'Network Address Translation (NAT) in Lightmesh is a method that allows multiple devices within a private subnet, identified by a specific CIDR (Classless Inter-Domain Routing) notation, to share a single public IP address when accessing resources outside the private network. NAT helps manage and conserve public IP addresses while enabling communication between devices in private subnets and the broader internet.',
  }

export { create, search, update, get, natSearch, destroy, importer };
