import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import * as serviceWorker from './serviceWorker';
import { cache } from './cache.js';
import { 
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from "@apollo/client";
import * as Sentry from "@sentry/react";

const client = new ApolloClient({
  cache: cache,
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include'
})

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENABLE_MOUSEFLOW === 'true') {
  Sentry.init({
    dsn: "https://9ac4590b63241cba4cc82bbd787af71e@o4507256180834304.ingest.us.sentry.io/4507256182276096",
    integrations: [
      Sentry.replayIntegration({
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
