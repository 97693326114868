import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { Row, Button, Form, Input, Space, Alert, Drawer, Divider, message } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { SearchSelect } from '../../components/pants-d';

const { ADD_SUPPORT_GROUPS_TO_SUBNET } = require("../../entities/support-group");
const { SUBNET_SUPPORT_GROUPS } = require("../../entities/support-group");
const subnet = require("../../entities/subnet");

const SupportGroupSubnetForm = ({ onCreate, onClose }) => {
  const { model, supernetId, subnetId } = useParams();
  const subnetUrl = `/subnets/${supernetId}/${subnetId || supernetId}`

  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [form] = Form.useForm();

  const { loading, error:dataError, data:subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });

  const [addSupportGroupsToSubnet, { data, error }] = useMutation(ADD_SUPPORT_GROUPS_TO_SUBNET, {
    onCompleted: (e) => { history.push(`${subnetUrl}/support-groups`); },
    onError: (e) => { console.log(e) },
    update(cache, { data }) {
      const searchVariables = { search: "", offset: 0, limit: 50, subnetId: parseInt(subnetId || supernetId) }
      const existing = cache.readQuery({ query: SUBNET_SUPPORT_GROUPS, variables: searchVariables })

      cache.writeQuery({
        query: SUBNET_SUPPORT_GROUPS,
        variables: searchVariables,
        data: {
          supportGroups: {
            count: existing?.count + 1,
            results: [...(existing.results || []), ...data.addSupportGroupsToSubnet]
          }
        }
      })

      setOpen(false);

    }
  })

  return (
    <Drawer
      title="Add support groups"
      width={520}
      visible={open}
      onClose={() => {
        history.push(`${subnetUrl}/support-groups`);
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="support-groups"
        initialValues={{}}
        onFinish={({ supportGroup }) => {
          addSupportGroupsToSubnet({
            variables: {
              subnetId: parseInt(subnetId || supernetId),
              supportGroupIds: [parseInt(supportGroup.id)],
              networkAddress: subnetData.subnet?.networkAddress, 
              zoneId: subnetData.subnet?.zone?.id,
              supportGroupName: supportGroup.name
            },
          });
        }}
      >
        <SearchSelect
          label="Support Groups"
          name="supportGroup"
          model="support-groups"
          query={ SUBNET_SUPPORT_GROUPS }
          resultKey='supportGroups'
          displayFn={(u) => { return (u.name) }}
          required
        />
          <div>
            <Button type="secondary" onClick={(e) => onClose()} style={{ float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>
            <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7',  color: '#ffffff'}} htmlType="submit">
              Submit
            </Button>
          </div>
      </Form>
    </Drawer>
  )
}

export default SupportGroupSubnetForm;
