import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import TextInput from './TextInput';
import {Row, Col, Checkbox, Form } from 'antd';
import Label from './Label';
import {upperFirst, lowerCase} from 'lodash';

const customAttribute = require('../../entities/custom-attribute')

const CustomAttributes = ({ initialValues = {}, modelName, startIndex, endIndex, readOnly, ...rest }) => {
  const { data, error, loading, refetch} = useQuery(customAttribute.searchQuery, {
    variables: { modelName: modelName },
    onCompleted: (response) => { console.log('') }
  })

  const attributes = data?.customAttributes.results.slice(startIndex, endIndex) || [];

  return (
    <Row gutter={ 0 }>
      {
        attributes.map(d => {
          return (
            <Col span={ 24 }>
              { readOnly && (
                <Label name={ upperFirst(lowerCase(d?.name)) } >
                  { initialValues?.[d?.name] || '--' }
                </Label>
              )}
              { !readOnly && (
                <>
                  { (d?.type || 'STRING') == 'STRING' && <TextInput label={ upperFirst(lowerCase(d?.name)) } name={['customAttributes', d?.name ]} { ...rest }/>}
                  { d?.type == 'FLOAT' && <TextInput name={ d?.name } />}
                  { d?.type == 'BOOLEAN' && <Checkbox name={ d?.name } />}
                </>
          )}
            </Col>)
        })
      }
    </Row>

  )
}

export default CustomAttributes;
