import React, { useState, useEffect, useRef } from 'react';
import { EditFilled } from '@ant-design/icons';
import { Input } from 'antd';


const TextEditable = ({ value, placeholder, focused, style, onSubmit, readOnly, ...rest }) => {

  const inputRef = useRef(null);

  const [editing, setEditing] = useState(false);

  const [val, setVal] = useState();

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    editing && ( inputRef.current.focus())
    !editing && (inputRef.current.blur())
  }, [editing])

  useEffect(() => {
    inputRef.current.focus()
    setEditing(focused);
  }, [focused]);

  return (
    <div class={`editable ${editing && !readOnly && "editing"}`}>
      { ((val?.length == 0 || !val) && placeholder) && (
        <div onClick={() => setEditing(true)} className="editable-value placeholder" style={{ ...style, color: '#888888', fontWeight: '400', opacity: '0.4'}}>
          {placeholder}
          {!readOnly && <EditFilled className='editable-icon' style={{float: 'right', marginTop: '5px'}}/>}
        </div>
      )}
      { (val?.length > 0) && (
        <div onClick={() => setEditing(true)} className="editable-value" style={{ fontSize: style?.fontSize }}>
          { val }
          {!readOnly && <EditFilled className='editable-icon' style={{float: 'right', marginTop: '5px'}}/>}
        </div>
      )}
      <Input 
        className="editable-input"
        bordered={ false }
        style={ style } 
        type="text" 
        value={ val } 
        ref={ inputRef }
        onKeyPress={(e) => { 
          if (e.key === 'Enter') { 
            e.preventDefault();
            onSubmit(e.target.value);
            setEditing(false);
          }}
        }
        onChange={(e) => setVal(e.target.value) } 
        onBlur={(e) => {
          onSubmit(e.target.value)
          setEditing(false);
        }} />
    </div>
  );
}

export default TextEditable;
