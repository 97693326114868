import React, { useEffect } from 'react';
import { Form, Input, } from 'antd';
import { PlusOutlined, FilterOutlined, UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { searchVar } from '../cache.js';
import { useQuery, useReactiveVar } from '@apollo/client';
import _ from "lodash";

const { Search } = Input;


const SearchForm = ({ style, placeholder, childView }) => {

  const currentSearch = useReactiveVar(searchVar);
  const debouncedSearch = _.debounce(v => searchVar(v), 500, {});

  useEffect(() => {
    searchVar('');
  }, [childView]);  

  return (
    <Search 
      key={childView} 
      style={{ width: '300px', marginTop: '10px', float: 'left', padding: '0px', ...style}}
      size="medium" 
      autoComplete="off" 
      placeholder={ placeholder || 'Search' }
      enterButton
      onChange={(e) => {
        debouncedSearch(e.target.value)
      }}/>
  )
}


export default SearchForm;
