import React, { useState } from 'react';
import { CopyOutlined, CheckOutlined, LinkOutlined } from '@ant-design/icons';
import { Layout, Menu, Row, Col, Breadcrumb, Drawer, Button, Popover, Input, Tooltip, Table, Form, Space, Alert} from 'antd';
import {
  useLocation
} from "react-router-dom";

import cidrRegex from 'cidr-regex';
const { Address6, Address4 } = require('ip-address');

import { TextInput } from '../../components/pants-d';
import TextEditable from '../../components/Editable';

import { NetmaskForm, SubnetBuilder, utils } from '../../components/SubnetBuilder';
import useCopy from 'use-copy';
import qs from 'qs';

const { Content } = Layout;


const defaultSubnet = {
  id: '10.0.0.0/16',
  networkAddress: "10.0.0.0/16",
  subnets: []
}

const removeSubnet = ( subnetId, supernet ) => {

  if (subnetId == supernet?.id) {
    return null
  } else {

    const data = { ...supernet }

    data?.subnets?.forEach((d,i) => {
      if (subnetId == d.id) {
        data.subnets[i] = null
      } else {
        data.subnets[i] = removeSubnet(subnetId, d)
      }
    })

    return { ...data, subnets: data?.subnets?.filter(d => d) };  
  }

}

const addSubnet = ( subnet, supernet ) => {

  const data = { ...supernet }

  if (data.id == subnet.supernetId) {
    data['subnets'] = [ ...data.subnets, subnet ]
  } else {
    data?.subnets?.forEach((d,i) => {
      data.subnets[i] = addSubnet(subnet, d)
    })
  }

  return data  
}

const updateSubnet = ( subnet, supernet ) => {

  const data = { ...supernet }

  if (data.id == subnet.id) {
    return { ...data, ...subnet }
  } else {
    data?.subnets?.forEach((d,i) => {
      data.subnets[i] = updateSubnet(subnet, d)
    })
  }

  return data  
}

export const parsedSubnet = (subnet) => {
  if (subnet) {
    if (cidrRegex.v4({exact: true}).test(subnet)) {
      return new Address4(subnet)
    } else {
      return new Address6(subnet)
    }
  }
}

export const getAllSubnets = ( subnet ) => {

  const results = []

  const getSubnets = ( subnet ) => {

    const snet = parsedSubnet( subnet.networkAddress )

    results.push({ 
      networkAddress: subnet.networkAddress, 
      name: (subnet.name || ''),
      broadcast: snet?.endAddress().address,
      hosts: Math.pow(2, (32 - snet.subnetMask)) - 2
    })

    subnet.subnets?.map(s => { getSubnets(s) })
  }

  getSubnets(subnet);

  return results
}

const Landing = ({ type }) => {

  const subnet = window?.location?.search ? qs.parse(window.location.search.slice(1), { depth: 10}) : null
  const subnets = (subnet || defaultSubnet) ;
  const [subnetTree, setSubnetTree] = useState(subnet);
  const [active, setActive] = useState(subnetTree?.id);
  const [netmask, setNetmask] = useState();
  const [error, setError] = useState();

  const [form] = Form.useForm()

  const [copied, copy, setCopied] = useCopy(`https://next.lightmesh.com/planner?${qs.stringify(subnetTree)}`);
  const copyText = () => {
    copy();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const [visible, setVisible] = useState(false);
  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  return (
    <Layout className="landing-layout"> 
      <Content style={{ background:' #FFFFFF', padding: '20px', marginTop: '60px' }}>
        <Row>
          <Col style={{textAlign: 'center'}} span={12} offset={6} >
            <h1 style={{fontSize: '40px'}}>Subnet Planner</h1>
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={1}>
            <h2 style={{textAlign: 'center', marginTop: '-20px'}}>from</h2>
            <a href="https://tidalcloud.com/lightmesh">
            <img 
              style={{ width: '180px', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px', marginBottom: '30px', display: 'block'}}
              src="/images/lightmesh-light-bg.png" />
            </a>
          </Col>
        </Row>
        {!subnetTree && (
          <Row>
            <Col offset={ 3 } span={18} >

              { error && <Alert message={ error } type="error"/> }
              <div class="subnet-block-form" style={{ marginTop: '20px' }}>
                <Form 
                  layout="vertical"
                  form={ form }
                  onFinish={({ networkAddress }) => {

                    if (!utils.parsedSubnet(networkAddress || '10.0.0.0/24').isValid()) { return setError('Please enter a valid CIDR address') }

                    setError(null)

                    const newSubnet = {
                      id:  (networkAddress || '10.0.0.0/24'),
                      networkAddress: (networkAddress || '10.0.0.0/24'), 
                      subnets: []
                    }

                    setNetmask(n => parseInt((networkAddress || '10.0.0.0/24').split('/')[1]) + 1 )
                    setActive(() => { return newSubnet })
                    setSubnetTree((s) => { return newSubnet })
                  }}
                  >
                  <Space direction="horizontal">
                    <TextInput 
                      name="networkAddress" 
                      hideLabel={ true }  
                      placeholder="10.0.0.0/24"
                      style={{ width: '240px', marginTop: '22px', marginRight: '10px' }}/>
                    <Button style={{ marginTop: '0px', marginLeft: '12px', padding: '2px 10px', height: '32px', border: 'none', background: '#F2F2F2', color: '#444444'}} type="primary" htmlType="submit">
                      Create subnet
                    </Button>
                  </Space>
                </Form>
              </div>
            </Col>
          </Row>
        )}
        { subnetTree && (
          <>
            <Row>
              <Col offset={ 3 } span={18} style={{ minHeight: '240px' }}>
                <SubnetBuilder 
                  style={{ marginBottom: '-10px', }} 
                  blockStyle={{ fontSize : '16px' }}
                  editing={ true }
                  editingNetmask={ netmask }
                  showSupernet={ false }
                  height={65}
                  active={ active?.networkAddress }
                  onClick={(s) => setActive( s ) }
                  data={ subnetTree }
                  onDestroy={(s) => {
                    const newSubnetTree = removeSubnet(s?.id, subnetTree)
                    setSubnetTree(newSubnetTree);
                  }}
                  onCreate={(s) => { 
                    const newSubnetTree = addSubnet({...s?.variables, id: s?.variables?.networkAddress, subnets: [],}, subnetTree) 

                    setSubnetTree(newSubnetTree);
                    setActive(s?.variables);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col offset={ 3 } span={12}>
                <h1 style={{ fontSize: '32px'}}> 
                  <TextEditable 
                    key={ active?.networkAddress }
                    style={{ 
                      background: 'none', 
                      border: 'none', 
                      fontSize: '32px', 
                      color: '#000000',
                      fontWeight: '400',
                      padding: '0px',
                    }}
                    focused={ true }
                    placeholder={ active?.networkAddress }
                    value={ active?.name } 
                    onSubmit={(d) => { 
                      const newSubnetTree = updateSubnet({id: active?.networkAddress, name: d}, subnetTree)

                      setSubnetTree(newSubnetTree)
                    }}
                  />
                </h1>
                </Col>
              <Col span={ 6 }>
                <Popover
                  content={
                    <Input.Group type="compact">
                      <Input 
                        style={{ width: '400px', borderRight: 'none' }}
                        value={`https://next.lightmesh.com/planner?${qs.stringify(subnetTree)}`} />
                      <Tooltip onClick={ copyText } title="copy subnet url">
                        { !copied && ( <Button style={{borderLeft: '1px solid #F2F2F2'}} icon={<CopyOutlined />} /> )}
                        { copied && ( <Button style={{borderLeft: '1px solid #F2F2F2'}} icon={<CheckOutlined />} /> )}
                      </Tooltip>
                    </Input.Group>
                  }
                  trigger="click"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                >
                  <Button 
                    style={{ float: 'right', marginBottom: '20px', background: '#FFFFFF', color: '#666666', boxShadow: 'none' }}
                    href={`/planner?${qs.stringify(subnetTree)}`} 
                    className="call-to-action share">
                    <LinkOutlined />
                    Copy plan URL
                  </Button>
                </Popover>
              </Col>
            </Row>
            <Row>
              <Col offset={ 3 } span={18} >
                <Table
                  onRow={(record) => {
                    return {
                      onClick: (e) => { 
                        setActive(record) 
                      }
                    }
                  }}
                  loading={ false }
                  dataSource={ getAllSubnets(subnetTree) } 
                  size="small"
                  pagination={{ 
                    pageSize: 20 
                  }}
                  columns={[
                    { title: 'Name', dataIndex: 'name', key: 'name' },
                    { title: 'Network Address', dataIndex: 'networkAddress', key: 'networkAddress' },
                    { title: 'Broadcast', dataIndex: 'broadcast', key: 'broadcast' },
                    { title: 'Host count', dataIndex: 'hosts', key: 'hosts' }
                  ]}
                />

              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col offset={ 18 } span={3} style={{ textAlign: 'center' }}>
            <Button className="call-to-action save rounded" style={{ padding: '20px'}} href={`/register?${qs.stringify(subnetTree)}`}>
              Get a free account!
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Landing;
