import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { Row, Button, Form, Input, Space, Alert, Drawer, Divider, message } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect, Select } from '../../components/pants-d';

const { Option } = Select;

const { ADD_CONTACTS_TO_SUBNET } = require("../../entities/contact");
const contact = require("../../entities/contact");
const { searchQuery } = require("../../entities/contact");
const { SUBNET_SUPPORT_GROUP_CONTACTS } = require("../../entities/support-group");
const subnet = require("../../entities/subnet");

const ContactSubnetForm = ({ onCreate, onClose }) => {
  const { model, supernetId, subnetId } = useParams();
  const subnetUrl = `/subnets/${supernetId}/${subnetId || supernetId}`

  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [alertStatus, setAlertStatus] = useState(null);
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [form] = Form.useForm();

  const { data: queryResults, refetch } = useQuery(searchQuery, { variables: { search: '', offset: 0, limit: 50 } })
  const { loading, error:dataError, data:subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });

  const [createRecord, { data: createData, error: createErrors }] = useMutation(contact.create, {
    onCompleted: (e) => {
      setAlertStatus(true),
        setTimeout(() => {
          setAlertStatus(false);
        }, 7000);
      setShowForm(false);
    },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
      console.log(e)
    },
    update(cache, { data }) {
      const existingContacts = cache.readQuery({ query: contact.searchQuery, variables: { search: "", offset: 0, limit: 50 } })
      cache.writeQuery({
        query: contact.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          contacts: {
            count: (existingContacts?.count || 0) + 1,
            results: [...(existingContacts?.contacts?.results || []), data.createContact]
          }
        }
      })
    }
  });

  const [addContactsToSubnet, { data, error }] = useMutation(ADD_CONTACTS_TO_SUBNET, {
    onCompleted: (e) => { history.push(`${subnetUrl}/contacts`); },
    onError: (e) => { console.log(e) },
    update(cache, { data }) {
      const searchVariables = { search: "", offset: 0, limit: 50, contactableId: parseInt(subnetId || supernetId), contactableType: 'Subnet', subnetId: parseInt(subnetId || supernetId) }
      const existing = cache.readQuery({ query: SUBNET_SUPPORT_GROUP_CONTACTS, variables: searchVariables })

      cache.writeQuery({
        query: searchQuery,
        variables: searchVariables,
        data: {
          subnetSupportGroupContacts: {
            count: existing?.count + 1,
            results: [...(existing.results || []), ...data.addContactsToSubnet]
          }
        }
      })

      setOpen(false);

    }
  })

  const handleAddContactClick = () => {
    setShowForm(true);
  };

  const handleAddContactFormCancel = () => {
    setShowForm(false);
  };

  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  return (
    <Drawer
      title="Add contacts"
      width={520}
      visible={open}
      onClose={() => {
        history.push(`${subnetUrl}/contacts`);
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="contacts"
        initialValues={{}}
        onFinish={({ contact }) => {
          const [contactId, contactName] = contact.split('|');
          addContactsToSubnet({
            variables: {
              subnetId: parseInt(subnetId || supernetId),
              contactIds: [parseInt(contactId)],
              contactName: contactName, 
              networkAddress: subnetData.subnet?.networkAddress, 
              zoneId: subnetData.subnet?.zone?.id
            },
          });
        }}
      >
        {alertStatus && (
          <Alert
            message="New Contact Added Successfully! Open Contact DropDown to Select"
            type="success"
            showIcon
            style={{ marginBottom: '16px' }}
          />
        )}
        {showForm ? (
          <Form
            layout="vertical"
            form={form}
            name="contact"
            onFinish={(values) => {
              createRecord({ variables: { ...values } })
            }} >
            <div ref={errorContainerRef}>
              {(errors?.length > 0) && (
                <>
                  {errors.map((errorMessage, index) => (
                    <Alert key={index} type="error" message={errorMessage} />
                  ))}
                  <br />
                </>
              )}
            </div>
            <TextInput name="firstName" required />
            <TextInput name="lastName" />
            <TextInput name="phone" />
            <TextInput name="cellPhone" />
            <TextInput name="email" />
            <TextInput name="email2" />
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button type="secondary" onClick={handleAddContactFormCancel}>
                Cancel
              </Button>
            </Space>
          </Form>
        ) : (
          <Select
            label="Contacts"
            name="contact"
            placeholder="Select / Create a Contact"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    style={{ marginLeft: '20px' }}
                    type="primary"
                    onClick={handleAddContactClick}
                  >
                    Add a new contact
                  </Button>
                </Space>
              </>
            )}
            required
          >
            {queryResults?.contacts?.results.map((contact) => (
              <Option key={contact.id} value={`${contact.id}|${(contact.firstName ? contact.firstName : '') + (contact.lastName ? ' ' + contact.lastName : '')}`}>{(contact.firstName ? contact.firstName : '') + ' ' + (contact.lastName ? contact.lastName : '') + ' ' + ( contact?.email ? `(${contact.email})` : '')}</Option>
            ))}
          </Select>
        )}
        {!showForm && (
          <div>
            <Button onClick={(e) => onClose()} style={{ float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>
            <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}} htmlType="submit">
              Submit
            </Button>
          </div>
        )}
      </Form>
    </Drawer>
  )
}

export default ContactSubnetForm;
