import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Form, Layout, Button, Drawer } from "antd";
import SearchForm from "../../components/SearchForm";
import SearchTable from "../../components/SearchTable";
import SearchAction from "../../components/SearchAction";
import SupportGroup from "../../views/support-groups/Record";
import SupportGroupForm from "../../views/support-groups/Form";
import SearchLayout from "../layout/Search.js";
import Entity from "../../entities";
import {PlusOutlined} from "@ant-design/icons";
import Info from "../info/Info";

const { Content } = Layout;

const supportGroup = Entity["support-group"];

const SupportGroups = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == "new";

  return (
    <>
      {(!id || newRecord) && (
        <SearchLayout section='Support Groups'>
          <SearchForm placeholder='Search support groups' modelName={SupportGroups} />
          <SearchAction 
            icon={ <PlusOutlined /> }
            url='/support-groups/new'
            style={{marginTop: '12px'}}>
            Add support group
          </SearchAction>
          <SearchTable
            style={{ marginTop: '70px' }}
            query={supportGroup.searchQuery}
            {...supportGroup.search}
            onAdd={() => {
              history.push("/support-groups/new");
              }}
              onClick={(record) => {
                history.push(`/support-groups/${record?.id}`);
              }}
              emptyMessage="Looks like you haven't created any support groups yet!"
              modelName="support-groups"
              emptyRender={<Info model="support-groups" />}
            />
        </SearchLayout>
      )}
      {id && !newRecord && <SupportGroup id={id} />}

      {newRecord && (
        <Drawer
          title="New Support Group"
          width={520}
          closable={false}
          visible={true}
          onClose={() => {
            history.push("/support-groups");
          }}
        >
          <SupportGroupForm id={id} />
        </Drawer>
      )}
    </>
  );
};

export default SupportGroups;
