import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";

import {  Row, Col, Button, Drawer, Form, Menu, Layout, Select, Input, Radio, List, Avatar } from 'antd';

const subnet = require('../../entities/subnet')

const removeUndefinedProps = (obj) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
};

const ChangeList = ({ changes }) => {

  return (
    <List
      style={{ width: '100%' }}
      dataSource={ changes }
      renderItem={(change) => {
        const date = moment(parseInt(change.createdAt))
        const { currentValue, previousValue, removedValue} = JSON.parse(change.document)

        delete currentValue?.VLANId;
        delete previousValue?.VLANId;

        delete currentValue?.zoneId;
        delete previousValue?.zoneId;

        const updatedCurrentValue = { ...currentValue, VLAN: currentValue?.VLANNumber, zone: currentValue?.zoneName };
        const updatedPreviousValue = { ...previousValue, VLAN: previousValue?.VLANNumber, zone: previousValue?.zoneName };

        removeUndefinedProps(updatedCurrentValue);
        removeUndefinedProps(updatedPreviousValue);

        delete updatedCurrentValue?.VLANNumber;
        delete updatedPreviousValue?.VLANNumber;

        delete updatedCurrentValue?.zoneName;
        delete updatedPreviousValue?.zoneName;

        const user = (change?.user?.firstName && change?.user?.lastName) ?
          `${change.user.firstName} ${change.user.lastName}` :
          change?.user?.email

        const descriptionDuringCreate = (user, updatedCurrentValue) => {
          if(change.changeableType == "IPAssignment"){
            return (
              <span>
                {user || ''}{' '}
                created{' '} an assignment at {updatedCurrentValue?.networkAddress}
              </span>
            );
          }
          if (change.changeableType === "SubnetCustomer") {
            return (
              <span>
                {user || ''}{' '}
                assigned{' '} Customer <i>{updatedCurrentValue?.customerName}</i> to {updatedCurrentValue?.networkAddress}
              </span>
            );
          }
          if (change.changeableType === "ContactContactable") {
            return (
              <span>
                {user || ''}{' '}
                assigned{' '} Contact <i>{updatedCurrentValue?.contactName}</i> to {updatedCurrentValue?.networkAddress}
              </span>
            );
          }
          if (change.changeableType === "RoleAssignment") {
            return (
              <span>
                {user || ''}{' '}
                assigned{' '} user <i>{updatedCurrentValue?.userName}</i> as a <i>{updatedCurrentValue?.roleName}</i>  to {updatedCurrentValue?.networkAddress}
              </span>
            );
          }
          if (change.changeableType === "Reservation") {
            const cleanedAddresses = updatedCurrentValue?.networkAddresses.replace(/\{|\}/g, '');
            const addressesLength = updatedCurrentValue?.networkAddresses.replace(/\{|\}/g, '').split(',').length

              return (
                <span>
                  {user || ''} reserved{' '}
                  {addressesLength > 5
                    ? (
                      <span>
                        <i>{addressesLength}</i> ips
                      </span>
                    )
                    : (
                      <span>
                        ips <i>{cleanedAddresses}</i>
                      </span>
                    )
                  } in {updatedCurrentValue?.networkAddress}
                </span>
              );
          }
          if(change.changeableType == "SubnetSupportGroup"){
            return (
              <span>
                {user || ''}{' '}
                attached{' '} a support group  <i>{updatedCurrentValue?.supportGroupName}</i> at {updatedCurrentValue?.networkAddress}
              </span>
            );
          }
          else {
            return (
              <span>
                {user || ''}{' '}
                created{' '} {updatedCurrentValue?.name || updatedCurrentValue?.networkAddress}
              </span>
            );
          }
        }; 

        const descriptionDuringDelete = (user, removedValue) => {
          if (change.changeableType === "SubnetCustomer") {
            return (
              <span>
                {user || ''}{' '}
                removed{' '} Customer <i>{removedValue?.customerName}</i> from {removedValue?.networkAddress}
              </span>
            );
          }
          if (change.changeableType === "ContactContactable") {
            return (
              <span>
                {user || ''}{' '}
                removed{' '} Contact <i>{removedValue?.contactName}</i> from {removedValue?.networkAddress}
              </span>
            );
          }
          if (change.changeableType === "RoleAssignment") {
            return (
              <span>
                {user || ''}{' '}
                removed{' '} user <i>{removedValue?.userName}</i> as a <i>{removedValue?.roleName}</i>  from {removedValue?.networkAddress}
              </span>
            );
          }
          if (change.changeableType === "IPAssignment") {
            return (
              <span>
                {user || ''}{' '}
                released{' '} ip assignment <i>{removedValue?.networkAddress}</i>
              </span>
            );
          }
          if (change.changeableType === "Reservation") {
            const cleanedAddresses = removedValue?.networkAddresses.replace(/\{|\}/g, '');
            const addressesLength = removedValue?.networkAddresses.replace(/\{|\}/g, '').split(',').length 

              return (
                <span>
                  {user || ''} removed{' '}
                  {addressesLength > 5
                    ? (
                      <span>
                        <i>{addressesLength}</i> ips
                      </span>
                    )
                    : (
                      <span>
                        ips <i>{cleanedAddresses}</i>
                      </span>
                    )
                  } in {removedValue?.networkAddress}
                </span>
              );
          }
          if (change.changeableType === "Subnet") {
            return (
              <span>
                {user || ''}{' '}
                removed{' '} child subnet <i>{removedValue?.name || removedValue?.networkAddress}</i>
              </span>
            );
          }
          if (change.changeableType === "SubnetSupportGroup") {
            return (
              <span>
                {user || ''}{' '}
                removed{' '} support group <i>{removedValue?.supportGroupName}</i> from  {removedValue?.networkAddress}
              </span>
            );
          }
        }; 

        const descriptionAfterCreate = (user, k, v, updatedPreviousValue, networkAddress) => {
          if (updatedPreviousValue[k] == null) {
            return `${user || ''} updated ${networkAddress}'s ${k} to ${v}`;
          } else {
            return `${user || ''} updated ${networkAddress}'s ${k} from ${updatedPreviousValue[k]} to ${v}`;
          }
        };

        if (updatedCurrentValue?.id && !updatedPreviousValue?.id) {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{user?.[0] || '?'}</Avatar>}
                title={date.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                description={descriptionDuringCreate(user, updatedCurrentValue)} />
            </List.Item>
          )
        } 
        if(removedValue?.customerId || removedValue?.contactId || removedValue?.roleId || removedValue?.networkAddresses || removedValue?.interfaceId || removedValue?.supernetId || removedValue?.supportGroupId ) {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{user?.[0] || '?'}</Avatar>}
                title={date.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                description={descriptionDuringDelete(user, removedValue)} />
            </List.Item>
          )
        }else {
          return (
            Object.entries(updatedCurrentValue).map(([k, v]) => {
              const networkAddress = change.networkAddress
              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{user?.[0] || '?'}</Avatar>}
                    title={date.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    description={descriptionAfterCreate(user, k, v, updatedPreviousValue, networkAddress)} />
                </List.Item>
              )
            })
          )
        }
      }} />
  )
}

const SubnetHistory = ({zoneId}) => {

  const params = useParams();

  const { subnetId, supernetId } = params;

  const {  error, data: subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });

  const { loading, error: recordError, data, refetch } = useQuery(subnet.historyQuery, { variables: { model: 'Subnet', id: parseInt(subnetId || supernetId), zoneId: zoneId, networkAddress: subnetData?.subnet?.networkAddress } });


  useEffect(() => {
    refetch();
  }, [refetch]);

  return(
    <Row style={{ marginTop: '40px'}}>
      <Col span={24}>
        <div style={{ padding: '10px', background: '#FFFFFF'}}>
          <ChangeList changes={ data?.changes?.results } />
        </div>
      </Col>
    </Row>
  )
}

export default SubnetHistory;
