import React from 'react'
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Link, useHistory, useParams } from "react-router-dom";

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const RemoveCustomAttribute = ({ id }) => {

  const [removeCustomAttribute, { data: updateData }] = useMutation(destroy, { 
    onCompleted: (v) => { console.log("completed") },
    update(cache, { data }) {
      cache.evict(`CustomAttribute:${id}`);
    }
  });

  return (
    <a onClick={(e) => {
      e.stopPropagation();
      removeCustomAttribute({variables: {id: parseInt(id)}})

      }}>
      Remove
    </a>
  )
}

const search = {
  query: searchQuery,
  columns:  [

    { title: 'modelName', key: 'modelName' },
    { title: 'name', key: 'name'},
    { title: 'type', key: 'type'},
    { title: 'required', key: 'required'},
    { title: 'sectionId', key: 'sectionId'},
    { title: 'rowIndex', key: 'rowIndex'},

    { title: 'rowColSpan', key: 'rowColSpan'},
    { title: 'colIndex', key: 'colIndex'},

    { title: 'colSpan', key: 'colSpan'},
    { title: 'component', key: 'component'},

  ]
}

export { search, searchQuery, getQuery, create, update, destroy };
