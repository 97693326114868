import React from 'react';
import { range} from 'lodash';
import { Button, Select } from 'antd';


const NetmaskForm = ({ netmask, onSelect, limit = 6, style  }) => {

  const netmasks = range(netmask + 1, Math.min(netmask + limit , 128))

  const netmaskCount = netmasks.length

  const base = 100

  const widths = [...Array(netmaskCount).keys()].map(idx => {
    const width = 80 + (40 / netmaskCount) * idx 
    return width
  }).reverse()

  return (
    <div className="netmask-form" style={ style }>
      <Select
        size='small'
        showSearch
        style={{ width: '140px', textAlign: 'right' }}
        defaultValue={ netmasks[0] }
        onSelect={(value) => { onSelect(value)}}
        options={ netmasks.map(netmask => {
          return { 
            label: '/' + netmask,
            value: netmask
          }})}
      />
    {/**
      netmasks.map((netmask,idx) => {
        return(
          <Button className="netmask-button" style={{ width: `${widths[idx]}px`, padding: '0px 10px 0px 10px' }} onClick={() => { onSelect(netmask) }}>
            { "/" + netmask }
            <div className="netmask-bar" style={{width: `${widths[idx] / 3}px`}} />
          </Button>
        )
      })
      **/}
    </div>
  )
}

export default NetmaskForm
