import React, { useState, useEffect  } from 'react';
import {  Row, Col, Button, Tabs } from 'antd';

import { useParams, useHistory } from 'react-router-dom';
import Importer from '../../components/RecordImporter';
import SearchTable from '../../components/SearchTable';
import SearchForm from '../../components/SearchForm';
import { TextInput, Form } from '../../components/pants-d';
import TextEditable from '../../components/Editable';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {ArrowLeftOutlined, PlusOutlined} from '@ant-design/icons';
import SearchAction from '../../components/SearchAction';
import RecordLayout from '../layout/Record';

const pluralize = require('pluralize')


const { SEARCH_IMPORTS, CREATE_IMPORT, GET_IMPORT, GET_IMPORT_STATS, UPDATE_IMPORT, search } = require('../../entities/import');

const customer  = require('../../entities/customer');
const customAttribute  = require('../../entities/custom-attribute');
const contact  = require('../../entities/contact');
const site = require('../../entities/site');
const ipAssignment  = require('../../entities/ip-assignment');
const zone  = require('../../entities/zone');
const subnet  = require('../../entities/subnet');

const models = {
  'customers': customer.importer.search,
  'contacts': contact.importer.search,
  'sites': site.importer.search,
  'zones': zone.importer.search,
  'subnets': subnet.importer.search,
  'ip-assignments': ipAssignment.importer.search,
}

const ImportForm = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(GET_IMPORT, {
    variables: { id: parseInt(id) },
    onCompleted: () => { console.log('done') }
  })

  const [updateRecord, {}] = useMutation(UPDATE_IMPORT, {})

  return (
    <TextEditable 
      style={{ 
        background: 'none', 
        border: 'none', 
        fontSize: '22px', 
        color: '#000000',
        fontWeight: '400',
        padding: '20px', 
      }} 
      value={data?.import?.description || ''} 
      onSubmit={(d) => { 
        updateRecord({variables: {id: parseInt(id), description: d}})
      }}
    />
  )
}

const ImportView = ({}) => {
  const history = useHistory();

  const { id, childView = 'customers' } = useParams()
  const newRecord = id == 'new'

  const [getImportStats, { data, loading, error }] = useLazyQuery(GET_IMPORT_STATS, {
    variables: { id: parseInt(id) },
    fetchPolicy: 'network-only',
    onCompleted: () => { console.log('done') }
  })

  const stats = data?.importStats || {}

  useEffect(() => { if ( id && !newRecord ) { getImportStats({ variables: { id: parseInt(id) }}) } },[])

  const [importing, setImporting] = useState(false)
  const [refetch, setRefetch] = useState();

  const { data: attributeData, loading: attributesLoading } = useQuery(customAttribute.searchQuery, { 
    variables: { 
      modelName: _.capitalize(pluralize.singular(childView)) 
    }})

  const customAttributeColumns = attributeData?.customAttributes.results?.map(attribute => {
    return { title: attribute?.name, render: d => d.customAttributes?.[attribute.name] } 
  })


  return (
    <RecordLayout section="Imports">
      { newRecord &&(
        <>
          <h1 style={{ marginBottom: '40px' }}> New Import </h1>
          <Form 
            style={{ width: '600px', marginLeft: '5px'}}
            mutation={ (id && !newRecord) ? UPDATE_IMPORT : CREATE_IMPORT }
            onCompleted={(record) => { 
              history.push(`/imports/${record?.createImport?.id}`) }}>
            <p style={{ fontSize: '14px', marginLeft: '15px', marginTop: '20px'}} >Add a description of your data import to track your import activies</p>
            <p style={{ marginLeft: '30px', marginBottom: '40px' }}>ex. 'Initial data load', 'East coast datacenter'</p>

            <TextInput style={{ marginBottom: '20px'}} name='description' required/>
          </Form>
        </>
      )}
      { id && !newRecord && ( 
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Row>
            <Col span={ 8 }> <ImportForm /> </Col>
            <Col offset={12} span={ 4 }>
              <a 
                onClick={() => history.push('/imports')}
                style={{ float: 'right' }}><ArrowLeftOutlined /> Back to imports</a> </Col>
          </Row>

          <p style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px', color: '#666666' }}>
            <i>For a step by step guide on how to do an inital dataload checkout our <a href="https://guides.lightmesh.com/">documentation</a></i>
          </p>

          <Row>
            <Col span={ 20 }>
              <Tabs
                type='card'
                defaultActiveKey='customers'
                size='medium'
                activeKey={ childView }
                style={{ height: '20px' }}
                onChange={(key) => { history.push(`/imports/${id}/${key}`) }}
                items={[
                  { children: '', label: `Customers ${stats.customer_count && `(${stats.customer_count})`|| ' '}`, key: 'customers' },
                  { children: '', label: `Contacts ${stats.contact_count && `(${stats.contact_count})`|| ' '}`, key: 'contacts' },
                  { children: '', label: `Sites ${stats.site_count && `(${stats.site_count})`|| ' '}`, key: 'sites' },
                  { children: '', label: `Network Zones ${stats.zone_count && `(${stats.zone_count})`|| ' '}`, key: 'zones' },
                  { children: '', label: `Subnets ${stats.subnet_count && `(${stats.subnet_count})`|| ' '}`, key: 'subnets' },
                  { children: '', label: `IP Addresses ${stats.ip_assignment_count && `(${stats.ip_assignment_count})`|| ' '}`, key: 'ip-assignments' },

                ]} />
            </Col>
          </Row>


          <div className='importer' style={{ overflowX: 'scroll', padding: '20px 28px 60px', minHeight: '500px', marginTop: '20px', background: '#FFFFFF' }}>
            { !importing && (
                <SearchTable
                  { ...(models[childView] || {}) }
                  key={ childView }
                  header={ 
                    <>
                      <SearchForm key={ childView } /> 

                      <SearchAction 
                        style={{ marginTop: '20px', marginBottom: '20px', fontSize: '12px', height: '32px', padding: '3px 15px 3px 10px'}}
                        onClick={() => { setImporting(true) }}>
                        <PlusOutlined />
                        New Batch
                      </SearchAction>
                    </>

                  }
                  hideAdd={ true }
                  columns={[ 
                    {
                      title: 'Batch',
                        width: '60px',
                        dataIndex: 'importBatchId',
                        render: (b) => { return `000${b}`}
                    },
                    ...models[childView]?.columns.slice(0,-1),
                    ...(customAttributeColumns || []),
                    ...models[childView]?.columns.slice(-1),
                  ]}
                  refetch={ refetch }
                  filters={{ importId: parseInt(id) }}
                  emptyRender={ <Importer model={ childView } onCompleted={() => {
                    setRefetch(refetch + 1); 
                    getImportStats({ variables: { id: parseInt(id) }})
                  }} /> }
                /> 
            )}
            { importing && ( <> 
              <Importer 
                model={ childView } 
                onViewRecords={() => {

                }}
                onCancel={() => { setImporting(false) }} 
                onCompleted={() => { 
                  getImportStats({ variables: { id: parseInt(id) }})
                  setImporting(false); 
                  setRefetch(refetch + 1);
              }}/> 
            </> ) }
          </div>
        </div>)}
    </RecordLayout>
  )
};

export default ImportView;
