import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input, Space, Col, Drawer, DatePicker } from 'antd';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { TextInput, TextArea } from '../../components/pants-d';
import ColorPick from '../../components/ColorPick';
const subnetEntity = require("../../entities/subnet");

import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';

const reservation = require('../../entities/reservation');
const ipAddress = require('../../entities/ip-address');

const ReservationForm = ({ onClose, data }) => {
  const { subnetId, supernetId, childId } = useParams();

  const history = useHistory();
  const [open, setOpen] = useState( true );

  const [form] = Form.useForm();

  const { loading: dataLoading, error:dataError, data:subnetData} = useQuery(subnetEntity.GET_QUERY, { variables: { id: parseInt(subnetId) } });
  const [reservationColor, setReservationColor] = useState(data?.color || '#7BDCB5')

  const [getReservation, { data: reservationData }] = useLazyQuery(reservation.getQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ reservation }) => {
      console.log(reservationData);

      form.setFieldsValue({
        name: reservation?.name,
        description: reservation?.description,
        NetworkRequestItems: reservation?.ipAddresses?.map(ip => ({
          ...ip,
          updateExpirationDate: ip?.expirationDate ? moment(ip.expirationDate) : null,
        })),
      });
    }
  });

  useEffect(() => {
    if (childId && childId !== 'new') {
      getReservation({ variables: { id: parseInt(childId) } });
    }
  }, [childId])

  const [createReservation, { data: createData, loading: createLoading, error: createErrors}] = useMutation(reservation.create, {
    onCompleted: () => {
      onClose()
    }
  })

  const [updateReservation, { data: updateData, loading: updateLoading, error: updateErrors}] = useMutation(reservation.update, {
    onCompleted: () => {
      onClose()
        }
  })

  const [destroyReservation, { data: destroyData, loading: destroyLoading, error: destroyErrors}] = useMutation(reservation.destroy, {
    onCompleted: () => {
      onClose()
    }
  })

  const [getAvailable, { loading, error, data: availableIPs, refetch }] = useLazyQuery(ipAddress.AVAILABLE_IP_ADDRESSES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ ipAddresses }) => {
      form.setFieldValue('ipAddresses', (ipAddresses?.results || []));
      form.setFieldsValue({
        NetworkRequestItems: ipAddresses?.results
      })
    }})

  const [IPFormType, setIPFormType] = useState(0);
  const [contiguous, setContiguous] = useState(true);
  const [selectedIPIndex, setSelectedIPIndex] = useState(null);

  const handleRemoveClick = (index) => {
    const values = form.getFieldsValue();
    values.NetworkRequestItems.splice(index, 1);
    form.setFieldsValue(values);
  };

  const [customDate, setCustomDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);

  const [selectedButton, setSelectedButton] = useState(Array(form.getFieldValue('NetworkRequestItems')?.length).fill(null));

  const handleDateChange = (value, index) => {
    if (value === 'custom') {
      setCustomDate(true);
      setSelectedIPIndex(index);
      setSelectedButton(prevButtons => {
        const newButtons = [...prevButtons];
        newButtons[index] = 'custom';
        return newButtons;
      });
    } else {
      const date = moment().add(value, 'days');
      setSelectedDate(prevDates => {
        const newDates = [...prevDates];
        newDates[index] = date;
        return newDates;
      });
      setCustomDate(false);
      setSelectedButton(prevButtons => {
        const newButtons = [...prevButtons];
        newButtons[index] = value;
        return newButtons;
      });
    }
  };


  const handleCustomDateChange = (date, index) => {
    setSelectedDate(prevDates => {
      const newDates = [...prevDates];
      newDates[index] = date;
      return newDates;
    });
  };


  const handleCancelCustomDate = () => {
    setCustomDate(false);
    setSelectedDate(prevDates => {
      const newDates = [...prevDates];
      newDates[selectedIPIndex] = null;
      return newDates;
    });
    setSelectedIPIndex(null);
  };

  return (
    <Drawer
      title={ (childId && (childId !== 'new')) ? "Update Reservation": "New Reservation" }
      width={650}
      extra={ childId !== 'new' && (
        <a
          style={{ fontSize: '14px', color: '#FF8888', '&:hover': { color: '#FF0000'} }}
          onClick={e => { destroyReservation({ variables: { id: parseInt(childId) }}) }}>
          <b> Release </b>
        </a>
      )}
      onClose={ onClose }
      visible={ open }>
      <Form
        layout="vertical"
        form={form}
        name="reservation"
        initialValues={{...reservationData, ipCount: 1}}
        onFinish={ ({NetworkRequestItems, ...values}) => {

          const ipAddressesItems = NetworkRequestItems.map(({networkAddress, expirationDate, __typename, ...item}, idx) => {
            return { networkAddress: networkAddress, expirationDate: selectedDate[idx]?.format('MMMM D, YYYY') };
          })

          const networkAddressesItems = NetworkRequestItems.map(({networkAddress, expirationDate, __typename, ...item}) => {
            return networkAddress
          })

          if (childId && ( childId !== 'new' )) {
            updateReservation({ variables: {
                ...values,
                id: parseInt(childId),
                color: reservationColor,
                ipAddresses: NetworkRequestItems.map(({networkAddress, updateExpirationDate}) => ({
                  networkAddress,
                  expirationDate: moment(updateExpirationDate).format('MMMM D, YYYY'),
                }))
              }})
          } else {
            createReservation({ variables: {
                ...values,
                color: reservationColor,
                subnetId: parseInt(subnetId || supernetId),
                networkAddress: subnetData?.subnet?.networkAddress,
                zoneId: subnetData?.subnet?.zone?.id,
                description: values.description,
                networkAddresses: networkAddressesItems,
                ipAddresses: ipAddressesItems,
              }})
          }

        }}>

        <Space>
          <>
            <ColorPick
              color={ reservationColor }
              onPick={(value) => { setReservationColor(value) }}
            />
          </>
          <TextInput style={{ width: '465px'}} required name="name" />
        </Space>
        <TextArea name="description" />

        <Row>
          <Col span={24}>
            <Space>
              <TextInput style={{ width: '140px'}} name="ipCount" label="# of IPs"/>

              {/** <Checkbox checked={ contiguous }>Contiguous</Checkbox> **/}
              <Button
                style={{ marginTop: '12px'}}
                onClick={(e) => {
                  getAvailable({
                    variables: {
                      search: "",
                      offset: 0,
                      limit: parseInt(form.getFieldValue('ipCount') || 1),
                      subnetId: parseInt(subnetId || supernetId),
                      contiguous: contiguous,
                      available: true
                    }
                  })
                }}
              >Fetch</Button>
            </Space>
            <div style={{ marginTop: '10px' }}>
              <Form.List
                name='NetworkRequestItems'
                initialValue={[]}
              >
                {(NetworkRequestItems, { add, remove }) => {
                  return (
                    <>
                { NetworkRequestItems?.length > 0 && ( <>
                
                        <div
                          style={{
                            padding: '15px 5px',
                            marginBottom: '20px',
                          }}>
                          <table
                            style={{ width: '100%', textAlign: 'left' }}
                          >
                            <thead>
                              <tr>
                                <th style={{ width: '30px' }}>IP Address</th>
                                <th style={{ width: '120px' }}>Expiration Date</th>
                                <th style={{ width: '60px' }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                form.getFieldValue('NetworkRequestItems').map((item, idx) => (
                                  <tr key={idx}>
                                    <td style={{ paddingBottom: '15px', width: '10px' }}>
                                      {item?.networkAddress?.length > 0 ?
                                        item?.networkAddress :
                                        <TextInput name={[idx, "ipAddress"]} hideLabel allowClear={false} style={{ padding: '4px', border: 'none' }} />
                                      }
                                    </td>
                                    <td style={{ paddingBottom: '15px', width: '120px', position: 'relative' }}>
                                      {childId && childId == 'new' ? (
                                        <Form.Item name={[idx, "expirationDate"]} initialValue={item?.expirationDate || selectedDate[idx]}>
                                          {item?.expirationDate || (customDate && idx === selectedIPIndex) ? (
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                              <DatePicker onChange={(date) => handleCustomDateChange(date, idx)} /> {/* Pass idx here */}
                                              <CloseOutlined
                                                onClick={handleCancelCustomDate}
                                                style={{
                                                  position: 'absolute',
                                                  top: '-7px',
                                                  right: '-15px',
                                                  cursor: 'pointer',
                                                  color: '#999',
                                                  zIndex: 1,
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <>
                                              <Button size="small"
                                                style={selectedButton[idx] === 30 ? { backgroundColor: 'lightgray' } : {}}
                                                onClick={() => handleDateChange(30, idx)}
                                              >
                                                30 days
                                              </Button>
                                              <Button size="small"
                                                style={selectedButton[idx] === 60 ? { backgroundColor: 'lightgray' } : {}}
                                                onClick={() => handleDateChange(60, idx)}
                                              >
                                                60 days
                                              </Button>
                                              <Button size="small"
                                                style={selectedButton[idx] === 90 ? { backgroundColor: 'lightgray' } : {}}
                                                onClick={() => handleDateChange(90, idx)}
                                              >
                                                90 days
                                              </Button>
                                              <Button size="small"
                                                style={selectedButton[idx] === 'custom' ? { backgroundColor: 'lightgray' } : {}}
                                                onClick={() => handleDateChange('custom', idx)}
                                              >
                                                Custom
                                              </Button>
                                            </>
                                          )}
                                        </Form.Item>
                                      ) : (
                                        <Form.Item name={[idx, "updateExpirationDate"]} >
                                          <DatePicker />
                                        </Form.Item>
                                      )}
                                    </td>
                                    <td style={{ paddingBottom: '15px', width: '30px' }}>
                                      <Button onClick={() => handleRemoveClick(idx)}>Remove</Button>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </>
                      )}
                    </>
                  )
                }}
              </Form.List>
            </div>
          </Col>
        </Row>
        <Button type="primary" style={{ position: 'absolute', bottom: '20px', right: '20px' }} htmlType="submit">
          Submit
        </Button>

        {data?.id && (
          <Button
            onClick={(e) => { destroyReservation({ variables: { id: parseInt(subnetId) } }) }}
            type="primary"
            style={{ position: 'absolute', bottom: '20px', right: '120px', background: '#AA2222', border: 'none' }}
            htmlType="button">
            Release
          </Button>
        )
        }
      </Form>
    </Drawer>
  )
}

export default ReservationForm;
