import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input, Drawer, Alert, Checkbox } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect, Select } from '../../components/pants-d';

const user = require("../../entities/user");
const role = require("../../entities/role");

const UserForm = ({ refetch, id }) => {
  const history = useHistory();

  const newRecord = id == 'new'
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);


  const [getUser, { loading, error: recordError, data }] = useLazyQuery(user.adminUserGet, { variables: { id: parseInt(id) }});

  useEffect(() => { if ( id && !newRecord ) { getUser({ variables: { id: parseInt(id) }}) } },[])

  const [updateRecord, { data: updateData }] = useMutation(user.adminUserUpdate, { onCompleted: () => { 
    history.push(`/tidal-admin/user`) 
    refetch();
  },
onError: e => {
    setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
    console.log(e)
  }})

  const [destroyUserOrganization, { data: destroyData}] = useMutation(user.destroyUserOrganization, { onCompleted: () => {
    onCompleted: () => {
      history.push(`/tidal-admin/user`)
    }
  }})

  const [form] = Form.useForm();

  if (loading) { return <></> }
  return (

    <Drawer
      title='Update User'
      width={520}
      closable={false}
      visible={true}
      onClose={() => { history.push('/tidal-admin/users')}}>
      <Form
        layout="vertical"
        form={form}
        name="user"
        initialValues={data?.['adminUserGet'] || {}}
        onFinish={ (values) => {
          console.log(values)

        updateRecord({ variables: { id: parseInt(id), ...values }})}} >

         <div ref={errorContainerRef}>
            {(errors?.length > 0) && (
              <>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} type="error" message={errorMessage} />
                ))}
                <br />
              </>
            )}
          </div>
        <Row gutter={ 24 }>
          <Col span={ 12 }>
            <TextInput required name="firstName" />
          </Col>

          <Col span={ 12 }>
            <TextInput required name="lastName" />
          </Col>
        </Row>

        <TextInput required name="email" />

        <Select name='role'>
          <Select.Option value='admin'>admin</Select.Option>
          <Select.Option value='user'>user</Select.Option>
        </Select>

        <Form.Item name="internal" valuePropName="checked">
          <Checkbox name='internal'>
            Internal
          </Checkbox>
        </Form.Item>

        <h4>Roles</h4>

        { data?.adminUserGet?.UserOrganizations?.map((userOrg, idx) => (
          <div style={{ background: '#FAFAFA', padding: '10px' }} >
            Organization: {userOrg?.Organization?.name}
            <br/>
            Role: { userOrg?.role }
            <br/>
            <a onClick={(e) => { destroyUserOrganization({ variables: { UserId: data?.adminUserGet?.id, OrganizationId: userOrg?.Organization?.id }}) }}>Destroy</a>
          </div>
        ))}

        <h4 style={{ marginTop: '20px' }}>Settings</h4>
        <TextArea name="config" hideLabel />

        <Row style={{ marginTop: '40px' }} gutter={ 24 }>
          <Col span={ 24 }>
            <Button onClick={(e) => { history.push('/tidal-admin/user-search') }} type="primary" style={{ width: '80px', float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>

            <Button type="primary" style={{ width: '80px', float: 'right' }} htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>

      </Form>
    </Drawer>
  )
}

export default UserForm;
