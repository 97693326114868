import React, { Component, useState, useForm, useEffect } from 'react';
import { Alert } from 'antd';
import { useLocation } from 'react-router-dom';

const defaultMessages = {
  "/activation_success": "Account successfully activated!"
}

const SystemAlert = ({ message, type = "success" }) => {
  const location = useLocation();

  const alertMessage = (message || defaultMessages[location.pathname]);

  return(
    <div>
      { alertMessage && (<Alert style={{margin: '30px 20px -10px'}} message={ alertMessage } type={ type } closable={ true }/>) }
    </div>
  )
}

export default SystemAlert;
