import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Drawer, Alert, message } from 'antd';
import { useMutation } from "@apollo/client";
const uuidAPIKey = require('uuid-apikey');

const apiKeys = require("../../entities/api-key");

const ApiKeyForm = ({ refetch, id, onClose }) => {
  const history = useHistory();
  const newRecord = id === 'new'

  const [showKey, setShowKey] = useState(false);
  const [apiKeyName, setApiKeyName] = useState('');
  const [customExpiration, setCustomExpiration] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [expirationDate, setExpirationDate] = useState('');

  const [generateKey, response] = useMutation(apiKeys.create, {
    onCompleted: (v) => { setShowKey(true) },
    update(cache, { data }) {
      const existingApiKeys = cache.readQuery({ query: apiKeys.searchQuery, variables: { search: "", offset: 0, limit: 50 } })

      cache.writeQuery({
        query: apiKeys.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          apiKeys: {
            count: (existingApiKeys?.count || 0) + 1,
            results: [...(existingApiKeys?.apiKeys?.results || []), data.generateApiKey]
          }
        }
      })
    }
  });

  const handleGenerateKey = () => {
    const keyPair = uuidAPIKey.create();
    setApiKey(keyPair.apiKey);
    setShowKey(true);
    form.setFieldsValue({ apiKey: keyPair.apiKey });
  };

  const handleCloseForm = () => {
    setApiKeyName('');
    setCustomExpiration('');
    setApiKey('');
    setExpirationDate('');
    setShowKey(false);
    history.push(`/settings/api-keys`)
    onClose();
  };

  const handleSubmit = (values) => {
    if (!apiKey) {
      message.error('API key is required!');
      return;
    }

    const variables = {
      apiKeyName: values.apiKeyName || null,
      apiKey: apiKey || null,
      customExpiration: customExpiration || undefined,
    };

    generateKey({ variables: variables })
      .then(() => {
        handleCloseForm();
      })
      .catch((error) => {
        console.error("Mutation error:", error);
      });
  };


  const [form] = Form.useForm();


  return (

    <Drawer
      title='Add API Key'
      width={520}
      closable={false}
      visible={true}
      onClose={handleCloseForm}>
      <Form
        form={form}
        layout="vertical"
        name="apikey"
        initialValues={{}}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="apiKeyName"
          label="Name"
          rules={[{ required: true, message: 'Please enter a name for the API key!' }]}
        >
          <Input
            value={apiKeyName}
            onChange={(e) => setApiKeyName(e.target.value)}
            placeholder="Enter a name for the API key"
          />
        </Form.Item>
        <Form.Item
          name="customExpiration"
          label="Custom Expiration (in days)"
        >
          <Input
            type="number"
            value={customExpiration}
            onChange={(e) => setCustomExpiration(e.target.value)}
            placeholder="Enter custom expiration in days"
          />
          <Alert
            style={{ marginTop: '10px' }}
            message="Default Expiration for API Keys will be 30 days"
            type="info" showIcon
          />
        </Form.Item>
        <Form.Item
          name="apiKey"
          rules={[{ required: true, message: 'Please generate an API key!' }]}
        >
          {showKey ? (
            <div>
              <label>Generated API Key:</label>
              <div>{apiKey}</div>
            </div>
          ) : (
            <Button onClick={handleGenerateKey}>Generate API Key</Button>
          )}
        </Form.Item>
        <Alert
          style={{ marginTop: '10px', marginBottom: '20px' }}
          message="Please save the generated API Key somewhere safe before submitting, as it will be masked after submission."
          type="warning"
          showIcon
        />

        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Drawer>
  )
}

export default ApiKeyForm;
