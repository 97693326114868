import React, {useEffect, useState, useRef} from 'react';

import {  Form, Row, Col, Layout, Button, Space, Table } from 'antd';

import { Subnav } from '../layout/Subnav.js';
import {Select} from '../../components/pants-d';
import { useReactToPrint } from 'react-to-print';
import {BarChartOutlined, DownloadOutlined, TableOutlined, PrinterOutlined} from '@ant-design/icons';
import { getUsage } from '../../entities/report';
import {useLazyQuery} from '@apollo/client';

import NetworkSummary from './NetworkSummary';

const { Option } = Select;

const { Content } = Layout;


const Reports = () => {

  const [getUsageStats, { loading, data, error }] = useLazyQuery(getUsage, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => { }
  })

  const [reportType, setReportType] = useState('NetworkSummary')

  useEffect(() => {
    getUsageStats({ variables: { reportType: reportType }})
  }, [reportType])

  const reportRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
  });

  return (
    <>
      <Subnav>
        <h1 style={{marginTop: '5px', display: 'inline-block'}}>Reports</h1>
      </Subnav>

      <Content style={{ position: 'fixed', width: '100%', padding: '0px 25px', zIndex: '2', minHeight: '100%'}}>
        <Row>
          <Col span={ 4 } style={{ marginTop: '25px'}}>
            <Form 
              layout='vertical'
              initialValues={{ report: reportType }}
              onFinish={({ report }) => { 
                setReportType(report)
              }}
            >
              <Select
                name='report'
                label="Select a Report"
                style={{ marginBottom: '10px' }}
              >
                <Option value='NetworkSummary'>Network Summary</Option>
                <Option value='Subnet'>By Subnet</Option>
                <Option value='Customer'>By Customer</Option>
                <Option value='Zone'>By Zone</Option>
                <Option value='VLAN'>By VLAN</Option>
                <Option value='Site'>By Site</Option>
                <Option value='Support Group'>Support Group</Option>
              </Select>
              <Button htmlType='submit' >Generate Report</Button>
            </Form>
          </Col>
          <Col offset={ 1 } span={ 18 }>
            <Row style={{ paddingTop: '5px' }}>
              <Col span={ 20 }>
              </Col>
              <Col span={ 4 }>
                <div style={{ float: 'right', padding: '1px'}}>
                  <Space size='large'>
                    {/**<TableOutlined /> 
                    <BarChartOutlined /> 
                    <DownloadOutlined />
                    **/}
                  </Space>
                </div>
              </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                  <div id="reports" ref={reportRef} style={{ background: '#FFFFFF', maxHeight: '90vh', overflowY: 'scroll', minWidth: '800px', padding: '60px 60px', border: '0px solid #E2E2E2'}}>
                    { reportType == 'NetworkSummary' && ( 
                      <style type="text/css" media="print">{"\ @page {\ size: landscape; margin: 0.2cm 1cm 2cm 1cm; \ }\ "}</style>
                    )}
                    <a style={{ fontSize: '12px', float: 'right', marginRight: '20px', paddingTop: '6px' }} onClick={() => {handlePrint()}}><PrinterOutlined/> Print</a>
                    <h1 style={{ marginBottom: '30px' }}>
                      {
                        reportType == 'NetworkSummary' ? `Network Summary` : `Network Utilization by ${ reportType }`
                      }

                    </h1>

                    <div style={{ overflow: 'auto'}}>
                      { reportType == 'NetworkSummary' && ( <NetworkSummary />)}
                      { reportType !== 'NetworkSummary' && (
                      <Table
                        size='large'
                        className="result-table"
                        pagination={ false }
                        columns={[
                          { title: reportType, dataIndex: 'name', key: 'name' },
                          { title: 'Allocated', width: 180, dataIndex: 'allocated', key: 'allocated', align: 'right', render: (allocated) => { return <>{allocated?.toLocaleString() || 0}</>}},
                          { title: 'In Use', width: 180, dataIndex: 'inUse', key: 'inUse', align: 'right', render: (inUse) => { return <>{inUse?.toLocaleString() || 0}</> }},
                          { title: '% In Use', width: 180, dataIndex: 'percentInUse', key: 'percentInUse', align: 'right', render: (percentInUse) => { return <>{ percentInUse || '0.00%'}</>}},
                        ]}
                        dataSource={ data?.getUsage }
                      />
                      )}
                    </div>
                  </div>
                </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </>
  )

}


export default Reports
