import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button, Form } from 'antd';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { TextInput } from '../../components/pants-d';

const userGroup = require("../../entities/user-group");

const UserGroupForm = () => {
  const { id } = useParams();
  const newRecord = id == 'new';


  const history = useHistory();

  const [getUserGroup, { loading, error, data }] = useLazyQuery(userGroup.getQuery, { variables: { id: parseInt(id) } });
  useEffect(() => { if (id && !newRecord) { getUserGroup() } }, [])

  const [createRecord, { data: createData }] = useMutation(userGroup.create, {
    onCompleted: () => { history.push(`/user-groups`) },
    update(cache, { data }) {
      const existingUserGroups = cache.readQuery({ query: userGroup.searchQuery, variables: { search: "", offset: 0, limit: 50 } })

      cache.writeQuery({
        query: userGroup.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          userGroups: {
            count: existingUserGroups.count + 1,
            results: [...(existingUserGroups?.userGroups?.results || []), data.createUserGroup]
          }
        }
      })
    }
  });

  const [updateRecord, { data: updateData }] = useMutation(userGroup.update, { onCompleted: () => { history.push(`/user-groups`) } })

  const saveRecord = (values) => {
    if (newRecord) {
      createRecord({ variables: { ...values } })
    } else {
      updateRecord({ variables: { id: parseInt(id), ...values } })
    }
  }

  const [form] = Form.useForm();

  if (loading) { return 'Loading' }
  return (
    <Form
      style={{ padding: '20px 0px' }}
      layout="vertical"
      form={form}
      name="userGroup"
      initialValues={(data || {})['userGroup']}
      onFinish={(values) => saveRecord(values)} >

      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please input the name' }]}
      >
        <TextInput />
      </Form.Item> 

      <Button type="primary" style={{marginTop: '5px'}} htmlType="submit">
        Submit
      </Button>

    </Form>
  )
}

export default UserGroupForm;
