import React, { useState, useEffect } from 'react';

import { Layout, Breadcrumb, Row, Col, Button, Form, Space, Table, Alert, Select, Radio, Badge, Tag, Dropdown, Menu } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, PlusOutlined, MinusOutlined, BarChartOutlined, ScissorOutlined, UpOutlined, ArrowUpOutlined, ArrowRightOutlined, PlusCircleFilled, DeleteFilled, FilterFilled, CloseOutlined, CheckOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";

import { useLazyQuery, useQuery, useMutation, useApolloClient } from '@apollo/client';

import { TextInput, DateTimeInput, TextArea, RemoveLink } from '../../components/pants-d';

import { Subnav, Actions, Links, NavLink } from '../layout/Subnav.js';
import { SubnetBuilder, NetmaskForm, utils } from '../../components/SubnetBuilder';
import TextEditable from '../../components/Editable';
import ContactEditable from '../../components/editable/ContactEditable';
import DateEditable from '../../components/editable/DateEditable';


const { Address6, Address4 } = require('ip-address');
import cidrRegex from 'cidr-regex';
import SearchSelect from '../../components/pants-d/SearchSelect';
import SearchTable from '../../components/SearchTable';
import SearchForm from '../../components/SearchForm';

const dev = process.env.NODE_ENV == 'development';

const { Sider, Content } = Layout;
const subnet = require('../../entities/subnet')
const plan = require('../../entities/plan')
const project = require('../../entities/project')
const plannedChange = require('../../entities/planned-change')

export const parsedSubnet = (subnet) => {
  if (subnet) {
    if (cidrRegex.v4({exact: true}).test(subnet)) {
      return new Address4(subnet)
    } else {
      return new Address6(subnet)
    }
  }
}

const actions = (record) => { 
  return (
    <Menu>
      <Menu.Item key={ record?.id }><RemoveLink id={ record?.id } mutation={ plan.destroy }/></Menu.Item>
    </Menu>
  )
}


const PlanForm = ({ onClose }) => {

  const [form] = Form.useForm();

  const [createPlan, { loading, data, error }] = useMutation(plan.create, {
    onCompleted: () => { onClose() },
    update(cache, { data }) {

      const payload = {
        query: plan.SEARCH_QUERY,
        variables: {
          search: "",
          offset: 0,
          limit: 50
        }
      }

      const existing = cache.readQuery({ ...payload })

      cache.writeQuery({
        ...payload,
        data: {
          plans: [
            ...existing?.plans?.results,
            data?.createPlan
          ]
        }
      })

    }
  })

  return (
    <div style={{ background: '#FFFFFF', padding: '20px'}}>
      <h3>
        New Change Plan
        <CloseOutlined 
          onClick={() => { onClose() }}
        style={{ float: 'right' }} />
      </h3>
      <Form
        style={{ marginTop: '40px' }}
        layout="vertical"
        form={ form }
        name="plan"
        initialValues={{}}
        onFinish={(values) => {
          createPlan({ variables: {...values}})
        }}>

        <TextInput name='name'/>
        <DateTimeInput name='time'/>
        <TextArea name='description' resize='false'/>

        <Button type="primary" htmlType="submit">
          Submit
        </Button>

      </Form>
    </div>
  )
}

const buildSubnets = (supernet, newSubnet) => {

  const data = { ...supernet }

  if (data.id == newSubnet.id) {

    data['subnets'] == [...data.subnets, subnet]
  } else {
    data?.subnets?.forEach((d, i) => {
      data.subnets[i] = addSubnet(subnet, d)
    })
  }
  return data
}

function PlanView(props) {
  const { id, childId } = useParams();

  const [subnetId, setSubnetId] = useState();
  const [editingNetmask, setEditingNetmask] = useState();
  const [active, setActive] = useState(subnetId);

  const [getPlan, { loadingPlan, error: planError, data: planData, refetch}] = useLazyQuery(plan.GET_QUERY, {variables: { id: parseInt(id) }});
  const [getSubnet, { loadingSubnet, error: subnetError, data: subnetData, refetch: refetchSubnet}] = useLazyQuery(subnet.GET_QUERY);
  const { data: projectData, error: projectError, loading: projectLoading } = useQuery(project.getQuery, { variables: { id: parseInt(id) }})


  const planId = ( childId || projectData?.project?.id )

  useEffect(() => { if (planId ) { 
    getPlan({ variables: { id: parseInt(planId) }}) 
  } }, [planId])

  useEffect(() => { if (subnetId) { 
    getSubnet({ variables: { id: parseInt(subnetId) }}) 
  } }, [subnetId])

  const history= useHistory();

  const [showPlanForm, setShowPlanForm] = useState(false)

  const [updatePlan, { data: updatedPlan, error: planUpdateError }] = useMutation(plan.update, {
    onCompleted: (updatedPlan) => {}
  })

  const [createPlan, { data: createdPlan, error: createPlanError }] = useMutation(plan.create, {
    onCompleted: ({ createPlan }) => { history.push(`/projects/${id}/planner/${createPlan?.id}`)},
    update(cache, { data }) {

      const queryOpts = { query: plan.SEARCH_QUERY, variables: {search: "", offset: 0, limit: 50} }

      const existing = cache.readQuery(queryOpts)

      cache.writeQuery({
        ...queryOpts,
        data: {
          plans: {
            __typename: 'PlanList',
            ...(existing?.data?.plans || []),
            count: (existing?.plans?.count || 0) + 1,
            results: [...(existing?.plans?.results || []), data?.createPlan],
          },
        }

      })

    }
  })

  const [createPlannedChange, { data: createPlanChange, error: plannedChangeError }] = useMutation(plannedChange.create, {
    onCompleted: (createPlannedChange) => {},
    onError: (e) => { console.log(e) },
    update(cache, { data }) {

      const existing = cache.readQuery({ 
        query: plan.GET_QUERY, 
        variables: { id: parseInt(id) }
      })

      cache.writeQuery({
        query: plan.GET_QUERY,
        variables: {id: parseInt(id)},
        data: {
          plan: {
            __typename: 'Plan',
            ...existing?.plan,
            plannedChanges: [
              ...existing?.plan?.plannedChanges,
              data?.createPlannedChange
            ]
          }
        }
      })

      setCreatingPlannedChange(false)
    }
  })

  const [updatePlannedChange, { error: updatePlannedChangeError }] = useMutation(plannedChange.update, {
    onCompleted: (createPlannedChange) => {},
    onError: (e) => { console.log(e) }
  })

  const contacts = [
    { firstName: 'Andrew Joe' },
    { firstName: 'David Colebatch' },
    { firstName: 'Philip Rees' }
  ]

  const [destroySubnet, { data: destroyData, error: destroyError  }] = useMutation(subnet.DESTROY_SUBNET, { 
    onCompleted: (createSubnet) => { 
      history.push(`/plans/${id}/${createSubnet?.createSubnet?.id}`) 
      refetch()
    }, 
    onError: (e) => { console.log(e) },
  });

  const [createSubnet, { data: createData, error: createError  }] = useMutation(subnet.create, { 
    onError: (e) => { console.log(e) },
    update(cache, { data }) {

      if (data?.createSubnet?.supernetId) {
        const queryOpts = { query: subnet.GET_QUERY, variables: {id: parseInt(subnetId) }}

        const existingSubnet = cache.readQuery(queryOpts)

        cache.writeQuery({
            ...queryOpts,
          data: {
            subnet: {
              ...existingSubnet?.data,
              subnets: buildSubnets(existingSubnet?.data, data?.createSubnet)
            }
          }
          }
        )
      } else {
        createPlannedChange({ 
          variables: {
            description: 'Create new subnet',
            destinationId: data?.createSubnet?.id, 
            destinationType: 'subnet',
            destinationNetworkAddress: data?.createSubnet?.networkAddress,
            planId: parseInt(planId)
          }})

        setSubnetId(data?.createSubnet.id)
      }
    }
  });

  const [destroyPlannedChange, { data: destroyPlannedChangeData, error: destroyPlannedChangeError  }] = useMutation(plannedChange.destroy, { 
    onCompleted: (destroyPlannedChange) => { }, 
    update(cache, { data }) { cache.evict(`PlannedChange:${id}`)},
    onError: (e) => { console.log(e) },
  });

  const [creatingPlannedChange, setCreatingPlannedChange] = useState()
  const [creatingSubnet, setCreatingSubnet] = useState()
  const [error, setError] = useState();

  const [form] = Form.useForm()

  if (loadingPlan || !planId) { return(<div />) } 
  else {
    return (
      <Layout>
        <Sider width={400} style={{ padding: '40px 20px', background: '#F6F6F6', borderRight: '2px solid #EAEAEA'}}>
                <Row style={{ marginBottom: '-22px' }}>
                  <Col span={24}>
                    <h2 style={{ display: 'inline-block', marginBottom: '-200px' }}> Plans </h2>
                    { !showPlanForm && (
                    <a style={{float: 'right', fontSize: '12px', borderRadius: '20px', marginTop: '10px' }} onClick={() => { setShowPlanForm(true) }}>
                      New Plan
                    </a> 
                    )}
                  </Col>
                </Row>

              <Row style={{ marginTop: '0px' }}>
                <SearchTable 
                  className="plan-table"
                  data={ showPlanForm ? {results: [{}]} : { results: []}}
                  editable={ true }
                  query={ plan.SEARCH_QUERY }
                  hideAdd
                  hideActions
                  onCreate={([record]) => {
                    createPlan({ variables: {
                      projectId: parseInt(id),
                      name: record?.name
                    }})
                    setShowPlanForm(false);
                  }}
                  onClick={(plan) => { plan?.id ? history.push(`/projects/${id}/planner/${plan?.id}`) : null}}
                  columns={[
                    { 
                      title: '', 
                        dataIndex: 'name',
                        editable: true,
                        renderFn: (record) => {
                          return (
                            <TextInput 
                              hideLabel
                              name="name"
                              value={ record?.name } />
                          )
                        }
                    },
                  ]}
                />
              </Row>
        </Sider>
        { planData && (
        <Content style={{ marginTop: '13px', padding: '80px 32px', minHeight: '800px' }}>
          <Row gutter={ 24 }>
            <Col span={ 16 }>
              <h2 style={{ fontSize: '28px' }}>
                <TextEditable 
                  style={{ 
                    background: 'none', 
                    border: 'none', 
                    fontSize: '22px', 
                    color: '#000000',
                    fontWeight: '400',
                  }} 
                  value={planData?.plan?.name || ''} 
                  placeholder={'Unnamed plan'}
                  onSubmit={(d) => { updatePlan({variables: {
                    projectId: parseInt(id),
                    ...planData?.plan, 
                name: d}})}} />
              </h2>
            </Col>
          </Row>
          <Row gutter={ 24 }>
            <Col span={ 12 }>
              <p>
                <TextEditable 
                  value={ planData?.plan?.description }
                  placeholder="Description"
                  onSubmit={(d) => { 
                updatePlan({variables: {
                  projectId: parseInt(id),
                  ...planData?.plan,
                  description: d}})}} />
              </p>
            </Col>
            <Col span={4}>
              <ContactEditable style={{ float: 'right' }} value={ contacts } />

              {/**
              <Tag icon={<CheckOutlined />} style={{ textAlign: 'top', marginLeft: '5px', marginTop: '10px', float: 'right', padding: '2px 8px'}} color="blue">
                Approved
              </Tag>
                **/}
            </Col>
          </Row>
          <Row style={{ marginTop: '40px' }} gutter={ 24 }>
            <Col span={ 24 }>
              {/**
                <Row>
                  <Radio.Group defaultValue="a" buttonStyle="solid" style={{ marginTop: '0px', marginBottom: '20px', width: '100%' }}> 
                    <Radio.Button style={{ textAlign: 'center', width: '33%'}} value="a">IP Assignments</Radio.Button>
                    <Radio.Button  style={{ textAlign: 'center', width: '33%'}} value="b">Subnets</Radio.Button>
                    <Radio.Button  style={{ textAlign: 'center', width: '33%'}} value="c">Customers</Radio.Button>
                  </Radio.Group>
                </Row>
                **/}
              <Row>
                <Col span={ 24 }>
                  { !creatingSubnet && !creatingPlannedChange && <a style={{ float: 'right', marginRight: '20px'}} onClick={() => { setCreatingSubnet(true) }}> Add New Subnet </a> }
                  { creatingSubnet && !creatingPlannedChange && <a style={{ float: 'right', marginRight: '20px'}} onClick={() => { setCreatingSubnet(false) }}> Cancel </a> }
                  { !creatingPlannedChange && !creatingSubnet && <a style={{ float: 'right', marginRight: '40px' }} onClick={() => { setCreatingPlannedChange(true) }}> Add Planned Change </a> }
                  { creatingPlannedChange && !creatingSubnet && <a style={{ float: 'right', marginRight: '40px' }} onClick={() => { setCreatingPlannedChange(false) }}> Cancel </a> }
                </Col>
              </Row>
              { creatingSubnet && ( 
                <Row>
                  <Col span={ 24 }>
                    { !subnetId && <>

                      { error && <Alert message={ error } type="error"/> }
                      <div class="subnet-block-form" style={{ marginTop: '20px' }}>
                        <Form 
                          layout="vertical"
                          form={ form }
                          onFinish={({ networkAddress }) => {

                            if (!utils.parsedSubnet(networkAddress || '10.0.0.0/24').isValid()) { return setError('Please enter a valid CIDR address') }

                            setError(null)

                            createSubnet({ variables: { networkAddress: networkAddress, planId: parseInt(childId) }}) 
                          }}
                        >
                          <Space direction="horizontal">
                            <TextInput 
                              name="networkAddress" 
                              hideLabel={ true }  
                              placeholder="10.0.0.0/24"
                              style={{ width: '240px', marginTop: '22px', marginRight: '10px' }}/>
                            <Button style={{ marginTop: '0px', marginLeft: '12px', padding: '2px 10px', height: '32px', border: 'none', background: '#F2F2F2', color: '#444444'}} type="primary" htmlType="submit">
                              Create subnet
                            </Button>
                          </Space>
                        </Form>
                      </div>
                    </> }
                    { subnetData &&

                      <>
                        <Row>
                          <Col span={ 24}>
                        <NetmaskForm netmask={ parseInt(subnetData?.subnet.networkAddress.split('/')[1]) } onSelect={(v) => { setEditingNetmask(v) }}/>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={ 24 }>
                            <SubnetBuilder 
                              data={ subnetData?.subnet }
                              onCreate={ createSubnet }
                              onDestroy={ destroySubnet }
                              style={{ marginBottom: '-10px', }} 
                              blockStyle={{ fontSize : '16px' }}
                              editing={ true }
                              editingNetmask={ editingNetmask }
                              showSupernet={ false }
                              height={65}
                              onClick={(s) => setActive( s ) }
                            /> 
                          </Col>
                        </Row>
                      </>
                        }
                  </Col>
                </Row>

              ) }
              <SearchTable 
                data={ creatingPlannedChange ? {results: [{status: 'Draft'}]} : {results: []}}
                normalize={(record) => { return { 
                  ...record,
                  source: { id: record?.sourceId, networkAddress: record?.sourceNetworkAddress }, 
                  destination: { id: record?.destinationId, networkAddress: record?.destinationNetworkAddress }}}}
                editable={ true }
                query={ plannedChange.SEARCH_QUERY }
                filters={{ planId: parseInt(childId)}}
                hideAdd
                onCancel={() => setCreatingPlannedChange(false)}
                onCreate={([record]) => {
                  createPlannedChange({ variables: { 
                    ...record,
                    sourceNetworkAddress: record?.source?.networkAddress,
                    sourceId: record?.source?.id,
                    sourceType: 'Subnet',
                    destinationNetworkAddress: record?.destination?.networkAddress,
                    destinationId: record?.destination?.id,
                    destinationType: 'Subnet',
                    planId: parseInt(planId) }})
                }}
                onSave={([record]) => {
                  updatePlannedChange({ variables: { 
                    ...record,
                    sourceNetworkAddress: record?.source?.networkAddress,
                    sourceId: record?.source?.id,
                    sourceType: 'Subnet',
                    destinationNetworkAddress: record?.destination?.networkAddress,
                    destinationId: record?.destination?.id,
                    destinationType: 'Subnet',
                    planId: parseInt(planId) }})
                }}
                onDestroy={record => { destroyPlannedChange({ variables: { id: record?.id }}) }}
                columns={[
                  { title:  'Status', width: '3.5em', dataIndex: 'status', render: (status) => { return (<Tag 
                    style={{ marginLeft: '2px' }}
                    color={ status == 'Draft' ? 'gray' : 'green'}>{ status || 'Draft'}</Tag>)  }},
                  { title:  'Description', 
                    width: '16em', 
                    dataIndex: 'description',
                    editable: true,
                    renderFn: (record) => { 
                      return (
                        <TextInput 
                          hideLabel
                          name="description"
                          value={ record?.description }
                        />
                      )
                    }},
                  { title: 'Source', 
                    width: '8em', 
                    dataIndex: 'sourceNetworkAddress', 
                    editable: true, 
                    renderFn: (record) => (
                      <>
                        <SearchSelect
                          name="source"
                          query={ subnet.searchQuery }
                          defaultValue={ record?.sourceId ? { id: parseInt(record?.sourceId), networkAddress: record?.sourceNetworkAddress } : {}}
                          resultKey='subnets'
                          displayKey='networkAddress'
                          hideLabel
                        />
                      </>
                      )
                  },
                  { title: '', width: '2.5em', render: () => { return (<ArrowRightOutlined />) } },
                  { title:  'Destination', 
                    editable: true, 
                    width: '8em', 
                    dataIndex: 'destinationNetworkAddress',
                    renderFn: (record) => (
                      <SearchSelect
                        name="destination"
                        defaultValue={{ id: parseInt(record?.destinationId), networkAddress: record?.destinationNetworkAddress }}
                        query={ subnet.searchQuery }
                        resultKey='subnets'
                        displayKey='networkAddress'
                        hideLabel
                      />
                    )
                  },
                ]}
              />
            </Col>
          </Row>
        </Content>
        )}
      </Layout>
    )
  }
};

export default PlanView;
