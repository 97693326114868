import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient, ApolloError } from '@apollo/client';

import { List, Switch, } from 'antd';
const user = require('../../entities/user');
const { CURRENT_USER_QUERY } = require('../../entities/auth');


const Notification = () => {

  const { loading, error, data } = useQuery(CURRENT_USER_QUERY);

  const [updateUser, { data: updateData }] = useMutation(user.update, { onCompleted: (v) => { } });

  const [masterSwitch, setMasterSwitch] = useState(false);
  const [childSwitches, setChildSwitches] = useState({});

  useEffect(() => {
    if (data) {
      const masterSwitchState = isMasterSwitchOn(data?.currentUser?.notificationSettings?.optOut);
      setMasterSwitch(masterSwitchState);
      setChildSwitches(getChildSwitchesState(data?.currentUser?.notificationSettings?.optOut));
    }
  }, [data]);

  const handleSwitchChange = (organization, checked, reportName) => {
    let newNotificationSettings = { ...data.currentUser.notificationSettings };
    if (checked) {
      newNotificationSettings.optOut = newNotificationSettings.optOut
        ? newNotificationSettings.optOut.filter(record => record.organization !== organization.name || record.name !== reportName)
        : [];
    } else {
      newNotificationSettings.optOut = newNotificationSettings.optOut
        ? [...newNotificationSettings.optOut, { organization: organization.name, name: reportName }]
        : [{ organization: organization.name, name: reportName }];
    }
    updateUser({ variables: { id: data.currentUser.id, notificationSettings: newNotificationSettings } });
    setMasterSwitch(isMasterSwitchOn(newNotificationSettings.optOut));
    setChildSwitches(getChildSwitchesState(newNotificationSettings.optOut));
  };

  const handleMasterSwitchChange = (checked) => {
    let newNotificationSettings = { ...data.currentUser.notificationSettings };
    const reportNames = ['Weekly Network Reports', 'Feature Announcements'];

    if (checked) {
      newNotificationSettings.optOut = [];
    } else {
      newNotificationSettings.optOut = reportNames.flatMap(reportName =>
        data?.currentUser?.Organizations.map(organization => ({ organization: organization.name, name: reportName }))
      );
    }

    updateUser({ variables: { id: data.currentUser.id, notificationSettings: newNotificationSettings } });
    setMasterSwitch(checked);
    setChildSwitches(getChildSwitchesState(newNotificationSettings.optOut));
  };

  const isMasterSwitchOn = (optOut) => {
    const reportNames = ['Weekly Network Reports', 'Feature Announcements'];
    return reportNames.some(reportName => {
      return data?.currentUser?.Organizations.some(organization => {
        return !(optOut && optOut.some(record => record.name === reportName && record.organization === organization.name));
      });
    });
  };

  const getChildSwitchesState = (optOut) => {
    const switches = {};
    const reportNames = ['Weekly Network Reports', 'Feature Announcements'];
    reportNames.forEach(reportName => {
      data?.currentUser?.Organizations.forEach(organization => {
        const isOptedOut = optOut && optOut.some(record => record.name === reportName && record.organization === organization.name);
        switches[`${organization.name}-${reportName}`] = !isOptedOut;
      });
    });
    return switches;
  };

  return (
    <>
      <h2>My Notifications</h2>
      <p style={{ marginBottom: '30px' }}>
        Here you can modify your notification settings.
      </p>
      <div style={{ maxWidth: '600px' }}>
        <List.Item style={{ backgroundColor: '#f0f2f5', padding: '20px', borderRadius: '5px' }}> {/* Add a background color and padding */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}> {/* Add a flex container */}
            <h3>All Notifications</h3> {/* Move the title out of the Meta component */}
            <Switch
              checked={masterSwitch}
              onChange={handleMasterSwitchChange}
              size="large" // Increase the size of the switch
              style={{ marginRight: '-8px' }} // Add a negative margin to the right
            />
          </div>
        </List.Item>
        <List
          itemLayout="horizontal"
          dataSource={data?.currentUser?.Organizations}
          renderItem={organization => {
            const reportNames = ['Weekly Network Reports', 'Feature Announcements'];
            return reportNames.map(reportName => {
              return (
                <List.Item>
                  <List.Item.Meta
                    title={reportName}
                    description={`Organization - ${organization.name}`}
                  />
                  <Switch checked={childSwitches[`${organization.name}-${reportName}`]} onChange={(checked) => handleSwitchChange(organization, checked, reportName)} />
                </List.Item>
              );
            })
          }}
        />
      </div>
    </>
  )
}

export default Notification;
