import React, { useState } from 'react';
import { Row, Col, Radio } from 'antd';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';
import RemoveRecordButton from '../lib/threeDotRemoveButton';

const EntityHeader = ({ options, baseUrl, children, entity, destroyMutation, record }) => {
    const history = useHistory();
    const match = useRouteMatch();

    const [selectedOption, setSelectedOption] = useState('summary');

    const handleRadioChange = (e) => {
        const value = e.target.value === 'summary' ? '' : e.target.value;
        history.push(`${baseUrl}/${value}`);
        setSelectedOption(e.target.value);
    };

    return (
        <div className="subnet-header" style={{ height: '90px', marginBottom: '15px', }}>
            <Row>
                <Col span={24}>
                    <Row>
                        <h2 style={{ background: '#fdfcfd', letterSpacing: '0.05em', marginLeft: '0px', marginRight: '8px', marginBottom: '0px' }}>
                            {entity?.name}
                        </h2>
                        <RemoveRecordButton
                            record={record}
                            mutation={destroyMutation}
                            mutationVariables={{ id: record?.id }}
                            menuItems={["remove"]}
                            entityName={entity?.__typename}
                            entityTitle={entity?.__typename}
                            redirectPath={`/${entity?.__typename.toLowerCase()}s`}
                        />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Radio.Group
                                value={selectedOption}
                                onChange={handleRadioChange}
                                style={{ marginLeft: '2px', padding: '2px' }}>
                                {options.map(option => (
                                    <Radio.Button
                                        key={option.value}
                                        size='small'
                                        style={{
                                            ...option.style,
                                            backgroundColor: selectedOption === option.value ? '#427aa9' : 'inherit',
                                            color: selectedOption === option.value ? '#fff' : 'inherit',
                                            border: selectedOption === option.value ? '1px solid #427aa9' : 'inherit',
                                        }}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </Col>
                    </Row>

                    <Switch>
                        {options.map(option => (
                            <Route key={option.value} path={`${match.path}/${option.value}`}>
                                {option.component}
                            </Route>
                        ))}
                        <Route path={match.path}>
                            {children}
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </ div>
    );
};

export default EntityHeader;
