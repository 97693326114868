import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Drawer, Alert, Select } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { SearchSelect } from '../../components/pants-d';
const uuidAPIKey = require('uuid-apikey');
import Entity from '../../entities'

const alertRule = require("../../entities/alert-rule");
const subnet = Entity['subnet']
const alertDestination = require("../../entities/alert-destination");

const AlertRuleForm = ({ refetch, id, onClose }) => {
    const history = useHistory();
    const { Option } = Select;

    const [selectedSubnetData, setSelectedSubnetData] = useState(null)
    const [selectedAlertDestination, setSelectedAlertDestination] = useState(null)
    const newRecord = id == 'new'

    const { loading, data: subnetData } = useQuery(subnet.searchQuery, { variables: { search: '', offset: 0, limit: 50 } })
    const { data: alertDestinationData } = useQuery(alertDestination.searchQuery, { variables: { search: '', offset: 0, limit: 50 } })

    const { loading: alertRuleLoading, error: recordError, data } = useQuery(alertRule.getQuery, {
        variables: { id: parseInt(id) },
        skip: newRecord
    });

    const defaultDestinations = data?.alertRule?.AlertDestinations?.map(destination => destination.id);
    const initialSubnetId = data?.alertRule?.SubnetId;

    const { loading: singleSubnetDataLoading, data: singleSubnetData } = useQuery(subnet.GET_QUERY, {
        variables: { id: parseInt(initialSubnetId) },
        skip: !initialSubnetId || newRecord
    })

    const statuses = ['Active', 'Inactive']
    const requestTypes = ['Network Requests', "Network Capacity", "NMAP Scan Results"]

    const [form] = Form.useForm();

    const [createAlertRule, response] = useMutation(alertRule.create, {
        onCompleted: (v) => { },
        update(cache, { data }) {
            const existingAlertRule = cache.readQuery({ query: alertRule.searchQuery, variables: { search: "", offset: 0, limit: 50 } })

            cache.writeQuery({
                query: alertRule.searchQuery,
                variables: { search: "", offset: 0, limit: 50 },
                data: {
                    alertRules: {
                        count: (existingAlertRule?.count || 0) + 1,
                        results: [...(existingAlertRule?.alertRules?.results || []), data.createAlertRule]
                    }
                }
            })
        }
    });

    const [updateAlertRule, { data: updateData }] = useMutation(alertRule.update, {
        onCompleted: () => { history.push(`/settings/alerting/alert-rules`) }, onError: e => {
            setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
            console.log(e)
        },
        refetchQueries: [{ query: alertRule.getQuery, variables: { id: parseInt(id) } }]
    })


    const handleCloseForm = () => {
        history.push(`/settings/alerting/alert-rules`)
        onClose();
    };

    const handleSubmit = (values) => {
        const variables = {
            alerts:
            {
                SubnetId: newRecord ? values?.networkAddress : initialSubnetId,
                ZoneId: newRecord ? selectedSubnetData?.zone?.id : singleSubnetData?.subnet?.zone?.id,
                networkAddress: newRecord ? selectedSubnetData?.networkAddress : values?.networkAddress,
                status: values?.status,
                type: values?.type,
                name: values?.name,
                description: values?.description,
                conditions: values?.conditions,
            },
            alertDestinationIds: values?.destination
        }
        if (newRecord) {
            createAlertRule({ variables: variables })
                .then(() => {
                    handleCloseForm();
                })
                .catch((error) => {
                    console.error("Add Alert Rule Mutation error:", error);
                });
        } else {
            updateAlertRule({ variables: { id: parseInt(id), ...variables } })
                .then(() => {
                    handleCloseForm();
                })
                .catch((error) => {
                    console.error("Update Alert Rule Mutation error:", error);
                });
        }
    };
    if (alertRuleLoading) { return <></> }
    return (

        <Form
            layout="vertical"
            name="alertRuleForm"
            initialValues={{ type: 'Network Requests', status: 'Active', networkAddress: initialSubnetId, ...data?.['alertRule'] }}
            onFinish={(values) => {
                handleSubmit(values)
            }}
        >
            <Form.Item
                name="type"
                label="Type"
            >
                <Select placeholder="Select a type">
                    {requestTypes?.map(types => (
                        <Select.Option key={types} value={types}>{types}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: 'Please select the status' }]}
            >
                <Select placeholder="Select a status">
                    {statuses?.map(status => (
                        <Select.Option key={status} value={status}>{status}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="name"
                label="Title"
                rules={[{ required: true, message: 'Please input the title' }]}
            >
                <Input placeholder="Enter the title" />
            </Form.Item>
            <Form.Item
                label='Subnet'
                name='networkAddress'
                rules={[{ required: true, message: 'Please select the subnet' }]}
            >
                <Select placeholder="Select a subnet"
                    onChange={value => {
                        const subnet = subnetData?.subnets?.results.find(subnet => subnet.id === value);
                        setSelectedSubnetData(subnet);
                    }}>
                    {subnetData?.subnets?.results.map(subnet => {
                        return (
                            <Option key={subnet?.id} value={subnet?.id}>
                                {subnet?.networkAddress}{subnet?.name ? ` - ${subnet?.name}` : ''}
                            </Option>
                        );
                    })}
                </Select>

            </Form.Item>
            <Form.Item
                label='Destination'
                name='destination'
                initialValue={defaultDestinations}
                rules={[{ required: true, message: 'Please select the destination' }]}
            >
                <Select placeholder="Select a destination"
                    mode="multiple"
                    name="alertDestination"
                    onChange={value => {
                        const alertDestination = alertDestinationData?.alertDestinations?.results.find(alertDestination => alertDestination.id === value);
                        setSelectedAlertDestination(alertDestination);
                    }}>
                    {alertDestinationData?.alertDestinations?.results.length > 0 ? (
                        alertDestinationData?.alertDestinations?.results.map(alertDestination => {
                            return (
                                <Option key={alertDestination?.id} value={alertDestination?.id}>{alertDestination?.name}</Option>
                            );
                        })
                    ) : (
                        <Option disabled>No destinations available. Please add a destination to proceed.</Option>
                    )}
                </Select>

            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
            >
                <Input placeholder="Enter the description" />
            </Form.Item>

            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form>
    )
}

export default AlertRuleForm;
