import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Alert } from 'antd';
import RemoveLink from '../../components/RemoveLink';

import { useParams } from 'react-router-dom';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')
const importRecords = loader('./mutations/import.graphql')

const ADD_CUSTOMERS_TO_SUBNET = loader('./mutations/ADD_CUSTOMERS_TO_SUBNET.graphql')
const REMOVE_CUSTOMER_FROM_SUBNET = loader('./mutations/REMOVE_CUSTOMER_FROM_SUBNET.graphql')
const subnet = require("../../entities/subnet");

const RemoveCustomerLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Customer"
        entityTitle="customer"
        redirectPath={`/customers`}
      />
    </div>

  )
}

const RemoveSubnetCustomerLink = ({ record, customerId, customerName }) => {

  const { subnetId, supernetId } = useParams();

  const { loading, error:dataError, data:subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={REMOVE_CUSTOMER_FROM_SUBNET}
        mutationVariables={{customerId: parseInt(customerId), subnetId: parseInt(subnetId || supernetId), customerName: customerName, networkAddress: subnetData.subnet?.networkAddress, zoneId: subnetData.subnet?.zone?.id}}
        menuItems={["remove"]} 
        entityName="Customer"
        entityTitle="customer"
        redirectPath={`/subnets/${supernetId}/${subnetId || supernetId}/customers`}
      />
    </div>

  )
}

const Address = ({ address }) => {
  const fields = _.pick(address, ['line1', 'line2', 'municipality', 'province', 'country', 'postalCode']);

  const fullAddress = _.compact(Object.values(fields)).join(', ')

  return (
    <> {fullAddress} </>
  )
}

const search = {
  query: searchQuery,
  columns: [
    { title: 'Name', key: 'name', dataIndex: 'name', sorter: true },
    { title: 'Address', key: 'name', render: customer => <Address address={customer?.addresses?.[0]} /> },
    { title: '', width: 100, key: 'remove', render: customer => <RemoveCustomerLink record={ customer }/> }
  ]
}

const subnetSearch = {
  columns: [
    { title: 'Name', sorter: true, render: customer => ( 
      <>
        {customer?.name ? (
          <a
          href={`/customers/${customer?.id}`} 
          onClick={event => event.stopPropagation()}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {customer?.name}
          </a>
        ) : null}
      </>
    )},
    { title: '', width: 100, key: 'remove', render: u => <RemoveSubnetCustomerLink record={u} customerId={u.id} customerName={u.name} /> }
  ]
}

const importer = {
  search: {
    query: searchQuery,
    columns: [
      { title: 'Name', key: 'name', dataIndex: 'name' },
      { title: 'Address', key: 'name', render: customer => <Address address={customer?.addresses?.[0]} /> },
      { title: '', width: 100, key: 'remove', render: customer => <RemoveLink record={customer} mutation={ destroy } /> }
    ]
  },
  exampleSheet: 'CustomersExample',
  mutation: importRecords,
  fields: [
    { name: 'name', required: true }
  ],
  title: 'Customers',
  copy: 'Customers in Lightmesh are any group that is able to use your parts of your network.  Examples might be a division, group, agency, or company.  They can be a great place to start with network design, cost estimation, and support.',
  note: (
    <Alert
      style={{ marginTop: '10px' }}
      message={
        <div dangerouslySetInnerHTML={{ __html: "<strong>Name</strong> is a required field." }} />
      }
      type="info"
      showIcon
    />
  )
}


export { ADD_CUSTOMERS_TO_SUBNET, search, subnetSearch, getQuery, searchQuery, create, destroy, update, importer };
