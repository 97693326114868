import React from 'react'
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import RemoveLink from '../../components/RemoveLink';

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')

const removeRole = loader('./mutations/destroy.graphql')

const readOnlyRoles = ['Owner', 'Administrator', 'Reader', 'Contributor'];

const search = {
  columns: [
    { title: 'Name', dataIndex: 'name', sorter: true, width: '220px' },
    { title: 'Description', dataIndex: 'description', sorter: true, width: '800px' },

    {
      title: 'Type',
      dataIndex: 'name', // Use the 'name' field from the record
      width: '200px',
      key: 'type',
      render: (name) => {
        // Check if the role is a built-in role based on the 'name' field
        if (readOnlyRoles.includes(name)) {
          return <span>Built-in Role</span>;
        }
        return <span>Custom Role</span>;
      },
    },
    {
      title: '', width: '80px', key: 'remove',
      render: (role) => {
        // Checking if the role is a custom role based on the 'name' field
        if (!readOnlyRoles.includes(role.name)) {
          return <RemoveLink record={role} mutation={ removeRole } />;
        }
        return null;
      },
    }
  ],
};


const form = {
  fields: [
    ["name", {}],
  ]
}

export { search, form, searchQuery, getQuery, create, update, destroy, readOnlyRoles };
