import { loader } from 'graphql.macro';
import RemoveLink from '../../components/RemoveLink';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  


const search = {
  columns:  [
    { title: 'Created at', dataIndex: 'createdAt', width: '240px', key: 'status', sorter: true, render: (createdAt) => { 

      const d = new Date(parseInt(createdAt))

      return (<p>{ d.toLocaleDateString() + ' ' + d.toLocaleTimeString() }</p>)

    }},
    { title: 'Details', dataIndex: 'details', key: 'details', sorter: true },
    { title: '# Hosts', dataIndex: 'ipAddressCount', key: 'ipAddressCount', sorter: true },
    { title: 'Status', dataIndex: 'status', width: '200px', key: 'status', sorter: true, render: (status) => { return (status || 'Submitted')}},
  ]
}

const importer = {
  title: 'Network Requests',
  copy: 'Network requests can be submitted by users with the Requester role.  IP addresses will be reserved until the requests have been reviewed. Upon approval requests will be converted to static IP assignments.',
}

export { search, searchQuery, getQuery, create, update, destroy, importer };

