import React, { useState } from 'react';
import {  Form, Select } from 'antd';
const { Option } = Select;

import { startCase } from "lodash";

class PantSelect extends React.Component {

  render () {
    const { name, required, hideLabel=false, ...props } = this.props;

    return (
      <Form.Item
        label={ hideLabel ? null : (props.label || startCase(name)) }
        name={ name }
        autoComplete="off"
        rules={[{ required: required, message: `${props.label || startCase(name)} is required.` }]}>
        <Select { ...props } >
          { props.children }
        </Select>
      </Form.Item>
    )
  }
}

PantSelect.Option = Option;

export default PantSelect;
