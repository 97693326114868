import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input, Drawer, Alert } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect } from '../../components/pants-d';

const user = require("../../entities/user");
const role = require("../../entities/role");

const roleDescriptions = { 
  Owner: 'Owners will have full access to all data in Lightmesh, including the ability to assign or revoke access for all users',
  Administrator: 'Administrators will have full access to all data Lightmesh, including the ability to assign or revoke access, with the exception of other administrators or owners',
  Contributor: 'Contributors will have full access to manage the data in Lightmesh, but is not able to assign or revoke access',
  Reader: 'Readers will have read access to Lightmesh, but is not able to update or manage access',
  Requestor: 'Has access to a request portal separate from the Lightmesh app and can submit network requests on selected network spaces'
}

const UserForm = ({ refetch, id }) => {
  const history = useHistory();

  const { model } = useParams();
  const newRecord = id == 'new'
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);


  const [getUser, { loading, error: recordError, data }] = useLazyQuery(user.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => { if ( id && !newRecord ) { getUser({ variables: { id: parseInt(id) }}) } },[])

  const [createRecord, { data: createData, error: createErrors }] = useMutation(user.create, {
    onCompleted: () => { history.push(`/users`) },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
    },
    update(cache, { data }) { 
      const existingUsers = cache.readQuery({ query: user.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: user.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          users: {
            count: existingUsers.count + 1,
            results: [...existingUsers.users.results, data.createUser]
          }
        }
      })
    }});

  const [updateRecord, { data: updateData }] = useMutation(user.update, { onCompleted: () => { 
    history.push(`/users`) 
    refetch();
  },
onError: e => {
    setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
    console.log(e)
  }})

  const [form] = Form.useForm();

  const roleValue = Form.useWatch('role', form)

  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  if (loading) { return <></> }
  return (

    <Drawer
      title={newRecord ? `Add user` : `Update user`}
      width={520}
      closable={false}
      visible={true}
      onClose={() => { history.push('/users')}}>
      <Form
        layout="vertical"
        form={form}
        name="user"
        initialValues={data?.['user'] || {}}
        onFinish={ (values) => {
          newRecord ? 
            createRecord({ variables: {...values, role: values?.role?.name }}) : 
            updateRecord({ variables: {id: parseInt(id), ...values, role: values?.role?.name}}) 
        }} >

         <div ref={errorContainerRef}>
            {(errors?.length > 0) && (
              <>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} type="error" message={errorMessage} />
                ))}
                <br />
              </>
            )}
          </div>
        <Row gutter={ 24 }>
          <Col span={ 12 }>
            <TextInput required name="firstName" />
          </Col>

          <Col span={ 12 }>
            <TextInput required name="lastName" />
          </Col>
        </Row>

        <TextInput required name="email" />
        <SearchSelect
          name="role"
          label="App Role"
          model="roles"
          query={ role.searchQuery }
          defaultValue={{ 
            name: data?.role,
            id: data?.roleAssignment?.role?.id }}
          resultKey='roles'
          displayKey='name'
          required
        />

        { roleDescriptions[roleValue?.name] }

        {/** newRecord && ( <TextArea name="message" /> )**/}

        <Row style={{ marginTop: '40px' }} gutter={ 24 }>
          <Col span={ 24 }>
            <Button onClick={(e) => { history.push('/users') }} type="primary" style={{ width: '80px', float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>

            <Button type="primary" style={{ width: '80px', float: 'right' }} htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>

      </Form>
    </Drawer>
  )
}

export default UserForm;
