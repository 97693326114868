import React from 'react'
import { loader } from 'graphql.macro';

const getMeasurement = loader('./queries/getMeasurement.graphql')
const getTimeSeries = loader('./queries/getTimeSeries.graphql')
const getTabulation = loader('./queries/getTabulation.graphql')
const getUsage = loader('./queries/getUsage.graphql')

export { getTimeSeries, getTabulation, getMeasurement, getUsage };

