import React, { useState, useEffect } from 'react';

import { useParams } from "react-router-dom";
import RecordLayout from '../layout/Record';
import SideLayout from '../layout/SideLayout';
import AlertDestination from './AlertDestination';
import AlertRule from './AlertRule';

const Alerting = ({ }) => {
  const { id, section } = useParams();
  const newRecord = id == 'new'

  return (
    <RecordLayout section="Alerting" >
      <div> 
      <SideLayout
        links={[
          { url: '/settings/alerting/alert-destinations', title: 'Alert Destinations' },
          { url: '/settings/alerting/alert-rules', title: 'Alert Rules' },
        ]}>
          { section == 'alert-destinations' &&  <AlertDestination />}
          {(!section || section == 'alert-rules') && <AlertRule />}
      </SideLayout>
      </div>
    </RecordLayout>
  )
};

export default Alerting;
