import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from "react-router-dom";
import SearchAction from '../../components/SearchAction';
import { PlusOutlined } from "@ant-design/icons";
import AlertDestinationForm from '../alerting/AlertDestinationForm';
import SearchTable from '../../components/SearchTable';
import { Drawer } from 'antd';

const alertDestination = require('../../entities/alert-destination');


const AlertDestination = ({ }) => {

  const history = useHistory();
  const { id: paramId } = useParams();

  const [id, setId] = useState(paramId === 'new')
  const [newRecord, setNewRecord] = useState(paramId === 'new'); 

  const [refetch, setRefetch] = useState()

  const [showAlertDestinationForm, setShowAlertDestinationForm] = useState(false);

  const handleAddAlertDestinationClick = () => {
    history.push('/settings/alerting/alert-destinations/new');
    setId("new");
    setNewRecord(true);
    setShowAlertDestinationForm(true);
  };


  const handleCloseAlertDestinationClick = () => {
    history.push('/settings/alerting/alert-destinations');
    setShowAlertDestinationForm(false);
  };

  const handleAlertDestinationClick = (record) => {

    history.push(`/settings/alerting/alert-destinations/${record?.id}`);
    setNewRecord(false);
    setId(record?.id);
    setShowAlertDestinationForm(true);
  };

  const { search } = alertDestination.SearchComponent();

  return (
    <>
      <h2>Alert Destinations</h2>
      <h1>
        <SearchAction
          icon={<PlusOutlined />}
          url='/settings/alerting/alert-destinations/new'
          onClick={handleAddAlertDestinationClick}
          style={{ marginBottom: '20px', marginTop: '0px' }}>
          Add Alert Destination
        </SearchAction>
      </h1>
      <SearchTable
        query={alertDestination.searchQuery}
        {...search}
        refetch={refetch}
        onAdd={() => {
          history.push("/settings/alerting/alert-destinations/new");
        }}
        onClick={handleAlertDestinationClick}
        modelName='alert destination'
      />
      {showAlertDestinationForm && (
        <Drawer
          title={newRecord ? `Add Destination` : `Update Destination`}
          width={520}
          closable={false}
          visible={true}
          onClose={handleCloseAlertDestinationClick}
        >
          <AlertDestinationForm id={id}
            onClose={handleCloseAlertDestinationClick}
          />
        </Drawer>
      )}
    </>
  )
};

export default AlertDestination;
