const { Address6, Address4 } = require('ip-address');

const getBroadcast = (networkAddress) => {
  const v4 = new Address4(networkAddress).isValid()

  const address = v4 ? new Address4(networkAddress) : new Address6(networkAddress)

  return v4 ? address.endAddress().address : address.endAddress().correctForm()
}

export { getBroadcast }
