import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input, Col, AutoComplete, Alert } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput } from '../../components/pants-d';

const { CustomAttributes } = require('../../components/pants-d');

const dnsEntry = require("../../entities/dns-entry");

const DNSEntryForm = () => {
  const { model, id } = useParams();
  
  const history = useHistory();

  const [recordType, setRecordType] = useState('');
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const handleRecordTypeChange = (value) => {
    setRecordType(value);
  };
  
  const handleRecordTypeSelect = (value) => {
    setRecordType(value);
  };
  

  const dnsTypeOptions = [
    { value: 'A' },
    { value: 'AAAA' },
    { value: 'CNAME' },
    { value: 'NS' },
    { value: 'MX' },
    { value: 'TXT' },
  ];

  const newRecord = id == 'new'
  const [getDNSEntry, { loading, error: recordError, data }] = useLazyQuery(dnsEntry.getQuery, { variables: { id: parseInt(id) }});
  useEffect(() => { if (!newRecord) { getDNSEntry({ variables: {id: parseInt(id)}})}}, [id])


  const [createRecord, { data: createData, error: createErrors }] = useMutation(dnsEntry.create, {
    onCompleted: () => { history.push(`/dns`) },
    onError: e => {
      setErrors(e?.graphQLErrors?.map(error => error.message) || [e.message]);
    },
    update(cache, { data }) {
      const existingDNSEntrys = cache.readQuery({ query: dnsEntry.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: dnsEntry.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          dnsEntries: {
            count: (existingDNSEntrys?.count || 0) + 1,
            results: [...(existingDNSEntrys?.dnsEntries?.results || []), data.createDNSEntry]
          }
        }
      })
    }});

  const [updateDNSEntry, { data: updateData }] = useMutation(dnsEntry.update, { onCompleted: () => { history.push(`/dns`) },     onError: e => {
    setErrors(
      e?.graphQLErrors.map(error => {
        if (error.extensions?.exception?.errors?.[0]?.path) {
          return {
            message: error.extensions?.exception?.errors?.[0]?.message.replace('Validation error: ', '')
          };
        }
        return null;
      }) || [{ message: e.message }]
    );
  }} )

  const [form] = Form.useForm();

  useEffect(() => { form.setFieldsValue(data?.['dnsEntry']) }, [data?.['dnsEntry']])

  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  if (loading) { return <></> }
  return (
    <Form
      key={ id }
      layout="vertical"
      form={form}
      name="dnsEntry"
      initialValues={data?.['dnsEntry'] || {}}
      onFinish={ (values) => {
        newRecord ? createRecord({ variables: {...values, ttl: parseInt(values?.ttl)}}) : updateDNSEntry({ variables: {id: parseInt(id), ...values, ttl: parseInt(values?.ttl)}}) 
      }} >
      <div ref={errorContainerRef} style={{ marginBottom: '10px' }}>
        {errors && errors.length > 0 && (
          <Alert type="error" message={errors} />
        )}
      </div>
      <Row style={{marginBottom: '10px'}} >
        <Col span={ 24} >
          <table className="dns-table" style={{ width: '100%', textAlign: 'left', marginBottom: '20px'}}>
            <tbody>
              <tr>
                <td>
                  <Form.Item
                    name="recordType"
                    label={<strong>Type</strong>}
                    style={{ marginBottom: "0px", marginRight: "10px" }}
                    rules={[{ required: true, message: 'Please select the record type' }]}
                  >
                    <AutoComplete
                      size="small"
                      style={{ width: "150px" }}
                      value={recordType}
                      onChange={handleRecordTypeChange}
                      onSelect={handleRecordTypeSelect}
                      placeholder="Select or enter"
                      allowClear={ false }
                      options={dnsTypeOptions}
                    />
                  </Form.Item>
                </td>
                <td>
                <Form.Item
                    name="name"
                    label={<strong>Value</strong>}
                    style={{ marginBottom: "0px", marginRight: "10px" }}
                    rules={[{ required: true, message: 'Please input the value' }]}
                  >
                    <TextInput
                      size="small"
                      allowClear={false}
                      style={{ width: "200px", marginBottom: "0px" }}
                    />
                </Form.Item>
                </td>
                <td>
                <Form.Item
                    name="ttl"
                    label={<strong>TTL</strong>}
                    style={{ marginBottom: "0px", marginRight: "10px" }}
                    rules={[{ required: true, message: 'Please input the TTL' }]}
                  >
                    <TextInput
                      type="number"
                      size="small"
                      allowClear={false}
                      style={{ width: "65px", marginBottom: "0px" }}
                    />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <CustomAttributes modelName='DNSEntry' />

      <Row>
        <Col span={ 4 } offset={ 20 }>
          <Button style={{ float: 'right' }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default DNSEntryForm;
