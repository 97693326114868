import React from 'react';
import { Table } from 'antd';
import { useQuery, useReactiveVar } from '@apollo/client'; import _ from "lodash";

function DataTable({ 
  query, 
  variables,
  data,
  ...props
}) {


  const { data: response, refetch } = useQuery(query, {
    variables: { ...variables },
  });

  const results = response ? Object.values(response)?.[0] : []

  return (
    <Table 
      className='data-table'
      style={{ marginTop: '0px' }}
      dataSource={ results } 
      pagination={ false }
      { ...props }
    />
  )
}

export default DataTable;
