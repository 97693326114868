import React, { useState, useEffect } from 'react';


const Dev = ({ children }) => {
  if (process.env.NODE_ENV !== 'development') { 
    return (<></>) 
  } else {
    return (
      <>
        { children }
      </>
    )
  }
}

export default Dev;
