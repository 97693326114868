import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, Drawer, Button, List, Switch } from 'antd';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')  

const allUsers = loader('./queries/allUsers.graphql')


const SEARCH_IDENTITIES = loader('./queries/searchIdentities.graphql')  
const getQuery = loader('./queries/get.graphql')  

const adminUserGet = loader('./queries/adminUserGet.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const adminUserUpdate = loader('./mutations/adminUserUpdate.graphql')  
const destroyUserOrganization = loader('./mutations/destroyUserOrganization.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const removeUser = loader('./mutations/remove.graphql')

const sendTidalAdminEmail = loader('./mutations/sendEmail.graphql')  


const RemoveUserLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={removeUser}
        mutationVariables={{email: record?.email, userId: record?.User?.id}}
        menuItems={["remove"]} 
        entityName="user"
        entityTitle="user"
        redirectPath={`/users`}
      />
    </div>

  )
}

const DestroyLink = ({ user }) => {
  const [destroyOrganization, { data: destroyData }] = useMutation(destroy, { onCompleted: (user) => { console.log(user) }});


  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popconfirm
        title="ARE YOU SURE you want to delete this user???"
        onConfirm={() => destroyOrganization({ variables: { id: parseInt(user?.id)}})}
        onCancel={() => { console.log('cancelled')}}
        okText="Yes... muahahahahahahahah"
        cancelText="No.  Oopsies">
        <a href="#"> Destroy </a>
      </Popconfirm>
    </div>
  )
}

const RemoveLink = ({ email, userId }) => {
  const [error, setError] = useState(null); 

  const [removeRecord, { loading }] = useMutation(removeUser, {
    onError: (error) => {
      setError(error.message);
    },
    onCompleted: () => {
      setError(null);
    },
    update(cache) {
      cache.evict(`UserOrganization:${userId}`);
    }

  });

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popconfirm
        title="Are you sure you want to remove this user?"
        onConfirm={(e) => {
          setError(null); 
          removeRecord({ variables: { 
            email: email,
            userId: userId
          } });
        }}
      >
        <a>Remove</a>
      </Popconfirm>
      {error && <Alert type="error" message={error} />}
    </div>
  );
};

const ManageNotificationsLink = ({ user }) => {
  const [notificationsDrawerVisible, setNotificationsDrawerVisible] = useState(false);

  const showNotificationsDrawer = () => {
    setNotificationsDrawerVisible(true);
  };

  const closeNotificationsDrawer = () => {
    setNotificationsDrawerVisible(false);

  };
  const [updateUser, { data: updateData }] = useMutation(update, { onCompleted: (v) => { } });

  const [masterSwitch, setMasterSwitch] = useState(false);
  const [childSwitches, setChildSwitches] = useState({});



  useEffect(() => {
    if (user) {
      const masterSwitchState = isMasterSwitchOn(user?.notificationSettings?.optOut);
      setMasterSwitch(masterSwitchState);
      setChildSwitches(getChildSwitchesState(user?.notificationSettings?.optOut));
    }
  }, [user]);


  const handleSwitchChange = (organization, checked, reportName) => {
    let newNotificationSettings = { ...user?.notificationSettings };

    if (checked) {
      newNotificationSettings.optOut = newNotificationSettings.optOut
        ? newNotificationSettings.optOut.filter(record => record.organization !== organization.name || record.name !== reportName)
        : [];
    } else {
      newNotificationSettings.optOut = newNotificationSettings.optOut
        ? [...newNotificationSettings.optOut, { organization: organization.name, name: reportName }]
        : [{ organization: organization.name, name: reportName }];
    }

    updateUser({ variables: { id: user?.id, notificationSettings: newNotificationSettings } });
    setMasterSwitch(isMasterSwitchOn(newNotificationSettings.optOut));
    setChildSwitches(getChildSwitchesState(newNotificationSettings.optOut));
  };

  const handleMasterSwitchChange = (checked) => {
    let newNotificationSettings = { ...user?.notificationSettings };
    const reportNames = ['Weekly Network Reports', 'Feature Announcements'];

    if (checked) {
      newNotificationSettings.optOut = [];
    } else {
      newNotificationSettings.optOut = reportNames.flatMap(reportName =>
        user?.Organizations.map(organization => ({ organization: organization.name, name: reportName }))
      );
    }

    updateUser({ variables: { id: user?.id, notificationSettings: newNotificationSettings } });
    setMasterSwitch(checked);
    setChildSwitches(getChildSwitchesState(newNotificationSettings.optOut));
  };

  const isMasterSwitchOn = (optOut) => {
    const reportNames = ['Weekly Network Reports', 'Feature Announcements'];
    return reportNames.some(reportName => {
      return user?.Organizations.some(organization => {
        return !(optOut && optOut.some(record => record.name === reportName && record.organization === organization.name));
      });
    });
  };

  const getChildSwitchesState = (optOut) => {
    const switches = {};
    const reportNames = ['Weekly Network Reports', 'Feature Announcements'];
    reportNames.forEach(reportName => {
      user?.Organizations.forEach(organization => {
        const isOptedOut = optOut && optOut.some(record => record.name === reportName && record.organization === organization.name);
        switches[`${organization.name}-${reportName}`] = !isOptedOut;
      });
    });
    return switches;
  };


  return (
    <div>

      <a href="#" onClick={(e) => { e.stopPropagation(); showNotificationsDrawer(); }} > Manage Notifications </a>
      <Drawer
        title={`Manage Notifications for ${user?.email}`}
        placement="right"
        closable={false}
        onClose={(e) => { e.stopPropagation(); closeNotificationsDrawer(); }}
        visible={notificationsDrawerVisible}
      >
        <div style={{ maxWidth: '600px' }} onClick={(e) => e.stopPropagation()}>
          <List.Item style={{ backgroundColor: '#f0f2f5', padding: '20px', borderRadius: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <h3>All Notifications</h3>
              <Switch
                checked={masterSwitch}
                onChange={handleMasterSwitchChange}
                size="large"
                style={{ marginRight: '-8px' }}
              />
            </div>
          </List.Item>
          <List
            itemLayout="horizontal"
            dataSource={user?.Organizations}
            renderItem={organization => {
              const reportNames = ['Weekly Network Reports', 'Feature Announcements'];

              return reportNames.map(reportName => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={reportName}
                      description={`Organization - ${organization.name || organization}`}
                    />
                    <Switch checked={childSwitches[`${organization.name}-${reportName}`]} onChange={(checked) => handleSwitchChange(organization, checked, reportName)} />
                  </List.Item>
                );
              })
            }}
          />
        </div>
      </Drawer>
    </div>
  );
};

const search = {
  query: searchQuery,
  columns:  [
    {title: 'Name', dataIndex: 'name', sorter: true, render: (_,record) => ( (record?.User?.firstName || record?.firstName || '') + " " + (record?.User?.lastName || record?.lastName || ''))},
    {title: 'Email', dataIndex: 'email', key: 'email', render: (_,record) => (record?.User?.email || record?.email)},
    {title: 'Role', dataIndex: 'role', key: 'role', render: (role, record) => (role || (record?.User?.role == 'admin' ? 'Owner' : '')) },
    {title: 'Status', key: 'status', render: (userOrg) => (userOrg?.User ? 'Active' : 'Invited') },
    { title: '', width: 100, key: 'remove', render: (user) => {
      const userRole = user?.role;
      if (userRole !== 'Owner') {
        return <RemoveUserLink record={ user }/>;
      }
      return null;
    }
  }
  ]
}

const allSearch = {
  query: allUsers,
  columns:  [
    {title: 'Name', dataIndex: 'name', sorter: true, render: (_,record) => ( (record?.firstName || '') + " " + (record?.lastName || ''))},
    {title: 'Role', dataIndex: 'role', key: 'role'},
    {title: 'Email', dataIndex: 'email', key: 'email', render: (_,record) => record?.email},
    {title: 'Hosting Type', dataIndex: 'profile', key: 'profile', render: (_,record) => record?.profile?.hostingType?.join(', ')},
    {title: 'Network Size', dataIndex: 'profile', key: 'profile', render: (_,record) => record?.profile?.networkSize?.join(', ')},
    {title: 'Network Sources', dataIndex: 'profile', key: 'profile', render: (_,record) => record?.profile?.networkSources?.join(', ')},
    {title: 'Import from', dataIndex: 'profile', key: 'profile', render: (_,record) => record?.profile?.importSource?.join(', ')},
    {title: 'Features', dataIndex: 'profile', key: 'profile', render: (_,record) => record?.profile?.featureInterest?.join(', ')},
    {title: 'Needs Demo', dataIndex: 'profile', key: 'profile', render: (_,record) => record?.profile?.demoRequested?.join(', ')},
    {title: 'Organizations', dataIndex: 'organization', key: 'organization', render: (_,record) => record?.Organizations?.map(o => { return o.name }).join(', ') },
    {title: 'Login Count', width: 100, align: 'right', dataIndex: 'loginCount', key: 'loginCount' },
    {title: 'Login Type', align: 'center', key: 'loginType', render: (u) => <>
      {u?.microsoftLoginEnabled ? 'Microsoft' : ''}
      {u?.googleLoginEnabled ? 'Google' : ''}
      {u?.passwordLoginEnabled ? 'Email' : ''}
    </> },
    {title: 'Last Login', width: 200, align: 'right', dataIndex: 'lastLogin', key: 'lastLogin', render: (lastLogin) => {

        const d = new Date(parseInt(lastLogin))

        return (<p>{ d.toLocaleDateString() + ' ' + d.toLocaleTimeString() }</p>)
    }},
    {title: 'Internal', align: 'center', dataIndex: 'internal', width: 80, key: 'internal', render: (internal) => internal ? 'true' : '--' },
    {title: '', align: 'center', dataIndex: 'destroy', width: 100, key: 'destroy', render: (_,record) => <DestroyLink user={ record } /> },
    {title: '', align: 'center', dataIndex: 'manageNotifications', width: 100, key: 'manageNotifications', render: (_, record) => <ManageNotificationsLink user={record} /> }
  ]
}


const form = {
  fields: [
    ["name", {}],
  ]
}

export { search, allSearch, form, SEARCH_IDENTITIES, searchQuery, getQuery, allUsers, create, update, adminUserUpdate, adminUserGet, destroyUserOrganization, destroy, RemoveLink, sendTidalAdminEmail };
