import React, { useState } from 'react';
import { loader } from 'graphql.macro';

import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')

const RemoveAlertRuleLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Alert Rule"
        entityTitle="Alert Rule"
        redirectPath={`/settings/alerting/alert-rules`}
      />
    </div>

  )
}

const RemoveAlertRuleSubnetLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Alert Rule"
        entityTitle="Alert Rule"
        redirectPath={`/subnets/${record?.subnet?.supernetId}/${record?.SubnetId||record?.subnet?.supernetId}/alerts`}
      />
    </div>

  )
}


  const search = {
    columns: [
      { title: 'Name', dataIndex: 'name', sorter: true},
      { title: 'Type', dataIndex: 'type', sorter: true },
      { title: 'Description', dataIndex: 'description', sorter: true },
      { 
        title: 'Subnet', 
        render: (alertRule) => {
          const networkAddress = alertRule?.subnet?.networkAddress;
          const name = alertRule?.subnet?.name;
          return networkAddress && name ? `${networkAddress} - ${name}` : networkAddress || name || '';
        }
      },
      { title: 'Destination', render: (alertRule) => alertRule?.AlertDestinations?.map(destination => destination.name).join(', ')},
      { title: 'Status', dataIndex: 'status', sorter: true },
      { title: '', width: 100, key: 'Destroy', render: alertRule => <RemoveAlertRuleLink record={ alertRule }/> }
    ],
  };

  const alertRuleSubnetSearch = {
    columns: [
      { title: 'Name', dataIndex: 'name', sorter: true},
      { title: 'Type', dataIndex: 'type' },
      { title: 'Description', dataIndex: 'description' },
      { 
        title: 'Recipient', 
        render: item => item.AlertRecipients.map(recipient => recipient.userName).join(', ')
      },
      { title: 'Status', dataIndex: 'status' },
      { title: '', width: 100, key: 'Destroy', render: alertRule => <RemoveAlertRuleSubnetLink record={ alertRule }/> }
    ],
  };


export { search, alertRuleSubnetSearch, getQuery, searchQuery, create, update, destroy };