import { loader } from 'graphql.macro';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const search = {
  columns:  [
    { title: 'Code', dataIndex: 'code', key: 'code', sorter: true},
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
  ]
}

const form = {
  fields: [
    ["code", {}],
    ["name", {}]
  ]
}

module.exports = { search, form, searchQuery, getQuery, create, update, destroy };
