import React from 'react';
import SearchTable from '../../components/SearchTable';
import { Col, Row } from 'antd';
import Info from '../info/Info';

const subnet = require('../../entities/subnet');

const CustomerSubnet = ({ customerId }) => {
  const id = parseInt(customerId);

  return (
    <div className={'subnet-view-body'} style={{ padding: '15px 25px', background: '#FFFFFF', minHeight: '400px' }}>
      <Row gutter={24}>
        <Col span={24}>
          <SearchTable
            query={subnet.customerSubnets}
            {...subnet.customerSubnetSearch}
            modelName='subnet'
            filters={{ customerId: id }}
            emptyRender={<Info model="subnets" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomerSubnet;