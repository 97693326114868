import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Drawer, Form, Input, Alert, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const RemoveRecordButton = ({ mutation, mutationVariables, menuItems, entityName, record, entityTitle, redirectPath }) => {
  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [menuTitle, setMenuTitle] = useState('');
  const history = useHistory();

  const [mutateFunction] = useMutation(mutation, {
    update(cache) {
      cache.evict({ id: `${record.__typename}:${record.id}` });
    },
    onCompleted() {
      history.replace('/page-not-found', { section: entityName });
    }
  });

  const handleMutation = async (variables) => {
    try {
      await mutateFunction({ variables });
    } catch (error) {
      console.error("Error executing mutation:", error);
    }
  };

  const handleRemoveRecord = async () => {
    await handleMutation(mutationVariables);
    history.push(redirectPath);
    setDrawerVisible(false);
    form.resetFields();
  };

  const handleClearRecordSpace = async () => {
    await handleMutation({ ...mutationVariables, clearSpace: true });
    setDrawerVisible(false);
    form.resetFields();
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  const ConfirmationForm = ({ title, confirmationText, buttonText, onSubmit, infoText, operation }) => (
    <Form form={form} onFinish={onSubmit}>
      <p>{title}</p>
      <div style={{ marginTop: '25px' }}>
        <Checkbox required>Yes, I'm ready</Checkbox>
      </div>
      <div style={{ marginTop: '25px' }}>
        <p>{confirmationText}</p>
        <Form.Item
          name="confirmation"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value === operation) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`Please type "${operation}" to confirm!`));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Alert
          style={{ marginTop: '20px' }}
          message={infoText}
          type="info"
          showIcon
        />
      </div>
      <div style={{ marginTop: '30px' }}>
        <Button type="secondary" onClick={handleDrawerClose} style={{ float: 'right', marginLeft: '20px' }}>
          Cancel
        </Button>
        <Button htmlType="submit" style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff' }}>
          {buttonText}
        </Button>
      </div>
    </Form>
  );

  const renderMenu = () => (
    <Menu>
      {menuItems.includes("clear") && (
        <Menu.Item onClick={() => { setMenuTitle(`Clear ${entityTitle} Space`); setDrawerVisible(true); }}>
          Clear {entityTitle} Space
        </Menu.Item>
      )}
      {menuItems.includes("remove") && (
        <Menu.Item onClick={() => { setMenuTitle(`Remove ${entityTitle}`); setDrawerVisible(true); }}>
          Remove {entityTitle}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={renderMenu} trigger={['click']}>
        <a style={{ marginTop: '30px', marginLeft: '20px' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <EllipsisOutlined style={{ fontSize: '16px', fontWeight: 'bold' }} />
        </a>
      </Dropdown>

      <Drawer
        width={500}
        title={menuTitle}
        onClose={handleDrawerClose}
        visible={drawerVisible}
      >
        {menuTitle === `Clear ${entityTitle} Space` ? (
          <ConfirmationForm
            title={`Are you ready to clear the ${entityTitle.toLowerCase()} space?`}
            confirmationText={`Please type 'clear' in the box and click Submit to complete the operation`}
            buttonText="Submit"
            operation="clear"
            onSubmit={handleClearRecordSpace}
            infoText={`This will remove all associated data. ${entityTitle} record remains. This action cannot be undone.`}
          />
        ) : (
          <ConfirmationForm
            title={`Are you ready to remove the complete ${entityTitle.toLowerCase()}?`}
            confirmationText={`Please type 'remove' in the box and click Submit in order to remove ${entityTitle.toLowerCase()}`}
            buttonText="Submit"
            operation="remove"
            onSubmit={handleRemoveRecord}
            infoText={`This will remove ${entityTitle.toLowerCase()} and associated history. This action cannot be undone.`}
          />
        )}
      </Drawer>
    </>
  );
};

export default RemoveRecordButton;
