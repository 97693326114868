const _ = require('lodash')

let context = require.context(".", true, /\.js$/);
let entities = {};
context.keys().forEach(function (key) {

  let entityName = key.split('/')[1]

  entities[entityName] = context(key);
});

module.exports = entities;
