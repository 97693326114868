import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Space } from 'antd';
import { range, unionWith, flattenDeep } from 'lodash';
import { ArrowUpOutlined, CloseCircleFilled, CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import cidrRegex from 'cidr-regex';
import { useHistory } from 'react-router';
const humanNumber = require('../../utils/number')

import NetmaskForm from './NetmaskForm'
import v6Count from './v6count';

const subnet = require('../../entities/subnet')


const { Address6 } = require('ip-address');
const { BigInteger } = require('jsbn');
const parsedSubnet = (subnet) => { return new Address6(subnet) } 

const getWidth = ( subnet, supernet ) => {

  const netmask = parsedSubnet( subnet.networkAddress ).subnetMask;
  const base = parsedSubnet( (supernet?.networkAddress || subnet?.networkAddress) ).subnetMask;

  const denominator = Math.pow(2, (netmask - base))

  if (denominator == 0) {
    return 100
  } else {
    return( 100 / denominator )
  }
}

const subnetOffset = ( subnet, supernet ) => {

  const supNet = parsedSubnet( supernet?.networkAddress || subnet.networkAddress );
  const subNet = parsedSubnet( subnet.networkAddress );


  const supernetSize = new BigInteger(supNet.possibleSubnets().toString())

  const offset = (subNet?.bigInteger()?.subtract(supNet.bigInteger())) / supernetSize * 100;

  return offset;
}

export const possibleSubnets = ( supernet, netmask ) => {

  const snet = parsedSubnet( supernet.networkAddress );


  let result = [] 

  const supernetAddress = snet.bigInteger();

  if (netmask && (netmask > snet.subnetMask)) {

    const supernetSize = new BigInteger(snet.possibleSubnets().toString())

    const subnetsCount = new BigInteger(snet.possibleSubnets(netmask).toString())
    const subnetSize = supernetSize.divide(subnetsCount)


    let i = new BigInteger('0');

    const snetSize = new BigInteger(subnetSize.toString())

    while (i.compareTo(new BigInteger(supernetSize.toString())) < 0) {

      const address = Address6.fromBigInteger(supernetAddress.add(i)).correctForm()

      result.push({ 
        networkAddress: address + "/" + netmask,
        supernetId: supernet.id,
        netmask: netmask
      })

      i = i.add(snetSize)
    }

    return result;

} else {

  return [];

  }
}

const inSubnet = (s1, s2) => {
  const subnet1 = new Address6(s1.networkAddress)

  const subnet2 = new Address6(s2.networkAddress)

  return subnet1.isInSubnet(subnet2) || subnet2.isInSubnet(subnet1);
}

const SupernetBlock = ({ data, history, onClick }) => {
  let text
  let url

  if ( data ) {
    text = (data.name || data.networkAddress)
    url = `/subnets/${data.id}/${data.id}`
  } else {
    text = "All Subnets"
    url = '/subnets'
  }

  return(
    <div className={ "supernet-block" } onClick={(e) => { history.push(url) }} >
      <div className={ "supernet-body" }>
        <div className="icon-wrapper up-icon" style={{marginRight: '80px'}}>
          <ArrowUpOutlined style={{ fontSize: '10px', textAlign: 'center', color: '#b7cee1', display: 'block', margin: 'auto', marginTop: '2px' }}/>
        </div>
        { text }
      </div>
    </div>
  )
}

const SubnetBlock = ({ data, id, editing, depth, siblingCount = 1, index = 0, totalWidth = 100, width, showSupernet, supernet, onClick, onDblClick, onCreate, onDestroy, editingNetmask, height, blockStyle }) => {

  const snets = unionWith(data.subnets, possibleSubnets(data, editingNetmask), inSubnet);

  const subnetWidth = getWidth(data, supernet);
  const remainingWidth = subnetWidth * totalWidth / 100;
  const netmask = data?.networkAddress.split("/")[1]

  const getTop = () => {
    // Top
    if ((depth == 0 || !depth) && !showSupernet ) {
      return 30 + ( height / 2) * -1
    } else {
      return height + 5
  }}


  const subnetStyle = { 
    left: subnetOffset(data, supernet) + "%",
    top: getTop() + "px",
    maxHeight: height + "px",
    height: height + "px",
    width: subnetWidth + "%"
  }

  let clickFn = () => {
    if (data.id) {
      onClick(data);
    } else {
      onCreate({ variables: {...data, supernetId: supernet.id }});
  }}


  const popOvercontent = (
    <div style={{marginBottom: 0}}>
      {data?.name && <p>{data.name}</p>}
      <p>{data?.networkAddress}</p>
    </div>
  );

  const subnetProps = {
    className: "subnet-body " + ( data.id ? "" : "new-record " ) + ( id == data.id ? "active" : "" ),
    style: { height: height + "px", overflow: "hidden", ...blockStyle },
    onDoubleClick: (e) => (data?.id && onDblClick) ? onDblClick(data) : null,
    onClick: (e) => { if (e.detail === 2 && onDblClick) { onDblClick(data) } else { clickFn() }}
  }

  return (
    <div className="subnet-block" style={subnetStyle}>
      { (remainingWidth < 10) && (
        <div {...subnetProps }>
          { data?.name?.length > 0 && 
          <>
            { data.name }
            <br/>
            { data.networkAddress || "-" }
            <br/>
          </>
          }

          { ( remainingWidth < 2.5 ) ?  "" : "" }
          { ( 2.5 < remainingWidth && remainingWidth < 10 ) ? (" / " + netmask) : "" }
        </div>
      )}

      { remainingWidth >= 10 && (
        <div {...subnetProps}>
          { data?.name?.length > 0 && 
          <>
            { data.name }
            <br/>
          </>
          }

          {`${data?.networkAddress?.replace('/', ' / ')}`}
          <div style={{ float: 'right', marginRight: '2px', marginTop: '-2px' }}>{`${ data?.id ? '' : '+ '}`}</div>
        </div>
      )}

      { (data?.id && editing && onDestroy ) && (
        <CloseCircleFilled 
          fill='white'
          height='10px'
          width='10px'
          style={{ 
            position: 'absolute', 
            top: '8px', 
            right: '10px', 
            color: '#FFFFFF', 
            fontWeight: 'bold',
            opacity: '0.8',
            hover: {
              opacity: '1'
            }
          }}
          onClick={(e) => {

            onDestroy(data)

          }} />
      )}
      { 
        snets.map((subnet, idx) => {
          return ( 
            <SubnetBlock 
              id={ id }
              index={ idx }
              editing={ editing }
              siblingCount={ snets.length }
              data={ subnet } 
              depth={ (depth || 0) + 1 } 
              onCreate={ onCreate } 
              onClick={ onClick }
              onDblClick={ onDblClick }
              onDestroy={ onDestroy }
              supernet={ data } 
              editingNetmask={ editingNetmask } 
              height={ height }
              totalWidth={ subnetWidth / 100 * totalWidth }
              key={ subnet?.id }
              blockStyle={ blockStyle }
            /> );
        })
      }
    </div>
  )
}

const getTotalHeight = ( data, height ) => {

  const getSubnets = (data, acc) => {

    const children = data?.subnets?.map((child) => {

      if (child.subnets?.length > 0) {
        return getSubnets(child, acc + 1);
      } else {
        return acc;
      }

    })

    if (children?.length > 0) {
      return Math.max(...flattenDeep(children))
    } else {
      return 0
    }
  }

  return (getSubnets(data, 1) + 2) * height + 30;
}


const NetmaskSelector = ({ netmask, setNetmask, min }) => {

  const caretStyle = { fontSize: '16px', color: '#3d75a4' }

  const netmasks = range(min + 1, min + 6)
  return(
      <Space size={ 2 } direction='vertical' style={{ width: '200px', height: '30px'}}>
        { netmasks.map(n => {

          return (
            <div
              className='rounded-6'
              style={{ 
                padding: '1px', 
                marginLeft: '6px', 
                marginBottom: '2px',
                border: '2px solid #729ec0',
                borderColor: netmask == n ? '#729ec0' : '#d9e7f2',
                background: '#d9e7f2',
                color: netmask == n ? '#3f6a8d' : '#81a9ca',
                fontWeight: netmask == n ? 'bold' : 'normal',
                width: '180px',
                lineHeight: '18px',
                height: '30px',
                cursor: 'pointer'
              }} 
              onClick={() => setNetmask(n)}>
              <div style={{ float: 'left', padding: '2px 1px', marginLeft: '3px', fontWeight: 'bold', width: '35px' }}>/{ n }</div> 
              <div style={{ float: 'left', padding: '2px 2px', textAlign: 'right', width: '130px'}}> { humanNumber(v6Count[n]) } hosts </div>
            </div>
          )})}
      </Space>
  )
}

const V6 = ({ title, onClick, onDblClick, onCreate, onDestroy, editing, data, active, showSupernet = true, height = 25, style = {}, blockStyle={} }) => {
  if (!data) { return (
    <Row>
      <Col span={6}>
        { title }
      </Col>
    </Row>
  )}

  const history = useHistory();

  const netmask = parseInt(data?.networkAddress?.split('/')?.[1])
  const defaultNetmask = netmask + 1

  const [editingNetmask, setEditingNetmask] = useState(defaultNetmask)

  const snet = data?.length > 0 ? null :  parsedSubnet(data?.networkAddress);

  const totalHeight = getTotalHeight(data, height)

  if (data) {
    return (
      <div className="subnet-builder" style={{...style, height: (totalHeight > 140 ? totalHeight : 140)  + "px" }}>
        { editing && (
          <Row>
            <Col span={6}>
              { title }
            </Col>
          </Row>
        )}
        <Row>
          <Col flex={1}>
            { showSupernet && (<SupernetBlock history={ history } data={ data.supernet } onClick={ onClick } />) }
            <SubnetBlock 
              id={ active } 
              editing={ editing }
              data={ data } 
              onDblClick={ onDblClick }
              onClick={ onClick ? onClick : function(s) { history.push(`/subnets/${s.id}`) }} 
              //onDestroy={ onDestroy }
              onCreate={ onCreate } 
              editingNetmask={ editingNetmask || defaultNetmask } 
              showSupernet={ showSupernet }
              height={ height }
              key={ data?.id}
            />
          </Col>

          { editing && (
            <Col style={{ width: '200px', textAlign: 'center' }}>

              <NetmaskSelector netmask={ editingNetmask } setNetmask={ setEditingNetmask } min={ netmask } />

            </Col>
          )}
        </Row>
      </div>
    )
  }
}

export default V6;
