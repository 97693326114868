import { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button, Form, Alert } from 'antd';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { TextInput, CustomAttributes, TextArea } from '../../components/pants-d';
import MultiSelect from '../../components/MultiSelect';

const supportGroup = require("../../entities/support-group");
const contact = require("../../entities/contact");

const SupportGroupForm = () => {
  const { id } = useParams();
  const newRecord = id == 'new';
  const history = useHistory();
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [getSupportGroup, { loading, error, data }] = useLazyQuery(supportGroup.getQuery, { variables: { id: parseInt(id) }});
  useEffect(() => { if ( id && !newRecord ) { getSupportGroup() } },[])

  const [createRecord, { data: createData, error: createErrors }] = useMutation(supportGroup.create, {
    onCompleted: () => { history.push(`/support-groups`) },
    onError: e => {
      setErrors(e.message);
    },
    update(cache, { data }) { 
      const existingSupportGroups = cache.readQuery({ query: supportGroup.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: supportGroup.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          supportGroups: {
            count: existingSupportGroups.count + 1,
            results: [...(existingSupportGroups?.supportGroups?.results || []), data.createSupportGroup]
          }
        }
      })
    }

  });

  const [updateRecord, { data: updateData }] = useMutation(supportGroup.update, { onCompleted: () => { history.push(`/support-groups`) }} )
  
  const saveRecord = (values) => { if (newRecord) {
    createRecord({ variables: {...values}}) 
  } else {
    updateRecord({variables: {id: parseInt(id), ...values}}) }
  }

  const [form] = Form.useForm();

  if (loading) { return 'Loading' }
  return (
    <Form
      layout="vertical"
      form={form}
      name="supportGroup"
      initialValues={(data||{})['supportGroup']}
      onFinish={ (values) => saveRecord(values) } >

      {errors && errors.length > 0 && (
        <div ref={errorContainerRef} style={{ marginBottom: '10px' }}>
          <Alert type="error" message={errors} />
        </div>
      )}

      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please input the Name' }]}
      >

        <TextInput />
      </Form.Item>

      <TextArea name="description"/>

      <CustomAttributes modelName='SupportGroup' />

      <Button type="primary" htmlType="submit">
        Submit
      </Button>

    </Form>
  )
}

export default SupportGroupForm;
