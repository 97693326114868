import React, { useState, useEffect  } from 'react';
import { Select, Input,  Spin, Divider, Button, Alert } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';


const { Option } = Select;

const RecordForm = ({ createMutation, createType, displayKey, createLabel, refetch, setAddValueError }) => {

  const [newRecord, setNewRecord] = useState('');

  const [createRecord, createdRecord] = useMutation(createMutation, { 
    onCompleted: () => {
      refetch()
      setNewRecord('')
      setAddValueError(''); 
    },
    onError: (error) => {
      const validationErrorPrefix = 'Validation error: ';
      const errorMessage = error.message.startsWith(validationErrorPrefix)
        ? error.message.substring(validationErrorPrefix.length)
        : error.message;
    
      setAddValueError(errorMessage);
    }
  });
  
  const handleClick = () => {
    let result = { variables: {} };
    if(!newRecord){
      setAddValueError('Input field should not be empty')
      return;
    }
    
    if (createType === 'Int') {
      if (/^\d+$/.test(newRecord)) {
        result['variables'][(displayKey || 'name')] = parseInt(newRecord);
      } else {
        setAddValueError('Input should be a valid integer');
        return;
      }
    } else if (createType === 'String') {
      result['variables'][(displayKey || 'name')] = newRecord;
    }
    createRecord(result);
  };

  return ( 
    <div style={{ display: 'flex', flexDirection: 'column', padding: 8 }}>
      <Input style={{ marginBottom: '8px' }} value={newRecord} onChange={(e) => {setNewRecord(e.target.value),  setAddValueError('');}} />
      <Button
        type='primary'
        onClick={handleClick} >
        { createLabel }
      </Button>
    </div>
  )
}


const SearchSelect = ({query, createMutation, createType='String', val, placeholder, resultKey, displayKey, displayFn, keyFn, createLabel='Add Item', onChange, defaultValue = {}, readOnly, ...rest}) => {
  const { loading, data, refetch } = useQuery(query, { variables: { search: '', offset: 0, limit: 50 }})

  const debouncedRefetch = debounce(refetch, 250)
  const [addValueError, setAddValueError] = useState('');

  const display = ( value ) => {
    return displayFn ?
      displayFn(value || {}) :
      (value?.[(displayKey || 'name')] || '')
  }

  const handleChange = (value) => {
    if (value === null) {
      onChange(null);
    } else {
      onChange(value);
    }
  }

  return(
    <>
      { readOnly && ( 
        <div style={{ color: '#1e4a71', fontSize: '16px', paddingTop: '7px' }} >
          { display(defaultValue || {}) }
        </div>
      )}
      { !readOnly && (
        <Select
          {...rest}
          value={val ? {key: keyFn ? keyFn(val) : val.id, label: display(val)} : undefined}
          defaultValue={defaultValue?.id ? {key: defaultValue?.id, label: display(defaultValue)} : undefined}
          placeholder={ placeholder }
          dropdownRender={menu => (
            <div>
              {menu}
              {addValueError && (
                // <div style={{ color: 'red', fontSize: '12px', paddingTop: '7px' }}>
                //   {addValueError}
                // </div>
                <Alert
                message={addValueError}
                type="error"
                showIcon
                style={{ marginBottom: '16px' }}
              />
              )}
              { !!createMutation && 

                <>
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ padding: '10px' }}>
                    <RecordForm createMutation={ createMutation } createType={createType} displayKey={ displayKey } createLabel={ createLabel } refetch={ refetch } setAddValueError={setAddValueError}/>
                  </div>
                </>
              }
            </div>
          )}
          showSearch
          filterOption={false}
          labelInValue={true}
          optionLabelProp="label"
          notFoundContent={loading ? <Spin size="small" /> : "No Results Found"}
          onFocus={() => refetch({search: ''})}
          onSearch={(value) => {
            debouncedRefetch({search: (value || '')}) 
          }}
          onChange={handleChange}
          allowClear
        >
          {data && data[resultKey] && data[resultKey].results.map(d => (
            <Option 
              value={keyFn ? keyFn(d) : (d?.id || display(d))}
              label={display(d)}>
              { display(d) }
            </Option>
          ))}
        </Select>
      )}
    </>
  )
}


export default SearchSelect;
