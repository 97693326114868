import React, { useState } from 'react'
import { loader } from 'graphql.macro';
import { Tag, Space, Button, Alert } from 'antd';

const searchQuery = loader('./queries/search.graphql')  
const GET_IP_ASSIGNMENT = loader('./queries/get.graphql')  

const CREATE_IP_ASSIGNMENT = loader('./mutations/create.graphql')  
const UPDATE_IP_ASSIGNMENT = loader('./mutations/update.graphql')  
const importRecords = loader('./mutations/import.graphql')
const DESTROY_IP_ASSIGNMENT = loader('./mutations/destroy.graphql')  
const historyQuery = loader('./queries/history.graphql')  

const Resource = ({ resource }) => {

  const hidden = useState(true);

  //const style = {border: '1px solid #DDDDDD', background: '#FBFBFB', padding: '2px 6px', borderRadius: '6px'}
  const style = {padding: '2px 10px', background: '#fcfcfd', minHeight: '30px'}
   
  return (
    <div style={style}>
      <div style={{width: '26px', padding: '3px 2px', float: 'left'}}>
        { (resource?.resourceType == null) && <img style={{width: '14px'}} src="/images/azure/compute/10021-icon-service-Virtual-Machine.svg" /> }
      </div>
        <div style={{float: 'left'}}>
          <div style={{fontWeight: 'bold', color: '#2c6696', fontSize: '11px', lineHeight: '23px', textTransform: 'uppercase'}}>{ resource?.hostname?.toUpperCase() }</div>
          { !hidden && ( 
            <p style={{fontSize: '11px', marginBottom: '0px'}}>
              <b>{ resource?.interfaces?.[0]?.name }</b>  
              <br/>
              { resource?.interfaces?.[0]?.macAddress }
            </p>
          )}
        </div>
    </div>
  )
}

const IPStatus = ({ ipAssignment }) => {

  const active = !!ipAssignment;

  const deviceState = () => {
    return 'green'
  }

  return (
    <span class={`dot ${ active ? deviceState() : '#FAFAFA' }`}/>
  )
}

const search = {
  query: searchQuery,
  columns:  [
    { title: ' ', key: 'id', width: '2.4em', render: assignment => <IPStatus ipAssignment={ assignment } /> },
    { title: 'Subnet', dataIndex: 'subnet', width: '7em', key: 'networkAddress', render: subnet => subnet?.networkAddress },
    { title: 'IP Address', dataIndex: 'networkAddress', width: '9em', key: 'networkAddress'},
    {
      title: 'Resource',
      key: 'Resource',
      width: '17em',
      render: ipAssignment => {
        return ipAssignment?.interface
          ? <Resource resource={{ ...ipAssignment?.interface.resource }} />
          : null;
      }
    },
    { title: 'DNS', key: 'DNSEntries', width: '18em', render: ipAssignment => ipAssignment?.DNSEntries?.map(entry => <div style={{width: '200px', padding:'4px', background: '#FAFAFA', fontSize: '12px'}}><Space>{ (entry?.recordType || 'A' )}  <b>{ entry?.name }</b>  <span style={{ textAlign: 'right' }}>{( entry?.ttl || 500 )}</span></Space></div>) },
    {
      title: 'NAT Type',
      key: 'nat',
      width: '10em',
      render: (ipAssignment) => {
        const natTypes = ipAssignment?.subnet?.nat
          ?.filter((nat) => nat.sourceNatAddress === ipAssignment?.networkAddress)
          .map((nat, index) => (
            <div key={index} style={{ whiteSpace: 'nowrap' }}>
              {nat?.natType}
            </div>
          ));
        return natTypes ? natTypes : null;
      },
    },
    {
      title: 'Destination NAT',
      key: 'nat',
      width: '20em',
      render: (ipAssignment) => {
        const destinationNat = ipAssignment?.subnet?.nat
          ?.filter((nat) => nat.sourceNatAddress === ipAssignment?.networkAddress)
          .map((nat, index) => (
            <div key={index} style={{ whiteSpace: 'nowrap' }}>
              {nat?.destinationNatAddress?.join(', ')}
            </div>
          ));
        return destinationNat ? destinationNat : null;
      },
    },
    { title: 'Notes', key: 'ipAssignment', width: '5em', render: ipAssignment => <p>{ ipAssignment?.description } </p>},
    { title: 'Contact', key: 'ipAssignment', width: '5em', render: ipAssignment => <p>{ ipAssignment?.contact } </p>},
    { title: 'Last Seen', width: '10em', key: 'ipAssignment', render: (ipAssignment) => {
      if (ipAssignment?.lastSeenAt || ipAssignment?.createdAt) {
        const d = new Date(parseInt(ipAssignment?.lastSeenAt || ipAssignment?.createdAt))

        return (<p>{ d.toLocaleDateString() + ' ' + d.toLocaleTimeString() }</p>)
      }
    }},
  ]
}

const form = {
  fields: [
    ["ipAddress", {}],
  ]
}

const importer = {
  mutation: importRecords,
  search: { 
    query: searchQuery,
    columns:  [
      { title: 'Subnet', dataIndex: 'subnet', width: '8em', key: 'networkAddress', render: subnet => subnet?.networkAddress },
      { title: 'IP Address', dataIndex: 'networkAddress', width: '8em', key: 'networkAddress'},
      { title: 'Hostname', key: 'resource', width: '10em', render: (ipAssignment) =>{return ipAssignment?.interface?.resource ? ipAssignment?.interface?.resource?.hostname : null }},
      { title: 'Interface', key: 'resource', width: '10em', render: ipAssignment => ipAssignment?.interface ? ipAssignment?.interface?.name : null },
      { title: 'MAC Address', key: 'resource', width: '12em', render: (ipAssignment) => { return ipAssignment?.interface ? ipAssignment?.interface?.macAddress : null }},
      { title: 'DNS', key: 'DNSEntries', width: '16em', render: ipAssignment => ipAssignment?.DNSEntries?.map(entry => <div style={{width: '200px', padding:'2px', background: '#FAFAFA', fontSize: '12px'}}><Space>{ (entry?.recordType || 'A' )}  <b>{ entry?.name }</b>  <span style={{ textAlign: 'right' }}>{( entry?.ttl || 500 )}</span></Space></div>) },
      { title: 'Contacts', key: 'ipAssignment', render: ipAssignment => <p>{ ipAssignment?.contact } </p>},
    ]
  },
  exampleSheet: 'IPAssignmentExample',
  fields: [
    {name: 'zone', required: true}, 
    {name: 'subnet', required: true }, 
    {name: 'networkAddress', alias: 'IP Address', required: true}, 
    {name: 'dnsEntryType', alias: 'DNS Entry Type'},
    {name: 'dnsEntryValue', alias: 'DNS Entry Value'},
    {name: 'dnsEntryTTL', alias: 'DNS Entry TTL'},
    {name: 'hostname', alias: 'Host Name'}, 
    {name: 'resourceType', alias: 'Resource Type'},
    {name: 'interface'}, 
    {name: 'macAddress', alias: 'Mac Address'}, 
    {name: 'direction'},
    {name: 'reservation'},
  ],

  title: 'IP Assignments',
  copy: 'IP Assignments represent an allocated resource or network interface, and can be associated with DNS entries and Reservations.',
  note: (
    <Alert
      style={{ marginTop: '10px' }}
      message={
        <div>
          <p dangerouslySetInnerHTML={{ __html: "Please note that <strong>Zone</strong>, <strong>Subnet</strong> and <strong>IPAddress</strong> are mandatory fields." }} />
          <p>For example, <strong>Subnet</strong> should be in <em>CIDR notation (e.g., 10.0.0.0/16)</em> and <strong>IPAddress</strong> can be <em> 10.0.0.25</em>.</p>
        </div>      
      }
      type="info"
      showIcon
    />
  )
}

export { search, form, importer, importRecords, searchQuery, GET_IP_ASSIGNMENT, CREATE_IP_ASSIGNMENT, UPDATE_IP_ASSIGNMENT, DESTROY_IP_ASSIGNMENT, historyQuery };
