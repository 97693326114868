import React from 'react'
import { loader } from 'graphql.macro';

const customerQuery = loader('../customer/queries/search.graphql')

const searchQuery = loader('./queries/search.graphql')  
const GET_QUERY = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const search = {
  columns:  [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true},
    { title: 'Netmask', dataIndex: 'netmask', key: 'netmask', sorter: true},
    { title: 'Description', dataIndex: 'description', key: 'description', sorter: true},
    { title: 'Customers', dataIndex: 'customers', key: 'customers', render: customers => <a>{ customers.map(c => c.name).join(", ") }</a>},
  ]
}

export { search, searchQuery, GET_QUERY, customerQuery, create, update, destroy };
