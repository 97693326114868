import React, {  useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Row, Col, Layout} from 'antd';
import { SubnetBuilder } from '../../components/SubnetBuilder';
import Slides from '../../components/Slides';
import qs from 'qs';
import {useHistory} from 'react-router-dom';
import { ArrowUpLeft, ArrowUpRight } from '../../components/pants-d/CustomIcons'
import { Fireworks } from '@fireworks-js/react'

import UserInfo from './UserInfo';
import UserProfile from './UserProfile';
import GettingStarted from './GettingStarted';

const { Content } = Layout

const { CURRENT_USER_QUERY, HIDE_WELCOME_MUTATION } = require('../../entities/auth');


const sampleSubnets = {
  id: '10.0.0.0/20',
  name: 'Example subnet',
  networkAddress: '10.0.0.0/20',
  subnets: [
    { id: '10.0.0.0/21', 
      networkAddress: '10.0.0.0/21',
      subnets: [
        { 
          id: '10.0.0.0/22', 
          networkAddress: '10.0.0.0/22' 
        }
      ]},
    { id: '10.0.8.0/21', 
      networkAddress: '10.0.8.0/21'
    }
  ]
}

const Intro = ({ onNext }) => {

  return (
    <>
      <h1 style={{ fontSize: '42px'}}>Welcome to Lightmesh</h1>
      <h2 style={{ fontSize: '20px', marginBottom: '40px'}}>
        Network management designed for modern enterprise.
      </h2>

      <div class='welcome-actions' style={{ width: '20%', marginLeft: '40%'}}>
          <a style={{ float: 'right' }} onClick={(e) => { onNext() }}>
            Get started
          </a>
      </div>
    </>
  )
}

const Navigation = ({ onNext }) => {

  const searchParams = window?.location?.search?.slice(1)
  const isInvite = searchParams.split('=')?.[0] == 'invite'
  const snet = (window?.location?.search && !isInvite) ? qs.parse(window.location.search.slice(1), { depth: 10}) : null
  const [active, setActive] = useState((snet || sampleSubnets)?.id);

  return (
    <>
      <h2 style={{ marginBottom: '20px', fontSize: '35px', display: 'inline-block', paddingTop: '5px'}}>
        You can select subnet blocks to navigate.
      </h2>
      <SubnetBuilder 
        showSupernet={ false }
        height={44}
        active={ active }
        onClick={(s) => onNext() }
        data={ snet || sampleSubnets }
      />
      <Row style={{ marginLeft: '40%', marginTop: '-70px' }}>
          <ArrowUpLeft style={{ marginRight: '5px', height: '40px', paddingBottom: '0px', display: 'inline-block' }}/>
        <h2 style={{ marginBottom: '20px', fontSize: '28px', display: 'inline-block', textAlign: 'left',  paddingTop: '0px', color: '#b74bb9', fontWeight: '400' }}>
          Click a <div style={{ color: '#3d75a4'}}>blue subnet block</div> to show that address space 
          <br/>
          </h2>
      </Row>
    </>
  )
}

const defaultSubnet = {
  id: '10.0.0.0/22',
  networkAddress: "10.0.0.0/22",
  subnets: []
}

const getSubnetProperties = ({ networkAddress, subnets }) => {

  const data = { networkAddress, subnets: [] }

  subnets?.forEach((d,i) => {
    data.subnets[i] = getSubnetProperties(d)
  })
          setTransitioning(true)

  return data  
}

const addSubnet = ( subnet, supernet ) => {

  const data = { ...supernet }

  if (data.id == subnet.supernetId) {
    const existingSubnets = (data?.subnets || [])

    data['subnets'] = [...existingSubnets , subnet ]
  } else {
    data?.subnets?.forEach((d,i) => {
      data.subnets[i] = addSubnet(subnet, d)
    })
  }

  return data  
}

const Editing = ({ showNetmask, onNext }) => {
  const client = useApolloClient();
  const history = useHistory();

  const searchParams = window?.location?.search?.slice(1)
  const isInvite = searchParams.split('=')?.[0] == 'invite'
  const subnet = (window?.location?.search && !isInvite) ? qs.parse(window.location.search.slice(1), { depth: 10}) : null

  const subnets = (subnet || defaultSubnet) ;
  const [subnetTree, setSubnetTree] = useState(subnets);
  const [active, setActive] = useState(subnetTree?.id);
  const [editingNetmask, setEditingNetmask] = useState(17);
  const [creationSuccess, setCreationSuccess] = useState(false);

  const { data: users, loading } = useQuery(CURRENT_USER_QUERY)

  const [hideWelcome, { data }] = useMutation(HIDE_WELCOME_MUTATION, {
    onCompleted: ({ hideWelcome }) => { 

      history.push('/')

      const query = {
        query: CURRENT_USER_QUERY,
        variables: {}
      }
      client.writeQuery({
        ...query,
        data: { currentUser: hideWelcome.user}
      }) 
  }})


  return (
    <>
      <h2 style={{ marginTop: '0px', marginBottom: '30px', fontSize: '28px'}}>
        { showNetmask && <> You can change the size of available subnets</> }
        { !showNetmask && <> Lightmesh will show you the available subnets </> }
      </h2>
      <SubnetBuilder 
        style={{ marginBottom: '20px', }} 
        editing={ true }
        editingNetmask={ editingNetmask }
        hideEditor={ !showNetmask }
        showSupernet={ false }
        height={50}
        active={ active }
        onClick={(s) => onNext() }
        data={ subnetTree }
        onCreate={(s) => { 
          const newSubnetTree = addSubnet({...s?.variables, id: s?.variables?.networkAddress, subnets: [],}, subnetTree) 

          setSubnetTree(newSubnetTree);
          setCreationSuccess(true)
          onNext()
        }}
      />
      { !showNetmask && (
      <Row style={{ marginLeft: '10%', marginTop: '-10px' }}>
          <ArrowUpLeft style={{ marginRight: '5px', height: '40px', paddingBottom: '0px', display: 'inline-block' }}/>
        <h2 style={{ marginBottom: '20px', fontSize: '28px', display: 'inline-block', paddingTop: '0px', color: '#b74bb9', fontWeight: '400' }}>
          Click here to allocate a /23 and continue
        </h2>
      </Row>
      )}

      { showNetmask && !creationSuccess && (
        <Row style={{ marginRight: '40px', marginTop: '40px', float: 'right' }}>
          <div>
            <h2 style={{ marginBottom: '20px', float: 'right', fontSize: '28px', display: 'inline-block', paddingTop: '0px', color: '#b74bb9', fontWeight: '400', textAlign: 'right' }}>
              Select a netmask size to allocate
            </h2>
            <br />
            <p style={{ float: 'right', fontSize: '22px', color: '#8b9ba7' }}>
              Allocate one of the available subnets to continue
            </p>
          </div>
          <ArrowUpRight style={{ marginLeft: '5px', height: '40px', paddingBottom: '0px', display: 'inline-block' }}/>
      </Row>
      )}


      { showNetmask && creationSuccess && (
        <>
          <h1 style={{ fontSize: '24px', color: '#a32da5'}}> You did it! </h1>
        <Fireworks
          options={{ 
          opacity: 0.9, 
          hue: { min: 302, max: 312}, 
          gravity: 2.72,  
          friction: 0.96, 
          acceleration: 1.5, 
          intensity: 35.63, 
          explosion: 7,
          rocketsPoint: { min: 0, max: 1200 }
          }}
          style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            background: 'none'
          }}
        />
        </>
      )}
    </>
  )
}

const Synchronize = () => {

  const [sampleData, setSampleData] = useState(sampleSubnets);

  return (
    <>
      <h2 style={{ marginBottom: '60px'}}>
        You can synchronize all of your network data across cloud and hosted environments
      </h2>
      <img style={{width: '80px', marginTop: '0px', marginRight: '20px', marginLeft: '12px'}} src='/images/azure.svg' />
      <img style={{width: '140px', marginTop: '3px', marginRight: '20px', marginLeft: '12px'}} src='/images/amazon.svg' />
      <img style={{width: '94px', marginTop: '-4px', marginRight: '20px', marginLeft: '18px'}} src='/images/google.svg' />
      <img style={{width: '102px', marginTop: '2px', marginRight: '16px', marginLeft: '25px'}} src='/images/server2.png' />

      <br />
      <br />
      <br />
      <a style={{ fontSize: '16px', color: '#888888', marginTop: '20px'}}>Learn More</a>
    </>
  )
}

const Action = () => {

  const client = useApolloClient();
  const history = useHistory()

  const [sampleData, setSampleData] = useState(sampleSubnets);

  const [hideWelcome, { data }] = useMutation(HIDE_WELCOME_MUTATION, {
    onCompleted: ({ hideWelcome }) => { 

      history.push('/')

      const query = {
        query: CURRENT_USER_QUERY,
        variables: {}
      }
      client.writeQuery({
        ...query,
        data: { currentUser: hideWelcome.user}
      }) 
  }})

  return (
    <div>
      <h2 style={{ marginBottom: '20px', fontSize: '35px', display: 'inline-block', paddingTop: '5px'}}>
        You're ready! Choose your next step:
      </h2>
      <GettingStarted showFeatures hideTitle hideDocs style={{ width: '1100px', marginLeft: 'auto', marginRight: 'auto' }} hideWelcome={ hideWelcome }/>
    </div>
  )
}

const Welcome = () => {

  const { data, loading } = useQuery(CURRENT_USER_QUERY);
  const { currentUser } = data;

  !!loading && ( <></> )

  const profileComplete = Object.keys(currentUser?.profile || {}).length == 6

  return (

    <Content style={{ position: 'fixed', width: '100%', padding: '0px 25px', zIndex: '2', background: 'none', marginTop: '-40px' }}>
        <div id="welcome" style={{ textAlign: 'center' }}>

          <Row>
            <Col span={22} offset={1}>
              <img 
                style={{ width: '180px', marginLeft: 'auto', marginRight: 'auto', marginTop: '0px', marginBottom: '40px', display: 'block'}}
                src="/images/lightmesh-light-bg.png" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Slides>
                <Intro skip={ currentUser?.hideWelcome }/>
                <UserInfo skip={ currentUser?.firstName && currentUser?.lastName } />
                <UserProfile skip={ currentUser?.profileComplete }/>
                <Navigation skip={ currentUser?.hideWelcome } />
                <Editing skip={ currentUser?.hideWelcome }/>
                <Editing skip={ currentUser?.hideWelcome } showNetmask={ true } transitionTime={ 4500 }/>
                <Action skip={ currentUser?.hideWelcome }/>
              </Slides>
            </Col>
          </Row>
        </div>
      </Content>
  )
}

export default Welcome;
