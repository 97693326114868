import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import SearchAction from "../../components/SearchAction";
import SearchTable from "../../components/SearchTable";
import Provider from "../../views/providers/Record";
import ProviderForm from "../../views/providers/Form";
import { Form, Layout, Table, Button, Drawer } from "antd";
import SearchLayout from "../layout/Search.js";
import Entity from "../../entities";
import SearchForm from "../../components/SearchForm";
import {PlusOutlined} from "@ant-design/icons";
import Info from "../info/Info";

const { Content } = Layout;

const cloudAccount = Entity['cloud-account'];

const CloudAccounts = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new';

  return (
    <SearchLayout section='Cloud Accounts'>
      <SearchForm placeholder='Search Cloud Accounts' modelName={Provider} />
      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/cloud-accounts/new'
        style={{marginTop: '12px'}}>
        Add Cloud Account
      </SearchAction>
      <SearchTable
        style={{ marginTop: '70px' }}
        query={cloudAccount.searchQuery}
        {...cloudAccount.search}
        onAdd={() => {
          history.push('/cloud-accounts/new');
        }}
        onClick={(record) => {
        }}
        emptyMessage="You haven't added any cloud accounts yet!"
        modelName="cloudAccounts"
        emptyRender={<Info model="cloudAccounts" />}
      />

      {(id || newRecord) && (
        <Drawer
          title={newRecord ? `Add cloud account` : `Update cloud account`}
          width={520}
          closable={false}
          visible={true}
          onClose={() => {
            history.push('/cloud-accounts');
          }}
        >
          <ProviderForm id={id} />
        </Drawer>
      )}
    </SearchLayout>
  );
};

export default CloudAccounts;
