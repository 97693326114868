import { loader } from 'graphql.macro';
import RemoveLink from '../../components/pants-d/RemoveLink';

const SEARCH_QUERY = loader('./queries/search.graphql')  
const GET_QUERY = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const name = "PlannedChange"

const search = {
  columns:  [
    { title: 'name', dataIndex: 'name', key: 'name', width: '14em' },
    { title: 'description', key: 'description', width: '60em' },
    { title: '', key: 'description', width: '60em', render: change => <RemoveLink record={ change } mutation={ destroy }/> }
  ]
}

export { name, search, SEARCH_QUERY, GET_QUERY, create, update, destroy };
