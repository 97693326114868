import React, { useState } from 'react';
import {  Form, Input } from 'antd';

import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import Submit from './Submit.js'


const PantDForm = ({ mutation, layout='vertical', hideControls, children, onCompleted, ...props }) => {
  const [onSubmit, { data, loading, error }] = useMutation(mutation, {
    onCompleted: (values) => { onCompleted && onCompleted(values) }
  })
  const [form] = Form.useForm()

  return (
    <Form
      form={ form }
      onFinish={(values) => { onSubmit({ variables: { ...values} })  }}
      layout={ layout }
      { ...props }>
      { children }
      { !hideControls && ( <Submit/> )}
    </Form>

  )
}

export default PantDForm;
