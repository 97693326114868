import React, { useState } from 'react';
import {  Form, Layout, Card, Row, Col, Button, Alert, Menu } from 'antd';
import { TextInput, PasswordInput, TextArea, SearchSelect, Select } from '../../components/pants-d';
import { useQuery, useMutation, useApolloClient, ApolloError } from '@apollo/client';
import zxcvbn from 'zxcvbn';
import PasswordStrengthBar from 'react-password-strength-bar';

const { CURRENT_USER_QUERY, LOGOUT_MUTATION } = require('../../entities/auth');
const user = require('../../entities/user');

const Profile = () => {
  const { loading, error, data } = useQuery(CURRENT_USER_QUERY);

  const [updateUser, { data: updateData }] = useMutation(user.update, { onCompleted: (v) => { console.log('updated') } });

  const [form] = Form.useForm();
  const [alertStatus, setAlertStatus] = useState(null);
  const [showPasswordHint, setShowPasswordHint] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState(0);

  if (loading) { return (<></>) }

  const onFinish = async (values) => {
    const { currentPassword, password, passwordConfirmation } = values;

    // Check if the password fields are empty, indicating the user doesn't want to change the password
    const isChangingPassword = password && passwordConfirmation;

    if (isChangingPassword && password !== passwordConfirmation) {
      form.setFields([
        {
          name: 'passwordConfirmation',
          errors: ['Password does not match'],
        },
      ]);
      setAlertStatus('error');
      return;
    }

    // Validate the confirm new password field only when the password field has a value
    if (!isChangingPassword && password) {
      form.setFields([
        {
          name: 'passwordConfirmation',
          errors: ['Please confirm your new password'],
        },
      ]);
      setAlertStatus('error');
      return;
    }

    // Validate the password field only when it's not empty
    if (isChangingPassword && (password.length < 8 || password.length > 64)) {
      form.setFields([
        {
          name: 'password',
          errors: ['The password must be at least 8 characters long and not longer than 64 characters.'],
        },
      ]);
      setAlertStatus('error');
      return;
    }

    try {
      await updateUser({ variables: { id: data?.currentUser?.id, currentPassword, ...values } });

      setAlertStatus(null);
      setAlertStatus('success');
    } catch (error) {
      console.error(error);
      if (error instanceof ApolloError && error.message === 'Incorrect current password') {
        form.setFields([
          {
            name: 'currentPassword',
            errors: ['Incorrect current password'],
          },
        ]);
        setAlertStatus('error');
      } else {
        setAlertStatus('error');
      }
    }
  };


  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (value.trim() !== '') {
      setShowPasswordHint(false);
      const strengthScore = zxcvbn(value).score;
      setPasswordStrength(strengthScore);
    } else {
      setShowPasswordHint(true);
      setPasswordStrength(0);
    }
  };

  // If there's no user data or if data.currentUser.localUser is false, hide the password change fields
  const showPasswordChangeFields = data?.currentUser?.localUser !== false;

  return (
    <>
      <h2>My Profile</h2>
      <p>Here you can modify your personal information</p>
      <Form
        layout="vertical"
        form={form}
        name="user"
        initialValues={data?.currentUser}
        onFinish={onFinish}
      >

        <Row>
          <Col span={12}>
            <div style={{ background: '#FFFFFF', padding: '20px', marginTop: '16px', border: '1px solid #DADADA' }}>
              {alertStatus === 'success' && (
                <Alert
                  message="Profile updated successfully!"
                  type="success"
                  showIcon
                  style={{ marginBottom: '16px' }}
                />
              )}
              {alertStatus === 'error' && (
                <Alert
                  message="Error updating the user. Please try again."
                  type="error"
                  showIcon
                  style={{ marginBottom: '16px' }}
                />
              )}
              <Row gutter={ 24 }>
                <Col span={12}>
                  <TextInput name="firstName" />
                </Col>
                <Col span={12}>
                  <TextInput name="lastName" />
                </Col>
              </Row>
              <Row gutter={ 24 }>
                <Col span={12}>
                  <TextInput name="email" />
                </Col>
              </Row>
              <Row gutter={24}>
                {showPasswordChangeFields && (
                  <>
                    <Col span={12}>
                      <PasswordInput name="currentPassword" label="Current Password" />
                    </Col>
                  </>
                )}
              </Row>
              <Row gutter={24}>
                {showPasswordChangeFields && (
                  <>
                    <Col span={12}>
                      <PasswordInput
                        name="password"
                        label="New Password"
                        onChange={handlePasswordChange}
                      />
                      <PasswordStrengthBar
                        password={form.getFieldValue('password')}
                      />
                      {showPasswordHint && (
                        <Alert
                          style={{ marginTop: '10px' }}
                          message="The password must be at least 8 characters long and not longer than 64 characters."
                          type="info"
                          showIcon
                        />
                      )}
                    </Col>
                    <Col span={12}>
                      <PasswordInput name="passwordConfirmation" label="Confirm New Password" />
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col span={12} offset={6}>
                  <Button type="primary" htmlType="submit" style={{margin: '20px 0px', float: 'right', width: '100%'}}>Update Profile</Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
};

export default Profile;
