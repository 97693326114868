import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Form, Row, Col, Space, Checkbox } from 'antd';
import qs from 'qs';
import {useHistory} from 'react-router-dom';
import {Submit, TextInput} from '../../components/pants-d';


const { CURRENT_USER_QUERY } = require('../../entities/auth');
const user = require('../../entities/user')


const profileOptions = [
  {
    key: 'hostingType',
    header: 'I am interested in',
    options: [ 'Lightmesh Cloud', 'Lightmesh Self-Hosted']
  }, {
    key: 'networkSources',
    header: 'I have addresses in',
    options: ['Azure', 'AWS', 'Google Cloud', 'Datacenters', 'Other'],
    extra: 'Please specify any additional details'
  }, {
    key: 'networkSize',
    header: 'I have roughly this many active IP addresses',
    options: ['1-100', '100-1000', '1,000 - 10,000', '10,000+', '1 million +']
  }, {
    key: 'importSource',
    header: 'I want to import my network information from:',
    options: ['Spreadsheets', 'Cloud Providers', 'Code', 'Other', 'IPAM software'],
    extra: 'Please specify any additional information'
  }, {
    key: 'featureInterest',
    header: 'I need Lightmesh for',
    options: ['Operations', 'Planning', 'Migration', 'Request Management', 'Reporting', 'Security', 'Cost Management'],
    extra: 'Please specify any addition needs'
  }, {
    key: 'demoRequested',
    header: 'I want a demo',
    options: ['Yes', 'No', 'Maybe later'],
    extra: 'Please specify any features you would like to see'
  }
]


const UserInfo = ({ onNext }) => {
  const client = useApolloClient();

  const { data } = useQuery(CURRENT_USER_QUERY);
  const { currentUser } = data;

  const [step, setStep] = useState(data?.currentUser?.length || 0);

  const [updateUser] = useMutation(user.update, {
    onError: ( e ) => { console.log(e) },

    update(cache, { updateUser }) {
      const existing = cache.readQuery({ query: CURRENT_USER_QUERY })

      cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: { currentUser: { ...existing, ...updateUser } }
      }) 
    }
  })

  const [selected, setSelected] = useState([])

  const currentStep = profileOptions[step];

  console.log(currentStep)
  console.log(profileOptions)

  const [form] = Form.useForm();

  return (
    <>
      <h2 style={{ fontSize: '20px', marginBottom: '20px'}}>
        Customize your lightmesh experience
      </h2>

      <div
        style={{ 
          background: '#F1F5F9AA',
          width: '500px', 
          padding: '35px 60px',
          marginLeft: 'auto', 
          marginRight: 'auto'}}>

        <Row>
          <h2 style={{ fontSize: '30px', marginBottom: '30px', marginLeft: '5px' }}>
            { currentStep && ( currentStep.header ) }
          </h2>
        </Row>
        <Row gutter={ 16 }>
          <Checkbox.Group onChange={(values) => { setSelected(values) }} style={{ marginLeft: '14px' }}>
            <Space direction='vertical' size='small'>
              {currentStep?.options?.map(option => ( 
                <Checkbox 
                  name={ currentStep.key }
                  style={{ 
                  fontSize: '1.5em',
                  float: 'left' }} value={option} >{option}</Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Row>

        <Row gutter={ 16 }>
          <Col span={24}> 
            <Submit 
              onClick={() => {
                updateUser({ variables: { 
                  id: currentUser.id, 
                  profile: { 
                    ...(currentUser.profile || {}),
                    [currentStep.key]: selected
                  }}})

                if (step == (profileOptions.length - 1)) {
                  onNext()
                } else {
                  setStep(step + 1)
                }
              }}
              label={ (step == (profileOptions.length - 1) && currentUser.hideWelcome )  ? "Finish" : "Next" }
              style={{
                marginTop: '40px',
                width: '200px',
                background: 'none',
            }} />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default UserInfo;
