import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";

import {  Form, Layout, Button, Drawer } from 'antd';
import SearchForm from '../../components/SearchForm';
import SearchAction from '../../components/SearchAction';
import SearchTable from '../../components/SearchTable';
import ContactForm from '../../views/contacts/Form';
import SearchLayout from '../layout/Search.js';
import Entity from '../../entities';
import {PlusOutlined} from '@ant-design/icons';
import Info from '../info/Info';

const contact = Entity['contact'] 

const { Content } = Layout;

const Contacts = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'

  return (
    <SearchLayout section='Contacts'>
        <SearchForm placeholder='Search contacts' modelName={ Contacts } />

        <SearchAction 
          icon={ <PlusOutlined /> }
          url='/contacts/new'
          style={{marginTop: '12px'}}>
          Add contact
        </SearchAction>
        <SearchTable 
          style={{ marginTop: '70px' }}
          query={ contact.searchQuery }
          { ...contact.search }
          onAdd={() => { 
            history.push('/contacts/new') 
          }}
          onClick={(record) => { history.push(`/contacts/${record?.id}`) }}
          emptyMessage="You haven't added any contacts yet!"
          modelName='contacts' 
          emptyRender={<Info model="contacts" />}
        />

      { (id || newRecord) && ( 
        <Drawer
          title={newRecord ? `Add contact` : `Update contact`}
          width={520}
          closable={false}
          visible={true}
          onClose={() => { history.push('/contacts')}}>

          <ContactForm id={ id } /> 

        </Drawer>
      ) }
    </SearchLayout>
  )
};

export default Contacts;
