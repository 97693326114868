import React, { useEffect, useState } from 'react';
import { ArrowUpOutlined, CaretLeftFilled, CompressOutlined, EllipsisOutlined, ExpandOutlined, FullscreenExitOutlined, FullscreenOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Layers } from '../../components/pants-d/CustomIcons';
import { Row, Col, Layout, Breadcrumb } from 'antd';
import { useHistory, useParams } from "react-router-dom";

import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { searchVar } from '../../cache.js';

import { Subnav, Actions } from '../layout/Subnav.js';
import { SubnetBuilder } from '../../components/SubnetBuilder';
import SubnetTree from '../../components/SubnetTree';
import SearchForm from '../../components/SearchForm';
import SearchTable from '../../components/SearchTable';
import SearchAction from '../../components/SearchAction';
import IPForm from '../ip-assignments/Form';
import SubnettingForm from '../subnets/SubnettingForm';
import ReservationForm from '../reservations/ReservationForm';
import SubnetHistory from './SubnetHistory';
import SubnetHeader from './SubnetHeader';
import SubnetDetails from './SubnetDetails';
import RoleAssignmentForm from './RoleAssignmentForm';
import ContactSubnetForm from './ContactSubnetForm';
import CustomerSubnetForm from './CustomerSubnetForm';
const { CURRENT_USER_QUERY } = require("../../entities/auth");
const { getActions } = require('../../lib/auth.js');


import cidrRegex from 'cidr-regex';

import Entity from '../../entities';
import Info from '../info/Info.js';
import ApprovalForm from '../requests/ApprovalForm.js';
import SupportGroupSubnetForm from './SupportGroupSubnetForm.js';
import NATSubnetForm from './NATSubnet.js';
import { activeUserVar } from '../../cache.js';
import ExportCSVButton from '../Export/ExportIpAddress.js';
import AlertSubnetForm from './AlertSubnetForm.js';
import ImportNmapSubnetForm from './ImportNmapSubnetForm.js';

const request = Entity['request'];
const subnet = Entity['subnet'];
const scan = Entity['scan'];
const customer = Entity['customer'];
const nat = Entity['nat'];
const ipAddress = Entity['ip-address'];
const resource = Entity['resource'];
const ipAssignment = Entity['ip-assignment'];
const supportGroup = Entity['support-group'];
const alertRule = Entity['alert-rule'];
const contact = Entity['contact'];
const vlan = Entity['vlan'];
const user = Entity['user'];
const roleAssignment = Entity['role-assignment'];

const { Content } = Layout;


const ReservationLink = subnet.ReservationLink;
const RequestLink = subnet.RequestLink;


function SubnetView({ editing }) {

  const { supernetId, subnetId, childView, childId } = useParams();
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    searchVar('')
    setFullScreen(false)
  }, [childView])
  
  let history = useHistory();

  const subnetUrl = `/subnets/${supernetId}/${subnetId || supernetId}`

  const onDrawerClose = () => {
    history.push(`/subnets/${(supernetId || subnetId)}/${subnetId ? subnetId + '/' : ''}${childView ? (childView == 'reservations' ? 'ip-addresses' : childView) : ''}`)
  }

  const [editingSubnet, setEditingSubnet] = useState(true);

  const [editingReservation, setEditingReservation] = useState();

  const [refetchIPAddresses, setRefetchIPAddresses] = useState(0);

  const [netmask, setNetmask] = useState();

  const [subnetHeight, setSubnetHeight] = useState(25);

  const { loading, error, data, refetch } = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(supernetId || subnetId) } });


  const [createRecord, { data: createData }] = useMutation(subnet.create, {
    onCompleted: (createSubnet) => {
      history.push(`/subnets/${(supernetId || subnetId)}/${createSubnet?.createSubnet?.id}`)
      refetch()
    }
  });

  const { loading: currentUserLoading, data: currentUserData } = useQuery(CURRENT_USER_QUERY);
  const userConfig = JSON.parse(currentUserData?.currentUser?.config)

  const [updateUser, {}] = useMutation(user.update, {
    update(cache, { data }) {

      cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: { 
          currentUser: {
            ...data?.currentUser 
          } 
        }
      })
    }
})

  const [offset, setOffset] = useState(0); 
  const [limit, setLimit] = useState(50); 

  const handleTableChange = (onTableChange) => {
      setLimit(onTableChange.pageSize);
      setOffset((onTableChange.current - 1) * onTableChange.pageSize);
  };

  const { data: subnetData } = useQuery(ipAddress.searchQuery, { variables: { subnetId: parseInt(subnetId || supernetId), search: "", limit, offset} });

  const [destroySubnet, { data: destroyData, error: destroyError }] = useMutation(subnet.DESTROY_SUBNET, {
    onCompleted: (destroyedSubnet) => {
      history.push(subnetUrl)
      refetch()
    },
    onError: (e) => { console.log(e) }
  });

  const activeUser = useReactiveVar(activeUserVar);

  const actions = getActions(activeUser, data?.subnet)

  const cloud = ['Azure', 'AWS']?.includes(data?.subnet?.zone?.provider?.type)

  const readOnly = (!actions?.includes('create') || cloud)

  const searchSections = {
    'ip-addresses': {
      search: {
        query: ipAddress.searchQuery,
        ...ipAddress.search,
        modelName: 'ip-addresses',
        refetch: refetchIPAddresses,
        onClick: (r) => {
          if (r.request) {
            return null
          } else if (r.ipAssignment) {
            history.push(`${subnetUrl}/ip-addresses/${r?.ipAssignment?.id}?networkAddress=${r?.networkAddress}`)
          } else {
            history.push(`${subnetUrl}/ip-addresses/new?networkAddress=${r?.networkAddress}`)
          }
        },
        columns: [
          ...subnet.ipSearch.columns,
          {
            title: 'Reservation', key: 'status', width: '14em', render: ipAddress => <>
              { ipAddress?.request && 
                <RequestLink
                  ipAddress={ipAddress}
                  onClick={(request) => { history.push(`${subnetUrl}/requests/${request?.networkRequestId}`) }}
                />
              }
              { !ipAddress?.request && (

                <ReservationLink
                  ipAddress={ipAddress}
                  onClick={(reservation) => { history.push(`${subnetUrl}/reservations/${reservation?.id}`) }}
                />
              )}
            </>
          },
          { title: ' ', dataIndex: 'reservation', width: '4em', align: 'center', render: reservation => <EllipsisOutlined /> }
        ],
        filters: { subnetId: parseInt(subnetId || supernetId), limit, offset }
      }
    },
    'subnets': {
      search: {
        emptyRenderName: "subnets",
        modelName: 'subnets',
        query: subnet.searchQuery,
        ...subnet.childSearch,
        emptyMessage: "No subnets currently allocated to this subnet",
        onAdd: () => { history.push(`${subnetUrl}/subnets/new`) },
        emptyAction: "Add subnets",
        filters: { supernetId: parseInt(subnetId || supernetId) }
      }
    },
    'nats': {
      search: {
        emptyRenderName: "nats",
        modelName: "nat",
        query: nat.search,
        ...nat.natSearch,
        emptyMessage: "Looks like no NAT have been associated to this subnet",
        emptyAction: "Manage NATs",
        onAdd: () => { history.push(`${subnetUrl}/nats/new`) },
        onClick: (nat) => { history.push(`${subnetUrl}/nats/${nat?.id}`) },
        filters: { subnetId: parseInt(subnetId || supernetId) }
      }
    },
    'resources': {
      search: {
        emptyRenderName: "resources",
        modelName: "resources",
        query: resource.searchQuery,
        ...resource.search,
        emptyMessage: "No resources currently allocated to this subnet",
        emptyAction: "Add resource",
        onAdd: () => { history.push(`${subnetUrl}/resources/new`) },
        filters: { subnetId: parseInt(subnetId || supernetId) }
      }
    },
    'requests': {
      search: {
        emptyRenderName: "requests",
        modelName: "requests",
        query: request.searchQuery,
        ...request.search,
        emptyMessage: "There are currently no requests for this subnet",
        onClick: (request) => { history.push(`${subnetUrl}/requests/${request?.id}`) },
        filters: { subnetId: parseInt(subnetId || supernetId) }
      }
    },
    'activity': {
      render: <SubnetHistory zoneId={ data?.subnet?.zone?.id } />
    },
    'role-assignments': {
      search: {
        emptyRenderName: "role-assignments",
        modelName: "role-assignments",
        query: roleAssignment.searchQuery,
        ...roleAssignment.search,
        emptyMessage: "Looks like you're the only one with permissions on this subnet",
        emptyAction: "Manage role assignments",
        onAdd: () => { history.push(`${subnetUrl}/role-assignments/new`) },
        onClick: (role) => { history.push(`${subnetUrl}/role-assignments/${role?.id}`) },
        filters: { recordId: parseInt(subnetId || supernetId), recordType: 'Subnet' }
      }},
    'support-groups': {
      search: {
        emptyRenderName: "support-groups",
        modelName: "subnet-support-groups",
        query: supportGroup.SUBNET_SUPPORT_GROUPS,
        ...supportGroup.subnetSearch,
        emptyMessage: "No support group has been added",
        emptyAction: "Add support group",
        onAdd: () => { history.push(`${subnetUrl}/support-groups/new`) },
        filters: { subnetId: parseInt(subnetId || supernetId) }
      }
    },
    'customers': {
      search: {
        emptyRenderName: "customers",
        modelName: "customers",
        query: customer.searchQuery,
        ...customer.subnetSearch,
        emptyMessage: "Looks like no customers have been associated to this subnet",
        emptyAction: "Manage customers",
        onAdd: () => { history.push(`${subnetUrl}/customers/new`) },
        filters: { subnetId: parseInt(subnetId || supernetId) },
      }
    },
    'contacts': {
      search: {
        emptyRenderName: "contacts",
        modelName: "contacts",
        query: supportGroup.SUBNET_SUPPORT_GROUP_CONTACTS,
        ...contact.subnetSearch,
        emptyMessage: "No contacts have been added",
        emptyAction: "Add contact",
        onAdd: () => { history.push(`${subnetUrl}/contacts/new`) },
        filters: { subnetId: parseInt(subnetId || supernetId), contactableId: parseInt(subnetId || supernetId), contactableType: 'Subnet' }
      }
    },
    'alerts': {
      search: {
        emptyRenderName: "alerts",
        modelName: "alerts",
        query: alertRule.searchQuery,
        ...alertRule.alertRuleSubnetSearch,
        emptyMessage: "No alert has been set for this subnet",
        emptyAction: "Add alert",
        onAdd: () => { history.push(`${subnetUrl}/alerts/new`) },
        filters: { SubnetId: parseInt(subnetId || supernetId) }
      }
    },
    'imports': {
      search: {
        emptyRenderName: "imports",
        modelName: "SubnetIPScans",
        query: scan.SEARCH_SCAN,
        ...scan.scanSearch,
        emptyAction: "Add NMAP",
        onAdd: () => { history.push(`${subnetUrl}/imports/new`) },
        filters: { subnetId: parseInt(subnetId || supernetId) }
      }
    },
  }

  const searchOpts = (childView && childView !== 'dashboard') ? searchSections[(childView == 'reservations' ? 'ip-addresses' : childView)] : {}

  if (loading || currentUserLoading) {
    return (
      <div className="record-wrapper">
        <div className="record">
        </div>
      </div>
    )
  } else {

    const v4 = (cidrRegex.v4({ exact: true }).test(data?.subnet?.networkAddress))

    const breadCrumbStyles={marginTop: '8px', display: 'inline-block', fontSize: '18px', color: '#336b99'};
    return (
      <>
        <Subnav class="sub-nav" style={{ padding: '1 10px 1px 0px' }}>
          <Breadcrumb style={{ marginTop: '8px', display: 'inline-block', fontSize: '18px', color: '#55738b'}}>
            <Breadcrumb.Item {...breadCrumbStyles}> 
              <a 
                style={{ color: '#55738b'}} 
                onClick={e => history.push('/network')}>
                <CaretLeftFilled style={{ display: 'inline-block', fontSize: '12px', lineHeight: '0px', verticalAlign: 'text-top', marginLeft: '-16px', paddingTop: '1px', marginRight: '4px' }} /></a>
              <a 
                onClick={e => history.push('/subnets')}
                style={{ color: '#55738b', fontWeight: '500', fontSize: '17px', lineHeight: '18px' }} >
                Subnets
              </a></Breadcrumb.Item>
            {data?.subnet && (
            <Breadcrumb.Item> 
              <a style={{ color: '#55738b',  fontWeight: '500',fontSize: '17px', lineHeight: '18px', letterSpacing: '0.1em' }} >
                {data?.subnet?.networkAddress?.replace('/', ' / ')}
              </a>
            </Breadcrumb.Item>
            )}
          </Breadcrumb>
          { data?.subnet?.networkAddress && (
          <Actions>
            <div
              style={{ fontSize: '14px', float: 'right', marginTop: '6px', marginRight: '20px' }} 
            >
            </div>
            <a 
              style={{ 
                fontSize: '14px', 
                float: 'right', 
                marginTop: '10px', 
                marginRight: '40px', 
                color:(userConfig?.views?.subnet?.navigation == 'tree' ? '#2c6696' : '#758fa3') 
              }} 
              onClick={(e) => { 
                updateUser({variables: { id: currentUserData?.currentUser?.id, config: JSON.stringify(_.merge(userConfig, { 
                  views: {subnet: {navigation: 'tree'}}
                }))}})
              }}> 
              tree view
            </a>

            <a 
              style={{ 
                fontSize: '14px', 
                float: 'right', 
                marginTop: '10px', 
                marginRight: '40px',
                color:((userConfig?.views?.subnet?.navigation || 'builder') == 'builder' ? '#2c6696' : '#758fa3') 
              }} 
              onClick={(e) => { 
                updateUser({variables: { id: currentUserData?.currentUser?.id, config: JSON.stringify(_.merge(userConfig, { 
                  views: {subnet: {navigation: 'builder'}}
                }))}})
              }}> 
              visual view
            </a>

          </Actions>
          )}
        </Subnav>
        <Content style={{ marginTop: '5px', padding: '40px 25px' }}>
          <Row type='flex'>
            <Col flex="5 1 200px" style={{ overflow: 'hidden' }}>
              { data && (userConfig?.views?.subnet?.navigation !== 'tree') &&
              <SubnetBuilder
                hideEditor={ readOnly }
                editing={editingSubnet}
                editingNetmask={netmask}
                data={data?.subnet}
                active={(subnetId || supernetId)}
                style={{ marginBottom: '20px' }}
                height={subnetHeight}
                onCreate={createRecord}
                onDestroy={(subnet) => { destroySubnet({ variables: { id: subnet?.id } }) }}
                onClick={(s) => {
                  const url = s ? `/subnets/${data?.subnet.id}/${s?.id}` : `/subnets`
                  history.push(url)
                }}
                onDblClick={(s) => {
                  if (s?.id) {
                    const url = `/subnets/${s.id}/${s.id}`;
                    history.push(url);
                  }
                }}
              />
              }
              <SubnetHeader id={(subnetId || supernetId)} readOnly={readOnly} style={{ marginTop: '0px' }} />

              { !(error && error.graphQLErrors?.filter(e => { return e?.extensions?.code == 'FORBIDDEN' })?.length > 0) && (

                <div className={ 'subnet-view-body' + (fullScreen ? ' full-screen' : '') } style={{ padding: '15px 25px', background: '#FFFFFF', minHeight: '400px'}}>

                  {actions?.includes('create') && childView == 'ip-addresses' && (
                    <div onClick={() => { setFullScreen(false) }}>
                      { currentUserData.currentUser.UserOrganizations[0].role === 'Owner' && <ExportCSVButton onClick={() => { setFullScreen(!fullScreen) }} subnetData={subnetData}/> }
                    </div>
                  )}

                  { childView == 'ip-addresses' && (
                    <SearchAction onClick={() => { setFullScreen(!fullScreen) }}>
                      { fullScreen ? <CompressOutlined style={{ fontSize: '14px', marginRight: '4px' }}/> : <ExpandOutlined style={{ fontSize: '13px', marginRight: '4px' }}/> } Fullscreen
                    </SearchAction>
                  )}

                  {/* {actions?.includes('create') && childView == 'ip-addresses' && (
                    <>
                      <SearchAction url={`${subnetUrl}/reservations/new`} style={{ marginRight: '20px' }}>
                        <Layers style={{ width: '15px', display: 'inline-block', marginRight: '8px', color: '#5384ac' }}/>
                        Layers
                      </SearchAction>
                      
                    </>
                  )} */}


                  {actions?.includes('create') && childView == 'ip-addresses' && (
                    <SearchAction 
                      url={`${subnetUrl}/reservations/new`}
                      style={{ marginRight: '20px' }}>
                      Reserve
                    </SearchAction>
                  )}


                  { searchOpts?.search && ( 
                    <SearchForm style={{ float: 'left' }} modelName={ipAssignment} defaultValue={searchVar} childView={childView} />
                  )}


                  {actions?.includes('create') && childView == 'subnets' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/subnets/new`}>Add a Subnet</SearchAction>)}
                  {actions?.includes('assign') && childView == 'role-assignments' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/role-assignments/new`}>Add Role Assignment</SearchAction>)}
                  {actions?.includes('create') && childView == 'customers' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/customers/new`}>Add Customer</SearchAction>)}
                  {actions?.includes('create') && childView == 'nats' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/nats/new`}>Add NAT</SearchAction>)}
                  {actions?.includes('create') && childView == 'contacts' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/contacts/new`}>Add Contacts</SearchAction>)}
                  {actions?.includes('create') && childView == 'support-groups' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/support-groups/new`}>Add Support Group</SearchAction>)}
                  {actions?.includes('create') && childView == 'alerts' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/alerts/new`}>Add Alert Rule</SearchAction>)}
                  {actions?.includes('create') && childView == 'imports' && (<SearchAction 
                    icon={<PlusOutlined style={{ fontSize: '12px', fontWeight: 'bold' }} />}
                    url={`${subnetUrl}/imports/new`}>Upload Scan</SearchAction>)}

                  {(!childView || childView == 'dashboard') && (
                    <SubnetDetails record={ data?.subnet } key={(subnetId || supernetId)} readOnly={ readOnly }/>
                  )}

                  {searchOpts?.search && (
                    <SearchTable
                      style={{ paddingTop: '5px', marginTop:'68px', marginLeft: '2px' }}
                      readOnly={readOnly}
                      {...searchOpts.search}
                      key={(subnetId || supernetId)}
                      emptyRender={<Info model={searchOpts.search.emptyRenderName} />}
                      onInternalTableChange={handleTableChange}
                    />
                  )}
                  {searchOpts.render}

                  { editing && (<SubnettingForm supernetId={parseInt(subnetId || supernetId)} onCreate={() => { onDrawerClose }} onClose={onDrawerClose} />)}

                  {childId && (
                    <>
                      {childView == 'ip-addresses' && (<IPForm
                        id={childId}
                        subnetId={(subnetId || supernetId)}
                        onClose={onDrawerClose}
                        onDestroy={onDrawerClose}
                      />)}
                      {childView == 'reservations' && (
                        <ReservationForm
                          data={editingReservation}
                          onClose={() => {
                            onDrawerClose()
                            setRefetchIPAddresses(refetchIPAddresses + 1)
                          }} />
                      )}
                      {childView == 'subnets' && (<SubnettingForm supernetId={parseInt(subnetId || supernetId)} onCreate={() => { onDrawerClose }} onClose={onDrawerClose} />)}
                      {childView == 'nats' && (<NATSubnetForm onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                      {childView == 'role-assignments' && (<RoleAssignmentForm onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                      {childView == 'contacts' && (<ContactSubnetForm onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                      {childView == 'support-groups' && (<SupportGroupSubnetForm onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                      {childView == 'customers' && (<CustomerSubnetForm onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                      {childView == 'requests' && (<ApprovalForm id={ childId } onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                      {childView == 'alerts' && (<AlertSubnetForm onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                      {childView == 'imports' && (<ImportNmapSubnetForm onCreate={onDrawerClose} onClose={onDrawerClose} />)}
                    </>
                  )}
                </div>
              )}
            </Col>
            { data && (userConfig?.views?.subnet?.navigation == 'tree') && 

            <Col 
              flex='0 0 320px'
              style={{ 
                width: '320px',
                background: 'none',
                marginTop: '22px',
                padding: '0px 10px'
              }}
            >
              <SubnetTree 
                onSelect={(subnet) => {
                  const url = subnet?.id ? `/subnets/${data?.subnet.id}/${subnet?.id}` : `/subnets`
                  history.push(url)
                }}
                readOnly={ readOnly }
                data={data?.subnet}
                editingNetmask={netmask}
                selected={[parseInt(subnetId || supernetId)]}
                onCreate={createRecord}
                onDestroy={(subnet) => { destroySubnet({ variables: { id: subnet?.id } }) }}
                onClick={(s) => {
                  const url = s ? `/subnets/${data?.subnet.id}/${s?.id}` : `/subnets`
                  history.push(url)
                }}
                onDblClick={(s) => {
                  const url = s ? `/subnets/${s?.id}/${s?.id}` : `/subnets`
                  history.push(url)
                }}
              />
            </Col>
            }
          </Row>
        </Content>
      </>
    )
  }
};

export default SubnetView;
