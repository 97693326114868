import React from 'react'
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Link, useHistory, useParams } from "react-router-dom";
import { Alert } from 'antd';

import RemoveRecordButton from '../../lib/threeDotRemoveButton';


const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  
const importRecords = loader('./mutations/import.graphql')  
const ADD_CONTACTS_TO_SUBNET = loader('./mutations/ADD_CONTACTS_TO_SUBNET.graphql')  
const REMOVE_CONTACT_FROM_SUBNET = loader('./mutations/REMOVE_CONTACT_FROM_SUBNET.graphql')  
const subnet = require("../../entities/subnet");

const RemoveContactLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Contact"
        entityTitle="contact"
        redirectPath={`/contacts`}
      />
    </div>

  )
}

const RemoveContactFromSubnetLink = ({ record, contactId, contactName }) => {

  const { subnetId, supernetId } = useParams();
  const { loading, error:dataError, data:subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={REMOVE_CONTACT_FROM_SUBNET}
        mutationVariables={{subnetId: parseInt(subnetId || supernetId), contactId: parseInt(contactId), contactName: contactName, networkAddress: subnetData.subnet?.networkAddress, zoneId: subnetData.subnet?.zone?.id}}
        menuItems={["remove"]} 
        entityName="Contact"
        entityTitle="contact"
        redirectPath={`/subnets/${supernetId}/${subnetId || supernetId}/contacts`}
      />
    </div>

  )
}

const search = {
  query: searchQuery,
  columns:  [
    { 
      title: 'Name',
      sorter: true,
      render: contact => (
        <>
          {contact.firstName && contact.lastName ? `${contact.firstName} ${contact.lastName}` :
            contact.firstName ? contact.firstName :
            contact.lastName ? contact.lastName : 'No Name'
          }
        </>
      )
    },
    { title: 'Email', key: 'email', dataIndex: 'email'},
    { 
      title: 'Support Group', 
      dataIndex: 'supportGroup', 
      key: 'supportGroup',
      render: supportGroup => (
        <>
          {supportGroup?.name ? (
            <a 
              href={`/support-groups/${supportGroup.id}`} 
              onClick={event => event.stopPropagation()}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {supportGroup.name}
            </a>
          ) : null}
        </>
      ) 
    },
    { title: '', width: 100, key: 'remove', render: contact => <RemoveContactLink record={ contact }/> } 
  ]
}

const subnetSearch = {
  columns:  [
    { 
      title: 'Name', 
      key: 'name', 
      sorter: true,
      render: contact => (
        <>
          {contact.firstName && contact.lastName ? `${contact.firstName} ${contact.lastName}` :
            contact.firstName ? contact.firstName :
            contact.lastName ? contact.lastName : 'No Name'
          }
        </>
      )
    },
    { title: 'Email', key: 'email', dataIndex: 'email' },
    { title: 'Group', key: 'supportGroup', render: contact => <>{ contact?.supportGroup?.name }</> },
    { title: '', width: 100, key: 'remove', render: contact => <RemoveContactFromSubnetLink record={contact} contactId={ contact.id } contactName={(contact.firstName ? contact.firstName : '') + ( contact.lastName ? ' ' +  contact.lastName : '')}/> }
  ]
}

const form = {
  fields: [
    ["name", {}]
  ]
}

const importer = {
  mutation: importRecords,
  search: {
    query: searchQuery,
    columns:  [
      { title: 'Name', key: 'name', render: contact => <>{ contact.firstName + ' ' + contact.lastName}</> },
      { title: 'Email', key: 'email', dataIndex: 'email' },
      { title: '', width: 100, key: 'remove', render: contact => <RemoveContactLink id={ contact.id }/> }
    ]
  },
  fields: [
    {name: 'firstName', alias: 'First Name', required: true},
    {name: 'lastName', alias: 'Last Name', required: true},
    {name: 'phone'},
    {name: 'cellPhone', alias: 'Cell Phone'},
    {name: 'email'},
    {name: 'email2', alias: 'Email 2'}
  ],
  exampleSheet: 'ContactsExample',
  title: 'Contacts',
  copy: 'Contacts in Lightmesh are any person who may be related to your network.  They can belong to a Customer, or a Support Group.  They can be tied to a subnet or resource and are useful to track for support and planning purposes.',
  note: (
    <Alert
      style={{ marginTop: '10px' }}
      message={
        <div dangerouslySetInnerHTML={{ __html: "<strong>First Name</strong> and <strong>Last Name</strong> are required fields." }} />
      }
      type="info"
      showIcon
    />
  )
}

export { search, subnetSearch, form, searchQuery, getQuery, create, update, destroy, importer, ADD_CONTACTS_TO_SUBNET };
