import React, { useState } from 'react';
import {  Row, Col, Button, Drawer, Form, Menu, Layout, Select, Input, Card, Space } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { USER_GROUP_USERS, getQuery, ADD_USERS_TO_USER_GROUP } from '../../entities/user-group';
import MultiSelect from '../../components/MultiSelect';

const user = require('../../entities/user');

const UserUserGroupForm = ({ userGroupId, onClose }) => {

  const [form] = Form.useForm();

  const { loading, error, data } = useQuery(getQuery, { variables: { id: parseInt(userGroupId) }});
  const [addUser, { createData, createLoading, createError}] = useMutation(ADD_USERS_TO_USER_GROUP, {
    onCompleted: () => onClose(),
    onError: (e) => { console.log(e) },
    update(cache, {data}) { 

      const existing = cache.readQuery({ query: USER_GROUP_USERS, variables: { search: "", offset: 0, limit: 50, userGroupId: parseInt(userGroupId) }})

      cache.writeQuery({
        query: USER_GROUP_USERS,
        variables: { search: "", offset: 0, limit: 50, userGroupId: parseInt(userGroupId) },
        data: {
          userGroupUsers: {
            count: (existing?.userGroupUsers?.count || 0) + 1,
            results: [...(existing?.userGroupUsers?.results || []), ...data?.addUsersToUserGroup],
            __typename: "UserList"
          }
        }
      })
    }
  });

  const { data: userData } = useQuery(user.searchQuery, {
    variables: {search: "", offset: 0, limit: 50},
  });

  if (loading) { return <></>}

  return (
    <Form
      style={{ padding: '20px 5px'}}
      layout="vertical"
      form={form}
      name="user"
      normalize={(users) => {
        const result = users?.map(c => {
          return {
            id: c,
          }})
        return result
      }}
      initialValues={[]}
      onFinish={ ({ users }) => {
        addUser({ variables: { userIds: users.map(u => parseInt(u)), id: parseInt(userGroupId) }})
      }}
    >
      <Form.Item 
        label={`Add users to ${ data?.userGroup?.name }`} 
        name="users">
          <Select
            name="users"
            mode="multiple"
          >
            {userData?.users?.results
              .filter((user) => user?.User !== null)
              .map((user) => (
                <Option key={user?.User?.id} value={user?.User?.id}>
                  {user?.User?.firstName} {user?.User?.lastName}
                </Option>
              ))}
          </Select>
      </Form.Item>

      <Row>
        <Col span={24}>
          <Space>
            <Button type="primary" htmlType="submit"> Submit </Button>
            <Button onClick={() => onClose()} type="primary"> Cancel </Button> 
          </Space>
        </Col>
      </Row>

    </Form>
  )
}

export default UserUserGroupForm;
