import React, { useState } from 'react';
import { loader } from 'graphql.macro';

import { useMutation } from '@apollo/client';
import { Alert, Button, Drawer, Form, Input, Spin } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')

const RemoveAlertDestinationLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Alert Destination"
        entityTitle="Alert Destination"
        redirectPath={`/settings/alerting/alert-destinations`}
      />
    </div>

  )
}

const SearchComponent = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [alertDestinationData, setAlertDestinationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [updateAlertDestination, { data: updateData }] = useMutation(update, {
    onCompleted: (v) => { console.log("completed") },
    update(cache, { data }) {
      cache.evict(`AlertDestination:${alertDestinationData.id}`);
    }
  });

  const showDrawer = () => {
    history.push(`/settings/alerting/alert-destinations`)
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const verifyCode = (enteredCode, expectedCode) => {
    return new Promise((resolve, reject) => {
      if (enteredCode === expectedCode) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const handleVerification = () => {
    setLoading(true);
    verifyCode(verificationCode, alertDestinationData.verificationCode)
      .then(isVerified => {
        if (isVerified) {
          updateAlertDestination({ variables: { id: alertDestinationData.id, status: 'Verified' } });
          setErrorMessage('');
        } else {
          setErrorMessage('Verification code does not match');
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const search = {
    columns: [
      { title: 'Name', dataIndex: 'name', sorter: true },
      { title: 'Type', dataIndex: 'type', sorter: true },
      {
        title: 'Webhook',
        dataIndex: 'URL',
        render: (text) => text && text !== 'N/A' ? 'X'.repeat(10) + text.slice(-4) : text
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (text) => text ? text : 'N/A'
      },
      {
        title: 'Status',
        sorter: true,
        render: (alertDestination) => {
          const status = alertDestination.status;
          if (status === 'Pending') {
            setAlertDestinationData(alertDestination)
            return (
              <>

                <ExclamationCircleOutlined style={{ color: 'grey', marginRight: '5px' }} />
                {status}

                <Button type="primary" style={{ marginLeft: '10px' }} onClick={(event) => { event.stopPropagation(); showDrawer(); }}>
                  Verify
                </Button>
                <Drawer
                  title="Verification"
                  visible={visible}
                  onClose={(event) => {
                    event.stopPropagation();
                    closeDrawer();
                  }}
                  onClick={(event) => event.stopPropagation()}
                >
                  <Spin spinning={loading}>
                    <Form layout="vertical" hideRequiredMark>
                      {errorMessage && 
                      <div>                  
                        <Alert
                        style={{ marginBottom: '20px' }}
                        message={errorMessage}
                        closable
                        type="error"
                        />
                      </div>}
                      <Form.Item
                        name="verificationCode"
                        label="Verification Code"
                        onClick={(event) => event.stopPropagation()}
                        rules={[{ required: true, message: 'Please input the verification code' }]}
                      >
                        <Input placeholder="Enter verification code" onChange={e => setVerificationCode(e.target.value)} />
                      </Form.Item>
                      <Button type="primary" htmlType="submit" onClick={(event) => { event.stopPropagation(); handleVerification() }}>
                        Submit
                      </Button>
                    </Form>
                  </Spin>
                </Drawer>
              </>
            );
          } else if (status === 'Verified') {
            return (
              <>
                <CheckCircleOutlined style={{ color: 'green', marginRight: '5px' }} />
                Verified
              </>);
          } else {
            return (
              <>
                <ExclamationCircleOutlined style={{ color: 'grey', marginRight: '5px' }} />
                {status}
              </>
            );
          }
        },
      },
      { title: '', width: 100, key: 'Destroy', render: alertDestination => <RemoveAlertDestinationLink record={ alertDestination }/> }
    ],
  };

  return { search }
}

export { SearchComponent, getQuery, searchQuery, create, update, destroy };
