import React from 'react';
import SearchTable from '../../components/SearchTable';
import { Row, Col } from 'antd';
import { GET_NEW_USERS_BY_DAY, GET_NEW_USERS_BY_MONTH } from '../../entities/api';

function toLocaleUTCDateString(date, locales, options) {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
}

function Registrations(props) {

  return (
      <Row gutter={ 24 }>
        <Col span={12}>
          <h2>New Users By Day</h2>
          <SearchTable
            size='small'
            query={ GET_NEW_USERS_BY_DAY}
            columns={[
              { title: 'Date', dataIndex: 'date', key: 'date', width: '14em', render: (label) => {
                if (label) {
                  const d = new Date(parseInt(label))
                  return (<p>{ toLocaleUTCDateString(d,undefined, { month: 'long', day: 'numeric', year: 'numeric' }) }</p>)
                }
              }},
              { title: 'Active Users', dataIndex: 'count', key: 'count', width: '14em' },
            ]}
          />
        </Col>
        <Col span={12}>
          <h2>New Users By Month</h2>
          <SearchTable
            query={ GET_NEW_USERS_BY_MONTH }
            columns={[
              { title: 'Month', dataIndex: 'label', key: 'month', width: '14em', render: (label) => {
                if (label) {
                  const d = new Date(parseInt(label))
                  return (<p>{ toLocaleUTCDateString(d,undefined, { month: 'long', year: 'numeric' }) }</p>)
                }
              }},
              { title: 'New Users', dataIndex: 'value', key: 'count', width: '14em' },
            ]}
          />
        </Col>
      </Row>
  )
}

export default Registrations;
