import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from '@apollo/client';

import { CustomAttributes, TextInput, TextArea, Select } from '../../components/pants-d';
import SearchTable from '../../components/SearchTable';
import SearchSelect from '../../components/pants-d/SearchSelect';

import {  Row, Col, Button, Form, Menu, Layout, Input, Radio } from 'antd';

const { Option } = Select;

const zone = require('../../entities/zone')
const provider = require('../../entities/provider')

const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 }


const ZoneForm = ({ onCreate, onUpdate, onClose, defaults }) => {
  const { model, id } = useParams();

  const newRecord = id == 'new'
  const [getZone, { loading, error: recordError, data }] = useLazyQuery(zone.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => { if (id && !newRecord) { getZone({ variables: {id: parseInt(id)}})}}, [id])


  const [ createZone, { data: createData, error: createErrors }] = useMutation(zone.create, { 
    onCompleted: onClose,
    onError: ( e ) => { console.log(e) },
    update(cache, { data }) {

      const existing = cache.readQuery({ 
        query: zone.searchQuery, 
        variables: {
          search: "", 
          offset: 0, 
          limit: 50, 
          providerId: parseInt(data?.provider?.id)} 
      })

      cache.writeQuery({
        query: zone.searchQuery,
        variables: {search: "", offset: 0, limit: 50, providerId: parseInt(data?.provider?.id)},
        data: {
          zones: {
            __typename: "ZoneList",
            count: (existing.count || 0) + 1,
            results: [...(existing?.zones?.results || []), data.createZone]
          }
        }
      })
    }
  })

  const [updateZone, { data: updateData }] = useMutation(zone.update, { 
    onCompleted: onClose,
    onError: ( e ) => { console.log(e) }
  })

  const [form] = Form.useForm()

  if (createErrors) { 
    const errors = createErrors.graphQLErrors.map(e => {
      return { 
        name: e.extensions.exception.errors[0].path,
        errors: [e.extensions.exception.errors[0].message]
      }
    });

    form.setFields(errors)
  }

  useEffect(() => { form.setFieldsValue(data?.['zone']) }, [data?.['zone']])

  const saveRecord = (values) => { if (newRecord) {
    createZone({ variables: {...values}}) 
  } else {
    updateZone({variables: {id: parseInt(id), ...values}}) }
  }

  if (loading) { return <></> }

  return (
    <Form
      layout="vertical"
      form={ form }
      name="zone"
      initialValues={ data?.['zone'] || {}}
      onFinish={ (values) => { 
      saveRecord({ ...values, providerId: parseInt(values?.provider?.id) })}}>

      <SearchSelect 
        style={{ width: '100%' }}
        name="provider"
        label="Datacenter"
        resultKey="providers"
        defaultValue={ data?.zone?.provider }
        displayKey="name"
        query={ provider.searchQuery }
        createLabel={ 'Create a datacenter' }
        createMutation={ provider.create }
        key={ data?.zone?.id }
      />
      <TextInput name="name" required />
      <TextInput name='VRF' placeholder='Add VRF ID or VRF name' autocomplete='off' showClear={ false } />

      <TextArea name="description" />

      <CustomAttributes modelName='Zone' />
      <Button style={{ marginLeft: '20px', float: 'right' }} type="secondary" onClick={(e) => { onClose() } }>
        Cancel
      </Button>

      <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}} htmlType="submit">
        Submit
      </Button>
    </Form>
  )
}


export default ZoneForm;

