import React from 'react';
import { Row, Col } from 'antd';

import MicrosoftLogin from './Microsoft.js';
import GoogleLogin from './Google.js';
import LDAPLogin from './LDAP.js';
import { useQuery } from '@apollo/client';
import AADLogin from './AAD.js';
const { CLIENT_CONFIG } = require("../../../entities/client-config");

const Providers = () => {

  const { data } = useQuery(CLIENT_CONFIG);
  const {
    REACT_APP_GOOGLE_LOGIN: GOOGLE,
    REACT_APP_MICROSOFT_LOGIN: MICROSOFT,
    REACT_APP_LDAP_ENABLED: LDAP,
    REACT_APP_API_URL: REACT_APP_API_URL,
    REACT_APP_AZURE_LOGIN: AZURE_AD
  } = process.env;

  if (data !== undefined) {
    const MICROSOFT = data.clientConfig.MicrosoftLoginEnabled;
    const GOOGLE = data.clientConfig.GoogleLoginEnabled;
    const LDAP = data.clientConfig.LdapEnabled;
    const AZURE_AD = data.clientConfig.AzureLoginEnabled;

    return (
      <>
        
<Row gutter={0}>
          {GOOGLE === 'true' && (
            <Col span={12} offset={MICROSOFT === 'true' || AZURE_AD === 'true' ? 0 : 6}>
              <GoogleLogin />
            </Col>
          )}
          
          {MICROSOFT === 'true' && (
            <Col span={12} offset={GOOGLE === 'true' || AZURE_AD === 'true' ? 0 : 6}>
              <MicrosoftLogin />
            </Col>
          )}

          {AZURE_AD === 'true' && (
            <Col span={12} offset={GOOGLE === 'true' || MICROSOFT === 'true' ? 0 : 6}>
              <AADLogin />
            </Col>
          )}
        </Row>
        <Row gutter={0}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}> {(LDAP == 'true') && (<LDAPLogin />)} </Col>
        </Row>
      </>
    )
  } else {
    return <></>
  }

}

export default Providers;
