import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/generateApiKey.graphql')  
const destroy = loader('./mutations/revokeApiKey.graphql')  


const RemoveApiKeyLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="API Key"
        entityTitle="API Key"
        redirectPath={`/settings/api-keys`}
      />
    </div>

  )
}

const search = {
    columns: [
      { title: 'API Key Name', dataIndex: 'apiKeyName', sorter: true },
      {
        title: 'API Key',
        dataIndex: 'apiKey',
        sorter: true,
        render: (apiKey) => (
          <span>
            XXXX-XXXX-XXXX-
            {apiKey.slice(-4)}
          </span>
        ),
      },
      {
        title: 'API Key Expiry',
        dataIndex: 'apiKeyExpiry',
        sorter: true,
        render: (apiKeyExpiry) => {
              const defaultDateTime = new Date(parseInt(apiKeyExpiry));
              return defaultDateTime.toISOString().split('T')[0];
        }, 
      },
      {title: '', width: 100, key: 'Revoke', render: apiKey =><RemoveApiKeyLink record={ apiKey }/> }
    ],
  };



export { search, getQuery, searchQuery, create, destroy };
