import React, { useState } from 'react';
import { Row, Col, Button, Form, Drawer, Select, Input, Checkbox } from 'antd';
import SearchTable from '../../components/SearchTable.js';
import SearchForm from '../../components/SearchForm';
import { allUsers, sendTidalAdminEmail } from '../../entities/user/index.js';

import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import ReactQuill from 'react-quill';

const UserCommunicationVersion = require('../../entities/user-communication-version');

function UserCommunicationVersionSearch(props) {

  const { id } = useParams();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [sendToAll, setSendToAll] = useState(false);
  const [form] = Form.useForm();
  const [body, setBody] = useState('');

  const { loading, data, refetch } = useQuery(allUsers, { variables: { search: '', offset: 0, limit: 50 } })

  const [sendEmail, { data: emailData, loading: emailDataLoading, error }] = useMutation(sendTidalAdminEmail, {
    onCompleted: () => {
      form.resetFields();
      setVisible(false);
      setBody('');
    },
  })


  return (
    <>
      <Row justify="space-between">
        <Col>
          <SearchForm placeholder="Search Emails" model={UserCommunicationVersion} />
        </Col>
        <Col>
          <Button type="primary" onClick={() => setVisible(true)}>
            Send Email
          </Button>
        </Col>
      </Row>
      <Drawer
        title="Send Email to Users"
        placement="right"
        closable={!emailDataLoading}
        onClose={() => {
          setVisible(false);
          form.resetFields();
        }}
        visible={visible}
        width="30%"
      >
        <Form
          form={form}
          onValuesChange={(changedValues) => {
            if (changedValues.hasOwnProperty('sendToAll')) {
              setSendToAll(changedValues.sendToAll);
            }
          }}
          onFinish={(values) => {
            sendEmail({ variables: { userIds: values?.users, subjectLine: values.subjectLine, body: body, buttonText: values.buttonText, buttonLink: values.buttonLink, imageURL: values?.imageURL, sendAll: values?.sendToAll, emailType: values?.emailType } });
          }}
        >
          <Form.Item name="subjectLine" label="Subject" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}  rules={[{ required: true, message: 'Please enter a subject!' }]}>
            <Input placeholder="Enter subject line" disabled={emailDataLoading} />
          </Form.Item>
          <Form.Item label="Body" name="body" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} required>
            <div style={{ margin: '10px 0' }}>
              <ReactQuill
                placeholder="Enter body text"
                disabled={emailDataLoading}
                value={body}
                onChange={setBody}
              />
            </div>
          </Form.Item>

          <Form.Item name="emailType" label="Email Type ( Weekly Network Reports or Feature Announcements )" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: 'Please select type of email' }]}>
            <Select placeholder="Select Email type">
              <Select.Option value="Weekly Network Reports">Weekly Network Reports</Select.Option>
              <Select.Option value="Feature Announcements">Feature Announcements</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Button Text" name="buttonText" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: 'Please enter button text!' }]}>
            <Input placeholder="Enter button text" disabled={emailDataLoading} />
          </Form.Item>
          <Form.Item label="Button Link" name="buttonLink" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: 'Please enter button link!' }]}>
            <Input placeholder="Enter button link" disabled={emailDataLoading} />
          </Form.Item>
          <Form.Item label="Image URL" name="imageURL" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Input placeholder="Enter Image URL" disabled={emailDataLoading} />
          </Form.Item>
          <Form.Item name="sendToAll" valuePropName="checked" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Checkbox disabled={emailDataLoading}>Send to all users</Checkbox>
          </Form.Item>
          {!sendToAll && (
            <Form.Item name="users" label="Multiple Users" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select users"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {data?.allUsers?.results?.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.firstName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit" loading={emailDataLoading}>
              Send Email
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SearchTable
            {...UserCommunicationVersion.userCommunicationSearch}
          />
        </Col>
      </Row>
    </>
  )
}

export default UserCommunicationVersionSearch;
