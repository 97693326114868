import React, { useState } from 'react';
import { Row, Col, Drawer, Button, Select, Form, Input, Checkbox } from 'antd';
import SearchTable from '../../components/SearchTable.js';
import SearchForm from '../../components/SearchForm';
import OrganizationForm from './OrganizationForm';

import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { allUsers, sendTidalAdminEmail } from '../../entities/user/index.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

const Organization = require('../../entities/organization');

function OrganizationSearch(props) {

  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      <Row justify="space-between">
        <Col>
          <SearchForm placeholder="Search Users" model={Organization} />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SearchTable
            {...Organization.search}
            onClick={(org) => {
              history.push(`/tidal-admin/organizations/${org?.id}`)
            }
            }
          />
        </Col>
        {id && (<OrganizationForm id={id} />)}
      </Row>
    </>
  )
}

export default OrganizationSearch;
