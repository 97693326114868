import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input, Alert } from 'antd';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { TextInput, CustomAttributes, SearchSelect } from '../../components/pants-d';
const supportGroup = require('../../entities/support-group');
const contact = require("../../entities/contact");

const ContactForm = () => {
  const { model, id } = useParams();
  const newRecord = id === 'new';
  const [errors, setErrors] = useState([]);
  const errorContainerRef = useRef(null);
  const [form] = Form.useForm();
  const history = useHistory();

  const [getContact, { loading, error: recordError, data }] = useLazyQuery(contact.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => {
    if (id && !newRecord) {
      getContact({ variables: { id: parseInt(id) } });
    }
  }, [id]);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [createRecord, { data: createData, error: createErrors }] = useMutation(contact.create, {
    onCompleted: () => { history.push(`/contacts`) },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
      console.log(e);
    },
    update(cache, { data }) {
      const existingContacts = cache.readQuery({ query: contact.searchQuery, variables: { search: "", offset: 0, limit: 50 } });

      cache.writeQuery({
        query: contact.searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          contacts: {
            count: (existingContacts?.count || 0) + 1,
            results: [...(existingContacts?.contacts?.results || []), data.createContact]
          }
        }
      });
    }
  });

  const [createContactContactable, { data: createContactContactableData, error: createContactContactableErrors }] = useMutation(supportGroup.ADD_CONTACTS_TO_SUPPORT_GROUP, {
    onCompleted: () => { history.push(`/contacts`) },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
      console.log(e);
    }
  });

  const [updateContact, { data: updateData }] = useMutation(contact.update, {
    onCompleted: () => { history.push(`/contacts`) },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
      console.log(e);
    }
  });

  const [removeContactFromSupportGroup, { data: updateRemoveData }] = useMutation(supportGroup.removeContact, {
    onCompleted: (v) => { console.log("completed") },
  });

  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }

  const handleFormSubmit = async (values, data, newRecord, createRecord, updateContact, createContactContactable, removeContactFromSupportGroup, id) => {
    const initialSupportGroupId = data?.contact?.supportGroup?.id || null;
    const supportGroupId = values?.supportgroup?.id || null;
  
    if (newRecord) {
      const response = await createRecord({ variables: { SupportGroupId: supportGroupId, ...values } });
      const newId = response?.data?.createContact?.id;
  
      if (values?.supportgroup) {
        createContactContactable({ variables: { id: supportGroupId, contactIds: [newId] } });
      }
    } else {
      updateContact({ variables: { id: parseInt(id), SupportGroupId: supportGroupId, ...values } });
  
      if (values?.supportgroup) {
        createContactContactable({ variables: { id: supportGroupId, contactIds: [parseInt(id)] } });
      }
      if (initialSupportGroupId && !supportGroupId) {
        removeContactFromSupportGroup({ variables: { id: initialSupportGroupId, contactId: parseInt(id) } });
      }
    }
  };

  useEffect(() => { form.setFieldsValue(data?.['contact']) }, [data?.['contact']])
  useEffect(() => {
    if (data?.contact?.supportGroup) {
      form.setFieldsValue({ supportgroup: data.contact.supportGroup });
    }
  }, [data, form]);

  if (loading) { return <></> }
  return (
    <Form
      layout="vertical"
      form={form}
      name="contact"
      initialValues={data?.['contact'] || {}}
      onFinish={async (values) => {
        await handleFormSubmit(values, data, newRecord, createRecord, updateContact, createContactContactable, removeContactFromSupportGroup, id);
      }} >
      <div ref={errorContainerRef}>
        {errors && (
          <>
            {errors.map((errorMessage, index) => (
              <Alert key={index} type="error" message={errorMessage} />
            ))}
            <br />
          </>
        )}
      </div>

      <SearchSelect
        style={{ width: '100%' }}
        name="supportgroup"
        label="Support Group"
        resultKey="supportGroups"
        defaultValue={data?.contact?.supportGroup}
        displayKey="name"
        query={supportGroup.searchQuery}
        createLabel={'Create a support group'}
        createMutation={supportGroup.create}
        key={data?.contact?.id}
        onChange={(value) => {
          form.setFieldsValue({ supportgroup: value });
        }}
      />

      <TextInput name="firstName" required />

      <TextInput name="lastName" />

      <TextInput name="phone" />

      <TextInput name="cellPhone" />

      <TextInput name="email" />

      <TextInput name="email2" />

      <CustomAttributes modelName='Contact' />

      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  )
}

export default ContactForm;
