import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Input } from 'antd';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { TextInput, CustomAttributes } from '../../components/pants-d';

import {CloseCircleFilled} from '@ant-design/icons';

const site = require("../../entities/site");

const RemoveLink = () => {

}

const SiteForm = () => {
  const { model, id } = useParams();
  const newRecord = id == 'new'

  const history = useHistory();

  const [getSite, { loading, error: recordError, data }] = useLazyQuery(site.getQuery, { variables: { id: parseInt(id) }});

  useEffect(() => { if (!newRecord) { getSite({ variables: {id: parseInt(id)}})}}, [])

  const [createRecord, { data: createData }] = useMutation(site.create, {
    onCompleted: () => { history.push(`/sites`) },
    update(cache, { data }) { 
      const existingSites = cache.readQuery({ query: site.searchQuery, variables: {search: "", offset: 0, limit: 50} })

      cache.writeQuery({
        query: site.searchQuery,
        variables: {search: "", offset: 0, limit: 50},
        data: {
          sites: {
            count: (existingSites?.count || 0) + 1,
            results: [...(existingSites?.sites?.results || []), data.createSite]
          }
        }
      })
    }});

  const [updateSite, { data: updateData }] = useMutation(site.update, { onCompleted: () => { history.push(`/sites`); },  onError: (err) => { console.log(err)} } )

  const [form] = Form.useForm();

  if (loading) { return <></> }
  return (
    <>
      { (newRecord || data) && (
        <Form
          layout="vertical"
          form={form}
          name="site"
          initialValues={{ ...(data?.['site'] || {}) }}
          onFinish={(values) => {
            if (newRecord) {
              createRecord({ variables: { ...values } });
            } else {
              const cleanedAddresses = values.addresses.map(address => {
                const { __typename, ...cleanedAddress } = address;
                return cleanedAddress;
              });
              updateSite({ variables: { id: parseInt(id), ...values, addresses: cleanedAddresses } });
            }
          }} >

          <TextInput name="name" required/>

          <Form.List name="addresses" initialValue={[...(data?.site?.addresses || []), {}]} >
            {(addresses, { add, remove }) => {
              return (
                <>
                  <h3>Address</h3>
                  <div style={{ padding: '15px 20px', marginBottom: '20px', background: "#F8F8F8"}}>
                    {
                      (addresses || []).map((address, idx) => (
                        <>
                          <Row gutter={ 12 }>
                            <Col span={ 24 }>
                              <TextInput size="small" allowClear={false} name={[idx, "line1"]} label="Street Address" />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={ 24}>
                              <TextInput size="small" allowClear={false} name={[idx, "line2"]} label="Street Address Line 2" />
                            </Col>
                          </Row>
                          <Row gutter={ 12 }>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "municipality"]} label="City" />
                            </Col>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "province"]} label="State / Province" />
                            </Col>
                          </Row>
                          <Row gutter={ 12 }>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "country"]} label="Country" />
                            </Col>
                            <Col span={ 12 }>
                              <TextInput size="small" allowClear={false} name={[idx, "postalCode"]} label="Postal / Zip Code" />
                            </Col>
                          </Row>
                        </>
                      ))
                    }
                  </div>

                </>
              )
            }}
          </Form.List>
          <CustomAttributes modelName='Site' />

          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      )}
    </>
  )
}

export default SiteForm;
