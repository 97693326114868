import React, { useState, useEffect } from 'react';
import { useProject, useParams, useHistory } from "react-router-dom";

import {  Form, Layout, Button, Drawer } from 'antd';


import SearchForm from '../../components/SearchForm';
import SearchAction from '../../components/SearchAction';
import SearchTable from '../../components/SearchTable';
import ProjectForm from '../../views/projects/Form';
import SearchLayout from '../layout/Search.js';
import Entity from '../../entities';
import {PlusOutlined} from '@ant-design/icons';

const { Content } = Layout;

const project = Entity['project'] 

const Projects = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == 'new'

  return (
    <SearchLayout section='Planning'>
      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/projects/new'
        style={{marginTop: '12px'}}>
        New Project            
      </SearchAction>
        <SearchTable 
          style={{ marginTop: '70px' }}
          query={ project.searchQuery }
          { ...project.search }
          onAdd={() => { 
            history.push('/projects/new') 
          }}
          onClick={(record) => { history.push(`/projects/${record?.id}`) }}
          modelName='projects' />

      { (id || newRecord) && ( 
        <Drawer
          title={newRecord ? `Add project` : `Update project`}
          width={520}
          closable={false}
          visible={true}
          onClose={() => { history.push('/projects')}}>

          <ProjectForm id={ id } /> 

        </Drawer>
      ) }
    </SearchLayout>
  )
};

export default Projects;
