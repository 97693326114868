import React, { useState } from 'react';
import { List, Form, Input, Badge, Row, Col, Button, Checkbox } from 'antd';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import TextInput from './TextInput';
import {CheckCircleFilled, CloseCircleFilled, DeleteOutlined, PlusCircleFilled, PlusOutlined} from '@ant-design/icons';


const PantList = ({ title, query, defaults, createMutation, updateMutation, destroyMutation, renderFn, filters, defaultValues, style, ...props }) => {

  const { data, error, loading, refetch} = useQuery(query, {
    variables: { ...filters },
    onCompleted: (response) => { console.log('') }
  })

  const [showForm, setShowForm] = useState(false);

  const [selected, setSelected] = useState([]);

  const [form] = Form.useForm();

  const [createRecord, {}] = useMutation(createMutation, { 
    onCompleted: () => {
      setShowForm(false)
      refetch();
      form.resetFields();
    }
  })

  const [destroyRecord, {}] = useMutation(destroyMutation, {
    onCompleted: () => {
      setSelected([]);
      refetch();
    }
  })


  return (
    <Checkbox.Group 
      onChange={(values) => { setSelected(values) }}
      style={{ width: '100%' }}>
      <List
        style={{ ...style, background: '#FFFFFF' }}
        header={  
          <h3 style={{ marginBottom: '0px' }}> 
            { title }

            <DeleteOutlined
              onClick={() => { 
                if ( selected.length > 0 ) {
                  destroyRecord({ variables: { ids: selected } })
                }
              }}
              style={{ marginTop: '4px', marginBottom: '-4px', float: 'right'}} />
            <PlusOutlined
              onClick={() => { 
                setShowForm(true) }}
              style={{ marginTop: '4px', marginRight: '8px', marginBottom: '-4px', float: 'right'}} />

          </h3>
        }
        footer={showForm ? <Form
          form={ form }
          onFinish={(values) => {
            createRecord({ variables: { ...defaultValues, ...values }})
          }}
        >
          <Row style={{ marginTop: '0px', marginBottom: '0px'}}>
            <Col flex="1 1 300px">
              <TextInput 
                name='name'
                hideLabel
                style={{ 
                  fontSize: '16px',
                  background: '#FAFAFA',
                  borderTop: '0px',
                  borderLeft: '0px',
                  borderRight: '0px',
                  marginBottom: '0px'
                }}
                allowClear={ false }
                formItemStyle={{
                  marginBottom: '10px'
                }}
              />
            </Col>
            <Col flex="1 1 32px">
              <CheckCircleFilled 
                onClick={() => {
                  form.submit()}}
                style={{ fontSize: '18px', color: '#52c41a', 
                  marginLeft: '10px',
                  marginTop: '8px', 
                  marginRight: '2px', 
                  display: 'inline-block', float: 'left' }}/>
              <CloseCircleFilled 
                onClick={() => { setShowForm(false) }}
                style={{ 
                  fontSize: '18px', 
                  color: '#ff4d4f', 
                  marginTop: '8px', 
                  marginLeft: '2px', 
                  marginRight: '0px', 
                  display: 'inline-block', 
                  float: 'left' }}/>
            </Col>
          </Row>
        </Form> : null
        }
        bordered
        dataSource={[
          ...(defaults || []),
          ...(data?.[Object.keys(data)[0]]?.results || [])
        ]}



        renderItem={item => (
          <List.Item 
            extra={ 
              <>
                { item.id &&
                <Checkbox 
                  style={{ 
                    fontSize: '8px',
                    marginRight: '10px' }}
                  value={ item?.id } />
                }

                { renderFn(item) }

              </>
            }>
          </List.Item>
        )}

        { ...props }
      />
    </Checkbox.Group>
  )
}


export default PantList
