import React from 'react'
import { loader } from 'graphql.macro';

import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Link, useHistory, useParams } from "react-router-dom";

import { Avatar, List, Popconfirm } from 'antd';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')
const customerQuery = loader('../customer/queries/search.graphql')
const getQuery = loader('./queries/get.graphql')
const USER_GROUP_USERS = loader('./queries/USER_GROUP_USERS.graphql')

const create = loader('./mutations/create.graphql')
const update = loader('./mutations/update.graphql')
const destroy = loader('./mutations/destroy.graphql')
const ADD_USERS_TO_USER_GROUP = loader('./mutations/ADD_USERS_TO_USER_GROUP.graphql')
const removeUser = loader('./mutations/removeUser.graphql')

const RemoveUserGroupLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="User Group"
        entityTitle="User Group"
        redirectPath={`/user-groups`}
      />
    </div>

  )
}

const RemoveUserLink = ({ userId }) => {
  const { id } = useParams();

  const [removeUserFromUserGroup, { data: updateData }] = useMutation(removeUser, {
    onCompleted: (v) => { console.log("completed") },
    update(cache, { data }) {

      const existing = cache.readQuery({ query: USER_GROUP_USERS, variables: { search: "", offset: 0, limit: 50, userGroupId: parseInt(id) } })

      cache.writeQuery({
        query: USER_GROUP_USERS,
        variables: { search: "", offset: 0, limit: 50, userGroupId: parseInt(id) },
        data: {
          userGroupUsers: {
            count: (existing?.userGroupUsers?.count || 1) - 1,
            results: [...existing?.userGroupUsers?.results.filter(user => user.id !== parseInt(userId))]
          }
        }
      })
    }
  });

  return (
    <div onClick={e => e.stopPropagation()}>
      <Popconfirm
        title="Are you sure you want to remove this user?"
        onConfirm={(e) => { removeUserFromUserGroup({ variables: { id: parseInt(id), userId: parseInt(userId) } }) }}>
        <a>
          Remove
        </a>
      </Popconfirm>
    </div>
  )
}

const search = {
  columns: [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
    { title: '', width: 100, key: 'remove', render: sg =><RemoveUserGroupLink record={ sg }/> }
  ]
}

const userSearch = {
  columns: [
    { title: 'Name', key: 'name', sorter: true, render: u => <>{`${u.firstName || ''} ${u.lastName || ''}`}</> },
    { title: 'Email', dataIndex: 'email', key: 'email', sorter: true },
    { title: '', width: 100, key: 'remove', render: u => <RemoveUserLink userId={u.id} /> }
  ]
}

const form = {
  fields: [
    ["name", {}],
    ["description", {}],
  ]
}

export { search, userSearch, form, ADD_USERS_TO_USER_GROUP, searchQuery, customerQuery, getQuery, USER_GROUP_USERS, create, update, destroy };
