import React, { useState, useEffect } from 'react';

import {  Row, Col, Menu, Layout, Breadcrumb, } from 'antd';
import { useParams } from "react-router-dom";

import { Subnav } from '../layout/Subnav.js';

import CloudAccounts from './CloudAccounts.js';
import CustomAttributes from './CustomAttributes.js';
import Keys from './Keys.js';
import License from './License.js';
import Downloads from './Downloads.js';
import Synchronization from './Synchronization.js';


const { Content } = Layout;

const Admin = () => {
  const { section } = useParams();

  const breadCrumbStyles={marginTop: '8px', display: 'inline-block', fontSize: '18px', color: '#336b99'};

  return (
    <>
      { (!section || section == 'profile') && <Profile />}
      { section == 'cloud-accounts' && <CloudAccounts />}
      { section == 'api-keys' && <Keys />}
      { section == 'license' && <License />}
      { section == 'imports' && <Importer />}
      { section == 'custom-attributes' && <CustomAttributes />}
      { section == 'downloads' && <Downloads />}
      { section == 'synchronization' && <Synchronization />}
    </>
  )
};

export default Admin
