import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Subnav } from '../layout/Subnav.js';

import { Row, Col, Form, Layout, Button, Badge, Breadcrumb } from 'antd';
import SearchTable from '../../components/SearchTable';
import List from '../../components/pants-d/List';
import {PlusOutlined} from '@ant-design/icons';

import RecordLayout from '../layout/Record.js';

const customAttribute = require('../../entities/custom-attribute')

const editableModels = [
  { 
    title: 'Subnets',
    defaults: [
      { name: 'Name', type: 'STRING'},
      { name: 'Network Address', type: 'STRING'},
    ],
    filters: { modelName: 'Subnet' },
    defaultValues: { modelName: 'Subnet' }
  },
  { 
    title: 'IP Assignments',
    defaults: [
      { name: 'Name', type: 'STRING'},
      { name: 'Network Address', type: 'STRING'},
    ],
    filters: { modelName: 'IPAssignment' },
    defaultValues: { modelName: 'IPAssignment' }
  },
  { 
    title: 'Zones',
    defaults: [
      { name: 'Name', type: 'STRING'},
    ],
    filters: { modelName: 'Zone' },
    defaultValues: { modelName: 'Zone' }
  },
  { 
    title: 'Sites',
    defaults: [
      { name: 'Name', type: 'STRING'},
    ],
    filters: { modelName: 'Site' },
    defaultValues: { modelName: 'Site' }
  },
  { 
    title: 'Datacenters',
    defaults: [
      { name: 'Name', type: 'STRING'},
    ],
    filters: { modelName: 'Provider' },
    defaultValues: { modelName: 'Provider' }
  },

  { 
    title: 'Customers',
    defaults: [
      { name: 'Name', type: 'STRING'},
    ],
    filters: { modelName: 'Customer' },
    defaultValues: { modelName: 'Customer' }
  },

  { 
    title: 'Support Groups',
    defaults: [
      { name: 'Name', type: 'STRING'},
    ],
    filters: { modelName: 'SupportGroup' },
    defaultValues: { modelName: 'SupportGroup' }
  },
]

const itemRender = (field) => { return (
  <div style={{width: '100%', fontSize: '13px', fontWeight: '400'}} >
    { field.name }
    <div style={{ fontWeight: 'bold', fontSize: '11px', float: 'right', background: '#EAEAEA', padding: '2px 4px'}}>
      { (field.type || 'String').toUpperCase() }
    </div>
  </div>
)}

const defaultProps = {
  query: customAttribute.searchQuery,
  createMutation: customAttribute.create,
  destroyMutation: customAttribute.destroy,
  size: 'small',
  bordered:  true,
  renderFn: (i) => itemRender(i)
}

const CustomAttributes = ({}) => {
  const [refetch, setRefetch] = useState()

  const breadCrumbStyles={marginTop: '8px', display: 'inline-block', fontSize: '18px', color: '#336b99'};

  return (
    <RecordLayout section="Schema">
        <Row gutter={ 24 }>
          { editableModels.map(props => {

            return (
              <Col span={ 8 }>
                <List 
                  style={{ marginBottom: '40px' }}
                  { ...defaultProps }
                  { ...props } 
                />
              </Col>

            )
          })}
        </Row>
    </RecordLayout>
  )
};

export default CustomAttributes;
