import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { Row, Button, Form, Input, Space, Alert, Drawer, Divider, message } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { Select } from '../../components/pants-d';
const { Option } = Select;
const alertRecipient = require("../../entities/alert-recipient");
const alertRule = require("../../entities/alert-rule");
const user = require("../../entities/user");

const AlertSubnetForm = ({ onCreate, onClose }) => {
    const { model, supernetId, subnetId } = useParams();
    const subnetUrl = `/subnets/${supernetId}/${subnetId || supernetId}`
    const [selectedUsers, setSelectedUsers] = useState([]);

    const history = useHistory();

    const [open, setOpen] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [alertStatus, setAlertStatus] = useState(null);
    const [errors, setErrors] = useState([])
    const errorContainerRef = useRef(null);

    useEffect(() => {
        if (errorContainerRef.current) {
            errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [errors]);

    const [form] = Form.useForm();
    const { data: userData } = useQuery(user.searchQuery, { variables: { search: '', offset: 0, limit: 50 } })

    const [createAlertRule, { data: createAlertRuleData, error: createAlertRuleErrors }] = useMutation(alertRule.create, {
        onCompleted: (e) => {
        },
        onError: e => {
            setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
            console.log(e)
        },
        update(cache, { data }) {
            const existingAlertRules = cache.readQuery({ query: alertRule.searchQuery, variables: { search: "", offset: 0, limit: 50 } })
            cache.writeQuery({
                query: alertRule.searchQuery,
                variables: { search: "", offset: 0, limit: 50 },
                data: {
                    alertRules: {
                        count: (existingAlertRules?.count || 0) + 1,
                        results: [...(existingAlertRules?.alertRules?.results || []), data.createAlertRule]
                    }
                }
            })
        }
    });

    const [updateAlertRule, { data: updateAlertRuleData, error: updateAlertRuleErrors }] = useMutation(alertRule.update, {
        onCompleted: (e) => {
        },
        onError: e => {
            setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
            console.log(e)
        },
    });

    const [createAlertRecipient, { data: createData, error: createErrors }] = useMutation(alertRecipient.create, {
        onCompleted: (e) => {
            setAlertStatus(true),
                setTimeout(() => {
                    setAlertStatus(false);
                }, 7000);
            setShowForm(false);
        },
        onError: e => {
            setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
            console.log(e)
        },
    });

    const statuses = ['Active', 'Inactive']
    const requestTypes = ['Network Requests', "Network Capacity", "NMAP Scan Results"]

    const handleCloseForm = () => {
        history.push(`${subnetUrl}/alerts`)
        onClose();
    };

    const handleSubmit = (values) => {
        const alertRulevariables = {
            alerts: {
                SubnetId: parseInt(subnetId),
                status: values?.status,
                type: values?.type,
                name: values?.name,
                description: values?.description,
            },
        }

        createAlertRule({ variables: alertRulevariables })
            .then((response) => {
                const alertRuleId = response.data.createAlertRule.id;

                const alertRecipientPromises = selectedUsers.map(user => {
                    const alertRecipientVariables = {
                        alertRecipient: {
                            AlertRuleId: alertRuleId,
                            UserId: user?.id,
                            userName: user?.firstName + ' ' + user?.lastName
                        }
                    }
    
                    return createAlertRecipient({ variables: alertRecipientVariables });
                });

                return Promise.all(alertRecipientPromises);
            })
            .then((responses) => {
                const alertRecipientIds = responses.map(response => response.data.createAlertRecipient.id);
    
                const alertRulevariables = {
                    id: responses[0].data.createAlertRecipient.AlertRuleId,
                    alertRecipientIds: alertRecipientIds
                }
    
                return updateAlertRule({ variables: alertRulevariables });
            })
            .then(() => {
                handleCloseForm();
            })
            .catch((error) => {
                console.error("Add Alert Rule Mutation error:", error);
            });
    };

    return (
        <Drawer
            title="Add alert Rule"
            width={520}
            visible={open}
            onClose={() => {
                history.push(`${subnetUrl}/alerts`);
            }}
        >
            <Form
                layout="vertical"
                form={form}
                name="alerts"
                initialValues={{}}
                onFinish={(values) => {
                    handleSubmit(values)
                }}
            >
                <Form.Item
                    name="type"
                    label="Alert"
                    rules={[{ required: true, message: 'Please select the Alert' }]}
                >
                    <Select placeholder="Select a type">
                        {requestTypes?.map(types => (
                            <Select.Option key={types} value={types}>{types}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: true, message: 'Please select the status' }]}
                >
                    <Select placeholder="Select a status">
                        {statuses?.map(status => (
                            <Select.Option key={status} value={status}>{status}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Title"
                    rules={[{ required: true, message: 'Please input the title' }]}
                >
                    <Input placeholder="Enter the title" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Description"
                >
                    <Input placeholder="Enter the description" />
                </Form.Item>
                <Form.Item
                    label='User'
                    name='user'
                    rules={[{ required: true, message: 'Please select the User' }]}
                >
                <Select 
                    placeholder="Select a user"
                    mode="multiple"
                    onChange={(value, option) => {
                        setSelectedUsers(option.map(opt => opt.user));
                    }}
                >
                    {userData?.users?.results.length > 0 ? (
                        userData?.users?.results.map(user => {
                            return (
                                <Option key={user?.User?.id} value={user?.User?.id} user={{...user?.User, userName: `${user?.User?.firstName} ${user?.User?.lastName}`}}>
                                    {user?.User?.firstName} {user?.User?.lastName}
                                </Option>
                            );
                        })
                    ) : (
                        <Option disabled>No alert rules available. Please add a rule first to proceed.</Option>
                    )}
                </Select>

                </Form.Item>
                <Button type="secondary" onClick={(e) => onClose()} style={{ float: 'right', marginLeft: '20px' }}>
                    Cancel
                </Button>
                <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff' }} htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Drawer>
    )
}

export default AlertSubnetForm;
