import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')
const destroy = loader('./mutations/destroy.graphql')

const RemoveUserCommunicationVersionLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="usercommunicationversion"
        entityTitle="user communication version"
        redirectPath={`/tidal-admin/user-communication`}
      />
    </div>

  )
}

const userCommunicationSearch = {
  query: searchQuery,
    columns:  [
      { title: 'Email Type', width: '12em',  render: userCommunicationVersion=> userCommunicationVersion?.UserCommunication?.name},
      {
        title: 'Schedule',
        width: '16em',
        render: userCommunicationVersion => {
          const minutes = userCommunicationVersion?.schedule?.minutes;
          return minutes ? `${minutes} days after register` : 'Manual';
        }
      },
      { title: 'Send', width: '16em', render: userCommunicationVersion => userCommunicationVersion?.send},
      { title: 'Open', width: '16em', render: userCommunicationVersion => userCommunicationVersion?.open},
      { title: 'Bounce', width: '16em', render: userCommunicationVersion => userCommunicationVersion?.bounce},
      { title: 'Email Body', width: '16em', render: userCommunicationVersion => {
          const body = userCommunicationVersion?.emailBody;
          const strippedBody = body ? body.replace(/(<([^>]+)>)/gi, "") : ""; // Remove HTML tags
          const shortBody = strippedBody.length > 100 ? strippedBody.substring(0, 100) + "..." : strippedBody; // Limit to 100 characters
          return shortBody;
        }
      },
      { title: 'Email Sent', width: '10em', render: userCommunicationVersion => (new Date(userCommunicationVersion?.createdAt).toLocaleString()) },
      { title: '', width: 100, key: 'remove', render: userCommunicationVersion => <RemoveUserCommunicationVersionLink record={ userCommunicationVersion }/> }
    ]
  }
export { searchQuery, userCommunicationSearch, destroy };