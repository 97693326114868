import React, { useState, useEffect, useRef } from 'react';

import {  Row, Col, Button, Drawer, Form, Menu, Layout, Select, Input, Card, Breadcrumb, Alert } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Pie } from 'react-chartjs-2'
import { Link, useHistory, useParams } from "react-router-dom";

import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import { TextInput } from '../../components/pants-d';

import { Subnav, Actions, Links, NavLink } from '../layout/Subnav.js';
import SearchTable from '../../components/SearchTable';
import SearchSelect from '../../components/SearchSelect';
import MultiSelect from '../../components/MultiSelect';
import TextEditable from '../../components/Editable';

import ContactSupportGroupForm from './ContactSupportGroupForm';
import Info from '../info/Info.js';

const { Content } = Layout;
const { Option } = Select;

const supportGroup = require('../../entities/support-group')

const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 }

const SupportGroupHeader = () => {
  const client = useApolloClient();
  const { id } = useParams();
  let history = useHistory();
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const { loading, error, data, refetch } = useQuery(supportGroup.getQuery, { variables: { id: parseInt(id) }});
  const [updateRecord, { data: updateData, error: updateErrors }] = useMutation(supportGroup.update, {onCompleted: (v) => { console.log("completed"), setErrors([]); }, onError: e => {
    setErrors(e.message);
  } });

  const [form] = Form.useForm();

  if (loading) {
    return (
      <div className="record-wrapper">
        <div className="record">
        </div>
      </div>
    )
  } else {
    return (
      <h1 style={{ marginTop: '20px', fontSize: '28px', marginBottom: '5px'}}> 
      <div ref={errorContainerRef} style={{ marginBottom: '10px' }}>
        {errors && errors.length > 0 && (
          <Alert type="error" message={errors} />
        )}
      </div>
        <TextEditable 
          style={{ 
            background: 'none', 
            border: 'none', 
            fontSize: '32px', 
            color: '#000000',
            fontWeight: '400',
            padding: '0px'
          }} 
          value={data?.supportGroup?.name || ''} 
          placeholder={'Unnamed subnet'}
          onSubmit={(d) => {
            if (d !== data?.supportGroup?.name) {
              if (!updateErrors || updateErrors.length === 0) {
                updateRecord({ variables: { id: parseInt(id), name: d } });
              }
          }
          }}
        />
      </h1>
    )
  }
}

function SupportGroupView(props) {
  const client = useApolloClient();
  const { id } = useParams();
  let history = useHistory();

  const [addingContact, setAddingContact] = useState(null)

  const { loading, error, data, refetch } = useQuery(supportGroup.getQuery, { variables: { id: parseInt(id) }});
  const [updateRecord, { data: updateData }] = useMutation(supportGroup.update, {onCompleted: (v) => { console.log("completed") }} );

  const [form] = Form.useForm();

  if (loading) {
    return(
      <div className="record-wrapper">
        <div className="record">
        </div>
      </div>
    )
  } else {
    return (
      <>
        <Subnav class="sub-nav">
          <Breadcrumb style={{ marginTop: '14px', display: 'inline-block', fontSize: '16px' }}>
            <Breadcrumb.Item> <a style={{ color: '#333', fontSize: '14px', lineHeight: '18px' }} onClick={e => history.push('/support-groups')}><LeftOutlined style={{ fontSize: '12px', marginRight: '5px' }} />Search</a> </Breadcrumb.Item>
            <Breadcrumb.Item> {data?.supportGroup?.name} </Breadcrumb.Item>
          </Breadcrumb>
        </Subnav>
        <Content style={{ position: 'fixed', width: '100%', padding: '0px 25px', zIndex: '2', background: 'none' }}>
          <div className="">
            <Row gutter={ rowGutter }>
              <Col span={ 24 }>
                <SupportGroupHeader id={ id } />

                <Row>
                  <Col span={24}>
                    <SearchTable 
                      query={ supportGroup.SUPPORT_GROUP_CONTACTS }
                      modelName={"support-group-contacts"} 
                      columns={ supportGroup.contactSearch.columns }
                      filters={{ supportGroupId: parseInt(id) }}
                      onClick={(r) => { null }}
                      onAdd={(e) => { setAddingContact(true)}}
                      emptyRender={<Info model="contacts" />}>
                    </SearchTable>
                  </Col>
                </Row>
              </Col>
            </Row>

          </div>
        </Content>
      </>) } };

export default SupportGroupView;
