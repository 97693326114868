const { Address4, Address6 } = require('ip-address');

const parsedSubnet = (subnet) => {

  if (!subnet) { return {}}
  if (cidrRegex.v4({exact: true}).test(subnet)) {
    return new Address4(subnet)
  } else {
    return new Address6(subnet)
  }
}

import cidrRegex from 'cidr-regex';

const actionsMap = {
  Subnet: {
    Owner: [
      'create', 'read', 'update', 'destroy', 'invite', 'assign'
    ],
    Administrator: [
      'create', 'read', 'update', 'destroy', 'invite', 'assign'
    ],
    Contributor: [
      'create', 'read', 'update', 'destroy'
    ],
    Reader: [ 
      'read' 
    ],
  }
}

const getRoles = (user, record) => {

  const appRole = user?.UserOrganizations.filter(u => {
    return u.Organization?.id == (user?.currentOrganizationId || user?.UserOrganizations?.[0]?.Organization?.id)
  })?.[0]?.role

  const recordRoles = user?.RoleAssignments?.filter(assignment => {
    return (record?.networkAddress && assignment?.networkAddress && parsedSubnet(record?.networkAddress).isInSubnet(parsedSubnet(assignment?.networkAddress)))
  }).map(assignment => assignment?.role?.name)

  return [appRole, ...recordRoles]

}

const getActions = (user, record) => {

  const roles = getRoles(user, record)

  const actions = roles.map(r => {
    return actionsMap[record?.__typename]?.[r]
  }).flat()

  return [...new Set(actions)]
}

export { getActions }
