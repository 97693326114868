import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import RemoveLink from '../../components/RemoveLink';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const search = {
  columns:  [
    { title: 'Name', key: 'name', dataIndex: 'name' },
    { title: 'Start Date', key: 'startDate', dataIndex: 'startDate' },
    { title: 'End Date', key: 'endDate', dataIndex: 'endDate' },
    { title: 'Status', key: 'status', dataIndex: 'status' },
    { title: '', width: 100, key: 'remove', render: location => <RemoveLink record={ location } mutation={ destroy }/> }
  ]
}


export { search, getQuery, searchQuery, create, destroy, update };
