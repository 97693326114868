import React from 'react'
import { loader } from 'graphql.macro';

const searchQuery = loader('./queries/search.graphql')  
const AVAILABLE_IP_ADDRESSES = loader('./queries/available.graphql')  
const importRecords = loader('./mutations/import.graphql')

const search = {
  rowKey: 'networkAddress',
  columns:  [
    { title: ' ', dataIndex: 'networkAddress', key: 'networkAddress', width: '1.5em', render: networkAddress => <span class="dot success"></span> },
    { title: 'Network Address', dataIndex: 'networkAddress', key: 'networkAddress' },
    { title: 'Subnet', dataIndex: 'subnet', key: 'subnet', render: subnet => <a>{ subnet.networkAddress }</a> },
    { title: 'DNS', dataIndex: 'dns', key: 'networkAddress' },
    { title: 'Interface', dataIndex: 'interface', key: 'interface' },
    { title: 'MAC Address', dataIndex: 'macAddress', key: 'macAddress' }
  ]
}

const importer = {
  mutation: importRecords,
  fields: [
    {name: 'zone', required: true}, 
    {name: 'subnet', required: true }, 
    {name: 'networkAddress', required: true}, 
    {name: 'dnsEntryType'},
    {name: 'dnsEntryValue'},
    {name: 'dnsEntryTTL'},
    {name: 'hostname'}, 
    {name: 'resourceType'},
    {name: 'interface'}, 
    {name: 'macAddress'}, 
    {name: 'direction'},
    {name: 'reservation'},
  ],

  title: 'IP Assignments',
  copy: 'IP Assignments represent an allocated resource or network interface, and can be associated with DNS entries and Reservations.'
}

export { search, searchQuery, importer, AVAILABLE_IP_ADDRESSES };
