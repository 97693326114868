import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button, Form, Input, Space, Alert, Drawer, Divider } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { TextInput, Select } from '../../components/pants-d';
import { possibleSubnets } from '../../components/SubnetBuilder/V4';
import cidrRegex from 'cidr-regex';
import { possibleSubnets as v6PossibleSubnets } from '../../components/SubnetBuilder/V6';
import Netmask from './CalculateNetmasks';

const { Option } = Select;

const subnet = require("../../entities/subnet");
const zone = require('../../entities/zone')

const SubnettingForm = ({ supernetId, networkAddress, onCreate, onClose }) => {

  const [errors, setErrors] = useState([])
  const [open, setOpen] = useState(true);
  const [childSubnets, setChildSubnets] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [netmask, setNetmask] = useState();

  const [createRecord, { data: createData, loading: createLoading, error: createErrors }] = useMutation(subnet.create, {
    onCompleted: ({ createSubnet: subnet }) => {
      onClose()
    },
    onError: (err) => {
      const message = err?.message

      setErrors([{ message: (message.charAt(0).toUpperCase() + message.slice(1)).replaceAll('\"', '') }])
    }
  });

  const { loading, error, data, refetch } = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(supernetId) } });
  const supernet = data?.subnet?.networkAddress

  const [form] = Form.useForm();

  const isV4 = cidrRegex.v4({exact: true}).test( data?.subnet?.networkAddress )

  useEffect(() => {
    let calculatedChildSubnets
    if (netmask) {
      if(isV4) {
      calculatedChildSubnets = possibleSubnets(data.subnet, netmask);
      } else {
        calculatedChildSubnets = v6PossibleSubnets(data.subnet, netmask)
      }
      setChildSubnets(calculatedChildSubnets);
      setShowSelect(true);
    } else {
      setShowSelect(false);
    }
  }, [netmask, supernet]);


  return (
    <Drawer
      title={`Create a subnet in ${data?.subnet?.networkAddress}`}
      onClose={onClose}
      width={520}
      visible={open}>
      <Form
        layout="vertical"
        form={form}
        name="subnet"
        onFinish={ ({networkAddress, netmask, zone, ...values}) => {

          createRecord({ variables: {...values, networkAddress: `${networkAddress}`, supernetId: supernetId, zoneId: data?.subnet?.zone?.id}}) 

        }} >

        { errors && errors.map(error => {
          return (<><Alert type="error" message={ error.message } /><br/></>) 
        })}
        <Form.Item label="Netmask">
          <Netmask subnet={data?.subnet?.networkAddress} onSelect={(v) => { setNetmask(v) }} defaultValue=' ' />
        </Form.Item>
        <Form.Item label="Subnet" name="networkAddress"  rules={[{ required: true, message: 'Please select a subnet!' }]}>
          <Select
            placeholder="Select a child Subnet"
          >
            {childSubnets.map((subnet) => (
              <Option key={subnet.networkAddress} value={subnet.networkAddress}>
                {subnet.networkAddress}
              </Option>
            ))}
          </Select>
        </Form.Item>
        
        <TextInput name="name" />

        <Row style={{marginTop: '40px'}}>
          <Col span={24}>
            <Button type="secondary" onClick={e => { onClose() }} style={{float: 'right', marginLeft: '20px'}}>
              Cancel
            </Button>
            <Button htmlType="submit"  style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}

export default SubnettingForm;
