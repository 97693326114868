import React, { useState } from 'react';
import { Space, Form, Input, Button, Alert, Row, Col,message } from 'antd';
import {  useMutation, useApolloClient } from '@apollo/client';
import _ from 'lodash';
import { useHistory } from "react-router-dom";

import { TextInput, PasswordInput, Submit } from '../../../components/pants-d';

const { REGISTRATION_MUTATION, CURRENT_USER_QUERY } = require('../../../entities/auth')
const { LOGIN_MUTATION, CURRENT_LOGIN_QUERY } = require('../../../entities/auth')
import axios from 'axios';  

function LDAPLogin() {

  const client = useApolloClient();
  
  const history = useHistory();

  const [loggingIn, setLoggingIn] = useState(false)

  const onFinish = (values) => {
      axios.post(`${process.env.REACT_APP_API_URL}/login/ldap`, {
      username: values.email,
      password: values.password,
    }).then(res => {
        var dt = res.data;
      if (dt.status === 200) {
        const u = dt.user;

        if (u){
          const v = {
            email: String(u.email),
            firstName: String(u.firstName),
            lastName: String(u.lastName),
            organizationName: String(u.organizationName),
            password: String(u.password),
            passwordConfirmation: String(u.passwordConfirmation)
          }
          register({ variables: {...v}});
        }
        else{
         login({ variables: {...values}})
         setTimeout(function(){
          window.location.reload();
         },1000)
        }

        message.success(dt.message);
      }
      else{
        message.error("Incorrect email or password");
      }
    }).catch(error => {   
      if (error.response.status === 401) {   
        message.error('Invalid Email or password credentials');
      } else {
        message.error('An error occurred while logging in');
      }
    })
  }

  const [register, { data, error } ] = useMutation(REGISTRATION_MUTATION, {
    onCompleted: ({ register }) => { 
      const query = {
        query: CURRENT_USER_QUERY,
        variables: {}
      }
      client.writeQuery({
        ...query,
        data: { currentUser: register.user}
      })

      history.push(`${window.location.search}`)
      
    },
    onError: ( e ) => { console.log(e) }
  })

    const [login, { login_data, err }] = useMutation(LOGIN_MUTATION, {
    onCompleted: ({ login }) => { 
      const query = {
        query: CURRENT_LOGIN_QUERY,
        variables: {}
      }
      client.writeQuery({
        ...query,
        data: { currentUser: login.user}
      }) 
  },
    onError: ( err ) => { console.log( err ); }
  })

  const loginButtonStyle={width: '285px', marginLeft: '28px', marginRight: 'auto', textAlign: 'left', fontSize: '28px', height: '75px', lineHeight: '65px', padding: '30px 60px 4px 40px'}
  const iconStyle={ marginTop: '18px', marginRight: '8px', fontSize: '28px', float: 'left' }


  const [form] = Form.useForm();

  return(
    <>
      { !login && ( <Button 
        style={{
          ...loginButtonStyle, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          padding: '0'
        }} 
        onClick={() => setLoggingIn(true)}
      >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
          <ApiFilled style={{...iconStyle}}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', marginLeft: '5px', marginRight: '5px'}}>
          LDAP
        </div>
      </Button>
      )
      }
      { loggingIn && (
        <Form
          layout="vertical"
          form={ form }
          onFinish={onFinish} >
          <Row gutter={24}>
            <Col span={24}>
              <TextInput required name="email" />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <PasswordInput required name="password" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Submit />
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}

export default LDAPLogin;
