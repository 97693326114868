import React, { useState } from 'react';
import {  Form } from 'antd';
import SearchSelect from '../../components/SearchSelect';

import { startCase } from "lodash";

class TextInput extends React.Component {

  render () {
    const { name, required, hideLabel=false, defaultValue, value, ...props } = this.props;

    return (
      <Form.Item
        label={  hideLabel ? null : props.label || startCase(name) }
        defaultValue={ defaultValue }
        value={ value }
        name={ name }
        autoComplete="off"
        normalize={(result => {
          return {id: result?.value, [props?.displayKey || 'name']: result?.label}}
        )}
        rules={[{ required: required, message: `${props.label || startCase(name)} is required.` }]}
      >
        <SearchSelect { ...props } defaultValue={ defaultValue } />
      </Form.Item>
    )
  }
}

export default TextInput;
