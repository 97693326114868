import React, { useState  } from 'react';

const Slides = ({ children }) => {
  const [page, setPage] = useState(0)
  const [transitioning, setTransitioning] = useState(false)

  const currentSlide = React.Children.toArray(children).filter((child) => { return !child.props.skip })[page]

  return (
    <div key={ page } className={`fade-in ${transitioning ? 'delayed-fade-out' : ''}`}>
      <>
        { React.cloneElement( currentSlide,
          {
            onNext: () => {
              setTransitioning(true)
              setTimeout(() => {
                setTransitioning(false)
                setPage(page + 1)
              }, (currentSlide.props.transitionTime || 400))
            }}
        )
        }
      </>
    </div>
  )
}

export default Slides;
