import React, { useState } from 'react';

import {  Row, Col, Button, Drawer, Form, Menu, Layout, Select, Input, Card } from 'antd';
import { Pie } from 'react-chartjs-2'
import { Link, useHistory, useParams } from "react-router-dom";

import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import { TextInput } from '../../components/pants-d';

import { Subnav, Actions, Links, NavLink } from '../layout/Subnav.js';
import { SubnetBuilder } from '../../components/SubnetBuilder';
import SearchTable from '../../components/SearchTable';
import SearchSelect from '../../components/SearchSelect';
import MultiSelect from '../../components/MultiSelect';

const { Address6, Address4 } = require('ip-address');

const { Content } = Layout;
const { Option } = Select;

const template = require('../../entities/subnet-template')
const customer = require('../../entities/customer')

const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 }

function SubnetTemplate(props) {
  const client = useApolloClient();
  const { id } = useParams();

  let history = useHistory();

  const [ editingTemplate, setEditingTemplate ] = useState(false);
  const [ showingHistory, setShowingHistory ] = useState(false);
  const [ editingIpAssignment, setEditingIpAssignment ] = useState();

  const { loading, error: recordError, data} = useQuery(template.GET_QUERY, {variables: { id: parseInt(id) }});

  const [updateRecord, { data: updateData }] = useMutation(template.update, {onCompleted: (v) => { console.log("completed") }} );
  const [createRecord, { data: createData }] = useMutation(template.create, { onCompleted: (template) => { 
    history.push(`/subnet-templates/${template?.createData?.id}`) 
  }} );

  const parsedTemplate = data?.subnetTemplate?.networkAddress ? (new Address4(data?.subnetTemplate?.networkAddress)) : null

  const [form] = Form.useForm();

  if (loading) {
    return(
      <div className="record-wrapper">
        <div className="record">
        </div>
      </div>
    )
  } else {
    return (
      <>
        <Subnav class="sub-nav">
          <Links>
            <NavLink> { data?.subnetTemplate?.name || data?.subnetTemplate?.networkAddress } </NavLink>
          </Links>
          <Actions>
            <NavLink onClick={(e) => { setEditingTemplate(true) }}> Add Template </NavLink>
            <NavLink> Delete Template </NavLink>
            <NavLink onClick={(e) => { setShowingHistory(true) }}> History </NavLink>
          </Actions>
        </Subnav>
        <Content style={{ marginTop: '15px', padding: '40px 25px' }}>
          <div className="record-wrapper">

            <SubnetBuilder 
              editing={ editingTemplate } 
              data={ data?.subnetTemplate }
              onCreate={ createRecord } 
              onClick={(template) => { 
                const url = template ? `/subnets/${template.id}` : `/subnets`
                history.push(url)
              }} />
            <Card bordered={true} style={{ margin: '0px 0px 20px'}} loading={loading}>
              <Form 
                style={{marginTop: '10px'}}
                layout="vertical"
                form={ form }
                name="subnetTemplate"
                initialValues={ data?.subnetTemplate || {} }
                onValuesChange={ (values) => {
                  const { customers, ...rest} =  { ...data?.subnetTemplate, ...values }
                  const customerIds = customers?.map(c => c.id)

                  updateRecord({ variables: {
                    customerIds,
                    ...rest, 
                    id: parseInt(id)
                  }}) 
                }}>

                <Row gutter={ rowGutter }>
                  <Col span={18}>
                    <Row gutter={rowGutter }>
                      <Col span={8}>
                        <TextInput name="name" />
                      </Col>
                      <Col span={8}>
                        <Form.Item 
                          label="Customers" 
                          name="customers"
                          normalize={(customers) => {
                            const result = customers?.map(c => {
                              return {
                                id: c.value,
                                name: c.label
                              }})
                            return result
                          }}>
                          <MultiSelect 
                            query={ template.customerQuery }
                            createMutation={ customer.create }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={ rowGutter }>
                      <Col span={8}>
                        <TextInput name="description" />
                      </Col>
                      <Col span={8}>
                        <TextInput name="supportGroups" />
                      </Col>
                    </Row>
                    <Row gutter={ rowGutter }>
                      <Col span={8}>
                        <TextInput name="defaultGateway" />
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Broadcast" name="broadcast">
                          { parsedTemplate?.endAddress().address }
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Content>
      </>
    )
  }
};

export default SubnetTemplate;
