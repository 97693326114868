import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import { Form, Layout, Button, Drawer } from "antd";

import SearchForm from "../../components/SearchForm";
import SearchAction from "../../components/SearchAction";
import SearchTable from "../../components/SearchTable";
import SiteForm from "../../views/sites/Form";
import SearchLayout from "../layout/Search.js";
import Entity from "../../entities";
import {PlusOutlined} from "@ant-design/icons";
import Info from "../info/Info";

const { Content } = Layout;

const site = Entity["site"];

const Sites = ({}) => {
  const history = useHistory();
  const { id } = useParams();
  const newRecord = id == "new";

  return (
    <SearchLayout section='Sites'>
      <SearchForm placeholder='Search sites' modelName={Sites} />

      <SearchAction 
        icon={ <PlusOutlined /> }
        url='/sites/new'
        style={{marginTop: '12px'}}>
        Add Site
      </SearchAction>
      <SearchTable
        style={{ marginTop: '70px' }}
        query={site.searchQuery}
        {...site.search}
        onAdd={() => {
          history.push("/sites/new");
        }}
        onClick={(record) => {
          history.push(`/sites/${record?.id}`);
        }}
        modelName="sites"
        emptyRender={<Info model="sites" />}
      />

      {(id || newRecord) && (
        <Drawer
          title={newRecord ? `Add site` : `Update site`}
          width={520}
          closable={false}
          visible={true}
          onClose={() => {
            history.push("/sites");
          }}
        >
          <SiteForm id={id} />
        </Drawer>
      )}
    </SearchLayout>
  );
};

export default Sites;
