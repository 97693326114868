import React from 'react';
import { Breadcrumb } from 'antd';
import { CaretLeftFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const EntityBreadcrumb = ({ entity, customerName }) => {
    const history = useHistory();

    return (
        <>
            <Breadcrumb style={{ marginTop: '8px', display: 'inline-block', fontSize: '18px', color: '#55738b' }}>
                <Breadcrumb.Item>
                    <a
                        style={{ color: '#55738b' }}
                        onClick={() => history.push(`/${entity.toLowerCase()}`)}
                    >
                        <CaretLeftFilled style={{ display: 'inline-block', fontSize: '12px', lineHeight: '0px', verticalAlign: 'text-top', marginLeft: '-16px', paddingTop: '1px', marginRight: '4px' }} />
                    </a>
                    <a
                        onClick={() => history.push(`/${entity.toLowerCase()}`)}
                        style={{ color: '#55738b', fontWeight: '500', fontSize: '17px', lineHeight: '18px' }}
                    >
                        {entity}
                    </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a style={{ color: '#55738b', fontWeight: '500', fontSize: '17px', lineHeight: '18px', letterSpacing: '0.1em' }}>
                        {customerName}
                    </a>
                </Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};

export default EntityBreadcrumb;