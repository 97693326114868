import React from 'react'
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import RemoveRecordButton from '../../lib/threeDotRemoveButton';

const searchQuery = loader('./queries/search.graphql')  
const getQuery = loader('./queries/get.graphql')  

const create = loader('./mutations/create.graphql')  
const update = loader('./mutations/update.graphql')  
const destroy = loader('./mutations/destroy.graphql')  

const RemoveDatacenterLink = ({ record }) => {

  return (
    <div onClick={e => e.stopPropagation()}>
      <RemoveRecordButton 
        record={record} 
        mutation={destroy}
        mutationVariables={{id: record?.id}}
        menuItems={["remove"]} 
        entityName="Datacenter"
        entityTitle="Datacenter"
        redirectPath={`/datacenters`}
      />
    </div>

  )
}


const search = {
  columns:  [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
    { title: '', width: 100, key: 'remove', render: provider =>  <RemoveDatacenterLink record={ provider }/>}
  ]
}

const form = {
  fields: [
    ["name", {}]
  ]
}

const importer = {
  title: 'Data Centers',
  copy: 'Data Centers in Lightmesh are specialized facilities where computer servers, networking equipment, and data storage systems are housed. Lightmesh assists in efficiently managing and organizing IP addresses within these data centers. It helps allocate and monitor IP addresses for devices and services, ensuring smooth and organized network operations within the data center environment.',
}

export { search, form, searchQuery, getQuery, create, update, destroy, importer };
