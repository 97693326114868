import React from 'react';
import SearchTable from '../../components/SearchTable';
import { Row, Col } from 'antd'
import { GET_ACTIVE_USERS_BY_DAY, GET_ACTIVE_USERS_BY_MONTH, GET_ACTIVITY_SUMMARY } from '../../entities/api';

function toLocaleUTCDateString(date, locales, options) {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
}



const Activity = () => {

  return (
    <Row gutter={ 24 } style={{ marginTop: '40px' }}>
      <Col span={ 8 }>
        <h2>Active Users By Day</h2>
        <SearchTable
          style={{ marginTop: '0px' }}
          query={ GET_ACTIVE_USERS_BY_DAY }
          rowKey="date"
          columns={[
            { title: 'Date', dataIndex: 'date', key: 'date', width: '14em', render: (label) => {
              if (label) {
                const d = new Date(parseInt(label))
                return (<p>{ toLocaleUTCDateString(d,undefined, { month: 'long', day: 'numeric', year: 'numeric' }) }</p>)
              }
            }},
            { title: 'Active Users', dataIndex: 'count', key: 'value', width: '14em' },
          ]}
        />       </Col>
      <Col span={ 8 }>
        <h2>Active Users By Month</h2>
        <SearchTable
          style={{ marginTop: '0px' }}
          query={ GET_ACTIVE_USERS_BY_MONTH }
          rowKey="createdAt"
          columns={[
            { title: 'Date', dataIndex: 'label', key: 'month', width: '14em', render: (label) => {
              if (label) {
                const d = new Date(parseInt(label))
                return (<p>{ toLocaleUTCDateString(d, undefined, { month: 'long', year: 'numeric' }) }</p>)
              }
            }},
            { title: 'Active Users', dataIndex: 'value', key: 'value', width: '14em' }
          ]}
        />
      </Col>
      <Col span={ 8 }>
        <h2> 10 subnet requests</h2>
        <SearchTable
          style={{ marginTop: '0px' }}
          query={ GET_ACTIVITY_SUMMARY }
          columns={[
            { title: 'Date', dataIndex: 'month', key: 'month', width: '14em', render: (month) => {
              if (month) {
                const d = new Date(parseInt(month))
                return (<p>{ toLocaleUTCDateString(d, undefined, { month: 'long', year: 'numeric' }) }</p>)
              }
            }},
            { title: 'Email', dataIndex: 'email', key: 'email', width: '14em' },
            { title: 'IP / Subnet Request Count', dataIndex: 'count', key: 'count', width: '14em' }
          ]}
        />
      </Col>
    </Row>
  )
}

export default Activity;
