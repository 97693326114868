import { loader } from 'graphql.macro';

const CURRENT_USER_QUERY = loader('./queries/currentUser.graphql')  
const CURRENT_ORGANIZATION_QUERY = loader('./queries/currentOrganization.graphql')  

const LOGIN_MUTATION = loader('./mutations/login.graphql')  
const LOGOUT_MUTATION = loader('./mutations/logout.graphql')  
const HIDE_WELCOME_MUTATION = loader('./mutations/hideWelcome.graphql')  
const SET_ORGANIZATION_MUTATION = loader('./mutations/setOrganization.graphql')  
const REGISTRATION_MUTATION = loader('./mutations/register.graphql')  
const RESET_PASSWORD_MUTATION = loader('./mutations/resetPassword.graphql')  
const FORGOT_PASSWORD_MUTATION = loader('./mutations/forgotPassword.graphql')  
const SEED_MUTATION = loader('./mutations/seed.graphql')  
const GENERATE_KEY_MUTATION = loader('./mutations/generateKey.graphql')  
const REVOKE_KEY_MUTATION = loader('./mutations/revokeKey.graphql')  

module.exports = { 
  CURRENT_USER_QUERY, 
  CURRENT_ORGANIZATION_QUERY, 
  REGISTRATION_MUTATION, 
  RESET_PASSWORD_MUTATION, 
  FORGOT_PASSWORD_MUTATION, 
  LOGIN_MUTATION, 
  HIDE_WELCOME_MUTATION, 
  LOGOUT_MUTATION,
  GENERATE_KEY_MUTATION,
  REVOKE_KEY_MUTATION,
  SET_ORGANIZATION_MUTATION
};
