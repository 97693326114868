import React, { useState, useEffect } from 'react';

import {  Row, Col, Menu, Layout, Breadcrumb, } from 'antd';
import { useParams } from "react-router-dom";
import RecordLayout from '../layout/Record';
import { Link } from "react-router-dom";

import { Subnav } from '../layout/Subnav.js';

import Profile from './Profile.js';
import History from './History.js';
import SideLayout from '../layout/SideLayout';
import Notification from './Notification.js';


const { Content } = Layout;


const menuStyles={
  fontSize: '15px',
  padding: '0px 0px 0px',
  lineHeight: '0px',
  textAlign: 'text-middle'
}

const Account = () => {
  const { section } = useParams();

  const breadCrumbStyles={marginTop: '8px', display: 'inline-block', fontSize: '18px', color: '#336b99'};

  return (
    <RecordLayout section="Account">
      <SideLayout
        links={[
          {url: '/account/profile', title: 'My Profile'},
          {url: '/account/history', title: 'History'},
          {url: '/account/notification', title: 'Notifications'},
        ]}>
          { (!section || section == 'profile') && <Profile />}
          { section == 'history' && <History />}
          { section == 'notification' && <Notification />}
          
      </SideLayout>
    </RecordLayout>
  )
};

export default Account
