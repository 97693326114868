import React from 'react';
import {  Form, Button } from 'antd';

class Submit extends React.Component {

  render () {
    const { name, label, ...props } = this.props; 

    return (
      <Form.Item> 
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} {...props}>
          { label || 'Submit' }
        </Button>
      </Form.Item>
    )
  }
}

export default Submit;
