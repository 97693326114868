import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Button, Form, Input, Space, Alert, Drawer, Divider, Col } from 'antd';
import { useQuery, useMutation } from "@apollo/client";

import { TextInput, TextArea, SearchSelect, Select } from '../../components/pants-d';

const { Option } = Select;

const { searchQuery, ADD_CUSTOMERS_TO_SUBNET, create } = require("../../entities/customer");
const subnet = require("../../entities/subnet");


const CustomerSubnetForm = ({ onCreate, onClose }) => {
  const { model, subnetId, supernetId } = useParams();

  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [alertStatus, setAlertStatus] = useState(null);
  const [errors, setErrors] = useState([])
  const errorContainerRef = useRef(null);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  const [form] = Form.useForm();

  const { data: queryResults, refetch } = useQuery(searchQuery, { variables: { search: '', offset: 0, limit: 50 } })

  const { loading, error:dataError, data:subnetData} = useQuery(subnet.GET_QUERY, { variables: { id: parseInt(subnetId) } });

  const [createRecord, { data: createData, error: createErrors }] = useMutation(create, {
    onCompleted: (e) => {
      setAlertStatus(true),
        setTimeout(() => {
          setAlertStatus(false);
        }, 7000);
      setShowForm(false);
    },
    onError: e => {
      setErrors(e?.graphQLErrors.map(error => error.message) || [e.message]);
      console.log(e)
    },
    update(cache, { data }) {
      const existingCustomers = cache.readQuery({ query: searchQuery, variables: { search: "", offset: 0, limit: 50 } })
      cache.writeQuery({
        query: searchQuery,
        variables: { search: "", offset: 0, limit: 50 },
        data: {
          customers: {
            count: (existingCustomers?.count || 0) + 1,
            results: [...(existingCustomers?.customers?.results || []), data.createCustomer]
          }
        }
      })
    }
  });

  const [addCustomersToSubnet, { data, error }] = useMutation(ADD_CUSTOMERS_TO_SUBNET, {
    onCompleted: onClose,
    onError: (e) => { console.log(e) },
    update(cache, { data }) {

      const searchVariables = { search: "", offset: 0, limit: 50, subnetId: parseInt(subnetId || supernetId) }
      const existingCustomers = cache.readQuery({ query: searchQuery, variables: searchVariables })

      cache.writeQuery({
        query: searchQuery,
        variables: searchVariables,
        data: {
          customerGroups: {
            count: existingCustomers?.count + 1,
            results: [...(existingCustomers.results || []), ...data.addCustomersToSubnet]
          }
        }
      })
    }
  })

  const handleAddCustomerClick = () => {
    setShowForm(true);
  };

  const handleAddCustomerFormCancel = () => {
    setShowForm(false);
  };

  if (createErrors) {
    const fieldErrors = createErrors.graphQLErrors
      ?.filter(e => e.extensions?.exception?.errors?.[0]?.path)
      .map(e => {
        return {
          name: e.extensions?.exception?.errors?.[0]?.path,
          errors: [e.extensions?.exception?.errors?.[0]?.message]
        }
      })
    if (fieldErrors) {
      form.setFields(fieldErrors)
    }
  }


  return (
    <Drawer
      title="Manage customers"
      onClose={onClose}
      width={520}
      visible={open}>
      <Form
        layout="vertical"
        form={form}
        name="customer"
        initialValues={{}}
        onFinish={({ customer }) => {
          const [customerId, customerName] = customer.split('|');

          addCustomersToSubnet({ variables: { id: parseInt(subnetId || supernetId), customerIds: [parseInt(customerId)], customerName: customerName, networkAddress: subnetData.subnet?.networkAddress, zoneId: subnetData.subnet?.zone?.id  } })

        }} >

        {alertStatus && (
          <Alert
            message="New Customer Added Successfully! Open Customer DropDown to Select"
            type="success"
            showIcon
            style={{ marginBottom: '16px' }}
          />
        )}

        {showForm ? (
          <Form
            layout="vertical"
            form={form}
            name="contact"
            onFinish={(values) => {
              createRecord({ variables: { name: values.name, addresses: [{ line1: values.line1, line2: values.line2, line3: values.line3, line4: values.line4, municipality: values.municipality, postalCode: values.postalCode, province: values.province, country: values.country }] } })
            }} >
            <div ref={errorContainerRef}>
              {(errors?.length > 0) && (
                <>
                  {errors.map((errorMessage, index) => (
                    <Alert key={index} type="error" message={errorMessage} />
                  ))}
                  <br />
                </>
              )}
            </div>
            <TextInput name="name" required />
            <h3>Address</h3>
            <div style={{ padding: '15px 20px', marginBottom: '20px', background: "#F8F8F8" }}>
              <Row gutter={12}>
                <Col span={24}>
                  <TextInput size="small" allowClear={false} name="line1" label="Street Address Line 1" />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TextInput size="small" allowClear={false} name="line2" label="Street Address Line 2" />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <TextInput size="small" allowClear={false} name="municipality" label="City" />
                </Col>
                <Col span={12}>
                  <TextInput size="small" allowClear={false} name="province" label="State / Province" />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <TextInput size="small" allowClear={false} name="country" label="Country" />
                </Col>
                <Col span={12}>
                  <TextInput size="small" allowClear={false} name="postalCode" label="Postal / Zip Code" />
                </Col>
              </Row>
            </div>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button type="secondary" onClick={handleAddCustomerFormCancel}>
                Cancel
              </Button>
            </Space>
          </Form>
        ) : (
          <Select
            label="Customers"
            name="customer"
            placeholder="Select / Create a Customer"
            required
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="primary"
                    onClick={handleAddCustomerClick}
                  >
                    Add a new Customer
                  </Button>
                </Space>
              </>
            )}
          >
            {queryResults?.customers?.results.map((customer) => (
              <Option key={customer.id} value={`${customer.id}|${customer.name}`}>{customer.name}</Option>
            ))}
          </Select>
        )}
        {!showForm && (
          <div>
            <Button type="secondary" onClick={(e) => onClose()} style={{ float: 'right', marginLeft: '20px' }}>
              Cancel
            </Button>
            <Button style={{ float: 'right', marginLeft: '20px', backgroundColor: '#447aa7', color: '#ffffff'}} htmlType="submit">
              Submit
            </Button>
          </div>
        )}
      </Form>
    </Drawer>
  )
}

export default CustomerSubnetForm;
