import React, { useRef } from 'react';
import { Row, Col, Layout, Button, Form, Menu, Table } from 'antd';

const { Content } = Layout;

import { Area } from '../../components/Charts';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

const API = require('../../entities/api')

const TidalSummary = () => {

  const reportRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
  });

  return (
    <Content style={{ padding: '100px', overflowY: 'auto'}}>
      
      <div ref={reportRef} style={{ position: 'relative', width: '1200px', margin: '0 auto', marginBottom: '40px', background: '#FFFFFF', padding: '120px 60px' }}>
        <a style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '16px' }} onClick={() => {handlePrint()}}><PrinterOutlined/> Print</a>
        <Row gutter={ 10 }>
          <Col span={ 11 } offset={ 1 }>
            <h3 style={{ fontSize: '20px', marginBottom: '20px'}}>Registrations</h3>
            <div style={{ background: '#FFFFFF', padding: '40px 20px 40px' }}>
              <Area pagination={{ pageSize: 12 }} query={ API.GET_NEW_USERS_BY_MONTH }/>
            </div>
          </Col>
          <Col span={ 11 } >
            <h3 style={{fontSize: '20px', marginBottom: '20px'}}>Monthly Returning Users</h3>
            <div style={{ background: '#FFFFFF', padding: '40px 20px 40px' }}>
              <Area pagination={{ pageSize: 12 }} query={ API.GET_RETURNING_USERS_BY_MONTH }/>
            </div>
          </Col>
        </Row>
        <Row gutter={ 10 } style={{ marginTop: '40px' }}>
          <Col span={ 11 } offset={ 1 }>
            <h3 style={{fontSize: '20px', marginBottom: '20px'}}>Daily Active Users ( Average )</h3>
            <div style={{ background: '#FFFFFF', padding: '40px 20px 40px' }}>
              <Area 
                pagination={{ pageSize: 12 }} 
                valueFn={(d) => { return d?.value.toFixed(1) }}
                query={ API.GET_AVERAGE_DAILY_ACTIVE_USERS_BY_MONTH }/>
            </div>
          </Col>
          <Col span={ 11 } >
            <h3 style={{fontSize: '20px', marginBottom: '20px'}}>Monthly Active Users</h3>
            <div style={{ background: '#FFFFFF', padding: '40px 20px 40px' }}>
              <Area pagination={{ pageSize: 12 }} query={ API.GET_ACTIVE_USERS_BY_MONTH }/>
            </div>
          </Col>
        </Row>
      </div>
    </Content>
  )
}

export default TidalSummary;
